<mat-toolbar class="bg-dark-green">
  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()">
      <img class="nav-item dropdownImg" src="{{ rootsLogoURL }}" />
      <i class="fa fa-caret-down" style="color: white"></i>
    </button>
    <span class="menu-spacer"></span>
    <div style="padding-left: 4em">
      <div *ngFor="let item of items" class="subnav">
        <a
          mat-button
          class="nav-item nav-link link-active subnavbtn"
          routerLink="{{item.link}}"
          routerLinkActive="active"
          matTooltip="{{item.toolTip}}"
          matTooltipPosition="below"
        >
          <i class="material-icons">{{item.icon}}</i>
          <!-- <i class="fa fa-caret-down"></i> -->
        </a>
        <div *ngIf="item.subNavItems.length > 0" class="subnav-content">
          <a *ngFor="let sub of items.subNavItems" class="pad" routerLink="{{sub.link}}">{{sub.name}}</a>
        </div>
      </div>
    </div>
    <!-- <app-searchbar
      style="flex-grow: 1; margin-right: 64px; margin-left: 5px"
    ></app-searchbar> -->
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav style="z-index: 100; min-width: 10em; min-height: 90vh">
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>