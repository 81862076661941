<div class="about-tab compact">
  <div class="device information" *ngFor="let device of devices">
    <div class="text small-text device-when superbold">
      {{device.name}}
    </div>
    <!-- <div class="text device-text">{{device.text}}</div> -->
  </div>
  <div>
    <div class="device information" *ngIf="devices.length > 0">
      <div class="text small-text device-when superbold">
        Name: N/A
        <hr>
        <br>
        DeviceId: N/A
        <hr>
        <br>
      </div>
      <!-- <div class="text device-text">{{device.text}}</div> -->
    </div>
    <div>
</div>