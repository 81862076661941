import { Component, OnInit, Injectable } from '@angular/core';
import { AppUserDevice } from '../../../../shared/interfaces/app/app-user-device';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
@Injectable()
export class DeviceComponent implements OnInit {
  devices: AppUserDevice[] = [];
  constructor(
    ) {
  }

  ngOnInit() {
  }
}
