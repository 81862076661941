<div mat-dialog-content>
  <div class="grid">
    <div *ngIf="customerGroups.length > 0;">
      <p class="list-inline-item font-weight-bold text-uppercase">Manage permissions for {{selectedGroup.name}}</p>
      <mat-form-field style="width: inherit;" style="float: right;">
        <mat-select matInput placeholder="Select other group" (selectionChange)="groupChanged($event)" name="name">
          <mat-select-trigger>{{selected}}</mat-select-trigger>
            <mat-option *ngFor="let group of customerGroups" [value]="group.id">
              <p><b>{{group.name}} </b></p>
            </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <hr>
      <mat-card class="col-6 card" style="float: left;">
        <mat-card-header>
          <div style="margin: auto;">
            <mat-card-title>
              <p class="list-inline-item font-weight-bold text-uppercase">Members</p>
            </mat-card-title>
            <mat-card-subtitle>
              <ul *ngFor="let member of members; index as i;">
                {{member.userName}}
                <br><hr>
              </ul>
            </mat-card-subtitle>
          </div>
        </mat-card-header>
      </mat-card>
      
      <mat-card class="col-6 card" style="float: left;">
        <mat-card-header>
          <div style="margin: auto;">
            <mat-card-title>
              <p class="list-inline-item font-weight-bold text-uppercase">Scouts</p>
            </mat-card-title>
            <mat-card-subtitle>
              <ul *ngFor="let scout of scouts; index as i;">
                {{scout.scoutCode}}
                <br><hr>
              </ul>
            </mat-card-subtitle>
          </div>
        </mat-card-header>
      </mat-card>
      <div class="col-12">
        <mat-form-field style="width: inherit;">
          <mat-select matInput placeholder="Select other user" required name="name">
              <mat-option *ngFor="let user of users" [value]="user.name">
                <p><b>{{user.name}} </b> ({{user.unit}})</p>
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <br>
  <hr>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <button class="btn btn-secondary scanner" style="right: 0px; position: absolute;"
        mat-raised-button color="primary" (click)="okayClick()">
          <p class="text btn-text">
            OKAY
          </p>
    </button>
</div>