import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ConstantService } from '../..';

@Component({
  moduleId: module.id,
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  source: string;
  @Input() user: any;
  public currentLanguage: any;
  public languageOptions = [
    { key: 'English-US', value: 'en' },
    { key: 'Nepali', value: 'ne' },
    { key: 'Russian', value: 'ru' }
  ];
  public width = '80%';
  public target = '.control-section';
  public userDetails: any;
  public userPermission: any;

  constructor(
    private _authService: AuthenticationService,
    private _constService: ConstantService
  ) {
  }

  ngOnInit() {
    this.userDetails = this._constService.userDetails;
    this.userPermission = this._constService.permissionType;
    this.user = this._authService.userInfo;
  }
}
