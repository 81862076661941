import { Component, Input, OnInit } from '@angular/core';
import { SnackService } from '../../services/extra/snack.service';

export interface CardListEntity {
  title: string;
  subtitle: string
  url: string
  content: string;
}

@Component({
  selector: 'app-roots-card-list',
  templateUrl: './roots-card-list.component.html',
  styleUrls: ['./roots-card-list.component.scss']
})
export class RootsCardListComponent implements OnInit {
  _entities: CardListEntity[] = [];
  @Input('entities') 
  set entities(entities: CardListEntity[]) {
    if (entities.length > 0) {
      this._snack.displaySuccess('Entity list data found');
    } else {
      this._snack.displayError('No entity list data found');
    }
    this._entities = entities;
  }
  get entities() {
    return this._entities;
  }

  
  constructor(private _snack: SnackService) { }

  ngOnInit(): void {
  }

}
