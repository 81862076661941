import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Utility } from '../classes/utility';
import { environment } from '../../../environments/environment';
import { HttpResult } from '../interfaces/http-result';
import { UtilityRun } from '../classes/utility-run';

@Injectable({
	providedIn: 'root'
})
export class UtilityService {

	constructor(
		private _http: HttpClient,
		private _constService: ConstantService,
	) { }

	public getUtilites(): Observable<Utility[]> {
		const params: any = {
			'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
			'content-type': "application/json",
		};
		const URL = `${this._constService.CONST.URL}api/app/utility`;

		return this._http.get<HttpResult<Utility[]>>(URL, { params: params }).pipe(
			map((resp: any) => {
				return resp.data;
			}),
			catchError(this._constService.handleErrorObservable)
		);
	}

	public runUtilityRun(utilityRun: UtilityRun): Observable<UtilityRun> {
		const params: any = {
			'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
			'content-type': "application/json",
		};

		const URL = `${this._constService.CONST.URL}api/app/utilityrun/run`;

		return this._http.post<HttpResult<UtilityRun>>(URL, utilityRun,
			{ params: params }).pipe(
				map((resp: any) => {
					return resp.data;
				}),
				catchError(this._constService.handleErrorObservable)
			);
	}
}
