<div *ngIf="notes; else noNotes" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left">
    <mat-card>
        <h3 class="text label-header-text">
            <mat-icon>speaker_notes</mat-icon>
            Notes ({{notes.length}})
        </h3>
        <button (click)="createNote()" style="float: right;" mat-icon-button><mat-icon>add</mat-icon></button>
        <hr />
        <div style="max-height: 50vh; overflow: auto" *ngIf="notes.length > 0; else emptyNotes">
            <div *ngFor="let note of notes; let i = index" class="text label-text item-card">
                <mat-card (click)="clickEvent(note)">
                    <p class="text label-text" style="margin: 0px; float:left">
                       {{i + 1}}. {{ note.basicName }}
                    </p>
                    <br>
                    <p class="text small-text" style="margin: 0px; float: right;">
                        <b>{{ note.createdAt | date: 'short'}}</b>
                    </p>
                    <p class="text small-text" style="margin: 0px">
                        {{ note.basicDescription }}
                    </p>
                    <!-- <br> -->
                    <p class="text small-text" style="margin: 0px;
                     text-align: center; 
                     padding: 10px; 
                     padding-top: 20px;
                     max-height: 100px; 
                     overflow-y: auto;">
                        {{ note.text }}
                    </p>
                    <br>
                    <div *ngIf="note.isExpanded">
                        <hr/>
                        <p class="text small-text" style="margin: 0px; float: left;">
                            Username: {{ note.username }}
                        </p>
                        <p class="text small-text" style="margin: 0px; float: right;">
                            Created: {{ note.createdAt | date: 'short'}}
                        </p>
                        <br>
                        <p class="text small-text" style="margin: 0px; float: left;">
                            Issue Value: {{ note.issueValue }}
                        </p>
                        <p class="text small-text" style="margin: 0px; float: right;">
                            Updated: {{ note.updatedAt | date: 'short'}}
                        </p>
                        <br>
                        <p class="text small-text" style="margin: 0px;">
                            Severity: {{ note.severity }}
                        </p>
                    </div>
                    <button href="javascript:void()" color="warn" style="position: absolute; right: 0px; top: 0px;" mat-icon-button
                        (click)="note.isExpanded = !note.isExpanded">
                        <mat-icon *ngIf="!note.isExpanded" class="mat-18">expand_more</mat-icon>
                        <mat-icon *ngIf="note.isExpanded" class="mat-18">expand_less</mat-icon>
                    </button>
                </mat-card>
            </div>
        </div>
        <ng-template #emptyNotes>
            <p class="text small-text" style="margin: 0px;">
                No notes have been found for this entity.
            </p>
        </ng-template>
    </mat-card>
</div>
<ng-template #noNotes>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-4" style="float: left">
        <mat-card>
            <h3 class="text label-header-text">
                Click to add new note
            </h3>
            <button (click)="createNote()" style="float: right;" mat-icon-button><mat-icon>add</mat-icon></button>
            <hr />
            <p class="text small-text" style="margin: 0px;">
                No data found.
            </p>
        </mat-card>
    </div>
</ng-template>