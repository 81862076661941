import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '@app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceComponent } from './components/device/device.component';
import { AboutComponent } from './components/about/about.component';
import { MatModule } from '../../core';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TrainingComponent } from './components/training/training.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { LiquidTitleComponent } from './components/liquid-title/liquid-title.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatModule,
    // CKEditorModule
  ],
  declarations: [
    SettingsComponent,
    DeviceComponent,
    AboutComponent,
    UserProfileComponent,
    TrainingComponent,
    LiquidTitleComponent],
    bootstrap: [
      SettingsComponent, 
      DeviceComponent, 
      AboutComponent, 
      UserProfileComponent
    ]
})
export class SettingsModule { }
