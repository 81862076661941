<div mat-dialog-content>
    <h3 class="text label-header-text">
      Prodscoutlog Db search results:
    </h3>
    <div *ngFor="let log of data | sort: 'name'" class="cub text label-text item-card">
      <div (click)="navigateLog(log)">
        {{ log.name }}
        <p *ngIf="log.modelNumber; else noModelNumber" 
        class="text small-text" style="color: green; position: inherit; float: right;">
          {{log.modelNumber}}</p>
        <ng-template #noModelNumber>
          <p class="text small-text" style="color: red;  position: inherit; float: right;">
            Model Number Not Found
          </p>
        </ng-template>
        <p class="text label-text" style="right: 2vw;
        top: 2vh;">
          {{ log.time | date: "yyyy-MM-dd HH:mm:ss a" }}
        </p>
      </div>
      <div>
        <hr>
        <h6 class="text label-text">
          Sensors tested with Device:
        </h6>
        <p *ngFor="let sensor of log.sensors" class="text small-text"
          [ngStyle]="{'color': !sensor.testSensor? 'green' : 'red'}" style="padding-left: 15px">
          - {{ sensor.name }}
        </p>
      </div>
    </div>
  </div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" (click)="onNoClick()" color="primary">Okay</button>
</div>