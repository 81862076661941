import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConstantService } from "..";
import { environment } from "../../../environments/environment";
import { ScoutLogExtended } from "../interfaces/scout-log-extended";
import { SnackService } from "./extra/snack.service";

@Injectable()
export class ScoutLogDialogService {
request: Request;
loading: boolean = true;
logs: ScoutLogExtended[];
logById: ScoutLogExtended;
httpHeaders: HttpHeaders = new HttpHeaders();
private functionURL = environment.scoutLogApiUrl + "ScoutLog";
private cubFunctionURL = environment.scoutLogApiUrl + "CubScoutLog";
private searchURL = environment.scoutLogApiUrl + "SearchLog";
    constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _snack: SnackService
    ) { }

    public updateValidated(id: string, serial: string) {
        // params
      const url = this.functionURL + '/validate/' + id;
      const headers = new Headers();
      let params: URLSearchParams = new URLSearchParams();
      params.set('serial', serial);
      // params.set('validatedBy', localStorage.getItem('userEmail'));
      headers.set("Content-Type","application/json");
      headers.set("x-functions-key", this._constService.CONST.pvtKey);

      this.request = new Request(
          url,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify({
              serial: serial,
              validatedBy: localStorage.getItem('userEmail')
          })
        }
      );
      fetch(this.request)
        .then((response) => {
            this._snack.displaySuccess(response.status.toString());
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }

    public validateCubScoutLog(id: string, serial: string) {
        // params
      const url = this.cubFunctionURL + '/validate/' + id;
      const headers = new Headers();
      let params: URLSearchParams = new URLSearchParams();
      params.set('serial', serial);
      // params.set('validatedBy', localStorage.getItem('userEmail'));
      headers.set("Content-Type","application/json");
      headers.set("x-functions-key", this._constService.CONST.pvtKey);
  
      this.request = new Request(
          url,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify({
              serial: serial,
              validatedBy: localStorage.getItem('userEmail')
          })
        }
      );
      fetch(this.request)
        .then((response) => {
            this._snack.displaySuccess(response.status.toString());
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }
}