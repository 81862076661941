<div class="card col-md-6 mt-3 mx-auto">
  <div id="body" class="card-body">
    <h4 class="card-title text-center">
      <i class="far fa-3x fa-user-circle text-muted color-gray"></i>
    </h4>
    <div #container class="root-container"></div>
    <div class="control-section root-container">
    </div>
    <div class="text h3 uppercase id" style="color: #69AA41">{{'welcome'}}, {{
      userDetails.basicName }}</div>
    <br>
    <hr>
    <div class="text-truncate"><b>{{'email'}}:</b>
      <p class="set-text">{{ user.userName }}</p>
    </div>
    <div class="text-truncate"><b>CustomerId:</b>
      <p class="set-text">{{ userDetails.customerId }}</p>
    </div>
    <div class="row">
      <div class="text-truncate col-md-6"><b>Type:</b>
        <p class="set-text">{{ userPermission.basicName }}</p>
      </div>
      <div class="text-truncate col-md-6"><b>Language:</b>
        <p class="set-text">{{ userDetails.languageTag }}</p>
      </div>
    </div>
  </div>
</div>