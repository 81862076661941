<section>
    <mat-table
      #sort="matSort"
      matSort
      [dataSource]="dataSource"
      class="main example-container"
      multiTemplateDataRows>

    <!--Primary Table Columns on main Datasource-->
      <ng-container *ngFor="let columnName of tableCols" matColumnDef="{{columnName}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{columnName | titlecase}}
        </mat-header-cell>
        <mat-cell *matCellDef="let entity">
          {{ entity[columnName] }}
        </mat-cell>
      </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell *matCellDef="let entity" [attr.colspan]="tableCols.length" 
        [@detailExpand]="checkExpanded(entity) ? 'expanded' : 'collapsed'">
            <div class="example-element-detail">
              <div class="example-element-description" 
              *ngIf="entity.additionalData; else noAdditionalData">
                <div *ngIf="entity.additionalDataIsArray; else notArray">
                  {{entity.additionalData}}
                </div>
                <ng-template #notArray>
                  {{entity.additionalData[entity.additionalProperty]}}
                </ng-template>
              </div>
              <ng-template #noAdditionalData>
                <div class="example-element-description">
                  No additional entity data
                </div>
              </ng-template>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="tableCols"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableCols" matRipple 
            class="example-element-row"
            [class.example-expanded-row]="checkExpanded(row)"
            (click)="pushPopElement(row)">
      </mat-row>
      <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
                [@detailExpand]="checkExpanded(row) ? 'expanded' : 'collapsed'"></mat-row>
    </mat-table>
  
    <mat-paginator [pageSize]="20" [pageSizeOptions]="[1, 2, 3, 5, 10, 20]" showFirstLastButtons>
    </mat-paginator>
</section>
