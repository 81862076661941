import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { NavItem} from '../nav-item.interface';
import { NavService } from './nav.service';
import { Subscription } from 'rxjs';
  
@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuListItemComponent implements OnInit, OnDestroy {
  expanded: boolean = false;
  currentActiveTab: NavItem;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Output() selectedTabChange: EventEmitter<any> = new EventEmitter();
  navSubscription: Subscription;
  constructor(
        public navService: NavService,
        public router: Router
        ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navSubscription = this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  ngOnDestroy() {
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }
  }

  onItemSelected(item: NavItem) {
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  onItemNavigate(item: NavItem, $event) {
    console.log(item);
    this.selectedTabChange.emit($event);
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]).then(value => console.log(value));
      if (this.navService) {
        this.navService.closeNav();
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}

