import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatModule } from "../../core";
import { ScoutsComponent } from "./scouts.component";
import { ScoutsFirmwareComponent } from './scouts-firmware/scouts-firmware.component';
import { ScoutsRoutingModule } from "./scouts-routing.module";
import { ScoutsEditComponent } from "./edit/scouts-edit.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ScoutsRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    PerfectScrollbarModule,
    MatModule,
  ],
  declarations: [
    ScoutsComponent,
    ScoutsFirmwareComponent,
    ScoutsEditComponent
  ],
  providers: [],
})
export class ScoutModule {}
