import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataStorageService } from '@app/shared';
import { LocalStorageService } from '@app/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatModule, MsalService } from '.';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatModule
  ],
  exports:[
    HttpClientModule,
    RouterModule,
    MatModule
  ],
  providers: [
    DataStorageService,
    LocalStorageService,
    AuthenticationService,
    MsalService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentMod?: CoreModule
  ) {
    if (parentMod) {
      // dont import the core mod anywhere else, services declared within this mod
      throw new Error(`${parentMod} has already been loaded. Import Core module in the AppModule only`);
    }
  }
}
