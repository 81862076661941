<div class="col-12" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <a class="list-inline-item font-weight-bold text-uppercase" routerLink="{{ url }}">{{ user.name }}
  </a>
  <mat-select style="width: fit-content;" matInput placeholder="Select User" required [(ngModel)]="user" name="name">
    <mat-option (click)="changeUser(user)" *ngFor="let u of all" [value]="user">
      {{ u.name }}
    </mat-option>
  </mat-select>
</div>
<div mat-dialog-content>
  <div class="col-6" style="float: left;">
    <mat-form-field style="min-width: -webkit-fill-available">
      <input matInput placeholder="User Email" [(ngModel)]="user.authUsername" disabled />
    </mat-form-field>
    <mat-form-field style="min-width: -webkit-fill-available">
      <input matInput placeholder="Customer Name" [(ngModel)]="user.customerName" disabled />
    </mat-form-field>
    <mat-form-field style="min-width: -webkit-fill-available">
      <input matInput placeholder="Description" [(ngModel)]="user.description" disabled />
    </mat-form-field>
  </div>
  <div class="col-6" style="float: left;">
    <mat-card-title>
      <p class="list-inline-item font-weight-bold text-uppercase">
        Groups ({{ user.userGroups.length }})
      </p>
      <button mat-raised-button (click)="addGroups()" style="float: right">
        Manage Groups
      </button>
    </mat-card-title>
    <mat-card-subtitle>
      <ul *ngFor="let group of user.userGroups | sort:'name'; index as i" (click)="pullUserGroup(group)">
        <a>{{ group.name }} {{group.entity ? "- " + group.entity.customerName : ""}}</a>
        <mat-progress-bar style="position: static; z-index: 1; left: 0; right: 0;" *ngIf="group.loading" mode="query">
        </mat-progress-bar>
        <div *ngIf="group.details" style="margin-left: 2vw">
          {{ group.details.basicName }} ({{ group.scouts.length }} Scout(s))
          <a style="color: blue; margin-left: 2vw" class="text small-text">
            {{ group.details.createdAt | date: "short" }} -
            {{ group.details.code }}
          </a>
          <div *ngIf="group.scouts">
            <div *ngFor="let scout of group.scouts  | sort:'scoutName'" style="margin-left: 4vw">
              {{ scout.scoutName }}
              <p style="color: green" class="text small-text">
                {{ scout.scoutId }}
              </p>
            </div>
          </div>
        </div>
        <hr />
      </ul>
    </mat-card-subtitle>
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary" mat-raised-button color="primary" (click)="okayClick()">
    Okay
  </button>
</div>