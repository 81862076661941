import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { map, mergeMap, tap } from "rxjs/operators";
import { ConstantService } from "../../shared/services/constant.service";
import { SnackService } from "../../shared/services/extra/snack.service";
import { IssueType } from "./enums/issue-type";
import { JiraIssue } from "./interfaces/jira-issue.interface";
import { AsyncSubject, forkJoin, Observable, Subject, throwError } from "rxjs";
import { AdminService } from "../../shared/services/admin.service";
import { RootsActionMenuItem, ActionType } from "../../shared";
import { AppService } from "../../shared/services/api/app.service";
import { ReportsService } from "../../shared/services/reports.service";

declare const window: any;

@Component({
  selector: "app-issues",
  templateUrl: "./issues.component.html",
  styleUrls: ["./issues.styles.scss"]
})
export class IssuesComponent implements OnInit {
  private jiraLocalDevelopmentUrl = "https://localhost:44358/api/jira";
  private jiraBugReportUrl =
    "https://industrialsmart.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-lgwqy6/b/3/9edb822e6c4db3635f27806476c088cb/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=30bfbe6a";
  private jiraFeatureReportUrl =
    "https://industrialsmart.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-lgwqy6/b/3/9edb822e6c4db3635f27806476c088cb/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9d859c5b";
  types: IssueType;
  startingScout = "0000C6418B6100340029300246614E45";
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  daysBack = 6;
  submitted = false;
  date = new Date(Date.now());
  jiraIssue: JiraIssue = {
    title: "",
    description: "",
    date: this.date,
  };
  toolTip =
    "Support page is the best! Too bad you can't visit any other page :P";
  title = "Support Landing page";
  projects = [
    {
      id: 0,
      name: "Internal Support Project",
      value: "IssueCollector_Internal",
    },
  ];
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Reset Selected",
      icon: "refresh",
      toolTip:
        "Remove selected scout.",
      type: ActionType.Create,
    },
  ];
  scouts: any[] = [];
  selectedScout: any;
  selectedProject = this.projects[0];
  loading = true;
  loadingIcon_1 = true;
  loadingIcon_2 = true;
  loadingIcon_3 = true;
  from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
  private editorSubject: Subject<any> = new AsyncSubject();
  constructor(
    private _http: HttpClient,
    private _adminService: AdminService,
    private _reportService: ReportsService,
    private _appService: AppService,
    private _constService: ConstantService,
    private _snack: SnackService
  ) {
  }

  ngOnInit() {
    //this._adminService.getAdminScout().subscribe(
    //   this._appService.getAppScoutWithFirmwareModel().subscribe(
    //   (results) => {
    //     this.scouts = results;
    //     this.loading = false;
    //   },
    //   throwError,
    //   () => {
    //     console.log("err has occurred");
    //   }
    // );
  }

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
  }

  onSubmit(): void {
    this.submitted = true;
    this.createIssue();
  }

  onChangeFile(file: any) {
    console.log(file);
  }

  loadingEvent($event) {
    this.loading = $event;
  }

  createIssue() {
    //grab paramaters required for creating issue from UI
    //Send paramaters to API that makes WebRequest to JIRA REST
    let createdIssue = null;
    createdIssue = this.jiraIssue;
    if (createdIssue) {
      const params: any = {
        "Content-Type": "application/json",
      };
      return this._http
        .post(this.jiraLocalDevelopmentUrl, createdIssue, {
          params: params,
        })
        .pipe(
          map((resp: any) => {
            return resp;
          }),
          tap(
            (data) =>
              this._constService.handleRequestToast(
                data,
                "Issue created in JIRA"
              ),
            (err) => {
              this._snack.displayError(
                "An error occurred when sending JIRA request"
              );
              console.log(err);
            }
          )
        );
    }
    this._snack.displayError("Provide JIRA paramaters");
  }

  getScout(scout: any){
    this.loading = true;
    const f = new Date(this.from);
    const ut = this._constService.convertDateUTC(f);
    const utc = this._constService.convertDateUTC(this.dateNow);

    // this._reportService.getAdminScoutReliability(ut.toString(), utc.toString()).subscribe(results => {
    //   this.loading = false;
    //   this.selectedScout = scout;
    //   console.log(results);
    //   this.title = `${scout.name} Data`
    // })
  }

  getJiraIssues(): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<any[]>(this.jiraLocalDevelopmentUrl, {
        params: null,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("Jira GET response: ", data))
      );
  }

  clickEvent(key: number) {
    switch (key) {
      case 1:
        this.loadingIcon_1 = !this.loadingIcon_1;
        break;
      case 2:
        this.loadingIcon_2 = !this.loadingIcon_2;
        break;
      case 3:
        this.loadingIcon_3 = !this.loadingIcon_3;
        break;
      default:
        break;
    }
    this.loading = !this.loading;
  }
}
