<div class="col-12" 
mat-dialog-title
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>
  <p class="list-inline-item font-weight-bold text-uppercase p-tag">
    Groups for: {{ user.name }}
  </p>
  <p class="p-tag">Auth username: {{ user.authUsername }}</p>
  <p class="text small-text p-tag">
    Clicking the 'X' to remove a group and '+' to add a group to this user
  </p>
  <mat-progress-bar
    style="position: static; z-index: 1; left: 0; right: 0;"
    *ngIf="loading"
    mode="query"
  >
  </mat-progress-bar>
</div>
<div mat-dialog-content>
    <div class="col-12">
      <div>
        <!-- <mat-card-title>
          <p class="list-inline-item font-weight-bold text-uppercase">Groups</p>
        </mat-card-title> -->

        <!--Current groups assigned-->
        <div class="grid">
            <mat-card class="col-6 card card-dialog-tag">
              <mat-card-header>
                <div style="margin: auto">
                  <mat-card-title>
                    <!-- *ngIf="user.userGroups" -->
                    <p class="list-inline-item font-weight-bold text-uppercase" >
                      Current ({{ mergedGroupDetails.length }})
                    </p>
                  </mat-card-title>
                  <mat-card-subtitle class="group-dialog-box">
                    <ul *ngFor="let group of mergedGroupDetails | sort:'name'; index as i">
                        <button
                        mat-icon-button
                        color="secondary"
                        matTooltip="Remove group from user"
                        matTooltipPosition="above"
                        (click)="removeUserFromGroupAsync(group)"
                        >
                        <mat-icon class="mat-18" style="color: red">clear</mat-icon>
                        </button>
                        <a
                            mat-button
                            matTooltip="Get scouts for group"
                            matTooltipPosition="above"
                            class="text label-text"
                            >{{ group.name }}</a
                        >
                        <mat-progress-bar
                          style="position: static; z-index: 1; left: 0; right: 0;"
                          *ngIf="group.loading"
                          mode="query"
                        >
                        </mat-progress-bar>
                      <hr />
                    </ul>
                  </mat-card-subtitle>
                </div>
              </mat-card-header>
            </mat-card>
    
            <!--Available groups to assign-->
            <mat-card class="col-6 card card-dialog-tag">
              <mat-card-header>
                <div style="margin: auto" *ngIf="mergedAvailableGroups">
                  <mat-card-title>
                    <p class="list-inline-item font-weight-bold text-uppercase">
                      Available ({{ mergedAvailableGroups.length }})
                    </p>
                  </mat-card-title>
                  <mat-card-subtitle class="group-dialog-box">
                    <!-- [ngStyle]= "'background-color': group.color? 'green' : 'red'" -->
                    <ul *ngFor="let group of mergedAvailableGroups | sort:'name'; index as i">
                      <button
                        mat-icon-button
                        color="secondary"
                        matTooltip="Add group to user"
                        matTooltipPosition="above"
                        (click)="addGroupToUserAsync(group)"
                        >
                        <mat-icon class="mat-18" style="color: green">add</mat-icon>
                        </button>
                        <a
                            mat-button
                            matTooltip="Get scouts for group"
                            matTooltipPosition="above"
                            class="text label-text"
                            >
                            {{
                                group.name
                            }}
                            </a
                        >
                        <mat-progress-bar
                        style="position: static; z-index: 1; left: 0; right: 0;"
                        *ngIf="group.loading"
                        mode="query"
                      >
                      </mat-progress-bar>
                      <hr />
                    </ul>
                  </mat-card-subtitle>
                </div>
              </mat-card-header>
            </mat-card>
        </div>
      </div>
    </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" 
  (click)="onNoClick()" color="warn" 
  style="max-width: min-content;">Cancel</button>
  <button
    style="max-width: min-content;"
    class="btn btn-secondary"
    mat-raised-button
    color="primary"
    (click)="okayClick()"
  >
    <p class="text btn-text">Okay</p>
  </button>
</div>
