<div id="earth-bg" class="forgot-container bg-light">
  <div class="forgot-box">
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h3 class="color-gray">Please wait... </h3>
          <br>
          <h4>Redirecting to Scout Dashboard</h4>
        </div>
      </div>
    </div>
  </div>
</div>
