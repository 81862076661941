<h3 mat-dialog-title> {{data.title}} </h3>
<p class="text small-text">Groups: {{targets.length}}</p>
<mat-progress-bar
  style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
  *ngIf="!groupsNotAssigned && !allGroups"
  mode="query"
>
</mat-progress-bar>
<button mat-dialog-title mat-icon-button color="primary" (click)="refreshCustomerGroups(); $event.preventDefault()"
  matTooltip="Refresh Users currently assigned groups" matTooltipPosition="above"
  style="float: right;">
  <mat-icon class="mat-18">refresh</mat-icon>
</button>
<div mat-dialog-content>
  <form class="user-form" #groupingForm="ngForm" (ngSubmit)="onSubmit($event)">
    <mat-grid-list cols="1" rowHeight="4:1">
      <div class="row" style="float: right;">
        <mat-label>
          <button mat-raised-button (click)="setAllCustomerGroups(); $event.preventDefault()" color="primary">All Customer Groups</button>
        </mat-label>
      </div>
      <br>

      <mat-grid-tile>
        <mat-form-field style="width: 50%;">
          <mat-select placeholder="Customer Groups" [formControl]="groups" (selectionChange)="selectedGrouped($event)" multiple
            [(ngModel)]="selected" [ngClass]="{'disabled': allChanged === true}">
            <mat-option (onSelectionChange)="change($event)" *ngFor="let allUG of groupsNotAssigned | sort:'name'" [value]="allUG" 
            [ngClass]="{'disabled': allChanged === true}">
              {{ allUG.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 50%;">
          <mat-select placeholder="All Groups" [formControl]="groupsTwo" (selectionChange)="selectedGrouped2($event)" multiple
            [(ngModel)]="selectedTwo" [ngClass]="{'disabled': customerChanged === true}">
            <mat-option (onSelectionChange)="changeAll($event)" *ngFor="let allUG of allGroups | sort:'name'" [value]="allUG"
            [ngClass]="{'disabled': customerChanged === true}">
              {{ allUG.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <hr>
    <!-- <mat-label mat-dialog-title *ngIf="targets.length > 0">
      <button mat-raised-button type="submit" [disabled]="!groupingForm.form.valid" color="primary">Save</button>
     <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Cancel</button>
      <button (click)="removeAll()" class="removeLabel" color="warn">
        <mat-icon class="mat-18">clear</mat-icon>
      </button>
    </mat-label> -->
    <mat-label mat-dialog-title *ngIf="targets.length < 1">
      <mat-card>
        <mat-label disabled><b class="text small-text">No User Groups</b></mat-label>
      </mat-card>
    </mat-label>
    <mat-card *ngIf="targets.length > 0">
      <mat-label *ngFor="let item of targets | sort:'name'" disabled><b>{{item.name}}</b> 
        <!-- -- {{item.id} -->
      <br><br></mat-label>
    </mat-card>
    <hr>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button type="submit" (click)="onSubmit($event)" color="primary">Save</button>
  <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Cancel</button>
</div>