import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Notes } from '../../interfaces/notes';
import { SnackService } from '../../services/extra/snack.service';
import { NotesCreationDialogComponent } from '../dialogs/notes-creation-dialog/notes-creation-dialog.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit {
  private _notes: Notes[] = [];

  //Action columns will be optionally for the tables that have inline actions
  @Output("noteAdded") noteAddedEmitter = new EventEmitter<any>();
  @Input("name") name = '';
  @Input("entityId") entityId: string;
  @Input("entityCode") entityCode: string;
  @Input("entityType") entityType: string;
  @Input("notes")
  public set notes(newNotes: Notes[]) {
    this._notes = newNotes;
  }
  public get notes(): Notes[] {
    return this._notes;
  }
  constructor(
    private _dialog: MatDialog,
    private _snackService: SnackService
    ) { }

  ngOnInit(): void {
  }

  clickEvent($event) {
    console.log($event);
  }
  
  createNote(): void {
    const dialogRef = this._dialog.open(NotesCreationDialogComponent, {
      width: "75vw",
      data: {
        entityCode: this.entityCode,
        entityId: this.entityId,
        entityTypeCode: this.entityType,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.error) {
      this._snackService.displayError(result.error.message);
      this.noteAddedEmitter.emit(false);
      return;
      }
      if (result) {
        this._snackService.displaySuccess("Note created for " + this.entityType);
        this.noteAddedEmitter.emit(true);
      return;
      }
    }, err => {
      console.log(err);
      this._snackService.displayError("An error has occurred: " + err);
    });
  }
}
