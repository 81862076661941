<mat-tab-group #tabGroup (selectedTabChange)="tabSelectionChanged($event)">
    <mat-tab label="1">
        <ng-template matTabContent>
        <p>Module 1</p>
        <video controls="controls" class="video-stream" x-webkit-airplay="allow" style="width: max-content; height: max-content;" 
        src="https://www.youtube.com/watch?v=8keIbJaxyuY&html5=True">
              <!-- <source src="https://www.youtube.com/watch?v=8keIbJaxyuY&html5=True"> -->
              Sorry, your browser doesn't support embedded videos.
          </video>
        </ng-template>
    </mat-tab>

    <mat-tab label="2">
    <ng-template matTabContent>
        <p>Module 2</p>
    </ng-template>
    </mat-tab>
    <mat-tab label="3">
    <ng-template matTabContent>
        <p>Module 3</p>
    </ng-template>
    </mat-tab>
    <mat-tab label="4">
    <ng-template matTabContent>
        <p>Module 4</p>
    </ng-template>
    </mat-tab>
    <mat-tab label="5">
    <ng-template matTabContent>
        <p>Module 5</p>
    </ng-template>
    </mat-tab>
    <mat-tab label="6">
    <ng-template matTabContent>
        <p>Module 6</p>
    </ng-template>
        
    </mat-tab>
    <mat-tab label="7">
    <ng-template matTabContent>
        <p>Module 7</p>
    </ng-template>
        
    </mat-tab>
    <mat-tab label="8">
    <ng-template matTabContent>
        <p>Module 8</p>
    </ng-template>
    </mat-tab>

    <mat-tab label="9">
    <ng-template matTabContent>
        <p>Module 9</p>
    </ng-template>
        
    </mat-tab>
    <mat-tab label="10"> 
        <ng-template matTabContent>
            <p>Module 10</p>
        </ng-template>
    </mat-tab>
</mat-tab-group>