<div mat-dialog-content>
  <mat-progress-bar style="position: static;
      z-index: 1;
      left: 0;
      right: 0;
      top: 50px;
      bottom: 0;" *ngIf="loading" mode="query">
</mat-progress-bar>
  <div class="col-12">
    <div *ngIf="response.message !== null && response.message !== undefined">
      <!-- {{response.message}} -->
      <p class="text header-text">Response: </p>
      <p class="text label-text">{{ response.message}}</p>
    </div>
    <div *ngIf="response.createdAssets !== undefined && response.createdAssets.length > 0">
      <p class="list-inline-item font-weight-bold text-uppercase">
        Created Assets
      </p>
      <!-- list of buttons to navigate -->
      <ul
        *ngFor="let created of response.createdAssets; index as i"
        matTooltip="Click to navigate"
      >
        {{
          created.name
        }}
        <button
          (click)="navigateToAsset(created)"
          mat-icon-button
          color="secondary"
          style="position: absolute; right: 5vw"
        >
          <mat-icon class="mat-18">search</mat-icon>
        </button>
        <button
          (click)="pullOwnership(created)"
          mat-icon-button
          color="secondary"
          style="position: absolute; right: 0"
        >
          <mat-icon class="mat-18">history</mat-icon>
        </button>
        <!-- <hr /> -->
        <div *ngIf="created.history">
          <button *ngIf="created.openForAssignment === false && created.isHealthSensor === false"
              (click)="deleteDialog(created, created.history[0].customerName)"
              mat-raised-button
              color="warn"
            >
            Remove asset ownership
              <!-- <mat-icon class="mat-18">close</mat-icon> -->
            </button>
            <button *ngIf="created.openForAssignment && created.isHealthSensor === false"
                  (click)="navigateToDialog(created)"
                  mat-raised-button
                  color="primary"
                >
                Assign asset ownership to customer
                  <!-- <mat-icon class="mat-18">close</mat-icon> -->
            </button>
          <ul
            class="text dashboard-text"
            *ngFor="let history of created.history; index as i"
            click="navigateToCustomer(history)"
          >
            {{
              history.customerName
            }}: 
            <p *ngIf="history.whenEnd === null" style="color: green;">
              <button
              (click)="navigateToCustomer(history)"
              mat-icon-button
              color="secondary"
            >
              ({{
                history.whenBegin | date: "short"
              }}
              - 
              Current
              )
            </button>
            </p>
            <p *ngIf="history.whenEnd">
              <button
              (click)="navigateToCustomer(history)"
              mat-icon-button
              color="secondary"
            >
              ({{
                history.whenBegin | date: "short"
              }}
              -
              {{
                history.whenEnd | date: "short"
              }})
            </button>
            </p>
            
          </ul>
        </div>
        <div *ngIf="created.noOwnership" class="text dashboard-text">
          No Asset History: 
          <p style="color: red;">
            <button
            (click)="navigateToDialog(created)"
            mat-icon-button
            color="secondary"
          >
            Click to assign asset to customer
          </button>
          </p>
        </div>
        <!-- <div *ngIf="created.noOwnership">
          <button (click)="navigateToDialog(created)"
          mat-icon-button
          color="secondary">Assign Asset</button>
        </div> -->
        <!-- <hr /> -->
        <br />
        <hr />
      </ul>
    </div>
    <!-- <ng-template #alreadyExist>
      <p class="text dashboard-text">
        This full scout configuration already exists as multiple assets
      </p>
    </ng-template> -->
    <hr />
    <div *ngIf="response.existingAssets !== undefined && response.existingAssets.length > 0">
      <p
        class="list-inline-item font-weight-bold text-uppercase"
        matTooltip="Navigate to Asset"
      >
        Existing Assets
      </p>
      <ul *ngFor="let existing of response.existingAssets; index as i">
        <!-- <button mat-raised-button (click)="navigateToAsset(existing)" color="primary">{{existing.code}}</button> -->
        {{
          existing.name
        }}
        <button
          (click)="navigateToAsset(existing)"
          mat-icon-button
          color="secondary"
          style="position: absolute; right: 5vw"
        >
          <mat-icon class="mat-18">search</mat-icon>
        </button>
        <button
          (click)="pullOwnership(existing)"
          mat-icon-button
          color="secondary"
          style="position: absolute; right: 0"
        >
          <mat-icon class="mat-18">history</mat-icon>
        </button>
        <!-- <hr /> -->
        <div *ngIf="existing.history">
          <button *ngIf="existing.openForAssignment === false && existing.isHealthSensor === false"
              (click)="deleteDialog(existing, existing.history[0].customerName)"
              mat-raised-button
              color="warn"
            >
            Remove asset ownership
              <!-- <mat-icon class="mat-18">close</mat-icon> -->
            </button>
            <button *ngIf="existing.openForAssignment && existing.isHealthSensor === false"
                  (click)="navigateToDialog(existing)"
                  mat-raised-button
                  color="primary"
                >
                Assign asset ownership to customer
                  <!-- <mat-icon class="mat-18">close</mat-icon> -->
            </button>
          <ul
            class="text dashboard-text"
            *ngFor="let history of existing.history; index as i"
            click="navigateToCustomer(history)"
          >
            {{
              history.customerName
            }}: 
            <p *ngIf="history.whenEnd === null" style="color: green;">
              <button
              (click)="navigateToCustomer(history)"
              mat-icon-button
              color="secondary"
            >
              ({{
                history.whenBegin | date: "short"
              }}
              - 
              Current
              )
            </button>
            </p>
            <p *ngIf="history.whenEnd">
              <button
              (click)="navigateToCustomer(history)"
              mat-icon-button
              color="secondary"
            >
              ({{
                history.whenBegin | date: "short"
              }}
              -
              {{
                history.whenEnd | date: "short"
              }})
            </button>
            </p>
            
          </ul>
        </div>
        <div *ngIf="existing.noOwnership" class="text dashboard-text">
          No Asset History: 
          <p style="color: red;">
            <button
            (click)="navigateToDialog(existing)"
            mat-icon-button
            color="secondary"
          >
            Click to assign asset to customer
          </button>
          </p>
        </div>
        <!-- <div *ngIf="existing.noOwnership">
          <button (click)="navigateToDialog(existing)"
          mat-icon-button
          color="secondary">Assign Asset</button>
        </div> -->
        <hr />
      </ul>
    </div>
    <!-- <ng-template #fullyCreated>
      <p class="text dashboard-text">
        This full scout configuration was created as multiple assets
      </p>
    </ng-template> -->
  </div>
  <br />
  <!-- <hr /> -->
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <button
      class="btn btn-secondary scanner"
      style="right: 0px; position: absolute"
      mat-raised-button
      color="primary"
      (click)="okayClick()"
    >
      <p class="text btn-text">OKAY</p>
    </button>
  </div>
</div>
