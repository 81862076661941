import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(success => {
    console.log(`**** APP version:  ` + environment.version + `  **** `);
    if (environment.production) {
      window.console.log = function () { };
    }
  }
  )
  .catch(err => console.error(err));
