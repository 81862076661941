import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../../../../environments";
import { NoteDialogInput } from "../../../../shared/interfaces/note-dialog-input";
import { Notes } from "../../../../shared/interfaces/notes";
@Component({
  selector: "app-notes-creation-dialog",
  templateUrl: "./notes-creation-dialog.component.html",
})
export class NotesCreationDialogComponent implements OnInit {
  newNote: NotesCreation = {
    basicName: "",
    basicDescription: "",
    text: "",
    issueValue: 0,
    severity: 0,
    username: "",
    entityCode: null,
    entityTypeCode: null,
    entityId: null,
  };
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<NotesCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public entity: NoteDialogInput,
    private _http: HttpClient
  ) {}

  ngOnInit(): void {
    this.newNote.entityId = this.entity.entityId;
    this.newNote.entityCode = this.entity.entityCode;
    this.newNote.entityTypeCode = this.entity.entityTypeCode;
  }

  createNote() {
    const userEmail = localStorage.getItem("userEmail");
    this.newNote.username = userEmail;
    this.postNote(this.newNote).subscribe((result) => {
      // console.log(result);
      this.event.emit(result);
      this.dialogRef.close(result);
    }, err => {
      this.event.emit(err);
      this.dialogRef.close(err);
    });
  }

  public postNote(note: NotesCreation): Observable<Notes> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      note: note,
    };
    const URL = `${environment.apiUrl}api/admin/note`;
    return this._http.post<any>(URL, note, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update Settings Response:", data))
    );
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }
}

export interface NotesCreation {
  username: string;
  basicName: string;
  basicDescription: string;
  text: string;
  issueValue: number;
  severity: number;
  entityCode?: string;
  entityTypeCode?: string;
  entityId?: string;
}
