<div
  class="col-12"
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <a
    class="list-inline-item font-weight-bold text-uppercase"
    > Create Firmware Model (FWM)
  </a>
  <p class="text small-text">
    Firmware for a Scout consists of both a Firmware Model (FWM) and a Firmware Instance (FWI) together. A FWM can have many FWI's assigned to it. This dialog will bring you through the process of first creating a FWM and then either assigning FWI's to it or creating a new FWI.
  </p>
</div>
<div mat-dialog-content class="col-12">
    <form class="customer-form" #firmwareForm="ngForm" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <textarea
          matInput
          placeholder="Firmware Version"
          required
          [(ngModel)]="firmwarePost.firmwareVersion"
          name="firmwareVersion"
        ></textarea>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Firmware Begins"
          type="text"
          required
          [(ngModel)]="firmwarePost.whenBegin"
          name="whenBegin"
          disabled
        />
      </mat-form-field>
      <mat-progress-bar
        *ngIf="loading"
        mode="query"
      >
      </mat-progress-bar>
      <mat-form-field *ngIf="instances.length > 0" [disabled]="!newInstance">
        <mat-select placeholder="Firmware Instances" [formControl]="fwi" [(ngModel)]="selected">
            <mat-option *ngFor="let instance of instances; let i =index" [value]="instance">
              <!-- <span (click)="onChange(instance);selected = i">[({{instance.code}}): {{instance.name}}]</span> -->
              <!-- [checked]="selected === i" -->
                <mat-checkbox (change)="onChange(instance);selected = i"> {{instance.code}} - {{instance.name}}</mat-checkbox>
            </mat-option>
        </mat-select>
      </mat-form-field>
      <hr>
      <button
        mat-raised-button
        (click)="startNewInstance()"
        [disabled]="newInstance"
        style="max-width: min-content"
      >
        Create Firmware Instance (FWI)
      </button>
      <button
        mat-raised-button
        (click)="noInstanceAttached()"
        style="max-width: min-content"
      >
        No FWI
      </button>
      <hr>
      <button
        mat-raised-button
        type="submit"
        [disabled]="!instanceAssigned"
        color="primary"
        style="max-width: min-content"
      >
        Create
      </button>
    </form>
</div>
<div mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-raised-button
    color="warn"
    (click)="closeDialog()"
  >
    Cancel
  </button>
</div>
