<div mat-dialog-content>
    <!-- {{data.value}} -->
      <h4> Parser Message:</h4>
      <p class="text">
        {{data.parserMessage}}
      </p>
      <br>
      <h4> Value:</h4>
      <p class="text">
        {{data.value}}
      </p>
      <hr>
      <b>When: </b>
      <p class="text">
        {{data.when | date: 'short'}}
      </p>
      <br>
</div>
<div mat-dialog-actions>
    <!-- <button mat-button color="warn" (click)="onNoClick()">No</button> -->
    <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Okay</button>
</div>