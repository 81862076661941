import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { UserLookupDialogComponent } from '../user-lookup-dialog.component';
@Component({
  selector: 'app-user-lookup-list-dialog',
  templateUrl: './user-lookup-list-dialog.component.html'
})
export class UserLookupListDialogComponent implements OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  user: any;
  allUsers: any[];
  url = '';
  loading = false;
  availableGroups: any[] = [];
  componentSubscription: Subscription;
  constructor(
    private _router: Router,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<UserLookupListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.allUsers = data;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  changeUser(user: any) {
    this.user = user;
    if (user) {
      const dialogRef = this._dialog.open(UserLookupDialogComponent, {
        width: "100%",
        height: "100%",
        data: user,
      });
      this.componentSubscription = dialogRef.componentInstance.event.subscribe((result) => {
        if (result.data) {
          console.log(result);
        }
      });
    }
  }

  expandUser(user: any) {
    const dialogRef = this._dialog.open(UserLookupDialogComponent, {
      width: "100%",
      height: "100%",
      data: { user: user, all: this.allUsers },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
      }
    });
  }

  navigate(user: any) {
    // console.log('navigating');
      this._router.navigate([`/customers/${user.customerId}/users/${user.id}`]);
    // this._router.navigate([])
  }
  
  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
