import {
  throwError as observableThrowError,
  of as observableOf,
  Observable,
} from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { ServiceConstant } from "../interfaces/service-constant";
import * as moment from "moment";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { Settings } from "../interfaces/settings";
import { ReportNode } from "../../modules/reports/tree";
import { MatTableDataSource } from "@angular/material/table";
@Injectable()
export class ConstantService {
  CONST: ServiceConstant;
  languageTag: string;
  isMobile: boolean;
  user;
  permissionType: string;
  userDetails;
  system_username: string;
  defaultParams = {
    "ZUMO-API-VERSION": "2.0.0",
    "Content-Type": "application/json",
  };
  userType: any;
  types: any[] = [];
  ipAddress: string;
  dateNow = new Date(Date.now());
  userSettings: Settings;
  fileToUpload: File = null;
  _isTypeAdmin: boolean = false;
  _isSupport: boolean = false;
  _isHardware: boolean = false;
  constructor() {
    const prodCONST = <ServiceConstant>{
      URL: environment.apiUrl,
      userId: "", // token:
      ZUMOAPIVersion: "2.0.0",
      pvtKey: "XF7CNoAstTukmO4q/8/MNIXyv1qJcK34BbjSqXduawL9h74bcCXRGA==",
      reportsApiUrl: environment.reportsApiUrl
    };
    const devCONST = <ServiceConstant>{
      URL: environment.apiUrl,
      userId: "", // token:
      ZUMOAPIVersion: "2.0.0",
      pvtKey: "rWnjenR73Dcif9TRaABOYT6CueQWUqrBWvdIYvXDBmK4M///sIBysQ==",
      reportsApiUrl: environment.reportsApiUrl
    };

    if (environment.production) {
      this.CONST = prodCONST;
    } else {
      this.CONST = devCONST;
    }
    this.getMobileWidth();
  }

  public getMobileWidth(): boolean {
    if (window.screen.width === 360 || window.screen.width < 361) {
      this.isMobile = true;
      return this.isMobile;
    } else {
      this.isMobile = false;
      return this.isMobile;
    }
  }


  isPermittedToUse(ut: string): boolean {
    switch (ut) {
      case "devadmin":
        console.log("Permitted");
        return true;
      case "advanced":
        console.log("Permitted");
        return true;
      case "supportadmin":
        console.log("Permitted");
        this._isSupport = true;
        return true;
      case "productionadmin":
        console.log("Permitted");
        this._isHardware = true;
        return true;
      case "super":
        console.log("Permitted");
        return true;
      default:
        console.log("Not permitted to use.. Logging out");
        return false;
    }
  }

  isPermittedToUseById(ut: string): boolean {
    switch (ut) {
      case "2419D037-2F66-47D3-A1BD-B68BA959F812": //devadmin
        return true;
      case "fe7df56e-0577-4867-899b-7320df998186": //advanced
        return true;
      case "DA150D8D-E7AC-4E91-BB0B-C83ADA018BFD": //supportadmin
        this._isSupport = true;
        return true;
      case "2C73C12A-A769-4279-B060-2865D9E6C1F3": //productionadmin
        this._isHardware = true;
        return true;
      case "5b780234-5ea7-449d-acee-698faa0c0a11": //super
        return true;
      default:
        return false;
    }
  }

  toggleTableRows(expanded: boolean, dataSource: MatTableDataSource<any>) {
    expanded = !expanded;

    dataSource.data.forEach((row: any) => {
      row.isExpanded = expanded;
    });
    return dataSource;
  }

  public getCONST(key: string) {
    return this.CONST[key];
  }

  public handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.log(error, error.message);
      console.log(`${operation} failed: ${error.message}`);
      return observableOf(result as T);
    };
  }

  public extractData(res: Response) {
    const body = res.json();
    return body || {};
  }

  public readData(res: Response) {
    const body = res.json();
    return body;
  }

  public exportCsv(data: any[], fileName: string) {
    let headers: string[] = [];
    headers = Object.getOwnPropertyNames(data[0]);
    var options: any = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      useBom: true,
      noDownload: false,
      headers: [headers],
      nullToEmptyString: true,
    };
    return new Angular5Csv(
      data,
      this.dateNow.toLocaleString() + " " + fileName,
      options
    );
  }

  getNodeLevel = (node: ReportNode) => {
    return node.level;
  };

  getNodeColor(node: ReportNode) {
    let level = this.getNodeLevel(node);
    let color = "";
    if (node.hasPercentage) {
      if (node.percentage > 30) {
        color = "red";
      }
      if (node.percentage > 60) {
        color = "yellow";
      }
      if (node.percentage > 80) {
        color = "blue";
      }
      if (node.percentage > 90) {
        color = "green";
      }
      return color;
    } else {
    }
    switch (level) {
      case 1:
        color = "#D3D3D3";
        break;
      case 2:
        color = "#C0C0C0";
        break;
      case 3:
        color = "#A9A9A9";
        break;
      case 4:
        color = "#778899";
        break;
      default:
        color = "white";
    }
    return color;
  }
  
  getNodeStyle(node: ReportNode) {
    let level = this.getNodeLevel(node);
    let styles = {};
    switch (level) {
      case 0:
        styles = {
          "-webkit-text-stroke-width": "medium",
          "text-decoration": "underline",
          "font-style": "italic",
          "letter-spacing": "2px"
        };
        break;
      case 1:
        styles = {
          "letter-spacing": "2px",
          "border": "outset"
        };
        break;
      case 2:
        styles = {
          "letter-spacing": "2px"
        };
        break;
      default:
        // styles = {"-webkit-text-stroke-width": "medium"};
    }
    return styles;
  }

  public handleErrorToast(error: Response | any, toastMsg: string) {
    // this._snack.displayError(toastMsg + ": " + error.status);
    return observableThrowError(error.message || error);
  }

  public handleRequestToast(resp: any, toastMsg: string) {
    // this._snack.displaySuccess(toastMsg);
    console.log(resp);
    return;
  }

  public handleErrorObservable(error: Response | any) {
    //console.error(error.error.message || error.message);
    return observableThrowError(error.error.message || error.message);
  }

  public handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    // this._snack.displayError(`${error} failed: ${error.message}`);
    return Promise.reject(error.message || error);
  }

  public convertDateUTC(date: Date) {
    return moment(date)
      .add(-moment().utcOffset(), "m")
      .format("YYYY-MM-DDTHH:mm:ss.00");
  }

  public calculateTimeFromEpoch(minutes: number, date: string): string {
    const converted = Math.round(minutes * 60 * 1000);
    const startTime = Date.parse(date);
    const appendedTime = startTime + converted;
    const returnedDate =
      new Date(appendedTime).toISOString().substring(0, 19) + "Z";
    return returnedDate;
  }

  public calculateTimeDuration(startDate: Date, endDate: Date): number {
    const duration = endDate.valueOf() - startDate.valueOf();
    const minutes = duration / (1000 * 60);
    return minutes;
  }

  public getRegions() {}
}
