import { catchError, map, mergeMap } from "rxjs/operators";
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, of, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AdminService } from "../../../shared/services/admin.service";
import { AssetsService } from "../../../shared/services/assets.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { RootsPageComponentConfig } from "../../../shared";

@Component({
  selector: "app-fwm-edit",
  templateUrl: "./fwm-edit.component.html",
})
export class FwmEditComponent implements OnInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<any>(),
    // displayedColumns: [
    //   'name',
    //   'firmwareVersion',
    //   'code',
    //   'description',
    //   'file', 
    //   'fileBin'
    // ],
    displayedColumns: [
      'basicName',
      'assetModelVersion',
      'code',
      'basicDescription',
      'whenBegin'
    ],
    message: ''
  };
  @Output() selectEmitt = new EventEmitter();
  @Input() firmware: any;
  customerInitState: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  event: EventEmitter<any> = new EventEmitter();
  firmwareId = "";
  assetModels: any[];
  origAssetModels: any[];
  componentSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public _dialog: MatDialog,
    private _adminService: AdminService,
    private _assetService: AssetsService
  ) {
    this._config.toolTip = "Configure or edit firmware model details.";
  }
    //TODO: Grab all instances for this datasource component and link up search local

  ngOnInit() {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.firmwareId = id;
          return id;
        }),
        mergeMap((id) => {
          const ids = [];
          ids.push(id);
          const getFirmwareDetails =
            this._adminService.getFirmwareModelById(id);
          const getFirmwareAssets =
            this._adminService.getAssetModelFirmwareModel(ids);
          const getAssetModels = this._assetService.getAssetModels();
          const getFwi = this._adminService.getFirmwareInstance(id);

          return forkJoin([
            getFirmwareDetails,
            getFirmwareAssets.pipe(catchError(error => of(error))),
            getAssetModels.pipe(catchError(error => of(error))),
            getFwi
          ]);
        })
      )
      .subscribe((results) => {
        const details = results[0];
        const models = results[1];
        const assets = results[2];
        const instances = results[3];
        this.origAssetModels = assets;
        this.firmware = details;
        this.assetModels = this.findFirmwareAsset(models);
        this._config.title = `${this.firmware.name} FWM Details`;
        // this._config.dataSource = new MatTableDataSource(instances);
        const modelCopy = this.assetModels;
        this._config.dataSource = new MatTableDataSource(modelCopy);
        this._config.loading = false;
        this._config.dataSource.sort = this.sort;
      });
  }
  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  onNoClick() {
    this._router.navigate(["/firmwares/models"]);
  }

  onUpdate(firmware: any): void {
    const updateobj = {
      basicName: firmware.name,
      code: firmware.code,
      basicDescription: firmware.description,
      firmwareVersion: firmware.firmwareVersion
    };
    this.componentSubscription = this._assetService.updateFirmwareModel(firmware.id, updateobj).subscribe(results => {
      console.log(results);
    })
  }

  findFirmwareAsset(data: any[]) {
    const scoutArray = [];
    for (let i = 0; i < this.origAssetModels.length; i++) {
      const model = this.origAssetModels[i];
      for (let j = 0; j < data.length; j++) {
        const d = data[j];
        if (model.id === d.assetModelId) {
          model.assetModelId = d.id;
          scoutArray.push(model);
        }
      }
    }
    return scoutArray;
  }
}
