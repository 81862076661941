import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActionType, ConstantService, RootsActionMenuItem, Scout } from '../../shared';
import { ScoutLogExtended } from '../../shared/interfaces/scout-log-extended';
import { SearchLogs } from '../../shared/interfaces/search-log';
import { SnackService } from '../../shared/services/extra/snack.service';
import { ScoutLogService } from '../../shared/services/scout-log.service';
import { SearchBarService } from '../../shared/services/searchbar.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html'
})
export class LogsComponent implements OnInit, AfterViewInit, OnDestroy  {
  scouts: Scout[];
  isCollapsed = false;
  showInactive = true;
  showSettings = false;
  displayedColumns: string[] = [
    'type',
    'name',
    'generate',
    'modelNumber',
    'voltage',
    'time',
    // 'number',
    'validatedBy',
    'sensors',
    // 'edit'
  ];
  dataSource = new MatTableDataSource<SearchLogs>([]);
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = true;
  dashboardId = '';
  logs: ScoutLogExtended[];
  allLogs: SearchLogs[] = [];
  server = environment.scoutLogApiUrl;
  private searchURL = environment.scoutLogApiUrl + "SearchLog";
  private statsURL = environment.scoutLogApiUrl + "LogStatistics";
  message: string;
  selectedRowIndex = -1;
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Get Statistics",
      icon: "add",
      toolTip:
        "Get general statistics from scout and cub logs.",
      type: ActionType.Create,
    },
  ];
  searchSubscription: Subscription;
  constructor(
    private _router: Router,
    private _snack: SnackService,
    private _constService: ConstantService,
    public searchBarService: SearchBarService,
    private _logService: ScoutLogService
  ) {
    this.getAllLogs();
    this.title = "Manufacturing Logs";
    this.toolTip =
      "Find all logs based on Scouts or Cubs - logs are created once the asset makes it to test on the manufacturing floor.";
    if (this.loading) {
      this._snack.displayLoading('Logs loading...');
      this.server = this.server.split('//')[1];
    }
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this.dataSource);
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  
  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.getLogStatistics();
        break;
      default:
        break;
    }
  }

  navigateToLogDetail(log: any) {
    if (log.type === "Scout") {
      this._router.navigate([`/manufacturinglogs/scouts/${log.link}`]);
    } else {
      this._router.navigate([`/manufacturinglogs/cubs/${log.link}`]);
    }
  }

  refresh() {
    this.loading = true;
    this.getAllLogs();
  }

  highlight(row){
    console.log(row);
    this.selectedRowIndex = row.id;
  }

  dialog(log:any) {
    if (log.type === 'Cub') {
      this._logService.cubLogDialog(log);
    } else { 
      this._logService.logDialog(log);
    }
  }

  generate(log:any) {
    if (log.type === 'Cub') {
      // this._logService.postCubLogCreate(log);
      this._logService.postCubLogCreateOriginal(log);
      //this.createAssetsDialog(log);
    } else {
      this._logService.createAssetsDialog(log);
    }
  }

  getLogStatistics() {
  let request: Request;  
  request = new Request(
    this.statsURL,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": this._constService.CONST.pvtKey,
      }),
    }
  );
  fetch(request)
    .then((response) => {
      response.json().then((res) => {
        this.loading = false;
        this._snack.displaySuccess('Log statistics loaded');
        return res;
      });
    })
  .catch((err) => {
    this._snack.displayError(err);
  })
  }

  getAllLogs() {
  let request: Request;  
  let results: SearchLogs[] = [];
  request = new Request(
    this.searchURL,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": this._constService.CONST.pvtKey,
      }),
    }
  );
  fetch(request)
    .then((response) => {
      response.json().then((res) => {
        this.loading = false;
          results = <SearchLogs[]>res;
          this.allLogs = results;
          this.dataSource.data = this.allLogs as SearchLogs[];
          // if (this.dataSource.data !== undefined && this.dataSource.data.length > 0) {
          //   this.dataSource.data = this.dataSource.data.sort((a, b) => {
          //     return (a.time > b.time) ? -1 : (a.time < b.time) ? 1 : 0;
          //   });
          // }
        this._snack.displaySuccess('All Logs loaded');
      });
    })
  .catch((err) => {
    this._snack.displayError(err);
  })
  }
}
