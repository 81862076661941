import { Injectable, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable()
export class NavService implements OnDestroy {
  public appDrawer: any;
  public currentUrl = new BehaviorSubject<string>(undefined);
  routerSubscription: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  public closeNav() {
    if (this.appDrawer) {   
      this.appDrawer.close();
    }
  }

  public openNav() {
    if (this.appDrawer) {  
      this.appDrawer.open();
    }
  }
}