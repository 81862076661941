<div
  class="col-12"
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <a
    class="list-inline-item font-weight-bold text-uppercase"
    > Create new Test Sensor
  </a>
  <p class="text small-text">
    Test sensors are used when testing a scout on the manufacturing floor.
  </p>
</div>
<div mat-dialog-content class="col-12">
    <form (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          placeholder="Example: 014AcclimaTR315L3.29004769"
          type="text"
          required
          [(ngModel)]="testSensor.name"
          name="name"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Prefixes">
            <mat-option *ngFor="let prefix of sensorPrefix; let i = index" [value]="prefix" (click)="onChange(prefix);selected = i">
              <span>{{prefix}}</span>
            </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <button
      class="btn btn-secondary"
        mat-raised-button
        type="submit"
        [disabled]="testSensor.name.length < 1"
        color="primary"
        style="max-width: min-content"
      >
        Create
      </button>
    </form>
</div>
<div mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-raised-button
    color="warn"
    (click)="closeDialog()"
  >
    Cancel
  </button>
</div>
