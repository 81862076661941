
export class EmailStructure {
    to: string;
    from: string;
    subject: string;
    body: string;
    html: string;
    constructor(to: string, from: string, subject: string, body: string, html: string) {
      this.to = to;
      this.from = from;
      this.body = body;
      this.subject = subject;
      this.html = html;
    }
}