import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {
  title='';
  toolTip='';
  loading = true;
  pageData = [
    {section: "Logs", name:"Manufacturing Logs", link: "/manufacturinglogs"},
    {section: "Logs", name:"Scout Logs", link: "/manufacturinglogs/scouts"},
    {section: "Logs", name:"Cub Logs", link: "/manufacturinglogs/cubs"},
    {section: "Reports", name:"Reports Root Page", link: "/reports"},
    {section: "Reports", name:"Packets Report", link: "/reports/packets"},
    {section: "Reports", name:"Scouts Report", link: "/reports/scouts"},
    {section: "Reports", name:"Sensors Report", link: "/reports/sensors"},
    {section: "Reports", name:"Users Report", link: "/reports/users"},
    {section: "Customers", name:"Customers Root Page", link: "/customers"},
    {section: "Scouts", name:"Scouts Root Page", link: "/scouts"},
    {section: "Assets", name:"Assets Root Page", link: "/assets"},
    {section: "Firmwares", name:"Firmwares Root Page", link: "/firmwares"},
    {section: "References", name:"References Root Page", link: "/references"},
    {section: "Settings", name:"Settings Page", link: "/settings"},
  ];

  constructor(
    private _location: Location,
    private _router: Router
  ) { }

  ngOnInit() {
    this.title='Click to navigate back';
    this.toolTip='Navigate back to the last page';
  }

  navigateBack() {
    this._location.back();
  }

  navigateToPage(data){
    this._router.navigate([data.link])
  }

}
