import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CubScoutLogDetailsComponent } from './components/cub-log-details/cub-log-details.component';
import { CubScoutLogsComponent } from './components/cub-scout-logs/cub-scout-logs.component';
import { ScoutLogDetailsComponent } from './components/scout-log-details/scout-log-details.component';
import { ScoutLogsComponent } from './components/scout-logs/scout-logs.component';
import { TestSensorsComponent } from './components/test-sensors/test-sensors.component';
import { LogsComponent } from './logs.component';

const routes: Routes = [
  { path: "",  component: LogsComponent },
  { path: "testsensors",  component: TestSensorsComponent },
  { path: "cubs",  component: CubScoutLogsComponent },
  { path: "cubs/:serialId", component: CubScoutLogDetailsComponent },
  { path: "scouts",  component: ScoutLogsComponent },
  { path: "scouts/:serialId", component: ScoutLogDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogsRoutingModule { }
