import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RootsActionMenuItem, ActionType } from '../../../shared';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from '../../../shared/services/admin.service';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FirmwareInstanceFormComponent } from '../create-instance/firmware-instance-form.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemsListDialogComponent } from '../../../shared/components/dialogs/items-list-dialog/items-list-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-firmware-instances',
  templateUrl: './firmware-instances.component.html'
})
export class FirmwareInstancesComponent implements OnInit, OnDestroy {
  firmwares: any[];
  loading = true;
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create FWI",
      icon: "add",
      toolTip:
        "Create new Instance for Firmware - Instancing can then be assigned to a given FWM (Firmware Model)",
      type: ActionType.Create,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "Find FWM",
      toolTip:
        'Find the FWM with reference to this FWI',
      icon: "find_in_page",
      type: 10,
    },
    {
      name: "FWI Details",
      toolTip:
        'Navigate to this FWIs details',
      icon: "edit",
      type: 11,
    }
  ];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'name',
    'code',
    'firmwareModelId',
    'description',
    'file'
  ];
  message: string;
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    public searchBarService: SearchBarService,
    public _dialog: MatDialog,
    private _router: Router
    ) { 
    this.title = "Firmware Instances";
    this.toolTip =
      "Find details regarding firmware instances and firmware models";
  }

  ngOnInit(): void {
    this.loading = true;
    const getFirmwareInstances = this._adminService.getFirmwareInstance();
    getFirmwareInstances.subscribe(firmwares => {
      this.dataSource = new MatTableDataSource(firmwares.reverse());
      this.loading = false;
    });
    this.dataSource.sort = this.sort;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this.dataSource);
      }
    });
  }
  
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  openInstanceDialog(): void {
    const dialogRef = this._dialog.open(FirmwareInstanceFormComponent, {
      width: "60vw",
      data: "Create Firmware Instance",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
        // this.openDialog(result.data);
      }
    });
  }

  getAssetFirmwareModel(asset: any) {
    let ids: string[] = [];
    ids.push(asset.id);
    this.loading = true;
    this.componentSubscription = this._adminService.getAssetModelFirmwareModel(ids).subscribe((results) => {
      console.log(results);
      const types = [];
      for (let i = 0; i < results.length; i++) {
        const firmware = results[i];
        for (let j = 0; j < this.firmwares.length; j++) {
          const firm = this.firmwares[j];
          if (firmware.firmwareModelId.includes(firm.id)) {
            types.push(firm);
          }
        }
      }
      asset.expanded = true;
      asset.firmwares = types;
      this.loading = false;
      this.findInstanceDialog(asset.firmwares);
    });
  }

  
  findInstanceDialog(results): void {
    const dialogRef = this._dialog.open(ItemsListDialogComponent, {
      width: "60vw",
      data: results,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
      }
    });
  }
  
  getInstances() {
    this.loading = true;
    this.componentSubscription = this._adminService.getFirmwareInstance().subscribe((models) => {
      this.firmwares = models.reverse();
      this.loading = false;
    });
  }

  topActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.openInstanceDialog();
        break;
        default:
        break;
    }
  }
  
  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
        case 10:
          this.loading = true;
          this.getAssetFirmwareModel(item);
        break;  
        case 11:
          this._router.navigate([`firmwares/instances/${item.id}`])
          break;
        default:
          break;
    }
  }
}

// code: "fwi1.0.0"
// content: null
// description: "Firmware instance file v1.0.0"
// file: ""
// fileBin: null
// firmwareModelId: null
// firmwareVersion: null
// id: "8DEA13F3-847C-4E9C-ABFD-C66A902F69EF"
// name: "FirmwareInstance 1.0.0"
