import { Modem } from "../classes/modem";
import { ScoutLog } from "../classes/scoutlog";
import { Sensors } from "../classes/sensors";
import { TestResult } from "../classes/testResults";

export class ScoutLogExtended extends ScoutLog{
    id: string;
    sequenceNumber: string;
    boardVersion: string;
    sleepTime: string;
    batteryVoltage: string;
    serial: string;
    modem: Modem;
    sensors: Sensors[];
    testResult: TestResult;
    validated: boolean;
    modelNumber: string;
    firmwareVersion: string;
    productLine: string;
    connectivityCode: string;
    //EXTRA added for UI
    testSensor: boolean;
    sensorsExpanded: boolean = false;
    testsExpanded: boolean = false;
    count: number = 0;
}