<div mat-dialog-content>
  <div class="col-12">
    {{log.sequenceNumber}}
    <button class="btn btn-secondary scanner" style="right: 0px; position: absolute;"
        mat-raised-button color="primary" (click)="renameScout(log)">
          <p class="text btn-text">
            RENAME
          </p>
    </button>
  </div>
  <br>
  <hr>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <mat-form-field id="form-input">
      <input matInput placeholder="Rename Scout" [(ngModel)]="log.sequenceNumber">
    </mat-form-field>
  </div>

<!-- <div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">No</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div> -->