import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { RootsPageComponentConfig } from '../../shared';
import { AdminService } from '../../shared/services/admin.service';
import { SearchBarService } from '../../shared/services/searchbar.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, OnDestroy {
_config: RootsPageComponentConfig = {
  loading: true,
  title:'',
  toolTip:'',
  dataSource: new MatTableDataSource<any>(),
  displayedColumns: ["name", "code", "description", "customer", "customerDescription"],
  message: ''
};
componentSubscription: Subscription;
searchSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    public searchBarService: SearchBarService,
    private _router: Router
    ) { 
    this.joinedServiceCalls();
    this._config.title = 'Groups';
    this._config.toolTip = 'Entity page for managing user and scout groups';
    }

    ngOnInit(): void {
      this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
        this._config.message = message;
      })
    }
    
  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
  
    getData() {
      this._config.loading = true;
      this.componentSubscription = this._adminService.getCustomersUserGroups().subscribe((groups) => {
        this._config.dataSource = new MatTableDataSource(groups);
        this._config.loading = false;
      });
    }

    joinedServiceCalls() {
      this._config.loading = true;
      const getAllUserGroups = this._adminService.getCustomersUserGroups();
      const getAllCustomers = this._adminService.getAdminCustomers();
  
      forkJoin([getAllUserGroups, getAllCustomers])
      .subscribe(results => {
        //define results
        let userGroups = results[0];
        const customers = results[1];

        userGroups = this.findUserGroupsCustomer(userGroups, customers);
        this._config.dataSource = new MatTableDataSource(userGroups);
        this._config.loading = false;
      })
    }

    findUserGroupsCustomer(groups: any[], customers: any[]): any[] {
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        for (let j = 0; j < customers.length; j++) {
          const customer = customers[j];
          if (group.customerId && group.customerId.includes(customer.id)) {
            group.customer = customer.name;
            group.customerDescription = customer.description;
            group.customerUserId = customer.userId;
          }
        }
      }
      return groups;
    }

    navigate(group: any) {
      this._router.navigate([`customers/${group.customerId}/usergroups/${group.id}/manage`])
    }

    refresh($event) {
      this._config.loading = true;
      this.getData();
    }
}
