import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { NicoUtils } from './nico-utils';

// <div class="clock">
// <div class="clock__second"></div>
// <div class="clock__minute"></div>
//   <div class="clock__hour"></div>
// <div class="clock__axis"></div>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
//   <section class="clock__indicator"></section>
// </div>
@Component({
  selector: 'app-avatar',
  template: `
    <span class="avatar {{avatarClassName}}" [style]="avatarStyles">
      <img *ngIf="imageLink" [src]="imageLink" alt="profile avatar" class="avatar mat-elevation-z8">
      <span *ngIf="username && !imageLink" class="avatar-initials">{{userInitials}}</span>
    </span>
  `,
  styles: [`:host {
    display: inline-block;
  }`],
  encapsulation: ViewEncapsulation.None
})

export class AvatarComponent implements OnInit, OnChanges {
  /**
   * Avatar image's source / link
   */
  @Input() imageLink?: string;
  /**
   * Custom additional classname for avatar (eg: 'my-avatar'})
   */
  @Input() avatarClassName?: string;
  /**
   * Custom styles for avatar (eg: {borderRadius: '0.75rem'})
   */
  @Input() avatarStyles?: any;
  /**
   * Username
   */
  @Input() username?: string;

  public userInitials: string;
  currentSec = this.getSecondsToday();

  seconds = (this.currentSec / 60) % 1;
  minutes = (this.currentSec / 3600) % 1;
  hours = (this.currentSec / 43200) % 1;
  constructor() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!NicoUtils.isNullOrUndefined(this.username)) {
      const nameArr: Array<string> = this.username.split(' ');
      this.userInitials = (nameArr[0].charAt(0) + (nameArr.length > 1 ? nameArr[nameArr.length - 1].charAt(0) : '')).toUpperCase();
    }
  }

  public ngOnInit(): void {
    this.imageLink = NicoUtils.isNullOrUndefined(this.imageLink) ? null : this.imageLink;
    this.avatarClassName = NicoUtils.isNullOrUndefined(this.avatarClassName) ? '' : this.avatarClassName;
    this.avatarStyles = NicoUtils.isNullOrUndefined(this.avatarStyles) ? {} : this.avatarStyles;
  }

  public getSecondsToday() {
      let now = new Date();

      let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      let diff = now.getTime() - today.getTime(); 
      return Math.round(diff / 1000);
  }
}
