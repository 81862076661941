<div class="card-padding">
  <h3 class="text label-header-text">Scout Interval</h3>
  <div class="text label-text">
    Scout interval set to: <b>{{counterValue}}</b>
    minutes (<b>{{ counterInHours }} hours</b>)
  </div>
  <div class="text small-text send-command-on-each" *ngIf="scout">
    <mat-slide-toggle labelPosition="before" [(ngModel)]="scout.sendCommandOnEachRequest">
      Send Command on Each ({{scout.sendCommandOnEachRequest}})
    </mat-slide-toggle>
  </div>
  <mat-form-field>
    <input matInput placeholder="Command to Send" [(ngModel)]="command" [formControl]="sendCommandFormControl" />
  </mat-form-field>
  <mat-slide-toggle class="send-command" [(ngModel)]="sendCommand" name="sendCommand" (change)="sendCommandChangeEvent()">
    Toggle Command Edit
  </mat-slide-toggle>
  <div class="small-text sideBySide">
    <p class="left interval-commands">
      {{'{'}}{{'{'}}SCT{{'}'}}{{'{'}}INT,60{{'}'}}{{'{'}}SEN,60{{'}'}}{{'}'}} for Scout 3.x.x
      <br>
      '\r''\n''\r''\n'{{'{'}}1,60{{'}'}}'\r''\n' for Scout 2.x.x
    </p>
    <button mat-raised-button color="primary" (click)="update(scoutId)" class="right">
      Set Interval/Send Command
    </button>
  </div>
  <mat-progress-bar style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0" *ngIf="loading"
    mode="query">
  </mat-progress-bar>
</div>