<h3 mat-dialog-title 
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>{{data}}</h3>
<div mat-dialog-content>
    
    <form class="reference-form" #referenceForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <input matInput placeholder="Name" type="text" required [(ngModel)]="referencePost.basicName" name="basicName">
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Description" required [(ngModel)]="referencePost.basicDescription" name="basicDescription"></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-select matInput placeholder="Scope" required [(ngModel)]="referencePost.scope"
            name="scope">
            <mat-option *ngFor="let sc of scope" [value]="sc">
                {{ sc }}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field>
            <input matInput placeholder="Scope" type="text" required [(ngModel)]="referencePost.scope" name="scope">
        </mat-form-field> -->
        <mat-form-field>
            <input matInput placeholder="Code" type="text" required [(ngModel)]="referencePost.code" name="code">
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="HyperLink" type="text" required [(ngModel)]="referencePost.content" name="content">
        </mat-form-field>
        <!-- <mat-form-field>
            <input matInput placeholder="Id" type="text" required [(ngModel)]="referencePost.id" name="id">
        </mat-form-field> -->
        
        <!-- [disabled]="formFilled()" -->
        <button mat-raised-button type="submit" [disabled]="!referenceForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
    <button mat-raised-button style="float: right" (click)="defaultReference()" color="primary" style="max-width: min-content;">Defaults</button>
</div>