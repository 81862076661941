import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingsComponent } from "./modules";
import { AuthGuard } from "@app/core/authentication/authentication.guard";
import { AuthCallbackComponent } from "@app/shared/components/auth-callback/auth-callback.component";
import { NotFoundComponent } from "./core/error-pages/not-found/not-found.component";
import { ServerErrorComponent } from "./core/error-pages/server-error/server-error.component";
import { DashboardComponent } from "./modules/dashboard/dashboard.component";
import { ManufacturingAuthGuard } from "./core/authentication/manufacturing.authentication.guard";


const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./modules/account/account.module").then(m => m.AccountModule)
  },
  {
    path: "customers",
    loadChildren: () => import("./modules/customer/customer.module").then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },
  {
    path: "groups",
    loadChildren: () => import("./modules/groups/groups.module").then(m => m.GroupsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "manufacturinglogs",
    loadChildren: () => import("./modules/logs/logs.module").then(m => m.LogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "reports",
    loadChildren: () => import("./modules/reports/reports.module").then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [ManufacturingAuthGuard],
  },
  {
    path: "firmwares",
    loadChildren: () => import("./modules/firmware/firmware.module").then(m => m.FirmwareModule),
    canActivate: [AuthGuard]
  },
  {
    path: "assets",
    loadChildren: () => import("./modules/assets/asset.module").then(m => m.AssetModule),
    canActivate: [AuthGuard]
  },
  {
    path: "alerts",
    loadChildren: () => import("./modules/alerts/alerts.module").then(m => m.AlertsModule),
    canActivate: [AuthGuard]
  },
  {
    path: "scouts",
    loadChildren: () => import("./modules/scouts/scouts.module").then(m => m.ScoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: "issues",
    loadChildren: () => import("./modules/issues/issues.module").then(m => m.IssuesModule),
    canActivate: [ManufacturingAuthGuard]
  },
  {
    path: "references",
    loadChildren: () => import("./modules/references/references.module").then(m => m.ReferencesModule),
    canActivate: [AuthGuard]
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "auth-callback",
    component: AuthCallbackComponent,
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "500",
    component: ServerErrorComponent,
  },
  {
    path: "",
    redirectTo: "/issues",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/404",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      scrollOffset: [0,100],
      anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
