import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsComponent } from './logs.component';
import { LogsRoutingModule } from './logs-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { GridsterModule } from 'angular-gridster2';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { MatModule } from '../../core';
import { DevelopersService } from '../../shared/services/developers.service';
import { SharedModule } from '../../shared/shared.module';
import { ScoutLogDetailsComponent } from '../logs/components/scout-log-details/scout-log-details.component';
import { CubScoutLogsComponent } from './components/cub-scout-logs/cub-scout-logs.component';
import { ScoutLogsComponent } from './components/scout-logs/scout-logs.component';
import { CubScoutLogDetailsComponent } from './components/cub-log-details/cub-log-details.component';
import { TestSensorsComponent } from './components/test-sensors/test-sensors.component';
import { CreateTestSensorDialogComponent } from './components/test-sensors/create-test-sensor-dialog/create-test-sensor-dialog.component';

import { ProdLogCubDialogComponent } from './dialogs/prodlog-cub-dialog/prodlog-cub-dialog.component';
import { ProdLogScoutDialogComponent } from './dialogs/prodlog-scout-dialog/prodlog-scout-dialog.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
  suppressScrollY: false
};


@NgModule({
  imports: [
    CommonModule,
    LogsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatModule,
    PerfectScrollbarModule,
    GridsterModule,
    // CKEditorModule
  ],
  declarations: [
    LogsComponent,
    ScoutLogsComponent,
    ScoutLogDetailsComponent,
    CubScoutLogsComponent,
    CubScoutLogDetailsComponent,
    TestSensorsComponent,
    CreateTestSensorDialogComponent,
    ProdLogScoutDialogComponent,
    ProdLogCubDialogComponent
  ],
  providers: [
    DevelopersService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [LogsComponent]
})
export class LogsModule { }
