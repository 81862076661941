import { Component, OnInit, ViewChild, OnDestroy, HostListener } from "@angular/core";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { ConfigurationAAD } from "./core";
import User = adal.User;
import { ConstantService } from "./shared/services/constant.service";
import { ReadingUser, UserService } from "./shared/services/api/user.service";
import * as jwt_decode from "jwt-decode";
import { delay, map, mergeMap } from "rxjs/operators";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { Settings } from "./shared/interfaces/settings";
import { MatDialog } from "@angular/material/dialog";
import { BreakpointObserver } from "@angular/cdk/layout";
import { NavItem } from "./core/components/nav-item.interface";
import { Subscription } from "rxjs";
import { ServerInfoDialogComponent } from "./shared/components/dialogs/server-info-dialog/server-info-dialog.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, { static: true }) sidenav!: MatSidenav;
  _navItems: NavItem[];
  get navItems(): NavItem[] {
    return this._navItems;
  }
  set navItems(navItems: NavItem[]) {
    this._navItems = navItems;
  }
  _subNav = {
    assetSubnav: false,
    assetId: "",
    customerSubnav: false,
    customerId: "",
    referenceSubnav: false,
    referenceId: "",
    reportsSubnav: false,
    reportsId: "",
    scoutsSubnav: false,
    scoutsId: "",
  }
  aadConfiguration: any;
  userDetails: ReadingUser;
  toggled = false;
  isLoggedIn = false;
  _config: any;
  tokenResource: any;
  currentUser: User;
  jwt: any;
  manufacturing: boolean = false;
  loginCover: boolean;
  currentSettings: Settings;
  time = new Date();
  expTime = new Date();
  timer;
  userType: string;
  currentIp: string;
  isLoginPage: boolean = true;
  outer: HTMLElement;
  //subscriptions
  routeSubscription: Subscription;
  userSubscription: Subscription;
  observerSubscription: Subscription;
  constructor(
    private _adalService: MsAdalAngular6Service,
    private _constService: ConstantService,
    private _userService: UserService,
    private _router: Router,
    public _dialog: MatDialog,
    private observer: BreakpointObserver
  ) {
    this.aadConfiguration = new ConfigurationAAD(this._constService);
    this._config = this.aadConfiguration.config;
    const token = localStorage.getItem("adal.idtoken");
    this.routeSubscription = this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.determineSubnav(val.url);
      }
    });
    if (token) {
      this.jwt = this.parseJwt(token);
      this.expTime = new Date(this.jwt.exp);
      this._constService.ipAddress = this.jwt.ipaddr;
      this.currentIp = this._constService.ipAddress;
      this.createNavItems();
    }
  }

  ngOnInit(): void {
    this.tokenResource = this._config.clientId;
    this.isLoggedIn = this._adalService.isAuthenticated;
    if (this.isLoggedIn) {
      this.currentUser = this._adalService.userInfo;
      this._constService.user = this.currentUser;
      this.userSubscription = this._userService
        .retreiveUserDetails()
        .pipe(
          map((data) => {
            this.userDetails = data;
            this._constService.userDetails = data;
            this._constService.system_username = data.systemUser_Username;
            return data;
          }),
          mergeMap(
            (data) => this._userService.getUserType(data.userTypeId))
        )
        .subscribe((userType) => {
          this.userType = userType.code;
          this._constService.permissionType = userType.code;
          const local = localStorage.getItem("adal.idtoken");
          this.getDecodedAccessToken(local);
          this._constService._isTypeAdmin = this._constService.isPermittedToUse(userType.code);
          localStorage.setItem("isTypeAdmin", `${this._constService._isTypeAdmin}`)
        });
    }
    if (this.jwt) {
      this.expTime = new Date(this.jwt.exp);
      this.timer = setInterval(() => {
        this.time = new Date();
      }, 1000);
    }
  }

  ngAfterViewInit() {
    this.observerSubscription = this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          if(this.sidenav) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
          }
        } else {
          if(this.sidenav) {
            this.sidenav.mode = 'side';
            if (this.isLoginPage) {
            this.sidenav.close();
            } else {
              this.sidenav.open();
            }
          }
        }
      });
  }

  public tabChanged(navElement): void {
    console.log(navElement);
    navElement.srcElement.classList.add('active-nav');
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.observerSubscription) {
      this.observerSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  createNavItems() {
    this.navItems = [
      // {
      //   displayName: 'Utilities',
      //   iconName: 'router',
      //   route: 'utilities',
      //   toolTip:'Run common stored procedures and queries that assist in everyday process'
      // },
      {
        displayName: 'Customers',
        iconName: 'person',
        route: 'customers',
        entityId: this._subNav.customerSubnav,
        toolTip:'Find all customers and their nested details',
      },
      {
        displayName: 'Assets',
        iconName: 'devices',
        route: 'assets',
        entityId: this._subNav.assetSubnav,
        toolTip:'Find all assets - an asset being the root entity for scouts, sensors, and other devices',
      },
      {
        displayName: "Alerts",
        iconName: 'folder',
        route: 'alerts',
        toolTip: 'Show all active alerts and remove ones that are no longer active',
      },
      {
        displayName: 'Logs',
        iconName: 'device_hub',
        route: 'manufacturinglogs',
        toolTip:'Scout & Cub manufacturing Logs',
        children: [
          {
            displayName: 'Test Sensors',
            iconName: 'equalizer',
            route: 'manufacturinglogs/testsensors'
          },
          {
            displayName: 'Scouts',
            iconName: 'wifi_tethering',
            route: 'manufacturinglogs/scouts'
          },
          {
            displayName: 'Cubs',
            iconName: 'settings_input_component',
            route: 'manufacturinglogs/cubs'
          }
        ]
      },
      // {
      //   displayName: 'Scouts',
      //   iconName: 'wifi_tethering',
      //   route: 'scouts',
      //   toolTip:'Find details regarding all scouts, installs, and children assets',
      //   children: [
      //     {
      //       displayName: 'Firmwares',
      //       iconName: 'important_devices',
      //       route: 'firmwares',
      //       toolTip:'Find, manage, and create firmwares for scouts',
      //       children: [
      //         {
      //           displayName: 'Models',
      //           iconName: 'list',
      //           route: 'firmwares/models',
      //           toolTip:'List of all the Firmware models available',
      //         },
      //         {
      //           displayName: 'Instances',
      //           iconName: 'list',
      //           route: 'firmwares/instances',
      //           toolTip:'List of all the Firmware instances available for a given FWM',
      //         },
      //         // {
      //         //   displayName: 'Relationships',
      //         //   iconName: 'list',
      //         //   route: 'firmwares/relationships',
      //         //   toolTip:'List of all the FWM and ASM relationships',
      //         // },
      //       ]
      //     }
      //   ]
      // },
      {
        displayName: 'Reports',
        iconName: 'timeline',
        route: 'reports',
        toolTip:'Find reports regarding Scouts, users, customers, and their statistics',
        children: [
          // {
          //   displayName: 'Packets',
          //   iconName: 'mail',
          //   route: 'reports/packets'
          // },
          {
            displayName: 'Weather',
            iconName: 'cloud',
            route: 'reports/weather',
            toolTip: 'Weather Scout Example Window'
          },
          // {
          //   displayName: 'Scouts',
          //   iconName: 'wifi_tethering',
          //   route: 'reports/scouts',
          //   children: [
          //     {
          //       displayName: 'Health',
          //       iconName: 'healing',
          //       route: 'reports/scouts/health'
          //     },
          //     {
          //       displayName: 'Reliability',
          //       iconName: 'assessment',
          //       route: 'reports/scouts/reliability'
          //     },
          //   ]
          // },
          // {
          //   displayName: 'Sensors',
          //   iconName: 'equalizer',
          //   route: 'reports/sensors'
          // },
          // {
          //   displayName: 'Users',
          //   iconName: 'people',
          //   route: 'reports/users'
          // },
          // {
          //   displayName: 'Report Builder',
          //   iconName: 'hardware',
          //   disabled: true,
          //   route: 'reports/builder'
          // }
        ]
      },
      // {
      //   displayName: 'References',
      //   iconName: 'school',
      //   route: 'references',
      //   entityId: this._subNav.referenceSubnav,
      //   toolTip:'Find all knowledge references and manage details',
      // },
      // {
      //   displayName: 'Issues',
      //   iconName: 'bug_report',
      //   route: 'issues',
      //   toolTip:'Support landing page with some basic controls for looking at a scouts packets',
      // },
      // {
      //   displayName: 'Settings',
      //   iconName: 'settings',
      //   route: 'settings',
      //   toolTip:'Manage user details and user settings',
      //   children: [
      //     {
      //       displayName: 'Profile',
      //       iconName: 'user',
      //       route: 'settings/profile',
      //       toolTip:'Find details regarding your profile and your account',
      //     }
      //   ]
      // }
    ];
  }

  checkServer(){
    this._userService.checkServer().subscribe(result => {
      const dialogRef = this._dialog.open(ServerInfoDialogComponent, {
        width: "60vw",
        data: {
          version: result.version,
          versionDbSchemaLastMigrationInConfig: result.versionDbSchemaLastMigrationInConfig,
          versionDbSchemaLastMigrationInDb: result.versionDbSchemaLastMigrationInDb,
          versionDescription: result.versionDescription,
          isOkay: true
        },
      });
      dialogRef.componentInstance.event.subscribe((result) => {
      });
      // console.log(result);
    }, err => {
      const dialogRef = this._dialog.open(ServerInfoDialogComponent, {
        width: "60vw",
        data: {
          version: 'No response from server',
          versionDbSchemaLastMigrationInConfig: 'You may have a bad token. Refresh & try again.',
          versionDbSchemaLastMigrationInDb: 'The server may be down for maintenance. Check with the team.',
          versionDescription: 'The server may need to be restarted. Team should check Azure.',
          isOkay: false
        },
      });
      dialogRef.componentInstance.event.subscribe((result) => {
      });
    })
  }

  refresh(){
    window.location.reload();
  }

  determineSubnav(url: string) {
    this._subNav.assetSubnav = false;
    this._subNav.customerSubnav = false;
    this._subNav.referenceSubnav = false;
    const routeSlashes = "/";
    const baseRoute = url.split(routeSlashes);
    switch (baseRoute[1]) {
      case "assets":
        if (baseRoute[2]) {
          this.isLoginPage = false;
          this._subNav.assetSubnav = true;
          this._subNav.assetId = baseRoute[2];
          this.navItems[1].children =  [
            {
              displayName: 'Details',
              iconName: 'edit',
              route:  `assets/${this._subNav.assetId}`
            }
          ]
        } else {
          this.isLoginPage = false;
          this._subNav.assetSubnav = false;
          this.navItems[1].children =  [];
        }
        return;
      case "customers":
        if (baseRoute[2]) {
          this.isLoginPage = false;
          this._subNav.customerSubnav = true;
          this._subNav.customerId = baseRoute[2];
          this.navItems[0].children =  [
            {
              displayName: 'Assets',
              iconName: 'widgets',
              route: `customers/${this._subNav.customerId}/assets`
            },
            {
              displayName: 'Users',
              iconName: 'person',
              route:  `customers/${this._subNav.customerId}/users`
            },
            {
              displayName: 'Groups',
              iconName: 'group',
              route:  `customers/${this._subNav.customerId}/usergroups`
            },
            {
              displayName: 'Details',
              iconName: 'edit',
              route:  `customers/${this._subNav.customerId}`
            },
            {
              displayName: 'Scouts',
              iconName: 'wifi_tethering',
              route:  `customers/${this._subNav.customerId}/scouts`
            },          
            {
              displayName: 'Ownership',
              iconName: 'history',
              route:  `customers/${this._subNav.customerId}/ownership`
            }
          ]
        } else {
          this.isLoginPage = false;
          this._subNav.customerSubnav = false;
          this.navItems[0].children =  [];
        }
        return;
      case "alerts":
        this.isLoginPage = false;
        return;
      case "references":
        if (baseRoute[2]) {
          this.isLoginPage = false;
          this._subNav.referenceSubnav = true;
          this._subNav.referenceId = baseRoute[2];
          this.navItems[5].children =  [
            {
              displayName: 'Details',
              // iconName: 'school',
              route:  `references/${this._subNav.referenceId}`
            }
          ]
        } else {
          this.isLoginPage = false;
          this._subNav.referenceSubnav = false;
          this.navItems[5].children =  [];
        }
        return;
      case "utilities":
        this.isLoginPage = false;
        return;
      case "scouts":
        this.isLoginPage = false;
        return;
      case "reports":
        this.isLoginPage = false;
        return;
      case "firmwares":
        this.isLoginPage = false;
        return;
      case "manufacturinglogs":
        this.isLoginPage = false;
        return;
      case "settings":
      case "issues":
        this.isLoginPage = false;
        return;
      case "login":
        this.loginCover = true;
        this.isLoginPage = true;
        this.sidenav.close();
        return;
      default:
        break;
    }
  }


  getDecodedAccessToken(token: string): any {
    try {
      const jwt = jwt_decode(token);
      localStorage.setItem("userEmail", jwt.email);
      return jwt;
    } catch (Error) {
      return null;
    }
  }

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  logout() {
    localStorage.setItem("id_token", "");
    localStorage.clear();
    this._adalService.logout();
  }

  get authenticated(): boolean {
    return typeof this._adalService.isAuthenticated === "string" &&
      (<string>this._adalService.isAuthenticated).length > 0
      ? true
      : false;
  }
}
