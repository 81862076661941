
import {map, catchError} from 'rxjs/operators';
import { Component, Output, Input, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { KnowledgeReference } from '../../../shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-references-edit',
  templateUrl: './references-edit.component.html'
})
export class ReferencesEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() reference: KnowledgeReference;
  referenceInitState: KnowledgeReference;
  event: EventEmitter<any> = new EventEmitter();
  referenceId = '';
  loading = true;
  title='';
  toolTip='';
  componentSubscription: Subscription;
  constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _http: HttpClient
      ) {
      }

  ngOnInit() {
    this._route.paramMap.subscribe(params => {
      console.log(params.get('id'));
      this.referenceId = params.get('id');
        this.componentSubscription = this.getReferenceDetails(this.referenceId).subscribe(a => {
          this.reference = a;
          this.loading = false;
          this.title = `Reference: ${this.reference.basicName}`;
          this.toolTip = "Find details regarding this reference. Click to refresh.";
        });
    });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }


  refresh() {
    this.loading = true;
    this.componentSubscription = this.getReferenceDetails(this.referenceId)
    .subscribe(a => {
      this.reference = a;
      this.loading = false;
    });
  }

  onUpdate(reference: KnowledgeReference): void {
    console.log('init reference: ' + this.referenceInitState);
    console.log('edited reference: ' + this.reference);
    const patchReference = {
      basicName: reference.basicName,
      basicDescription: reference.basicDescription
    };
    this.componentSubscription = this.updateReference(reference.id, patchReference).subscribe(update => {
      console.log(update);
      this._router.navigate(['/references']);
    });
  }

  onNoClick() {
    this._router.navigate(['/references']);
  }

  onSubmit(): void {
    this.event.emit({init: this.referenceInitState, change: this.reference, });
  }

  navigateReferenceOverview() {
    this._router.navigate(['/references']);
  }

  // CRUD functions
  getReferenceDetails(id: string): Observable<KnowledgeReference> {
    const params: any = {
        'ZUMO-API-VERSION': '2.0.0'
      };
      return this._http
      .get<KnowledgeReference>(environment.apiUrl + `tables/KnowledgeReference/${id}`, { params: params }).pipe(
      map((resp: any) => {
          return resp;
      }), catchError(err => of(err)));
  }

  updateReference(id: string, reference: any): Observable<KnowledgeReference> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0',
      'Content-Type': 'application/json'
    };
    const URL = environment.apiUrl + `tables/KnowledgeReference/${id}`;
    return this._http
    .patch(URL, reference, {params: params}).pipe(
    map((res: any) => {
      return res;
    }), catchError(err => of(err))); // debug
  }
}
