<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center" fxLayoutGap="25px">
    <mat-card *ngFor="let entity of entities" fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" >
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{entity.title}}</mat-card-title>
        <mat-card-subtitle>{{entity.subtitle}}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="entity.url" alt="Photo of {{entity.title}}">
      <mat-card-content>
        <p>
          {{entity.content}}
        </p>
      </mat-card-content>
    </mat-card>
  </div>