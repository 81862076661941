<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  [items]="actionButtons"
  (refresh)="getFirmwares()"
  (actionListClicked)="actionsClicked($event)"
>
</roots-page-header>
<section style="min-height: 90vh;">
  <div *ngIf="!initLoad">
    <div class="col-12 firmware-card" style="float: left">
      <div style="width: 100%">
        <div class="firmware-card-title">
          <p class="list-inline-item font-weight-bold text-uppercase">
            Select FWI
          </p>
          <p
            class="list-inline-item font-weight-bold text-uppercase"
            style="position: sticky; float: right"
          >
          Assign to FWM
          </p>
        </div>
        <div class="firmware-card-title">
          <p class="text small-text">
            Select a FWI by clicking the top-left box and then save it to a FWM (top-left green save icon) to create the complete Firmware relationship. If you use local search bar you can filter either the FWM's or the FWI's
          </p>
          <hr style="margin: 0" />
        </div>
        <div class="group-box firmware-card-subtitle col-6" style="float: left">
          <div
            *ngFor="let model of assetModelsDataSource.filteredData; index as i"
            style="padding: 0"
            [ngClass]="{ firmwareBox: model.assignFirmware }"
            class="fb"
          >
            <div class="full-col">
              <button
                mat-icon-button
                color="secondary"
                matTooltip="Click to assign to FWM"
                matTooltipPosition="above"
                (click)="firmwareAssign(model)"
                [ngClass]="{ firmwareSelected: model.assignFirmware }"
              >
              <!-- <mat-checkbox></mat-checkbox> -->
              <mat-icon class="mat-18 action-btn">select_all</mat-icon>
              <!-- <p class="text small-text">
                Assign to FWM
              </p> -->
              </button>
              {{ model.basicName }}
              <!-- <button
                mat-icon-button
                color="primary"
                matTooltip="View Firmware's Details"
                style="float: right;"
                matTooltipPosition="above"
                (click)="fwiDetails(model)"
              >
                <mat-icon class="mat-18">edit</mat-icon>
              </button> -->
              <br />
              <hr />
              <p class="text small-text">
                {{ model.basicDescription }}
              </p>
              <p class="text small-text">
                {{ model.createdAt | date: "short" }}
              </p>
            </div>
            <div class="firmware-col">
              <p class="text small-text">Code: {{ model.code }} 
              </p>
              <button
                mat-icon-button
                color="primary"
                matTooltip="View Firmware's Details"
                matTooltipPosition="above"
                (click)="getAssetFirmwareModel(model)"
              >
                Show Connected FWMs
              </button>
              <!-- <p class="text small-text">
                {{ model.identifier }}
              </p> -->
            </div>
            <div *ngIf="model.expanded">
              <div *ngIf="model.firmwares; else noFirmware" class="group-box">
                <hr style="margin: 0" />
                <p style="margin: 0">
                  Firmwares Models assigned to this Asset Model:
                </p>
                <hr />
                <p
                  class="text small-text"
                  style="text-decoration: underline"
                  *ngFor="let fir of model.firmwares"
                  (click)="grabFirmwareDetails(fir)"
                >
                  {{ fir.name }}
                </p>
                <hr style="margin: 0" />
              </div>
              <ng-template #noFirmware class="group-box">
                <p style="margin: 0">
                  Firmwares Models assigned to this Asset Model:
                </p>
                <hr />
                <p class="text small-text">No Firmware Models found</p>
                <hr style="margin: 0" />
              </ng-template>
            </div>
            <hr />
          </div>
        </div>
        <div class="group-box firmware-card-subtitle col-6" >
          <div
            *ngFor="let firmware of firmwaresDataSource.filteredData; index as i"
            style="padding: 0"
          >
            <div class="full-col">
              <button
                mat-icon-button
                color="secondary"
                matTooltip="Save FWI to this FWM when highlighted"
                matTooltipPosition="above" 
                (click)="createFirmwareAssetModel(firmware)"
                [ngClass]="{ firmwareSelected: selected, firmwareNotSelected: !selected }"
              >
                <mat-icon class="mat-18 action-btn">save</mat-icon>
              </button>
              {{ firmware.name }}
              <button
                mat-icon-button
                color="primary"
                matTooltip="View Firmware's Details"
                matTooltipPosition="above"
                (click)="grabFirmwareDetails(firmware)"
              >
                <mat-icon class="mat-18">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                color="warn"
                matTooltip="Delete Firmware Version"
                matTooltipPosition="above"
                (click)="deleteFirmwares(firmware)"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <br />
              <p class="text small-text">
                {{ firmware.description }}
              </p>
              <p class="text small-text">
                {{ firmware.whenBegin | date: "short" }} -
                {{ firmware.whenEnd | date: "short" }}
              </p>
            </div>
            <div class="full-col">
              <p class="text small-text">Code: {{ firmware.code }}</p>
              <p class="text small-text">
                {{ firmware.firmwareVersion }}
              </p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <br />
    <hr />
  </div>
</section>
