<mat-card class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-4" style="float: left">
    <h3 class="text label-header-text">{{header}}</h3>
    <mat-form-field *ngFor="let f of fields">
        <mat-label>{{f.name}}</mat-label>
        <input *ngIf="!isSelectComponent; else isSelectComponent"
          matInput
          placeholder="f.name"
          required
        />
      </mat-form-field>
      <ng-template #isSelectComponent>
        <mat-select
          matInput
          placeholder="f.name"
          required
          name="f.name"
        >
          <mat-option
            *ngFor="let opt of options | sort: 'key'"
            [value]="opt.value"
          >
            {{ opt.key }}
          </mat-option>
        </mat-select>
      </ng-template>
</mat-card>