import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
@Component({
  selector: "roots-main-menu-button",
  templateUrl: "./roots-main-menu-button.component.html",
  styleUrls: ["./roots-main-menu-button.component.scss"],
})
export class RootsMainMenuButtonComponent implements OnInit {
  @Output() menuEvent: EventEmitter<any> = new EventEmitter();
  @Input("isOpen") open: boolean;
  constructor() {
    this.isOpen();
  }
  ngOnInit(): void {
    this.isOpen();
  }

  menuClicked() {
    const context = this;
    document
      .querySelector("button")
      .addEventListener("click", function (e: any) {
        context.clickEvent(e);
      });
  }

  clickEvent(e: any) {
    if (this.open) {
      e.currentTarget.classList.remove("on");
    } else {
      e.currentTarget.classList.add("on");
    }
    this.menuEvent.emit(this.open);
  }

  isOpen() {
    if (this.open) {
      document
      .querySelector("button")
      .classList.add("on")
    } else {
      document
      .querySelector("button")
      .classList.remove("on")
    }
  }
}
