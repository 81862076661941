
import {catchError, map, mergeMap} from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGroup } from '../../../../shared/interfaces/user-group';
import { CustomerService } from '../../../../shared/services/customer.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { AdminService } from '../../../../shared/services/admin.service';
import { SnackService } from '../../../../shared/services/extra/snack.service';
import { Notes } from '../../../../shared/interfaces/notes';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user-group-edit.component.html'
})
export class UserGroupEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() userGroup: UserGroup;
  userGroupInitState: UserGroup;
  event: EventEmitter<any> = new EventEmitter();
  types = [];
  notes: Notes[] = [];
  customerId = '';
  userGroupId = '';
  loading = true;
  users: any;
  title='';
  toolTip='';
  customerSubscription: Subscription;
  constructor(
      private _route: ActivatedRoute,
      private _router: Router,
      private _customerService: CustomerService,
      private _adminService: AdminService,
      private _snack: SnackService
      ) {
      }

  ngOnInit() {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        const uid = params.get('uid');
        this.userGroupId = uid;
        this.customerId = id;
        return uid;
      }),
      mergeMap( uid => {
        const getUserGroupDetails = this._customerService.getUserGroupDetails(uid);
        const getGroupsUsers = this._adminService.getAdminUserGroupMember(uid);
        const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.userGroupId)
        .pipe(catchError((err) => of(undefined)));

        return forkJoin([getUserGroupDetails, getGroupsUsers, getNotes]);
        })).subscribe(results => {
        const details = results[0];
        const users = results[1];
        const notes = results[2];

        this.notes = notes;
        this.userGroup = details;
        this.users = users;
        this.title = `UG: ${this.userGroup.basicName}`;
        this.toolTip = "Find details regarding this usergroup's users and entity details. Click to refresh.";
        this.loading = false;
      })
  }

  onNoClick() {
    this._router.navigate([`/customers/${this.customerId}/usergroups`]);
  }

  onUpdate(userGroup: UserGroup): void {
    this.loading = true;
    const patchUserGroup: any = {
      basicDescription: userGroup.basicDescription,
      basicName: userGroup.basicName
    //   customerTypeId: user.customerTypeId
    };
    this.customerSubscription = this._customerService.updateUserGroup(userGroup.id, patchUserGroup).subscribe(update => {
      this.loading = false;
      this.refresh();
    });
  }

  refresh() {
    this.loading = true;
    const getUserGroupDetails = this._customerService.getUserGroupDetails(this.userGroupId);
    getUserGroupDetails.subscribe(results => {
      this.userGroup = results;
      this.loading = false;
      this._snack.displaySuccess(`${this.userGroup.basicName} successfully updated`)
    })
    // this.customerSubscription = getUserGroupDetails;
  }
  
  noteAdded($event) {
    if($event){
      this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.userGroupId).subscribe(result => {
        this.notes = result;
      })
    }
  }
  
  ngOnDestroy() {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
  }

  navigateToUser(user:any){
    // this._router.navigate([`/customers/${this.customerId}/usergroups`]);
  }

  public onChange = (event) => {
    this.selectEmitt.emit(event.value);
  }
}

