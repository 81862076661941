import { map, tap, mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../shared/interfaces/customer';
import { AdminService } from '../../../shared/services/admin.service';
import { AdminAsset } from '../../../shared/interfaces/admin/admin-asset';
import { forkJoin, Subscription } from 'rxjs';
import { AssetOwnership } from '../../../shared/interfaces/asset-ownership';
import { CustomerService } from '../../../shared/services/customer.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { RootsPageComponentConfig, RootsActionMenuItem, ActionType, Asset, ConfirmationDialogComponent, ConstantService } from '../../../shared';
import { SnackService } from '../../../shared/services/extra/snack.service';
import { AssignAssetFormComponent, XferFormComponent } from '..';

@Component({
  selector: 'app-customer-assets',
  templateUrl: './customer-assets.component.html'
})
export class CustomerAssetsComponent implements AfterViewInit, OnInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      'name',
      'scoutOrSensorName',
      'assetTypeName',
      'code'
    ],
    message: "",
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: 'Assign New Asset Customer',
      icon: 'add',
      toolTip: 'Assign a new asset to the customer for ownership - A scout asset type will also transfer the health assettype sensor paired with it',
      type: ActionType.Create
    }
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "Details",
      toolTip:
        'Navigate to this Assets details',
      icon: "edit",
      type: 9,
    },
    {
      name: "Transfer",
      toolTip:
        'Transfer this unit configuration to another customer',
      icon: "swap_horiz",
      type: 10,
    },
    {
      name: "Terminate",
      toolTip:
        "Terminate ownership of this asset",
      icon: "close",
      type: 11,
    },
  ];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() customer: Customer;
  event: EventEmitter<any> = new EventEmitter();
  assets?: AdminAsset[] = [];
  initAssets?: AdminAsset[];
  userGroups: any[];
  ownerships: AssetOwnership[] = [];
  customerId = '';
  noAssets = false;
  searchSubscription: Subscription;
  userSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _snack: SnackService,
    private _router: Router,
    private _adminService: AdminService,
    private _constService: ConstantService,
    public _dialog: MatDialog,
    private _customerService: CustomerService,
    public searchBarService: SearchBarService
  ) {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.customerId = id;
        return id;
      }),
      mergeMap(id => {
        const getCustomerDetails = this._customerService.getCustomerDetails(id);
        const getCustomersAssets = this._adminService.getCustomersAssets(id);

        return forkJoin([getCustomerDetails, getCustomersAssets]);
      })).subscribe(results => {
        const customer = results[0];
        const customersAssets = results[1];

        this.customer = customer;
        this._config.title = `${customer.basicName} Assets`;
        this.assets = customersAssets;
        this.initAssets = customersAssets;
        this._config.toolTip = "Find any Asset assigned to this Customer. Click to refresh.";

        this.populateScoutOrSensorName();
        this._config.dataSource.data = this.assets as AdminAsset[];
        this._config.loading = false;
        //this.pageEvent();
        if (this.assets.length < 1) {
          this.noData();
        }
      })
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this._config.dataSource);
      }
    })
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  topActionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.openDialog(this.customer);
        break;
      default:
        break;
    }
  }

  refresh() {
    this.rePopulateAssets();
  }

  openDialog(customer: Customer) {
    const title = "Available Assets";
    const dialogRef = this._dialog.open(AssignAssetFormComponent, {
      width: "50vw",
      height: "70vh",
      data: { title, customer },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        this.rePopulateAssets();
      }
    });
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 9:
        this.navigateToAsset(item.id)
        break;
      case 10:
        //TODO: Query
        if (item.assetTypeId !== 'da921dad-912a-4d7f-abd9-a03274ac9094') {
          this.xferOwnership(item)
        } else {
          this._snack.displayError("Asset has to be type scout. If this is a health sensor, transfer the base asset and it will also transfer the health.");
        }
        break;
      case 11:
        //TODO: Query
        if (item.assetTypeId !== 'da921dad-912a-4d7f-abd9-a03274ac9094') {
          this.deleteDialog(item)
        } else {
          this._snack.displayError("Asset has to be type scout. If this is a health sensor, delete the base asset and it will also delete the health.");
        }
        break;
      default:
        break;
    }
  }

  navigateToAsset = (id: string) => {
    const url = `/assets/${id}`;
    this._router.navigate([url]);
  };

  xferOwnership(asset: Asset, customer = this.customer) {
    if (asset) {
      const dialogRef = this._dialog.open(XferFormComponent, {
        width: "60vw",
        data: { asset, customer },
      });
      dialogRef.componentInstance.event.subscribe((result) => {
        if (result) {
          console.log(result);
          this.rePopulateAssets();
        }
      });
      console.log("change asset: " + asset);
    }
  }

  deleteDialog(assetOwnership: AssetOwnership): void {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `You are about to terminate an assets ownership from customer: ${this.customer.basicName}. Please confirm ownership termination for asset: ${assetOwnership.name}.`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log("Yes clicked");
        console.log(result);
        if (result) {
          // added table delete because full delete doesn't always work when defaults weren't setup
          return this.endOwnership(assetOwnership);
        }
      }
    });
  }

  endOwnership(assetOwnership: AssetOwnership) {
    const healthSensor = this.findHealthSensor(assetOwnership.code);
    const patchAsset: any = {
      whenEnd: this._constService.convertDateUTC(new Date()),
    };
    if (healthSensor === null || healthSensor === undefined) {
      this.userSubscription = this._customerService
        .removeOwnership(assetOwnership.currentAssetOwnershipId, patchAsset)
        .subscribe((update) => {
          console.log(update);
        });
      this._router.navigate(["/customers/" + `${this.customerId}/` + "assets"]);
      console.log("end asset: " + assetOwnership);
    } else {
      const removeScout = this._customerService.removeOwnership(
        assetOwnership.currentAssetOwnershipId,
        patchAsset
      );
      const removeHealth = this._customerService.removeOwnership(
        healthSensor.currentAssetOwnershipId,
        patchAsset
      );

      forkJoin([removeScout, removeHealth]).subscribe((results) => {
        const scoutRemoved = results[0];
        const healthRemoved = results[1];
        console.log("Scout: " + scoutRemoved + "; Health: " + healthRemoved);
        this.rePopulateAssets();
      });
    }
    this.rePopulateAssets();
  }

  findHealthSensor(code: string): any {
    const healthSensor = code + "_HS";
    let sensorFound: any;
    this.assets.find((asset) => {
      if (asset.code.includes(healthSensor)) {
        sensorFound = asset;
        return sensorFound;
      } else {
        sensorFound = null;
        return sensorFound;
      }
    });
    return sensorFound;
  }

  noData() {
    this.noAssets = true;
    const asset: AdminAsset = {
      currentAssetOwnership: null,
      currentAssetOwnershipId: null,
      assetModelId: 'N/A',
      whenBegin: Date.now().toString(),
      whenEnd: 'N/A',
      code: 'N/A',
      name: 'No Asset Ownership',
      description: 'This is a placeholder',
      id: 'N/A',
      assetStatusId: 'N/A',
      content: null
    };
    this.assets.push(asset);
    this.populateScoutOrSensorName();
    this._config.dataSource.data = this.assets as AdminAsset[];
    // console.log('User has no assets: ' + this.noAssets);
  }

  changeSearch(newItem: string) {
    console.log(newItem);
  }

  tabSelectionChanged(event) {
    // Get the selected tab
    let selectedTab = event.tab;
    console.log(selectedTab);
  }

  populateScoutOrSensorName() {
    for (let i = 0; i < this.assets.length; i++) {
      if (this.assets[i].currentAssetOwnership.scoutName != null &&
        this.assets[i].currentAssetOwnership.scoutName != this.assets[i].name) {
        this.assets[i]["scoutOrSensorName"] = this.assets[i].currentAssetOwnership.scoutName;
      }
      else if (this.assets[i].currentAssetOwnership.sensorName != null &&
        this.assets[i].currentAssetOwnership.scoutName != this.assets[i].name) {
        this.assets[i]["scoutOrSensorName"] = this.assets[i].currentAssetOwnership.sensorName;
      }
    }
  }

  pullGroups() {
    for (let i = 0; i < this.assets.length; i++) {
      const asset = this.assets[i];
      if (
        asset.assetModelId.includes(
          '25A57B26-D5C9-4669-BDBF-C0AB80AF2635' ||
          'fbd23ea7-8b40-40d8-a97e-d0d4778c5a63'
        )
      ) {
        this._adminService
          .getScoutGroupMember(asset.id)
          .subscribe(scoutGroups => {
            console.log(scoutGroups);
          });
      } else {
        console.log('AssetModelId not a scout: ' + asset.assetModelId);
      }
    }
  }

  onNoClick() {
    this._router.navigate(['/customers']);
  }

  rePopulateAssets() {
    this._config.loading = true;
    this._adminService.getCustomersAssets(this.customerId)
      .subscribe(assets => {
        this.assets = assets;
        this._config.loading = false;
        this._snack.displaySuccess("Customer assets refreshed.");
        this.populateScoutOrSensorName();
        this._config.dataSource.data = this.assets as AdminAsset[];
        // const obsData: Observable<any[]>;
        if (this.assets.length < 1) {
          this.noAssets = true;
          const asset: AdminAsset = {
            currentAssetOwnership: null,
            currentAssetOwnershipId: null,
            assetModelId: 'N/A',
            whenBegin: Date.now().toString(),
            whenEnd: 'N/A',
            code: '',
            name: 'No Asset Ownership',
            description: '',
            id: 'N/A',
            assetStatusId: '',
            content: null
          };
          this.assets.push(asset);
          this.populateScoutOrSensorName();
          this._config.dataSource.data = this.assets as AdminAsset[];
          console.log('User has no assets: ' + this.noAssets);
        }
      });
  }
}
