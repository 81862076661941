<div (click)="clickEvent($event)">
    <button class="dropdownImg">
        <svg class="menu" viewBox="0 0 480 120" width="100%" height="100%">
          <g>
            <path class="line top" d="M35,35h50c14.1,0,50.6,13,20.5,53.5s-121.9,21.6-94.4-40.3S111.6,8.4,85,35L35,85"/>
            <path class="line bottom" d="M35,85h50c14.1,0,50.6-13,20.5-53.5S-16.4,9.9,11.1,71.8S111.6,111.6,85,85L35,35"/>
            <line class="line cross" x1="35" y1="60" x2="85" y2="60"/>
          </g>
          <svg width="100%" height="100%">
          <g> 
          <text class="roots-text" transform="matrix(1 0 0 1 100 95)" fill="white" font-size="80">ROOTS</text>
          </g>
        </svg>
        </svg>
        <!-- <div class="unit">
            <div class = "unit-centered">
              <div class = "unit-solar"></div>
              <div class = "unit-top"></div>
              <div class = "unit-1"></div>
              <div class = "unit-1-base"></div>
              <div class = "unit-2"></div>
              <div class = "unit-3"></div>
              <div class = "unit-4"></div>
          </div>
        </div> -->
      </button>
</div>
  