<div
  class="col-12"
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
</div>
<div mat-dialog-content>
  <div class="col-12">
    <mat-card-title>
      <p class="list-inline-item font-weight-bold text-uppercase">
        ({{ allAssets.length }}) Assets found
      </p>
    </mat-card-title>
    <mat-card-subtitle>
      <ul
        *ngFor="let asset of allAssets | sort:'basicName'; index as i"
      >
      {{ asset.basicName }} - ({{ asset.code }})
      <button style="float: right;"
      (click)="navigate(asset)" 
      class="btn btn-secondary"
      mat-icon-button
      color="primary">
      <mat-icon>
        search
      </mat-icon>
      </button>
        <hr />
      </ul>
    </mat-card-subtitle>
  </div>
</div>
<div mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-raised-button
    color="primary"
    (click)="okayClick()"
  >
    Close
  </button>
</div>
