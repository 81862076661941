import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Settings } from '../../../interfaces/settings';
import { AdminService } from '../../../services/admin.service';
import { SnackService } from '../../../services/extra/snack.service';
import { retryWhen } from 'rxjs/operators';
@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.scss']
})
export class NewUserDialogComponent {
  input = '';
  searchedAsset: any;
  selectionMade = false;
  currentSettings: Settings;
  userDetails: any;
  status: string = '';
  userReminder: string = '';
  constructor(
    public dialogRef: MatDialogRef<NewUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    private _snack: SnackService
    ) {
      console.log(this.data);
      this.currentSettings = this.data.settings;
      this.userDetails = this.data.userDetails;
  }

  saveSettings() {
    console.log(this.data);
    // populate the correct settings
    const settingsObject: Settings = {
      overlord: this.currentSettings.overlord,
      darkTheme: this.currentSettings.darkTheme,
      tutorialCompleted: this.currentSettings.tutorialCompleted,
      userStatus: this.currentSettings.userStatus,
      userReminder: this.currentSettings.userReminder
    };
    const stringifiedSettings = JSON.stringify(settingsObject);
    //const content = JSON.stringify({content: stringifiedSettings}) 
    this._adminService.patchTableUsersSettings(this.userDetails.id, stringifiedSettings).subscribe(result => {
      console.log(result);
      this._snack.displaySuccess('Settings saved successfully')
      this.dialogRef.close();
    }, err => {
      this._snack.displayError('An error occurred while saving settings')
      this.dialogRef.close();
    })
  }

  resetToNull(){
    const settingsObj = null;
    this._adminService.patchTableUsersSettings(this.userDetails.id, settingsObj).subscribe(result => {
      console.log(result);
      this._snack.displaySuccess('Settings reset to null successfully')
      this.dialogRef.close();
    }, err => {
      this._snack.displayError('An error occurred while saving null settings')
      this.dialogRef.close();
    })
  }

  clearReminder(){
    this.currentSettings.userReminder = '';
    this.saveSettings();
  }
  
  clearStatus() {
    this.currentSettings.userStatus = '';
    this.saveSettings();
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  swapTheme() {
    return;
  }
}
