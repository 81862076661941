import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportsService } from "../../shared/services/reports.service";
import {
  ReportsComponent,
  GeneralreportComponent,
  ScoutreportComponent,
  GReportViewComponent,
  SensorreportComponent,
  PacketreportComponent,
  ReportsRoutingModule,
  BuilderComponent,
} from ".";
import { HealthLogsComponent } from "./scout/health-logs/health-logs.component";
import { ScoutLogsComponent } from "./scout/scout-logs/scout-logs.component";
import { PacketReliabilityComponent } from "./scout/packet-reliability/packet-reliability.component";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { RouterModule } from "@angular/router";
import { CurrentUsersComponent } from "./priority/current-users/current-users.component";
import { CurrentScoutsComponent } from './priority/current-scouts/current-scouts.component';
import { HistoricalScoutsComponent } from './priority/historical-scouts/historical-scouts.component';
import { HistoricalAssetsComponent } from "./priority/historical-assets/historical-assets.component";
import { SharedModule } from "../../shared/shared.module";
import { MatModule } from "../../core";
import { VoltageDialogComponent } from "./scout/health-logs/dialog/voltage-dialog.component";
import { ChartsModule } from "../../shared/charts/charts.module";
import { MatTableExporterModule } from "mat-table-exporter";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { WeatherComponent } from './weather/weather.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
  suppressScrollY: false,
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReportsRoutingModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    SharedModule,
    ChartsModule,
    MatModule,
    RouterModule,
    MatTableExporterModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    ReportsComponent,
    GeneralreportComponent,
    ScoutreportComponent,
    GReportViewComponent,
    SensorreportComponent,
    PacketreportComponent,
    BuilderComponent,
    HealthLogsComponent,
    ScoutLogsComponent,
    PacketReliabilityComponent,
    CurrentUsersComponent,
    CurrentScoutsComponent,
    HistoricalScoutsComponent,
    HistoricalAssetsComponent,
    VoltageDialogComponent,
    BuilderComponent,
    WeatherComponent,
  ],
  providers: [
    ReportsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [ReportsComponent],
})
export class ReportsModule { }
