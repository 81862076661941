import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AdminService } from '../../../shared/services/admin.service';

@Component({
  selector: 'app-scouts-firmware',
  templateUrl: './scouts-firmware.component.html',
  styleUrls: ['./scouts-firmware.component.scss']
})
export class ScoutsFirmwareComponent implements OnInit {
scoutId = '';
firmwares: any[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _adminService: AdminService
    ) { }

  ngOnInit(): void {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.scoutId = id;
        return id;
      }),
      mergeMap( id => {
        const getFirmwares = this._adminService.getFirmwareInstance(id);
        return forkJoin([getFirmwares]);
      })
    ).subscribe(results => {
      const firmwares = results[0];
      this.firmwares = firmwares;
    }, 
    throwError, 
    () => {
      console.log('an error has occurred');
    });
  }

}
