<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  (refresh)="refresh()"
>
</roots-page-header>
<div class="data-wrapper gray">
  <mat-card class="col-12" style="float: left" *ngIf="reference">
    <h3>Details</h3>
    <hr/>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        placeholder="Name"
        [(ngModel)]="reference.basicName"
        required
      />
    </mat-form-field>
  
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input
        matInput
        placeholder="Description"
        [(ngModel)]="reference.basicDescription"
        required
      />
    </mat-form-field>
  
    <mat-form-field>
      <mat-label> Code</mat-label>
      <input matInput placeholder="Code" [(ngModel)]="reference.code" disabled />
    </mat-form-field>
  
    <mat-form-field>
      <mat-label> Id</mat-label>
      <input matInput placeholder="Id" [(ngModel)]="reference.id" disabled />
    </mat-form-field>
    <button
      mat-raised-button
      type="submit"
      (click)="onUpdate(reference)"
      style="width: fit-content; float: left"
      color="primary"
    >
      Update
    </button>
    <button
      mat-raised-button
      class="close"
      (click)="onNoClick()"
      color="warn"
      style="width: fit-content"
    >
      Cancel
    </button>
  </mat-card>
</div>
