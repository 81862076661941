<!-- <button (click)="addItem($event)"></button> -->
<gridster [options]="options">

  <ng-container *ngFor="let item of dashboard">

    <gridster-item [item]="item">

      <!-- Your content goes here -->
      <div class="gridster-item-content">
        Some content to select and click without dragging the widget
    </div>
    <div class="item-buttons">
      <button md-icon-button md-raised-button class="drag-handler">
        <md-icon>open_with</md-icon>
      </button>
    </div>

    </gridster-item>

  </ng-container>

</gridster>