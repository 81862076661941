import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.scss']
})
export class AssetViewComponent implements OnInit {
@Input('type') type: string;
@Input() asset: any;
imageUrl = '';

  constructor() { }

  ngOnInit(): void {
    switch (this.type) {
      case 'value':
        
        break;
    
      default:
        break;
    }
  }
}
