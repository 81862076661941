import { Sensors } from "./sensors";
import { TestResult } from "./testResults";
import { Modem } from "./modem";

export class ScoutLog {
    sequenceNumber: string;
    boardVersion: string;
    sleepTime: string;
    batteryVoltage: string;
    serial: string;
    modem: Modem;
    sensors: Sensors[];
    testResult: TestResult;
}