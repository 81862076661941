import { Component, Input } from '@angular/core';
@Component({
  selector: 'email-template',
  templateUrl: './EmailTemplate.html'
})
export class EmailTemplate {
  @Input() name: string = '';
  @Input() email: string = '';
  constructor(
    ) {
  }
}
