import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-page-info-dialog',
  templateUrl: './page-info-dialog.component.html'
})
export class PageInfoDialogComponent {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<PageInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public pageInfo: PageDialog,
    ) { }

onNoClick(): void {
    this.event.emit(true);
    this.dialogRef.close();
  }
}

export interface PageDialog{
  name: string;
  description: string;
  picture?: string;
}