import { Component, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Output() emitData = new EventEmitter();
  @Output() emitPage = new EventEmitter<{}>();
  pageSizes: number[] = [5, 10, 15, 25, 50];
  pageIndex = 0;
  rangeStart = 1;
  rangeEnd = 10;
  range: any = {
    start: this.rangeStart,
    end: this.rangeEnd
  };
  total: number;
  numberOfPages: number;
  pages: any[];
  pageSize: number;
  isLastPage = false;
  @Input() initData: any[] = [];
  private _data: any[] = [];
  @Input() set data(data: any[]) {
    if (data !== undefined && data.length > 0) {
      setTimeout(() => {
        this._data = data;
        this.pages = this.buildPages(this.pageSize, this.data);
        // this.navigate(0);
        this.emitPage.emit({page: this.pages[this.pageIndex]})
        this.total = data.length;
      });
      
    }
  }
  get data() {
    return this._data;
  }

  constructor() {
    this.pageSize = this.pageSizes[1];
    console.log(this.data);
  }

  buildPages(chunkSize: number, data: any[]) {
    const arr = this.pagesChunked(chunkSize, data);
    this.emitData.emit(this.pages);
    return arr;
  }

  pagesChunked(chunkSize: number, data: any[]) {
    return Array.from(Array(Math.ceil(data.length / chunkSize)),
      (_, i) => data.slice(i * chunkSize, i * chunkSize + chunkSize));
  }

  perPageChange(pageSize: number) {
    this.pageSize = pageSize;
    if (pageSize <= this.total) {
      this.pages = this.buildPages(this.pageSize, this.data);
      this.navigate(0);
      console.log(pageSize);
    } else {
      this.pages = this.buildPages(this.pageSize, this.data);
      this.navigate(0);
      console.log(pageSize);
    }
  }

  navigate(index: number) {
    this.pageIndex += index;
    if (this.pageIndex < 0) {
      this.pageIndex = 0;
      console.log(this.pageIndex);
    }
    this.emitPage.emit({page: this.pages[this.pageIndex]})
    this.processPageRanges();
    console.log(this.pageIndex);
  }

  processPageRanges() {
    if (this.pages.length > 0) {
      if (this.pageIndex < 1) {
        this.range.start = 1;
        this.range.end = this.pages[this.pageIndex].length;
      } else {
        let appendStart = 1;
        let appendEnd = this.pageSize;
        for (let i = 0; i < this.pageIndex; i++){
          appendStart += this.pages[this.pageIndex - 1].length;
          appendEnd += this.pages[this.pageIndex - 1].length;
          // appendStart = this.pages[this.pageIndex - 1].length + 1;
          // appendEnd = (this.pages[this.pageIndex - 1].length + this.pages[this.pageIndex].length);
        }
        this.range.start = appendStart;
        this.range.end = appendEnd;
        if (this.range.end > this.total) {
          this.range.end = this.total;
        }
      }
    }
  }
}
