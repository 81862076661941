
export interface RootsActionMenuItem {
    name: string;
    icon?: string;
    link?: string;
    toolTip?: string;
    type?: ActionType;
    info?: (pageInfo: any) => void;
}

export enum ActionType
{
    Create,
    Update,
    Delete,
    Dialog,
    Link,
    Info,
    Unique,
    Select = 99
}