<div mat-dialog-content>
    <mat-card *ngIf="pageInfo">
        <mat-card-header>
              <mat-card-title>
                <p class="list-inline-item font-weight-bold text-uppercase">
                  {{pageInfo.name}}
                </p>
              </mat-card-title>
              <mat-card-subtitle class="group-dialog-box">
                  {{pageInfo.description}}
              </mat-card-subtitle>
          </mat-card-header>
    </mat-card>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onNoClick()">Okay</button>
</div>