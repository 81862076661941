import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AdminService } from '../../../shared/services/admin.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { SearchBarService } from '../../../shared/services/searchbar.service';

@Component({
  selector: 'app-customer-scouts',
  templateUrl: './customer-scouts.component.html'
})
export class CustomerScoutsComponent implements OnInit, OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  scouts?: {}[] = [];
  initScouts?: any[];
  title='';
  toolTip='';
  customerId = '';
  noScouts = false;
  message: string;
  loading = true;
  customer: any;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'assetName',
    'name',
    'content',
    'code',
    'description',
    'statistics', 
    'firmwareVersion'
  ];
  searchSubscription: Subscription;
  adminSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _customerService: CustomerService,
    public searchBarService: SearchBarService
    ) 
  {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.customerId = id;
        return id;
      }),
      mergeMap( id => {
        const getCustomerDetails = this._customerService.getCustomerDetails(id)
        .pipe(catchError((err) => of(undefined)));
        const getCustomersScouts = this._adminService.getCustomersScouts(id)
        .pipe(catchError((err) => of(undefined)));

        return forkJoin([
          getCustomerDetails, 
          getCustomersScouts
        ]);
      })).subscribe(results => {
        const customer = results[0];
        const customersScouts = results[1];
        this.customer = customer;
        this.scouts = customersScouts;
        this.dataSource = new MatTableDataSource(customersScouts);
        this.title = customer.basicName + ' Scouts';
        this.toolTip = 'Details regarding a given customers Scouts';
        this.loading = false;
      })
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this.message = message;
    })
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
  }

  refresh($event) {
    this.loading = true;
    this.adminSubscription = this._adminService.getCustomersScouts(this.customerId).subscribe(results => {
      this.dataSource = new MatTableDataSource(results);
      this.loading = false;
    })
  }
}
