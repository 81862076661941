<!-- <mat-progress-bar
  style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
  *ngIf="loading"
  mode="query"
>
</mat-progress-bar> -->
<h3
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  {{ data }}
</h3>
<div mat-dialog-content>
  <app-file-upload (onNewFileEvent)="onChangeFile(event)"> </app-file-upload>
</div>
<div mat-dialog-content>
  <form class="customer-form" #firmwareForm="ngForm" (ngSubmit)="onSubmit()">
    <!-- <mat-form-field>
      <input
        matInput
        placeholder="Firmware Code"
        type="text"
        required
        [(ngModel)]="firmwareInstancePost.code"
        name="code"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Firmware Content"
        type="text"
        required
        [(ngModel)]="firmwareInstancePost.content"
        name="content"
      />
    </mat-form-field>-->
    <!-- <mat-form-field>
      <textarea
        matInput
        placeholder="File"
        required
        [(ngModel)]="firmwareInstancePost.file"
        name="file"
      ></textarea>
    </mat-form-field> -->
    <mat-form-field>
      <input
        matInput
        placeholder="Firmware Version"
        type="text"
        required
        [(ngModel)]="firmwareInstancePost.firmwareVersion"
        name="firmwareVersion"
      />
    </mat-form-field>
    <mat-form-field>
      <textarea
        matInput
        placeholder="Firmware Description"
        [(ngModel)]="firmwareInstancePost.description"
        name="description"
      ></textarea>
    </mat-form-field>
    <!-- <mat-form-field>
      <input
        matInput
        placeholder="Firmware Name"
        type="text"
        required
        [(ngModel)]="firmwareInstancePost.name"
        name="name"
      />
    </mat-form-field> -->
    <button
      mat-raised-button
      type="submit"
      [disabled]="!firmwareForm.form.valid"
      color="primary"
      style="max-width: min-content"
    >
      Create
    </button>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    class="close"
    (click)="onNoClick()"
    color="warn"
    style="max-width: min-content"
  >
    Cancel
  </button>
</div>
