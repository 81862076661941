import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ConstantService } from '../../shared/services/constant.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private _adalService: MsAdalAngular6Service,
        private _constService: ConstantService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const admin = localStorage.getItem("isTypeAdmin");
        if (this._adalService.isAuthenticated && admin === "true") {
            if (this._constService._isSupport || this._constService._isHardware) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(childRoute, state);
    }
}
