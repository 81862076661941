import { tap, map, mergeMap } from "rxjs/operators";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Customer } from "../../../shared/interfaces/customer";
import { Observable, forkJoin, Subscription } from "rxjs";
import { AdminService } from "../../../shared/services/admin.service";
import { UserGroupCreateFormComponent } from "./create/user-group-create-form.component";
import { UserGroup } from "../../../shared/interfaces/user-group";
import { GroupsDialogComponent } from "../../../shared/components/dialogs";
import { CustomerService } from "../../../shared/services/customer.service";
import { MatDialog } from "@angular/material/dialog";
import { AdminAsset } from "../../../shared/interfaces/admin/admin-asset";
import { AssetOwnership } from "../../../shared/interfaces/asset-ownership";
import { SearchBarService } from "../../../shared/services/searchbar.service";
import { MatTableDataSource } from "@angular/material/table";
import { AdminUserGroup } from "../../../shared/interfaces/admin/admin-user-group";
import { ConfirmationDialogComponent } from "../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  RootsActionMenuItem,
  ActionType,
} from "../../../shared/interfaces/roots-action-menu-item";
import { RootsPageComponentConfig } from "../../../shared/interfaces/roots-page-component-config";
@Component({
  selector: "app-user-groups",
  templateUrl: "./user-groups.component.html"
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() customer: Customer;
  scoutsFromAssets: any[] = [];
  types = [];
  userGroups = [];
  customerId = "";
  selectedGroup: any;
  users: any[] = [];
  assignedUsers: any[] = [];
  assignedScouts: any[] = [];
  members: any[];
  scouts: any[];
  noUser = false;
  noScout = false;
  noUsers: any[] = [{ message: "No Users" }];
  noScouts: any[] = [{ message: "No Scouts" }];
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "name",
      "description",
      //"code",
      // "scouts",
      // "users",
    ],
    message: "",
  };
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create UserGroup",
      icon: "add",
      toolTip: "Create a new UserGroup",
      type: ActionType.Create,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "Manage Group",
      toolTip:
        'Manage users and scouts on this group',
      icon: "group_add",
      type: 10,
    },
    {
      name: "Details",
      toolTip:
        'View the entity details of this group',
      icon: "edit",
      type: 11,
    },
    {
      name: "Delete",
      toolTip:
        'Delete this usergroup - WARNING this may cause unintended errors if it belongs to existing units and users',
      icon: "delete",
      type: 12,
    },
  ];
  searchSubscription: Subscription;
  usergroupsSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _customerService: CustomerService,
    public searchBarService: SearchBarService
  ) {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.customerId = id;
          return id;
        }),
        mergeMap((id) => {
          const getCustomerDetails =
            this._customerService.getCustomerDetails(id);
          const getCustomersUserGroups =
            this._adminService.getCustomersUserGroups(id);
          const getCustomersUsers = this._adminService.getCustomersUsers(id);
          // const getCustomersAssets = this._adminService.getCustomersAssets(id);

          return forkJoin([
            getCustomerDetails,
            getCustomersUserGroups,
            getCustomersUsers
          ]);
        })
      )
      .subscribe((results) => {
        const details = results[0];
        const userGroups = results[1];
        const customerUsers = results[2];
        // const assets = results[3];

        this.customer = details;
        this.users = customerUsers;
        this.userGroups = userGroups;
        this.defaultGroupVal();
        this._config.dataSource.data = this.userGroups;
        this._config.title = `${this.customer.basicName}'s Groups`;
        this._config.toolTip =
          "Find details regarding this customer's usergroups. Click to refresh.";
        this._config.loading = false;
        this.matchUsersToGroup();
        this._config.displayedColumns.push("users");
      });
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
    });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.usergroupsSubscription) {
      this.usergroupsSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  defaultGroupVal() {
    for (let k = 0; k < this.userGroups.length; k++) {
      const groups = this.userGroups[k];
      groups.userCount = 0;
      groups.users = [];
    }
  }

  matchUsersToGroup() {
    if (this.users !== null || this.users !== undefined) {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        for (let j = 0; j < user.userGroups.length; j++) {
          const group = user.userGroups[j];
          for (let k = 0; k < this.userGroups.length; k++) {
            const groups = this.userGroups[k];
            if (group.id === groups.id) {
              groups.users += user.name + ', ';
              groups.userCount += 1;
            }
          }
        }
      }
    }
  }

  alreadyAssigned() {
    if (this.users !== null || this.users !== undefined) {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        for (let j = 0; j < this.assignedUsers.length; j++) {
          const u = this.assignedUsers[j];
          if (user.id.includes(u.userId)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  alreadyAssignedScout(asset: any) {
    if (asset !== null || asset !== undefined) {
      for (let i = 0; i < this.assignedScouts.length; i++) {
        const u = this.assignedScouts[i];
        if (asset.code.includes(u.scoutCode)) {
          return true;
        }
      }
    }
    return false;
  }

  groupChanged($event?) {
    console.log($event);
    const eid = $event.value.id;
    this.selectedGroup = $event.value;
    this.serviceCalls(eid);
  }

  serviceCalls(groupId: string) {
    this._config.loading = true;
    const memberCall = this._adminService.getAdminUserGroupMember(groupId);
    const scoutCall = this._adminService.getAdminUserGroupScout(groupId);
    // const getCustomersUsers = this._adminService.getCustomersUsers(this.customerId)

    return forkJoin([memberCall, scoutCall]).subscribe(
      (result) => {
        const users = result[0];
        const scouts = result[1];
        //const customerUsers = result[2];

        this.assignedUsers = users;
        this.assignedScouts = scouts;
        this._config.loading = false;
      },
      (err) => {
        if (err) {
          this.noUser = true;
          this.noScout = true;
          this._config.loading = false;
          this.assignedUsers = [];
          this.assignedScouts = [];
          this.matchUsersToGroup();
        }
      }
    );
  }

  topMenuActionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.openDialog();
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this.navigate('usersGroup-manage', item)
        break;
      case 11:
        this.navigate('usersGroup', item);
        break;
      case 12:
        this.deleteDialog(item);
      break;
      default:
        break;
    }
  }

  getCustomersUserGroups() {
    this.usergroupsSubscription = this._adminService
      .getCustomersUserGroups(this.customerId)
      .subscribe((ug) => {
        this.userGroups = ug;
        this._config.dataSource.data = this.userGroups;
      });
  }

  openDialog(): void {
    this._config.loading = true;
    const dialogRef = this._dialog.open(UserGroupCreateFormComponent, {
      width: "60vw",
      data: {
        dataKey: this.customer,
        id: this.customerId,
        title: "Create UserGroup",
      },
    });
    dialogRef.componentInstance.event.subscribe(
      (result) => {
        if (result.data) {
          this._config.loading = false;
          this.getCustomersUserGroups();
        }
      },
      (err) => {
        this._config.loading = false;
      }
    );
  }

  onNoClick() {
    this._router.navigate([`/customers`]);
  }

  navigate(command: string, object?: any) {
    switch (command) {
      case "users":
        this._router.navigate([`/customers/${this.customerId}/users`]);
        break;
      case "assets":
        this._router.navigate([`/customers/${this.customerId}/assets`]);
        break;
      case "ownership":
        this._router.navigate([`/customers/${this.customerId}/ownership`]);
        break;
      case "usersGroup":
        this._router.navigate([
          `/customers/${this.customerId}/usergroups/${object.id}`,
        ]);
        break;
      case "usersGroup-manage":
        this._router.navigate([
          `/customers/${this.customerId}/usergroups/${object.id}/manage`,
        ]);
        break;
      case "back":
        this._router.navigate([`/customers`]);
        break;
      default:
        break;
    }
  }

  manageDialog(group, customerGroups): void {
    const dialogRef = this._dialog.open(GroupsDialogComponent, {
      width: "80vw",
      height: "80vh",
      data: {
        group,
        customerGroups,
      },
    });
    // dialogRef.componentInstance.event.subscribe((result) => {
    //   console.log(result);
    //   if (result.data) {
    //     this.getCustomersUserGroups();
    //   }
    // });
  }

  refresh() {
    this._config.loading = true;
    const getCustomerDetails = this._customerService.getCustomerDetails(
      this.customerId
    );
    const getCustomersUserGroups = this._adminService.getCustomersUserGroups(
      this.customerId
    );
    const getCustomersUsers = this._adminService.getCustomersUsers(
      this.customerId
    );

    return forkJoin([
      getCustomerDetails,
      getCustomersUserGroups,
      getCustomersUsers,
    ]).subscribe((results) => {
      const details = results[0];
      const userGroups = results[1];
      const customerUsers = results[2];

      this.customer = details;
      this.users = customerUsers;
      this.userGroups = userGroups;
      this.matchUsersToGroup();
      this._config.loading = false;
    });
  }

  deleteDialog(userGroup: UserGroup): void {
    this._config.loading = true;
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "80vw",
      data: `Delete ${userGroup["name"]}?`,
    });
    dialogRef
      .afterClosed()
      .pipe(
        map((result) => {
          return result;
        }),
        mergeMap((result) => {
          if (result) {
            const deleteUserGroup = this._customerService.deleteUserGroup(
              userGroup.id
            );
            const getCustomerUserGroups =
              this._adminService.getCustomersUserGroups(this.customerId);

            return forkJoin([deleteUserGroup, getCustomerUserGroups]);
          }
        })
      )
      .subscribe((results) => {
        const userGroups = results[1];
        this.userGroups = userGroups;
        this._config.loading = false;
        this.getCustomersUserGroups();
      });
  }
}
