import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-packet-dialog',
  templateUrl: './packet-dialog.component.html',
  styleUrls: ['./packet-dialog.component.scss']
})
export class PacketDialogComponent {
  packet: Packet = new Packet();
  constructor(
    public dialogRef: MatDialogRef<PacketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { this.parsePacket(data) }

    parsePacket(data: any) {
      console.log(data);
    }

onNoClick(): void {
    this.dialogRef.close();
  }
}

export class Packet {
  leftBracket = '{';
  rightBracket = '}';
}
