<div mat-dialog-content>Voltage Min and Max:</div>
<p class="text small-text">
  Please set your desired query voltage values (min and max).
</p>
<div>
  <div>
    <div class="col-12">
        <div class="col-4" style="float: left">
            <label for="hasGeo">Has Geolocation</label><br /><br />
            <mat-slide-toggle
              [(ngModel)]="hasGeo"
              name="hasGeo"
              (change)="geoCheck()"
            >
            </mat-slide-toggle>
          </div>
          <div class="col-4" style="float: left">
            <label for="min">Volt Min</label>
            <input
              type="number"
              class="form-control"
              id="min"
              [(ngModel)]="min"
              name="min"
              step="0.01"
              [min]="2"
              [max]="5"
            />
          </div>
  
          <div class="col-4" style="float: left">
            <label for="max">Volt Max</label>
            <input
              type="number"
              class="form-control"
              id="max"
              [(ngModel)]="max"
              name="max"
              step="0.01"
              [min]="1"
              [max]="10"
            />
          </div>
    </div>
    <hr />
  </div>
</div>
<br>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">Cancel</button>
  <button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
    (click)="yesClicked()"
    cdkFocusInitial
  >
    Query
  </button>
</div>
