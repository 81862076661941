<mat-progress-bar
  style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
  *ngIf="loading"
  mode="query"
>
</mat-progress-bar>
<!-- <roots-loading style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
*ngIf="loading"></roots-loading> -->
<header>
  <div class="overlay">
    <mat-select *ngIf="selection" matInput placeholder="Select A Query" name="selection">
        <mat-option *ngFor="let selection of selections" [value]="selection.name" class="text label-text">
          {{ selection.name }}
      </mat-option>
    </mat-select>
    <mat-button *ngIf="icon.length > 0" (click)="iconBtnClicked($event)" mat-icon-button>
      <mat-icon>{{icon}}</mat-icon>
    </mat-button>
    <h3
      matTooltip="{{ titleTooltip }}"
      matTooltipPosition="above"
      (click)="refresh()"
    >
      {{ pageTitle }}
    </h3>
    <roots-action-menu
      class="hamburger-button __button roots-action-menu"
      [items]="items"
      (onItemClick)="toggleAction($event)"
    >
    </roots-action-menu>
  </div>
</header>
