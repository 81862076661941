import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoutLogExtended } from '../../../interfaces/scout-log-extended';
import { ScoutLogService } from '../../../services/scout-log.service';
import { AssetsService } from '../../../services/assets.service';
import { ScoutLogDialogService } from '../../../services/scout-log-dialog.service';
@Component({
  selector: 'app-verify-dialog',
  templateUrl: './verify-dialog.component.html',
  styleUrls: ['./verify-dialog.component.scss']
})
export class VerifyDialogComponent {
  input = '';
  searchedAsset: any;
  selectionMade = false;
  constructor(
    public dialogRef: MatDialogRef<VerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public log: ScoutLogExtended,
    @Inject(MAT_DIALOG_DATA) public serial: string,
    //private _logService: ScoutLogService,
    private _logDialogService: ScoutLogDialogService,
    private _assetService: AssetsService
    ) {
      this.input = this.log.serial;
      if (this.log.serial == null || this.log.serial == undefined) {
        this.log.serial = this.log['link'];
        this.log.sequenceNumber = this.log['name'];
        this.input = this.log.serial;
      }
  }

  validateScout(id: string, sent: boolean) {
    console.log(id);
    if (sent) {
      this.selectionMade = true;
      //this._logService.updateValidated(this.log.id, this.input);
      this._logDialogService.updateValidated(this.log.id, this.input);
      this.dialogRef.close(sent);
    }
  }


  unValidateScout(id: string, sent: boolean) {
    console.log(id);
    if (sent) {
      this.selectionMade = true;
      //this._logService.updateValidated(this.log.id, ('UNDO_' + this.input));
      this._logDialogService.updateValidated(this.log.id, ('UNDO_' + this.input));
      this.dialogRef.close(sent);
    }
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
