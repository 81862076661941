import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { RootsActionMenuItem } from "../..";
import { LocalStorageService } from "../../../core";
import { SnackService } from "../../services/extra/snack.service";
import { D3SelectDialogComponent } from "./dialogs/d3-select-dialog.component";

export class RootsTableDataSource<T> extends MatTableDataSource<T> {
  hasAdditionalData = false;
  hasActionButtons = true;
}

@Component({
  selector: "roots-table",
  templateUrl: "./roots-table.component.html",
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RootsTableComponent implements AfterViewInit {
  // private variables for component that get and set
  private _dataSource = new RootsTableDataSource<any>();
  private _tableCols: string[] = [];
  private _columnFormatting: string[] = [];
  private _message: string;
  private _clickingEnabled: boolean;
  private _defaultActions: boolean;
  private _items: RootsActionMenuItem[] = [
    {
      name: "Add to Report Builder",
      toolTip:
        'Add to the custom report which is stored within local storage of your session, this enables you to join multiple tables data into one csv or one set of json data.',
      icon: "add",
      type: 100,
    },
    // {
    //   name: "View with D3",
    //   toolTip:
    //     'Generate an svg graphic based on the current row and input data',
    //   icon: "panorama_fish_eye",
    //   type: 101,
    // }
  ];
  
  // local variables for table
  dateNow = new Date(Date.now());
  additionalColumns: string[] = ["expandedDetail"];
  csvCreated = false;
  expandedData: any[] = [];
  additionalDataIsarray = false;
  actionNameList: string[] = [];
  rowActionButtons: [];
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  //For additional actions on entities - CRUD, dialogs, modals
  @Output("actionsClicked") actionsEmit = new EventEmitter();
  @Output("actionList") actionListEmitter = new EventEmitter();
  @Output("pulledRowData") pulledRowDataEmitter = new EventEmitter();
  @Output("onBtnItemClick") onBtnItemClick = new EventEmitter<void>();
  @Input("csv") csv = "";

  @Input("items")
  public set items(items: RootsActionMenuItem[]) {
    if(items.length < 1) {
      items = [
        {
          name: "Add to Report Builder",
          toolTip:
            'Add to the custom report which is stored within local storage of your session, this enables you to join multiple tables data into one csv or one set of json data.',
          icon: "add",
          type: 100,
        },
        // {
        //   name: "View with D3",
        //   toolTip:
        //     'Generate an svg graphic based on the current row and input data',
        //   icon: "panorama_fish_eye",
        //   type: 101,
        // }
      ]
    }
    this._items = items;
  }
  public get items(): RootsActionMenuItem[] {
    return this._items;
  }
  @Input("clickingEnabled")
  public set clickingEnabled(clickingEnabled: boolean) {
    this._clickingEnabled = clickingEnabled;
  }
  public get clickingEnabled(): boolean {
    return this._clickingEnabled;
  }
  @Input("tableColumns")
  public set tableCols(tableCols: string[]) {
    this._tableCols = tableCols;
  }
  public get tableCols(): string[] {
    return this._tableCols;
  }
  @Input("filter")
  set message(message: string) {
    if (message.length > 0) {
      this.onSearchInput(message);
    } else {
      this.onSearchInput(message);
    }
    this._message = message;
  }
  get message() {
    return this._message;
  }
  @Input("dataSource")
  set dataSource(dataSource: RootsTableDataSource<any>) {
    if (dataSource.data.length > 0) {
      dataSource.sort = this.sort;
      dataSource.paginator = this.paginator;
      this._snack.displaySuccess("Entity data found");
      this.initCsvButton();
    } 
    // else {
    //   this._snack.displayError("No entity data found");
    // }
    this._dataSource = dataSource;
  }
  get dataSource() {
    return this._dataSource;
  }
  @Input("defaultActions")
  set defaultActions(defaultActions: boolean) {
    if (defaultActions == null || defaultActions == undefined) {
      defaultActions = true;
    } 
    this._defaultActions = defaultActions;
  }
  get defaultActions() {
    return this._defaultActions;
  }
  constructor(
    private _snack: SnackService,
    private _localStorage: LocalStorageService,
    private _dialog: MatDialog
    ) {}
  
  ngAfterViewInit(): void {
    this.initTable();
  }

  ngOnDestroy() {
    this._dialog.ngOnDestroy();
  }

  emitAction($event, entity: any) {
    this.onBtnItemClick.emit($event);
    this.actionsEmit.emit([$event, entity]);
    switch ($event.type) {
      case 100:
        this.addDataToCustomDataSet(entity);
        break;
      case 101:
        this.d3Dialog(entity);
        //generate a d3 instance based on the entity coming in and the 
        //(needs a dialog to pass in params) clickable params sent in for comparison
        break;
      default:
        break;
    }
  }

  d3Dialog(data: any): void {
    const dialogRef = this._dialog.open(D3SelectDialogComponent, {
      width: "90vw",
      height: "90vh",
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  private initTable() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.initCsvButton();
  }

  private initCsvButton() {
    const csvBtnCheck = document.getElementById("csv-btn");
    if (!csvBtnCheck) {
      const csvBtn = document.createElement("button");
      const matIcon = document.createElement("mat-icon");
      matIcon.setAttribute("_ngcontent-c14", "");
      matIcon.classList.add("mat-icon");
      matIcon.classList.add("material-icons");
      matIcon.setAttribute("role", "img");
      matIcon.setAttribute("aria-hidden", "true");
      matIcon.innerHTML = "get_app";
      csvBtn.className =
        "mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base mat-primary";
      csvBtn.appendChild(matIcon);
      csvBtn.style.paddingTop = "1vh";
      csvBtn.style.color = "none";
      csvBtn.id = "csv-btn";
      csvBtn.onclick = () => {
        this.exportCsv(this.dataSource.data, this.csv);
      };
      const actionbar = document.getElementsByClassName(
        "mat-paginator-range-actions"
      );
      const item = actionbar.item(0);
      item.appendChild(csvBtn);
      this.csvCreated = true;
    }
  }

  addDataToCustomDataSet(data: any){
    this._localStorage.addRowToCustomDataTable(data);
  }

  onSearchInput(message: string) {
    const searchTarget = message;
    this.dataSource.filter = searchTarget.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this._snack.displaySearchError("No search results found");
    } else if (
      this.dataSource.filteredData.length > 0 &&
      this.dataSource.filter.length > 3
    ) {
      this._snack.displaySearchSuccess(
        `(${this.dataSource.filteredData.length}) Results found`
      );
    }
  }

  isExpansionDetailRow = (index, row) => row.hasOwnProperty("detailRow");

  checkExpanded(data): boolean {
    if (this.clickingEnabled) {
      let flag = false;
      for (let i = 0; i < this.expandedData.length; i++) {
        const e = this.expandedData[i];
        if (e === data) {
          flag = true;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  switchClicked(switchClicked: any, entity: any) {
    switchClicked.entity = entity;
    this.actionsEmit.emit(switchClicked);
  }

  pushPopElement(data) {
    if (this.clickingEnabled) {
      const index = this.expandedData.indexOf(data);
      // console.log(index);
      if (index === -1) {
        this.expandedData.push(data);
      } else {
        this.expandedData.splice(index, 1);
      }
    }
  }

  actionClicked(entity, button) {
    button.entity = entity;
    this.actionsEmit.emit(button);
  }

  exportCsv(data: any[], fileName: string) {
    let headers: string[] = [];
    let addedHeaders = false;
    headers = Object.getOwnPropertyNames(data[0]);
    var options: any = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      useBom: true,
      noDownload: false,
      headers: [headers],
      nullToEmptyString: true,
    };
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
    if (d.additionalData !== undefined) {
        let additionalHeaders: string[] = [];
        additionalHeaders = Object.getOwnPropertyNames(d.additionalData[0]);
        for (let j = 0; j < additionalHeaders.length; j++) {
          const header = additionalHeaders[j];
          headers.push(header);
        }
      }
    }
    return new Angular5Csv(
      data,
      this.dateNow.toLocaleString() + " " + fileName,
      options
    );
  }
}
