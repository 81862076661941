<div *ngIf="data; else noData" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left">
    <mat-card>
        <h3 class="text label-header-text">
            <mat-icon *ngIf="icon"> {{icon}} </mat-icon>
            {{title}} ({{ data.length }})
        </h3>
        <hr />
        <div style="max-height: 50vh; overflow: auto" *ngIf="data.length > 0; else emptyData">
            <div *ngFor="let d of data" class="text label-text item-card">
                <mat-card *ngIf="prop_1; else noProp_1" (click)="clickEvent(d)">
                    {{ d[prop_1] }}
                    <button *ngIf="prop_1 && prop_2 && (prop_3 || prop_4 || prop_5 || prop_6)" href="javascript:void()"
                        color="warn" style="float: right;" mat-icon-button (click)="d.isExpanded = !d.isExpanded">
                        <mat-icon *ngIf="!d.isExpanded" class="mat-18">expand_more</mat-icon>
                        <mat-icon *ngIf="d.isExpanded" class="mat-18">expand_less</mat-icon>
                    </button>
                    <button (click)="navigateToType(d)" color="primary" style="float: right;" mat-icon-button>
                        <mat-icon class="mat-18">search</mat-icon>
                    </button>
                    <p class="text medium-text" style="margin: 0px;" *ngIf="prop_2">
                        {{ d[prop_2] }}
                    </p>
                    <p class="text medium-text" style="margin: 0px; padding-left: 15px"
                        *ngIf="d.isExpanded && prop_3; else noProp_3">
                        - {{ d[prop_3] }}
                    </p>
                    <p class="text medium-text" style="margin: 0px; padding-left: 15px" *ngIf="d.isExpanded && prop_4">
                        - {{ d[prop_4] }}
                    </p>
                    <p class="text medium-text" style="margin: 0px; padding-left: 15px" *ngIf="d.isExpanded && prop_5">
                        - {{ d[prop_5] }}
                    </p>
                    <p class="text medium-text" style="margin: 0px; padding-left: 15px" *ngIf="d.isExpanded && prop_6">
                        - {{ d[prop_6] }}
                    </p>
                </mat-card>

            </div>
            <ng-template #noProp_1>
                <mat-card>
                    <h3 class="text medium-text">No property 1 was found.</h3>
                    <mat-progress-bar mode="query">
                    </mat-progress-bar>
                </mat-card>
            </ng-template>
        </div>
        <ng-template #emptyData>
            <p class="text medium-text" style="margin: 0px;">
                No {{title}} found.
            </p>
        </ng-template>
    </mat-card>
</div>
<ng-template #noData>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-4" style="float: left">
        <mat-card>
            <h3 class="text label-header-text">
                <mat-icon *ngIf="icon"> {{icon}} </mat-icon>
                {{title}}
            </h3>
            <hr />
            <p class="text medium-text" style="margin: 0px;">
                No data found.
            </p>
        </mat-card>
    </div>
</ng-template>