import { Component, Input, OnInit } from '@angular/core';
import { burgerLineAnimation } from './roots-burger.animations';

@Component({
  selector: 'roots-burger',
  templateUrl: './roots-burger.component.html',
  styleUrls: ['./roots-burger.component.scss'],
  animations: [
    burgerLineAnimation('firstLine'),
    burgerLineAnimation('thirdLine', '-15px', '-45deg', '-60deg')
  ]
})
export class RootsBurgerComponent {
  @Input() 
  open: boolean;

  @Input()
  color = '#F1F2F2';

}
