import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoutLogExtended } from '../../../interfaces/scout-log-extended';
@Component({
  selector: 'app-d3-select-dialog',
  templateUrl: './d3-select-dialog.component.html'
})
export class D3SelectDialogComponent implements AfterViewInit{
  input = '';
  searchedAsset: any;
  selectionMade = false;
  dataType: any;
  dataCanBeCompared = false;
  options = [
    {
      title: 'Time Chart',
      option: 0,
      available: false
    },
    {
      title: 'Pie Chart',
      option: 1,
      available: false
    },
    {
      title: 'Map Address',
      option: 2,
      available: false
    },
    {
      title: 'Percentage',
      option: 3,
      available: false
    },
  ];
  address: string;
  constructor(
    public dialogRef: MatDialogRef<D3SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
  }
  ngAfterViewInit(): void {
    this.appendData(this.data);
  }

  

  appendData(data) {
    var mainContainer = document.getElementById("myData");
    if (data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        var div = document.createElement("div");
        var keys = Object.keys(data[i]);
        div.innerHTML = keys[0] + data[i].keys[0];
        mainContainer.appendChild(div);
      }
    } else {
      var keys = Object.keys(data);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        var div = document.createElement("div");
        var value = data[key];
        div.innerHTML = key + ': ' + value;
        mainContainer.appendChild(div);
        this.getType(value);
        this.checkProperty(key, value)
      }
    }
  }

  getType(obj: any) {
    if (obj instanceof Number) {
      this.options[0].available = true;
      this.options[1].available = true;
    }
    if (obj instanceof Array) {
      this.options[0].available = true;
      this.options[1].available = true;
    }
  }

  checkProperty(key, val) {
    if (key instanceof String) {
      if (key.toLocaleLowerCase().search("address")) {
        this.address = val;
        this.options[2].available = true;
      }
    }
  }

  optionClicked($event) {
    // switch (key) {
    //   case value:
        
    //     break;
    
    //   default:
    //     break;
    // }
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
