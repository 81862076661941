import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Customer } from "../../../../shared";
import { NgForm } from "@angular/forms";
import { Asset } from "../../../../shared/interfaces/asset";
import { AdminService } from "../../../../shared/services/admin.service";
import { CustomerService } from "../../../../shared/services/customer.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-xfer-form",
  templateUrl: "./xfer-form.component.html",
  styleUrls: ["./xfer-form.component.scss"],
})
export class XferFormComponent implements OnDestroy {
  @ViewChild(NgForm, { static: true }) assignmentForm: NgForm;
  adminSubscription: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<XferFormComponent>,
    private _adminService: AdminService,
    private _customerService: CustomerService,
    public _dialog: MatDialog
  ) {
    this.adminSubscription = this._customerService.getAdminCustomers().subscribe((customers) => {
      this.customers = customers;
      if (this.data.customer) {
        this.customers = this.removeSelf(this.customers);
      }
      this.fromCustomer = data.customer;
    });
  }
  doScoutSensor: boolean = true;
  name: string = "";
  customers: Customer[] = [];
  fromCustomer: Customer;
  currentDate = new Date().getTime();
  assetToAssign: Asset;
  assetsToAssign: Asset[] = [];
  targetCustomer: Customer;
  event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  isScout = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // if (this.adminSubscription) {
    //   this.adminSubscription.unsubscribe();
    // }
  }

  removeSelf(customers: Customer[]): Customer[] {
    const refactored: Customer[] = [];
    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      if (this.data.customer.id.includes(customer.id)) {
        continue;
      } else {
        refactored.push(customer);
      }
    }
    return refactored;
  }

  onSubmit($event): void {
    this.adminSubscription = this._adminService
      .xferAssetToCustomer(
        this.data.asset.id,
        this.targetCustomer.id,
        [{ id: this.data.asset.id, scoutName: this.name }],
        this.doScoutSensor
      )
      .subscribe((response) => {
        console.log(response);
        this.event.emit({ data: response });
      });
    this.dialogRef.close();
  }

  public checkChanged = (event) => {
    this.doScoutSensor = event.checked;
  }

  selectedCustomer($event) {
    this.targetCustomer = $event.value;
    if (this.data.asset.assetTypeCode.includes("Scout")) {
      this.isScout = true;
    }
    if (this.data.asset.currentAssetOwnership) {
      this.name = this.data.asset.currentAssetOwnership.assetName;
    }
    else {
      this.name = this.data.asset.name;
    }
  }

  onEvent($event) {
    console.log($event);
  }

  removeHyphens(code: string): string {
    const replaced = code.replace(/-/g, "");
    return replaced;
  }
}
