import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Angular5Csv } from "angular5-csv/dist/Angular5-csv";
import { LocalStorageService } from "../../../core";
import { RootsTableActionColumn } from "../../interfaces/roots-table-action-column";
import { SnackService } from "../../services/extra/snack.service";

export class RootsTableDataSource<T> extends MatTableDataSource<T> {
  hasAdditionalData = false;
  hasActionButtons = true;
}

@Component({
  selector: "roots-actionable-table",
  templateUrl: "./roots-actionable-table.component.html",
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RootsActionableTableComponent implements AfterViewInit {
  // private variables for component that get and set
  private _dataSource = new RootsTableDataSource<any>();
  private _actionCols: RootsTableActionColumn[] = [];
  private _message: string;
  private _clickingEnabled: boolean;
  private _reportBuilder : boolean;

  // local variables for table
  dateNow = new Date(Date.now());
  additionalColumns: string[] = ["expandedDetail"];
  csvCreated = false;
  expandedData: any[] = [];
  actionNameList: string[] = [];
  @ViewChild(MatSort) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  //For additional actions on entities - CRUD, dialogs, modals
  @Output("actionsClicked") actionsEmit = new EventEmitter();
  @Output("pulledRowData") pulledRowDataEmitter = new EventEmitter();
  @Input("actionsEnabled") actionsEnabled = false;
  @Input("csv") csv = "";

  @Input("clickingEnabled")
  public set clickingEnabled(clickingEnabled: boolean) {
    this._clickingEnabled = clickingEnabled;
  }
  public get clickingEnabled(): boolean {
    return this._clickingEnabled;
  }

  @Input("reportBuilder") set reportBuilder(builder: boolean) {
    if (builder) {
      this._snack.displaySuccess("Report builder mode enabled");
    }
    if (this.dataSource.data.length > 0 && !builder) {
      this._snack.displayError("Report builder mode disabled");
    }
    this._reportBuilder = builder;
  }

  get reportbuilder() {
    return this._reportBuilder;
  }

  //Action columns will be optionally for the tables that have inline actions
  @Input("actionColumns")
  public set actionCols(actionCols: RootsTableActionColumn[]) {
    if (actionCols) {
      if (actionCols.length > 0 && this.actionNameList.length < 1) {
        for (let i = 0; i < actionCols.length; i++) {
          const col = actionCols[i];
          this.actionNameList.push(col.name);
        }
        // if (this.dataSource.data.length > 0) {
        //   this._snack.displaySuccess("This table has actionable columns");
        //   setTimeout(() => {
        //     this.initTable();
        //   }, 500);
        // }
      }
    }
    this._actionCols = actionCols;
  }
  public get actionCols(): RootsTableActionColumn[] {
    return this._actionCols;
  }
  @Input("filter")
  set message(message: string) {
    if (message.length > 0) {
      this.onSearchInput(message);
    } else {
      this.onSearchInput(message);
    }
    this._message = message;
  }
  get message() {
    return this._message;
  }
  @Input("dataSource")
  set dataSource(dataSource: RootsTableDataSource<any>) {
    if (dataSource.data.length > 0) {
      dataSource.sort = this.sort;
      dataSource.paginator = this.paginator;
      this._snack.displaySuccess("Entity data found");
      // this.initCsvButton();
    } else {
      this._snack.displayError("No entity data found");
    }
    this._dataSource = dataSource;
  }
  get dataSource() {
    return this._dataSource;
  }

  constructor(
    private _snack: SnackService,
    private _localStorage: LocalStorageService
  ) {}

  ngAfterViewInit(): void {
    this.initTable();
  }

  private initTable() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this.initCsvButton();
  }

  private initCsvButton() {
    const csvBtnCheck = document.getElementById("csv-btn");
    if (!csvBtnCheck) {
      const csvBtn = document.createElement("button");
      const matIcon = document.createElement("mat-icon");
      matIcon.setAttribute("_ngcontent-c14", "");
      matIcon.classList.add("mat-icon");
      matIcon.classList.add("material-icons");
      matIcon.setAttribute("role", "img");
      matIcon.setAttribute("aria-hidden", "true");
      matIcon.innerHTML = "get_app";
      csvBtn.className =
        "mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base mat-primary";
      csvBtn.appendChild(matIcon);
      csvBtn.style.paddingTop = "1vh";
      csvBtn.style.color = "none";
      csvBtn.id = "csv-btn";
      csvBtn.onclick = () => {
        this.exportCsv(this.dataSource.data, this.csv);
      };
      const actionbar = document.getElementsByClassName(
        "mat-paginator-range-actions"
      );
      const item = actionbar.item(0);
      item.appendChild(csvBtn);
      this.csvCreated = true;
    }
  }

  enableReportBuilderMode() {
  }

  addDataToCustomDataSet(data: any) {
    if (this._reportBuilder) {
      console.log(data);
      //this._localStorage.addRowToCustomDataTable(data);
    }
  }

  onSearchInput(message: string) {
    const searchTarget = message;
    this.dataSource.filter = searchTarget.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0 &&
      this.dataSource.filter.length > 1) {
      this._snack.displaySearchError("No search results found");
    } else if (
      this.dataSource.filteredData.length > 0 &&
      this.dataSource.filter.length > 3
    ) {
      this._snack.displaySearchSuccess(
        `(${this.dataSource.filteredData.length}) Results found`
      );
    }
  }

  checkExpanded(data): boolean {
    if (this.clickingEnabled) {
      let flag = false;
      for (let i = 0; i < this.expandedData.length; i++) {
        const e = this.expandedData[i];
        if (e === data) {
          flag = true;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  switchClicked(switchClicked: any, entity: any) {
    switchClicked.entity = entity;
    this.actionsEmit.emit(switchClicked);
  }

  actionClicked(entity, button) {
    button.entity = entity;
    this.actionsEmit.emit(button);
  }

  exportCsv(data: any[], fileName: string) {
    let headers: string[] = [];
    let addedHeaders = false;
    headers = Object.getOwnPropertyNames(data[0]);
    var options: any = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      useBom: true,
      noDownload: false,
      headers: [headers],
      nullToEmptyString: true,
    };
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      if (d.additionalData !== undefined) {
        let additionalHeaders: string[] = [];
        additionalHeaders = Object.getOwnPropertyNames(d.additionalData[0]);
        for (let j = 0; j < additionalHeaders.length; j++) {
          const header = additionalHeaders[j];
          headers.push(header);
        }
      }
    }
    return new Angular5Csv(
      data,
      this.dateNow.toLocaleString() + " " + fileName,
      options
    );
  }
}
