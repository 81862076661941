import { Component, EventEmitter, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Asset } from "../../../../shared/interfaces/asset";
import { Customer } from "../../../../shared";
import { NgForm } from "@angular/forms";
import { AdminService } from "../../../../shared/services/admin.service";
import { AdminAsset } from "../../../../shared/interfaces/admin/admin-asset";
import { AdminAssetNotOwned } from "../../../../shared/interfaces/admin/admin-asset-not-owned";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-assign-asset-form",
  templateUrl: "./assign-asset-form.component.html",
  styleUrls: ["./assign-asset-form.component.scss"],
})
export class AssignAssetFormComponent implements OnDestroy{
  @ViewChild(NgForm, { static: true }) assignmentForm: NgForm;
  adminSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<AssignAssetFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    public searchBarService: SearchBarService
  ) {
    this.adminSubscription = this._adminService.getAdminAssetNotOwned().subscribe((notOwned) => {
      this.notOwned = notOwned;
      this.initNowOwned = notOwned;
    });
  }
  doScoutSensor = true;
  assets: AdminAsset[] = [];
  notOwned: AdminAssetNotOwned[] = [];
  initNowOwned: AdminAssetNotOwned[] = [];
  availableAssets: Asset[] = [];
  unavailableAssets: AdminAsset[] = [];
  currentDate = new Date().getTime();
  assetsToAssign: Asset[] = [];
  assetAssignment: any = {};
  event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  customer: Customer;
  message: string;
  options = [
    "all",
    "scout",
    "health",
    "sensor",
    // "cub",
  ];
  option = this.options[0];
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  ngOnDestroy() {
    // if (this.adminSubscription) {
    //   this.adminSubscription.unsubscribe();
    // }
  }

  onSubmit($event): void {
    this.customer = this.data.customer;
    this.submitted = true;
    const assetsIdArray: string[] = [];
    const assetArray: any[] = [];
    if (this.assetsToAssign.length > 0) {
      for (let i = 0; i < this.assetsToAssign.length; i++) {
        const asset = this.assetsToAssign[i];
        assetsIdArray.push(asset.id);
        assetArray.push({id: asset.id, scoutName: asset.basicName});
      }
      this.adminSubscription = this._adminService
        .xferAssetToCustomer(assetsIdArray, this.customer.id, assetArray, this.doScoutSensor)
        .subscribe((response) => {
          console.log(response);
          if (response) {
            this.event.emit({ data: response });
          }
          return response;
        });
    }
    this.dialogRef.close();
  }

  onEvent($event) {
    console.log($event);
  }

  removeHyphens(code: string): string {
    const replaced = code.replace(/-/g, "");
    return replaced;
  }

  filter(term: string) {
    let filteredData = [];
    switch (term) {
      case "all":
        this.adminSubscription = this._adminService.getAdminAssetNotOwned().subscribe((notOwned) => {
          this.notOwned = notOwned;
          this.initNowOwned = notOwned;
          // this.parseAvailableAssets(this.notOwned)
        });
      case "scout":
        for (let i = 0; i < this.initNowOwned.length; i++) {
          const asset = this.initNowOwned[i];
          if (asset.assetTypeCode.toLocaleLowerCase().includes(term)) {
            filteredData.push(asset);
          }
          this.notOwned = filteredData;
        }
        break;
      case "health":
        for (let i = 0; i < this.initNowOwned.length; i++) {
          const asset = this.initNowOwned[i];
          if (
            asset.assetTypeCode.toLocaleLowerCase().includes(term) ||
            asset.assetTypeCode.toLocaleLowerCase().includes("scout")
          ) {
            filteredData.push(asset);
          }
          this.notOwned = filteredData;
        }
        break;
      case "cub":
        for (let i = 0; i < this.initNowOwned.length; i++) {
          const asset = this.initNowOwned[i];
          if (asset.assetTypeCode.toLocaleLowerCase().includes(term)) {
            filteredData.push(asset);
          }
          this.notOwned = filteredData;
        }
        break;
      case "sensor":
        for (let i = 0; i < this.initNowOwned.length; i++) {
          const asset = this.initNowOwned[i];
          if (asset.assetTypeCode.toLocaleLowerCase().includes(term)) {
            filteredData.push(asset);
          }
          this.notOwned = filteredData;
        }
        break;
      default:
        break;
    }
  }

  parseAvailableAssets(assets: any): Asset[] {
    const availableAsset: Asset[] = [];
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      if (asset.whenEnd !== null || asset.whenEnd !== undefined) {
        availableAsset.push(asset);
        console.log("available Asset: " + asset);
      } else {
        this.unavailableAssets.push(asset);
        console.log("unavailable Asset: " + asset);
      }
    }
    return availableAsset;
  }
}
