<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh()"
  (actionListClicked)="topActionsClicked($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
<!-- <mat-tab-group *ngIf="!loading" #tabGroup (selectedTabChange)="tabSelectionChanged($event)">
  <mat-tab label="All Assets" > 
    <ng-template matTabContent>
      <app-assets [assets]="dataSource.data" 
                  [customerId]="customerId" 
                  [customer]="customer"
                  [noAssets]="noAssets"
                  [loading]="loading">
      </app-assets>
    </ng-template>
  </mat-tab>
  <mat-tab label="Scouts" style="overflow: hidden; max-height:70vh;"> 
    <ng-template matTabContent>
      <app-scouts [assets]="dataSource.data" 
                  [customerId]="customerId" 
                  [customer]="customer" 
                  [noAssets]="noAssets"
                  [loading]="loading">
      </app-scouts>
    </ng-template>
  </mat-tab>
</mat-tab-group> -->