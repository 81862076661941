import { catchError, map, mergeMap } from "rxjs/operators";
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, of, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { AdminService } from "../../../shared/services/admin.service";
import { AssetsService } from "../../../shared/services/assets.service";
import { MatTableDataSource } from "@angular/material/table";
import { RootsPageComponentConfig } from "../../../shared";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-fwi-edit",
  templateUrl: "./fwi-edit.component.html",
})
export class FwiEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() firmware: any;
  customerInitState: any;
  event: EventEmitter<any> = new EventEmitter();
  firmwareId = "";
  assetModels: any[];
  origAssetModels: any[];
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
    'name',
    'firmwareVersion',
    'code',
    'description',
    'whenBegin', 
    'whenEnd'
    ],
    message: ''
  };
  firmwares = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  componentSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public _dialog: MatDialog,
    private _adminService: AdminService,
    private _assetService: AssetsService
  ) {
    this._config.toolTip = "Configure or edit firmware model details.";
  }

  ngOnInit() {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.firmwareId = id;
          return id;
        }),
        mergeMap((id) => {
          const ids = [];
          ids.push(id);
          // const getFirmwareInstance = this._adminService.getFirmwareInstance(id);
          const getFwi = this._assetService.getFirmwareInstance(id);
          const getFirmware = this._adminService.getFirmwareModels();
          //TODO: Grab all models for this datasource component and link up search local
          return forkJoin([
            getFwi,
            getFirmware.pipe(catchError(error => of(error))),
          ]);
        })
      )
      .subscribe((results) => {
        const details = results[0];
        const firmwares = results[1];
        
        this.firmwares = firmwares.reverse();
        this.firmware = details;
        // this.getAssetFirmwareModel(this.firmware);
        const modelsFound = this.findFirmwareAsset(details);

        this._config.title = `${this.firmware.basicName} Firmware Details`;
        this._config.dataSource = new MatTableDataSource(modelsFound);
        this._config.loading = false;
        this._config.dataSource.sort = this.sort;
      });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  
  getAssetFirmwareModel(asset: any) {
    let ids: string[] = [];
    ids.push(asset.id);
    this._config.loading = true;
    this.componentSubscription = this._adminService.getAssetModelFirmwareModel(ids).subscribe((results) => {
      console.log(results);
      const types = [];
      for (let i = 0; i < results.length; i++) {
        const firmware = results[i];
        for (let j = 0; j < this.firmwares.length; j++) {
          const firm = this.firmwares[j];
          if (firmware.firmwareModelId.includes(firm.id)) {
            types.push(firm);
          }
        }
      }
      asset.expanded = true;
      asset.firmwares = types;
      this._config.loading = false;
      // this.styleModelCards(types);
    });
  }

  onNoClick() {
    this._router.navigate(["/firmwares"]);
  }

  onUpdate(firmware: any): void {
    console.log(firmware);
    const updateobj = {
      basicName: firmware.name,
      code: firmware.code,
      basicDescription: firmware.description
    };
    this.componentSubscription = this._assetService.updateFirmwareInstance(firmware.id, updateobj).subscribe(results => {
      console.log(results);
    })
  }

  findFirmwareAsset(data: any): any[] {
    const scoutArray = [];
    for (let i = 0; i < this.firmwares.length; i++) {
      const model = this.firmwares[i];
      if (model.id === data.firmwareModelId) {
        model.assetModelId = data.id;
        scoutArray.push(model);
      }
    }
    return scoutArray;
  }
}
