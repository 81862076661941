import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MatModule } from '../../core';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DashboardComponent } from './dashboard.component';
import { DevelopersService } from '../../shared/services/developers.service';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { GridsterModule } from 'angular-gridster2';
import { DashboardConfigurationComponent } from './components/dashboard-configuration.component';
//import { ScoutLogsComponent } from './components/scout-logs/scout-logs.component';
import { ScoutToolsComponent } from './components/scout-tools/scout-tools.component';
import { PacketsComponent } from './components/packets/packets.component';
//import { ScoutLogDetailsComponent } from './components/scout-log-details/scout-log-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
  suppressScrollY: false
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DashboardRoutingModule,
    SharedModule,
    MatModule,
    PerfectScrollbarModule,
    GridsterModule,
    // CKEditorModule
  ],
  declarations: [
    DashboardComponent, 
    DashboardConfigurationComponent,
    ScoutToolsComponent, 
    PacketsComponent
  ],
  providers: [
    DevelopersService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class DashboardModule { }
