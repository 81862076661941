import { Component, EventEmitter, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-server-info-dialog',
  templateUrl: './server-info-dialog.component.html'
})
export class ServerInfoDialogComponent {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ServerInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public serverInfo: ServerInfo,
    ) { }

onNoClick(): void {
    this.event.emit(true);
    this.dialogRef.close();
  }
}

export interface ServerInfo{
  version: string;
  versionDescription: string;
  versionDbSchemaLastMigrationInConfig: string;
  versionDbSchemaLastMigrationInDb: string;
  isOkay?: boolean;
}