import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { ReportsService } from "../../../../shared/services/reports.service";
import { ActionType, ConstantService, RootsActionMenuItem, RootsPageComponentConfig } from "../../../../shared";
import { MatDialog } from "@angular/material/dialog";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormGroup, FormControl } from "@angular/forms";
import { ScoutReliability } from "../../../../shared/interfaces/scout-reliability";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { RootsTableInterface } from "../../../../shared/components/roots-table/roots-table.interface";
import { Subscription } from "rxjs";


@Component({
  selector: "app-packet-reliability",
  templateUrl: "./packet-reliability.component.html",
  encapsulation: ViewEncapsulation.None

})
export class PacketReliabilityComponent implements RootsTableInterface, OnInit, AfterViewInit, OnDestroy {
  scoutLogs: ScoutReliability[] = [];
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  daysBack = 30;
  events: string[] = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  minDate = new Date(2015, 2, 1);
  maxDate = new Date(Date.now());
  startDate: Date;
  endDate: Date;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() scoutDirect = new EventEmitter<any>();
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  submitted = false;
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<ScoutReliability>(),
    displayedColumns: [
      "assetName",
      "customerName",
      "lastPayLoadDate",
      "interval",
      "totalPacketCount",
      "successfulPacketcount",
      "failedPacketCount",
      "expectedPacketCount"
    ],
    message: ''
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Choose Date Range",
      icon: "calendar_today",
      toolTip:
        "Choose a query date for packet report",
      type: ActionType.Dialog,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "View Scout's reliability info",
      toolTip:
        'View scout info regarding reliability and packet sending success/failure',
      icon: "search",
      type: 10,
    }
  ];
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    public _dialog: MatDialog,
    private _snack: SnackService,
    private _reportService: ReportsService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = `Scout Reliability ${this.minDate.toLocaleDateString('en-US')} - ${this.maxDate.toLocaleDateString('en-US')}`;
    this._config.toolTip = "Scout reliability report shows the packet success rates for a given unit and the interval with last payload date. Click to refresh."
  }

  ngOnInit(): void {
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    // to
    const utc = this._constService.convertDateUTC(this.dateNow);
    this.showScoutLogList(ut.toString(), utc.toString());
    if (this._config.loading) {
      this._snack.displayLoading("Scout report loading...");
    }
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  topMenuActionsClicked(action) {
    switch (action.type) {
      case 3:
        this.datePicker.open();
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        // this._config.loading = true;
      break;
      default:
        break;
    }
  }  

  changeEndEvent(event) {
    if (event.value !== null || event.value !== undefined) {
      this.endDate = event.value;
      console.log(event);
      // this.daysBack = event.daySpan;
      const sDate = this._constService.convertDateUTC(this.startDate);
      const eDate = this._constService.convertDateUTC(this.endDate);
      this.showScoutLogList(sDate, eDate);
    }
  }

  changeEvent(event) {
    this.startDate = event.value;
    console.log(event.value);
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.selectedTime = event.value;
  }

  dateChange(event: any) {
    console.log(event);
    this.daysBack = event.daySpan;
    const sDate = this._constService.convertDateUTC(event.startDate);
    const eDate = this._constService.convertDateUTC(event.endDate);
    this.showScoutLogList(sDate, eDate);
  }

  showScoutLogList(dFrom: string, dTo: string) {
    // this.componentSubscription = this._reportService.getAdminScoutReliability(dFrom, dTo).subscribe((r) => {
    //   console.log(r);
    //   this.scoutLogs = r;
    //   if (r !== null || r !== undefined) {
    //     this._config.dataSource.data = this.scoutLogs as ScoutReliability[];
    //     this._config.loading = false;
    //     this._snack.displaySuccess("Scout reliability report loaded");
    //   }
    //   setTimeout(() => (this._config.dataSource.paginator = this.paginator));
    // });
  }

  refresh($event) {
    this._config.loading = true;
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    const utc = this._constService.convertDateUTC(this.dateNow);

    this.showScoutLogList(ut.toString(), utc.toString());
  }

  valueChanged(event: any) {
    console.log(event);
  }
}
