<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading" [items]="actionButtons" (refresh)="refresh()"
  (actionListClicked)="actionsClicked($event)">
</roots-page-header>
<!-- <roots-actionable-table
  [actionColumns]="actionColumns"
  [dataSource]="dataSource"
  [filter]="message"
  [csv]="title"
  (actionsClicked)="actionsClicked($event)"
  [reportBuilder]="reportBuilderEnabled">
</roots-actionable-table> -->
<div style="padding-right: 20px;">  
  <mat-table #sort="matSort" matSort [dataSource]="dataSource" class="main">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let customer" matTooltip="Customer Id: {{ customer.id }}">
        {{ customer.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer
      </mat-header-cell>
      <mat-cell *matCellDef="let customer" class="table-overflow">
        {{ customer.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerTypeId">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Type
      </mat-header-cell>
      <mat-cell *matCellDef="let customer" matTooltip="Customer Type Id: {{ customer.customerTypeId }}"
        class="table-overflow center">
        {{ customer.typeName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="regionId">
      <mat-header-cell *matHeaderCellDef class="center" mat-sort-header>
        Region
      </mat-header-cell>
      <mat-cell *matCellDef="let customer" matTooltip="Region Id: {{ customer.regionId }}"
        class="table-overflow center">
        {{ customer.regionName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ownership">
      <mat-header-cell class="align-mid" *matHeaderCellDef>
        ASO
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let customer">
        <button mat-icon-button color="accent" (click)="navigate(customer, 1)" matTooltip="View Customer's Ownership"
          matTooltipPosition="above">
          <mat-icon class="mat-18">history</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assets">
      <mat-header-cell class="align-right" *matHeaderCellDef>
        Assets
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let customer">
        <button mat-icon-button color="accent" (click)="navigate(customer, 2)" matTooltip="View Customer's Assets"
          matTooltipPosition="above">
          <mat-icon class="mat-18">widgets</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="users">
      <mat-header-cell class="align-right" *matHeaderCellDef>
        Users
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let customer">
        <button mat-icon-button color="primary" (click)="navigate(customer, 3)" matTooltip="View Customer's Users"
          matTooltipPosition="above">
          <mat-icon class="mat-18">person</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userGroups">
      <mat-header-cell class="align-right" *matHeaderCellDef>
        Groups
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let customer">
        <button mat-icon-button color="primary" (click)="navigate(customer, 4)" matTooltip="View Customer's UserGroups"
          matTooltipPosition="above">
          <mat-icon class="mat-18">group</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell class="align-mid" *matHeaderCellDef>
        Details
      </mat-header-cell>
      <mat-cell class="align-right" *matCellDef="let customer">
        <button mat-icon-button color="primary" (click)="navigate(customer, 0)" matTooltip="Edit Customer's Details"
          matTooltipPosition="above">
          <mat-icon class="mat-18">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" class="mobile-mat-row"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]">
  </mat-paginator>
</div>