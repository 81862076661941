<mat-card class="col-12" style="float: left">
  <h4>Scout Packets: <a (click)="navigateToScout(scout)" *ngIf="scout">{{scout.assetName}}</a></h4>
  <mat-form-field appearance="fill" style="float: right" class="col-6">
    <mat-label>{{selectedDate}}</mat-label>
    <mat-date-range-input (touchUi)="(true)" [formGroup]="range" [rangePicker]="picker">
      <input matStartDate [min]="minDate" formControlName="start" placeholder="Start date"
        (dateChange)="changeEvent($event)" />
      <input matEndDate [max]="maxDate" formControlName="end" placeholder="End date"
        (dateChange)="changeEndEvent($event)" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  <mat-card class="col-6" style="float: right" *ngIf="scout">
    <mat-select>
      <mat-form-field>
        <mat-select matInput placeholder="Select Scout" required [(ngModel)]="scout" name="scout">
          <mat-option *ngFor="let s of availableScouts" [value]="s" (click)="scoutSelect(s)">
            {{ s.assetName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-select>
    <p class="name" style="margin: 0px">
      {{ selectedDate }}
    </p>
    <!-- <p class="designation" style="margin: 0px">
      Name: {{ scout.assetName }}
    </p> -->
    <p class="designation-normal" style="margin: 0px">
      Customer: {{ scout.customerName }}
    </p>
    <p class="designation-normal" style="margin: 0px">
      Last Payload: {{ lastPayloadDate }}
    </p>
  </mat-card>
</mat-card>
<mat-card class="col-12" style="float: left" *ngIf="scout">
  <div class="col-3" style="float: left">
    <p class="text label-text margin none">
      Expected: {{ scout.expectedPacketCount.toString() }}
    </p>
  </div>
  <div class="col-3" style="float: left">
    <p class="text label-text margin none">
      Failed: {{ scout.failedPacketCount.toString() }}
    </p>
  </div>
  <div class="col-3" style="float: left">
    <p class="text label-text margin none">
      Successful: {{ scout.successfulPacketCount.toString() }}
    </p>
  </div>
  <div class="col-3" style="float: left" *ngIf="scout">
    <p class="text label-text margin none s-text" *ngIf="scout.percentage > 75; else badHealth">
      {{scout.percentage}}%
    </p>
    <ng-template #badHealth>
      <p class="text label-text margin none e-text">
        {{scout.percentage}}%
      </p>
    </ng-template>
  </div>
  <br>
  <app-health-percentage-bar [total]="scout.totalPacketCount" [successful]="scout.successfulPacketCount"
    [failed]="scout.failedPacketCount">
  </app-health-percentage-bar>
</mat-card>
<div>
  <figure class="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12" style="float: left" id="pie">
  </figure>
</div>