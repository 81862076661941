import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ScoutLogService } from '../../../../shared/services/scout-log.service';
import { ActionType, ConfirmationDialogComponent, ConstantService, RootsActionMenuItem, RootsPageComponentConfig } from '../../../../shared';
import { SearchBarService } from '../../../../shared/services/searchbar.service';
import { SnackService } from '../../../../shared/services/extra/snack.service';
import { environment } from '../../../../../environments';
import { MatDialog } from '@angular/material/dialog';
import { CreateTestSensorDialogComponent } from './create-test-sensor-dialog/create-test-sensor-dialog.component';
import { RootsTableInterface } from '../../../../shared/components/roots-table/roots-table.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-test-sensors',
  templateUrl: './test-sensors.component.html'
})
export class TestSensorsComponent implements OnInit, RootsTableInterface, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: ["id", "name", "inUse", "created", "ended"],
    message: ''
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create TestSensor",
      icon: "add",
      toolTip:
        "Create new TestSensor in db - this will be flagged for incoming scout logs",
      type: ActionType.Create,
    },
    {
      name: "Get Active Test Sensors",
      icon: "link",
      toolTip:
        "Get ONLY the active Test Sensors that will be marked for the Scout Logs",
      type: ActionType.Select,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "End this TestSensor",
      toolTip:
        'End this test sensor so incoming Scout Logs show up with sensors marked correctly',
      icon: "restore_from_trash",
      type: 10,
    },
    {
      name: "Delete",
      toolTip:
        'Delete Test Sensors ONLY if you are testing locally',
      icon: "remove_circle",
      type: 11,
    }
  ];
  testSensors: any[];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    public searchBarService: SearchBarService,
    private _logService: ScoutLogService,
    private _snack: SnackService,
    public _dialog: MatDialog,
    private _constService: ConstantService
  ) {
    this.getAllTestSensors();
    this._config.title = 'Test Sensors';
    this._config.toolTip = 'Find Sensors that will be marked as Test Sensors when a log is submitted from the manufacturing floor.';
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  getData() {
    this._config.loading = true;
    this.componentSubscription = this._logService.httpGetTestSensors().subscribe(data => {
      this._config.dataSource = new MatTableDataSource(data);
      this._config.loading = false;
    })
  }
  
  public getCurrentTestSensors(): any[] {
    this._config.loading = true;
    let request = new Request(
      environment.scoutLogApiUrl + "TestSensors",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": this._constService.CONST.pvtKey,
        }),
      }
    );
    fetch(request)
      .then((response) => {
        response.json().then((res) => { 
          this.testSensors = <any[]>res;
          this.sensorHasEnded(this.testSensors);
          this._config.dataSource = new MatTableDataSource(this.testSensors);
          this._config.loading = false;
          return <any[]>res;
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
      return this.testSensors;
}

public getAllTestSensors(): any[] {
  this._config.loading = true;
  let request = new Request(
    environment.scoutLogApiUrl + "TestSensors/all",
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": this._constService.CONST.pvtKey,
      }),
    }
  );
  fetch(request)
    .then((response) => {
      response.json().then((res) => { 
        this.testSensors = <any[]>res;
        this.sensorHasEnded(this.testSensors);
        this._config.dataSource = new MatTableDataSource(this.testSensors);
        this._config.loading = false;
        return <any[]>res;
      });
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
    return this.testSensors;
}

sensorHasEnded(sensors) {
  for (let i = 0; i < sensors.length; i++) {
    const s = sensors[i];
    if (s.ended === null) {
      s.inUse = 'In Use';
    } else {
      s.inUse = 'Ended';
    }
  }
}

topMenuActionsClicked(action) {
  switch (action.type) {
    case 0:
      //create dialog for test sensor
    const dialogRef = this._dialog.open(CreateTestSensorDialogComponent, {
      width: "60vw"
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getAllTestSensors();
    });
      break;
    case 99:
      this.getCurrentTestSensors();
      break;
    default:
      break;
  }
}

actionsClicked(action) {
  const type = action[0];
  const item = action[1];
  switch (type.type) {
    case 10:
      this._config.loading = true;
      this.endTestSensor(item.name, item.id)
    break;
    case 11:
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
        width: "60vw",
        data: `Are you sure you want to delete ${item.name} Test Sensor?`
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteTestSensor(item.name, item.id); 
        }
      });
    break;
    default:
      break;
  }
}


public endTestSensor(serial: string, id: number) {
  // params
  const url = environment.scoutLogApiUrl + 'TestSensors/End/' + id;
  const headers = new Headers();
  let params: URLSearchParams = new URLSearchParams();
  params.set('serial', serial);
  // params.set('validatedBy', localStorage.getItem('userEmail'));
  headers.set("Content-Type","application/json");
  headers.set("x-functions-key", this._constService.CONST.pvtKey);

  let request = new Request(
      url,
    {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
          name: serial
      })
    }
  );
  fetch(request)
    .then((response) => {
        this.getAllTestSensors();
        this._snack.displaySuccess(response.status.toString());
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
}

public deleteTestSensor(serial: string, id: number) {
  // params
  const url = environment.scoutLogApiUrl + 'TestSensors/Delete/' + id;
  const headers = new Headers();
  let params: URLSearchParams = new URLSearchParams();
  params.set('serial', serial);
  // params.set('validatedBy', localStorage.getItem('userEmail'));
  headers.set("Content-Type","application/json");
  headers.set("x-functions-key", this._constService.CONST.pvtKey);

  let request = new Request(
      url,
    {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify({
          name: serial
      })
    }
  );
  fetch(request)
    .then((response) => {
        this.getAllTestSensors();
        this._snack.displaySuccess(response.status.toString());
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
}

  refresh($event) {
    this._config.loading = true;
    this.getAllTestSensors();
  }
}
