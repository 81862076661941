<a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}"
   [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
   [ngClass]="{ 'disabled': item.disabled === true }"
   id="{{item.id}}"
   class="menu-list-item" 
   matTooltip="{{item.toolTip}}"
   matTooltipPosition="below"
   routerLinkActive="active">
  <button mat-button 
   routerLink="{{item.route}}" (click)="onItemNavigate(item, $event)">
    <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
    {{item.displayName}}
  </button>
  <span fxFlex *ngIf="item.children && item.children.length">
    <button mat-button (click)="onItemSelected(item)">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </button>
  </span>
</a>
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
  </app-menu-list-item>
</div>
