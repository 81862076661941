<div class="col-12" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <!-- <a
    class="list-inline-item font-weight-bold text-uppercase"
    routerLink="{{ url }}"
    >{{ user.name }}
  </a>
  <mat-select style="width: fit-content;" matInput placeholder="Select User" required [(ngModel)]="user" 
    name="name">
    <mat-option (click)="changeUser(user)" *ngFor="let u of allUsers" [value]="user">
        {{ u.name }}
    </mat-option>
  </mat-select> -->
</div>
<div mat-dialog-content>
  <div class="col-12">
    <mat-card-title>
      <p class="list-inline-item font-weight-bold text-uppercase">
        ({{ allUsers.length }}) Users found
      </p>
    </mat-card-title>
    <mat-card-subtitle>
      <ul *ngFor="let user of allUsers | sort:'name'; index as i">
        {{ user.authUsername }} - ({{ user.name }}) - {{user.customerName}}
        <button style="float: right;" (click)="navigate(user)" class="btn btn-secondary" mat-icon-button
          color="primary" matTooltip="Navigate to user details">
          <mat-icon>
            search
          </mat-icon>
        </button>
        <button style="float: right;" (click)="expandUser(user)" class="btn btn-secondary" mat-icon-button
          color="primary" matTooltip="View user information">
          <mat-icon>
            group
          </mat-icon>
        </button>
        <hr />
      </ul>
    </mat-card-subtitle>
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary" mat-raised-button color="primary" (click)="okayClick()">
    Close
  </button>
</div>