import { tap, map } from "rxjs/operators";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ConstantService, RootsPageComponentConfig } from "../../../../shared";
import { MatTableDataSource } from "@angular/material/table";
import { ScoutLogExtended } from "../../../../shared/interfaces/scout-log-extended";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { ScoutLogService } from "../../../../shared/services/scout-log.service";
import { Observable, Subscription } from "rxjs";
import { Asset } from "../../../../shared/interfaces/asset";
import { environment } from "../../../../../environments/environment";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { RenameLogDialogComponent } from "../../../../shared/components/dialogs/rename-log-dialog/rename-log-dialog.component";
import { VerifyDialogComponent } from "../../../../shared/components/dialogs/verify-dialog/verify-dialog.component";
import { RootsActionMenuItem } from "../../../../shared/interfaces/roots-action-menu-item";
import { ProdLogScoutDialogComponent } from "../../dialogs/prodlog-scout-dialog/prodlog-scout-dialog.component";

@Component({
  selector: "app-scout-logs",
  templateUrl: "./scout-logs.component.html",
})
export class ScoutLogsComponent implements OnInit, OnDestroy {
  userDetails: any;
  queryData = "";
  _logs: ScoutLogExtended[];
  duplicates = false;
  request: Request;
  server = environment.scoutLogApiUrl;
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "time",
      "sequenceNumber",
      "productLine",
      "serial",
      "boardVersion",
      "validated",
      "validatedBy",
    ],
    message: "",
  };
  items: RootsActionMenuItem[] = [
    {
      name: "Show Log",
      toolTip: "Expand details of this log",
      icon: "search",
      type: 10,
    },
    {
      name: "More Unit Logs",
      toolTip: "Navigate to a page table showing all the other logs for this unit",
      icon: "search",
      type: 14,
    },
    {
      name: "Create Assets",
      toolTip: "Create assets based on this unit and its sensors",
      icon: "add",
      type: 11,
    },
    {
      name: "Validate/ Un-Validate Log",
      toolTip: "Confirm validation over this log",
      icon: "check",
      type: 12,
    },
    {
      name: "Validate All",
      toolTip: "Confirm validation for all logs containing this same serial",
      icon: "check_box",
      type: 13,
    },
  ];
  get logs(): ScoutLogExtended[] {
    return this._logs;
  }
  set logs(logs: ScoutLogExtended[]) {
    if (this._logs !== logs) {
      this._logs = logs;
    }
  }
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Get Latest",
      icon: "today",
      toolTip: "Pull latest Scout Logs",
      type: 0,
    },
    {
      name: "Get Validated",
      icon: "done",
      toolTip: "Get validated unit logs",
      type: 1,
    },
    {
      name: "Get Non-Validated",
      icon: "clear",
      toolTip: "Get non-validated unit logs",
      type: 2,
    },
    {
      name: `Duplicates? ${this.duplicates}`,
      icon: "today",
      toolTip:
        "If true, pull duplicates for latest, validated, and non-validated log calls",
      type: 3,
    },
  ];
  searchSubscription: Subscription;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private _location: Location,
    private _constService: ConstantService,
    private _http: HttpClient,
    private _router: Router,
    private _dialog: MatDialog,
    private _snack: SnackService,
    private _logService: ScoutLogService,
    public searchBarService: SearchBarService
  ) {
    this.userDetails = this._constService.userDetails;
    this.getScoutLogRequest();
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
    });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  topMenuActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.grabLatest();
        break;
      case 1:
        this.grabValidated();
        break;
      case 2:
        this.grabNotValidated();
        break;
      case 3:
        this.toggleDuplicates();
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
        case 10:
          this.logDialog(item);
          break;
          case 11:
        this.createAssetsDialog(item);
        break;
      case 12:
        this.verifyLogDialog(item);
        break;
      case 13:
        this._logService.validateScoutHistory(item.serial);
        this.refresh();
        break;
        case 14:
          this.navigateToScout(item);
          break;
      default:
        break;
    }
  }

  navigateToScoutLogs(log: ScoutLogExtended) {
    this._router.navigate([`manufacturinglogs/scouts/${log.serial}`]);
  }

  renameDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(RenameLogDialogComponent, {
      width: "60vw",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  findAssets(log: ScoutLogExtended) {
    this._logService.postScoutLogCreate(log);
  }

  createAssetsDialog(log: ScoutLogExtended): void {
    this._logService.createAssetsDialog(log);
  }

  verifyLogDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(VerifyDialogComponent, {
      width: "40vw",
      height: "40vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  logDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(ProdLogScoutDialogComponent, {
      width: "90vw",
      height: "90vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  refresh() {
    this.getScoutLogRequest();
    this._config.dataSource.data = this.logs;
  }

  private getScoutLogRequest() {
    this._config.loading = true;
    this.request = new Request(environment.scoutLogApiUrl + "ScoutLog", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": environment.SCOUTLOG_API_KEY,
      }),
    });
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          const response = <ScoutLogExtended[]>res;
          this.logs = response;
          if (this.logs.length > 0) {
            this._config.loading = false;
            this.logs = this.findCount(response);
            this.queryData = "All ScoutLogs with Duplicates";
            if (this.duplicates == false) {
              this.queryData = "All ScoutLogs";
              this._config.toolTip = `Manage scout logs for both Scout units and the CubScout base units ${this.server}`;
              this._config.title = `${this.queryData}`;
              if (this.logs.length > 0) {
                this.logs = this.removeDuplicates(response);
              }
            }
            this._config.dataSource.data = this.logs;
            this._config.title = this.queryData;
            this._snack.displaySuccess("Logs loaded");
          }
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  toggleDuplicates() {
    this.duplicates != this.duplicates;
    this.actionButtons[3].name = `Duplicates? ${this.duplicates}`;
    this.getScoutLogRequest();
  }

  removeDuplicates(logs: ScoutLogExtended[]): ScoutLogExtended[] {
    for (let i = 0; i < logs.length; i++) {
      const log = logs[i];
      const nextLog = logs[i + 1];
      if (nextLog) {
        if (log.sequenceNumber == nextLog.sequenceNumber) {
          const index = logs.indexOf(nextLog, 0);
          logs.splice(index, 1);
        }
      }
    }
    return logs;
  }

  findCount(logs: ScoutLogExtended[]): any {
    logs.forEach((log) => {
      log.count = 0;
      log.sensors.forEach((sensor) => {
        if (sensor.testSensor === false) {
          log.count++;
        }
      });
    });
    return logs;
  }

  getAssetDetails(id: string): Observable<Asset> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<Asset>(environment.apiUrl + `tables/Asset/${id}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("Asset retrieved:", data))
      );
  }

  navigateToScout(scoutLog: any) {
    console.log(scoutLog);
    this._router.navigate(['/manufacturinglogs/scouts/', scoutLog.serial]);
  }

  grabLatest() {
    this._config.loading = true;
    this.request = new Request(environment.scoutLogApiUrl + "ScoutLog/latest", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": environment.SCOUTLOG_API_KEY,
      }),
    });
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <ScoutLogExtended[]>res;
          if (this.logs.length > 0) {
            this._config.loading = false;
            this.logs = this.findCount(this.logs);
            this.queryData = "All Latest Logs with Duplicates";
            if (this.duplicates == false) {
              this.queryData = "All Latest Logs";
              this.logs = this.removeDuplicates(this.logs);
            }
            this._config.dataSource.data = this.logs;
            this._config.title = this.queryData;
            this._snack.displaySuccess("Logs loaded");
          }
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  grabValidated() {
    this._config.loading = true;
    this.request = new Request(
      environment.scoutLogApiUrl + "ScoutLog/validated/true",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": environment.SCOUTLOG_API_KEY,
        }),
      }
    );
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <ScoutLogExtended[]>res;
          if (this.logs.length > 0) {
            this._config.loading = false;
            this.logs = this.findCount(this.logs);
            this.queryData = "Validated ScoutLogs with Duplicates";
            if (this.duplicates == false) {
              this.queryData = "Validated ScoutLogs";
              this.logs = this.removeDuplicates(this.logs);
            }
            this._config.dataSource.data = this.logs;
            this._config.title = this.queryData;
            this._snack.displaySuccess("Logs loaded");
          }
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  grabNotValidated() {
    this._config.loading = true;
    this.request = new Request(
      environment.scoutLogApiUrl + "ScoutLog/validated/false",
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": environment.SCOUTLOG_API_KEY,
        }),
      }
    );
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <ScoutLogExtended[]>res;
          if (this.logs.length > 0) {
            this._config.loading = false;
            this.logs = this.findCount(this.logs);
            this.queryData = "Non-Validated ScoutLogs with Duplicates";
            if (this.duplicates == false) {
              this.queryData = "Non-Validated ScoutLogs";
              this.logs = this.removeDuplicates(this.logs);
            }
            this._config.dataSource.data = this.logs;
            this._config.title = this.queryData;
            this._snack.displaySuccess("Logs loaded");
          }
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  clickSensors(log: ScoutLogExtended) {
    log.sensorsExpanded = !log.sensorsExpanded;
  }

  clickTestResult(log: ScoutLogExtended) {
    log.testsExpanded = !log.testsExpanded;
  }
}
