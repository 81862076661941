<div mat-dialog-content>
  <mat-card *ngIf="serverInfo">
    <mat-card-header>
      <mat-card-title>
        <p class="list-inline-item font-weight-bold text-uppercase">
          v{{serverInfo.version}}
        </p>
        <p *ngIf="serverInfo.isOkay; else notOkay" style="position: absolute;
                top: 0px;
                right: 0px;">
          Server Responsive
          <mat-icon style="color: green;">check</mat-icon>
        </p>
        <ng-template #notOkay>
          <p style="position: absolute;
                  top: 0px;
                  right: 0px;">
            Server Unresponsive
            <mat-icon style="color: red;">close</mat-icon>
          </p>
        </ng-template>
      </mat-card-title>
      <mat-card-subtitle class="group-dialog-box">
        - {{serverInfo.versionDbSchemaLastMigrationInDb}} <br>
        - {{serverInfo.versionDbSchemaLastMigrationInConfig}} <br>
        - {{serverInfo.versionDescription}}
      </mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onNoClick()">Okay</button>
</div>