<div class="text-center">
  <div class="col-12">
    <input
      #myInput
      style="max-width: 90%; float: left;"
      class="form-control"
      type="file"
      (change)="onChange($event)"
    />
    <button mat-button (click)="remove()" 
      style="max-width: 10%">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <br>
  <hr>

  <!-- <button (click)="onUpload()"  
        class="btn btn-success"> 
        Upload 
    </button>  -->
</div>

<!-- Shareable short link of  uoloaded file -->
<!-- <div class="container text-center jumbotron"
    *ngIf="shortLink"> 
    <h2> Visit Here</h2> 
    <a href="{{shortLink}}">{{shortLink}}</a> 
</div>  -->

<!--Flag variable is used here-->
<div class="container" *ngIf="loading">
  <h3>Loading ...</h3>
</div>
