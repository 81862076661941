<h3 mat-dialog-title 
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>{{data.title}}</h3>
<mat-card *ngIf="response.length > 0">
    <p class="text label-text" style="color: red;">
        Response: {{response}}
    </p>
</mat-card>
<br>
<p [hidden]="!reqLoading"> <i class="fas fa-cog fa-spin"></i> Creating user and sending invite...</p>
<div mat-dialog-content>
    <form class="user-form" #userForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <input matInput placeholder="User Email" type="email" required [(ngModel)]="userPost.systemUser_Username" name="systemUser_Username">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Name" type="text" required [(ngModel)]="userPost.basicName"
                name="basicName">
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Description" required [(ngModel)]="userPost.basicDescription"
                name="basicDescription">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-select matInput placeholder="User Type" required [(ngModel)]="userPost.userTypeId"
                name="userType">
                <mat-option *ngFor="let cat of types | sort:'key'" [value]="cat.value">
                    {{ cat.key }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button type="submit" [disabled]="!userForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
    <button mat-raised-button style="max-width: min-content;" (click)="defaultUser()" color="primary">Defaults</button>

</div>