import { Component, OnInit, HostBinding } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ConstantService, Scout } from '../../../shared';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.scss']
})
export class AccountLoginComponent implements OnInit {
  @HostBinding('class.component-wrapper') defaultClass: boolean;
  version: string = environment.version;
  error: string;
  loginForm: FormGroup;
  returnUrl: string;
  isLoading = false;
  submitted = false;
  authenticated: boolean;
  scouts: Scout[];
  userName: string;
  currentIp: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _adalService: MsAdalAngular6Service,
    private _constService: ConstantService
  ) {
    if (this._constService.user) {
      this.userName = this._constService.user.userName;
      this.currentIp = this._constService.ipAddress;
    }
    if (this._adalService.isAuthenticated) {
      this.router.navigate(['issues']);
    } 
    else {
      this.createForm();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.defaultClass = true;
  }

  azureLogin() {
    this._adalService.login();
  }

  login() {
    console.log('form control here')
  }

  sendNotification() {
    console.log('button clicked for notification');
  }

  logout() {
    localStorage.setItem("id_token", "");
    localStorage.clear();
    this._adalService.logout();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        Validators.required
      ],
      password: [
        '',
        Validators.required,
        Validators.minLength(5)
      ],
      remember: true
    });
  }
}
