<h3 mat-dialog-title>{{data.title}}</h3>
<mat-select matInput placeholder="Type" [(ngModel)]="option">
    <mat-option *ngFor="let option of options" [value]="option" (click)="filter(option)">
        {{ option }}
    </mat-option>
</mat-select>
<div mat-dialog-content 
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>
    <form class="asset-form" #assignmentForm="ngForm" (ngSubmit)="onSubmit($event)">
        <mat-form-field>
            <mat-select matInput multiple placeholder="Assets" required [(ngModel)]="assetsToAssign"
                name="basicName">
                <mat-option *ngFor="let available of notOwned | sort:'name'" [value]="available">
                    {{ available.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
            <mat-label mat-dialog-title>Assets To Assign: </mat-label>
            <mat-label *ngFor="let item of assetsToAssign | sort:'name'" disabled><b>{{item.name}}</b> -- {{item.assetTypeCode}}</mat-label>
            <mat-label *ngIf="assetsToAssign.length < 1" disabled>N/A</mat-label>
            <br>
            <hr>
            <button mat-raised-button type="submit" style="width: fit-content;"
            (click)="onSubmit($event)" [disabled]="!assignmentForm.form.valid" color="primary">
                Assign
            </button>
        </form>
    </div>
    <div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()"
    style="width: fit-content;" color="warn">
        Cancel
    </button>
</div>