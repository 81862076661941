<div class="search-bar">
  <mat-tab-group class="tabs" headerPosition="below" 
  #tabGroup>
  <!-- (selectedTabChange)="tabSelectionChanged($event)" -->
    <mat-tab label="Local" class="search-tab">
    <ng-template matTabContent>
      <div style="max-width: 100%" style="overflow: hidden">
        <input
          class="form-control"
          (keyup)="searchLocal($event.target.value)"
          type="text"
          name="search"
          [(ngModel)]="message"
          autocomplete="off"
          placeholder="Enter local search parameters"
          style="max-width: 90%; float: left;"
        />
      <mat-checkbox 
          matTooltip="Preserve filter for navigation" 
          style="max-width: 8%; padding-left: 1%; background: transparent;" 
          [(ngModel)]="saveFilter" 
          [ngModelOptions]="{standalone: true}"
          matTooltipPosition="below" 
          (change)="checkChanged($event)"
          class="checkbox-top">
      </mat-checkbox>
      </div>
    </ng-template>
    </mat-tab>
    <mat-tab label="Users" class="search-tab">
    <ng-template matTabContent>
      <div style="max-width: 100%" style="overflow: hidden">
        <input
          class="form-control"
          type="text"
          name="search"
          [(ngModel)]="searchUsersText"
          autocomplete="off"
          placeholder="Enter the users full email address for entity search"
          style="max-width: 80%; float: right"
        />
        <button
          mat-icon-button
          (click)="searchAdminUser(searchUsersText)"
          style="width: 20%; float: right; color: white;"
        >
          <span>Search</span>
        </button>
      </div>
    </ng-template>
    </mat-tab>
    <mat-tab label="Assets" class="search-tab">
      <ng-template matTabContent>
        <div style="max-width: 100%" style="overflow: hidden">
          <input
            class="form-control"
            type="text"
            name="search"
            [(ngModel)]="assetSearchText"
            autocomplete="off"
            placeholder="Enter the name or partial name to find asset (excluding health sensors)"
            style="max-width: 80%; float: right"
          />
          <button
            mat-icon-button
            (click)="searchAdminAsset(assetSearchText)"
            style="width: 20%; float: right; color: white;"
          >
            <span>Search</span>
          </button>
        </div>
      </ng-template>
      </mat-tab>
    <mat-tab label="Logs" class="search-tab">
    <ng-template matTabContent>
      <div style="max-width: 100%" style="overflow: hidden">
        <input
          class="form-control"
          type="text"
          name="search"
          [(ngModel)]="searchLogText"
          autocomplete="off"
          placeholder="Search ProdScoutLog for unit sequence # or test result information"
          style="max-width: 80%; float: right"
        />
        <button
          mat-icon-button
          (click)="searchLogs(searchLogText)"
          style="width: 20%; float: right; color: white;"
        >
          <span>Search</span>
        </button>
      </div>
    </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
