<div mat-dialog-content>
<mat-card>
  <h3 class="text label-header-text">Create New Note</h3>
  <mat-form-field>
      <mat-label>Note Name</mat-label>
      <input class="text dashboard-text" matInput placsseholder="Create a name for this note" [(ngModel)]="newNote.basicName" required>
  </mat-form-field>

  <mat-form-field>
      <mat-label>Description</mat-label>
      <input class="text dashboard-text" matInput placeholder="Place an additional description for this note if needed"
       [(ngModel)]="newNote.basicDescription">
  </mat-form-field>

  <mat-form-field>
      <mat-label>Note Text</mat-label>
      <input class="text dashboard-text" type="text" matInput placeholder="Place the main body text here for the new note" 
      [(ngModel)]="newNote.text" required>
  </mat-form-field>

  <mat-form-field>
      <mat-label>Entity Code</mat-label>
      <input class="text dashboard-text" matInput placeholder="Entity Code" [(ngModel)]="newNote.entityCode" disabled>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Entity Type Code</mat-label>
    <input class="text dashboard-text" matInput placeholder="Entity Type Code" [(ngModel)]="newNote.entityTypeCode" disabled>
</mat-form-field>

<mat-form-field>
  <mat-label>Entity Id</mat-label>
  <input class="text dashboard-text" matInput placeholder="Entity Id" [(ngModel)]="newNote.entityId" disabled>
</mat-form-field>

<!-- 
  <mat-form-field>
      <mat-label> Created At</mat-label>
      <input class="text dashboard-text" matInput placeholder="Created At"
          [ngModel]="newNote.createdAt | date: 'short'" disabled>
  </mat-form-field>

  <mat-form-field>
      <mat-label> Updated At</mat-label>
      <input class="text dashboard-text" matInput placeholder="Updated At"
          [ngModel]="newNote.updatedAt | date: 'short'" disabled>
  </mat-form-field> -->
</mat-card>
</div>
<div mat-dialog-actions>
  <button class="text dashboard-text" mat-raised-button type="submit" style="width: fit-content"
      (click)="createNote()" color="primary">Create
  </button>
    <button mat-button color="primary" (click)="onNoClick()">Close</button>
</div>