<div class="row bar">
  <div class="mTop">
    <mat-form-field>
        <mat-label>Items per page:</mat-label>
        <mat-select [(ngModel)]="pageSize"> 
          <mat-option *ngFor="let page of pageSizes" (click)="perPageChange(page)" [value]="page">{{page}}</mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <button mat-raised-button class="paginatorBtn mTop mLeft" (click)="navigate(-1)">
    <mat-icon class="color">keyboard_arrow_left</mat-icon>
  </button>
  <button mat-raised-button class="paginatorBtn mTop mRight" (click)="navigate(1)" [ngClass]="{'btn-disable': range.end >= total}">
    <mat-icon class="color">keyboard_arrow_right</mat-icon>
  </button>
  <div class="mTop">
    {{range.start}} - {{range.end}} of {{total}}
  </div>
</div>