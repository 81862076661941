<mat-progress-bar
  style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
  *ngIf="loading"
  mode="query"
>
</mat-progress-bar>
<div mat-dialog-content>Current Assets with this Firmware:</div>
<p class="text small-text">
  Please remove ALL the assets listed below to complete this deletion.
</p>
<hr />
<div *ngIf="!removalAvailable; else noData">
  <div *ngFor="let d of data.firmwares">
    <div class="col-12" style="max-height: 20em; overflow: auto">
      <p class="data">
        {{ d.basicName }}
      </p>
      <!-- <p class="text small-text">
        {{d.basicDescription}}
      </p> -->
      <button
        mat-button
        color="warn"
        (click)="removeAssetModelRelationship(d)"
        style="max-width: 5%"
      >
        X
      </button>
    </div>
    <hr />
  </div>
</div>
<ng-template #noData>
  <p class="text small-text">No Assets with this Firmware Model</p>
  <hr />
</ng-template>
<h5 [ngClass]="{ disabled: removalAvailable === false }">
  Confirm Deletion of this Model?
</h5>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">No</button>
  <button
    mat-button
    color="primary"
    [ngClass]="{ disabled: removalAvailable === false }"
    [mat-dialog-close]="true"
    (click)="yesClicked()"
    cdkFocusInitial
  >
    Yes
  </button>
</div>
