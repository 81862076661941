import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { ReferencesEditComponent } from './edit/references-edit.component';
import { ReferencesComponent } from './references.component';

const routes: Routes = [
  { path: '', component: ReferencesComponent },
  { path: ":id", component: ReferencesEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferencesRoutingModule { }
