import { OnInit, Injectable, Directive } from '@angular/core';
import { ConstantService } from '../../shared/services/constant.service';

export interface AadModel {
    authority: string;
    resourceId: string;
    clientId: string;
    redirectUri: string;
}

export class AadConfiguration implements AadModel {
    authority: string;
    resourceId: string;
    clientId: string;
    redirectUri: string;

    constructor(
        authority: string,
        resourceId: string,
        clientId: string,
        redirectUri: string
    ) {
        this.authority = authority;
        this.resourceId = resourceId;
        this.clientId = clientId;
        this.redirectUri = redirectUri;
    }
}

@Directive()
export class Aad implements OnInit {
    config: AadConfiguration;
    _dbString: string;
    ngOnInit(): void {
        this.config = this.info;
    }

    constructor(dbString: string) {
        this._dbString = dbString;
        this.config = this.info;
    }

    get info(): AadConfiguration {
        switch (this._dbString) {
            case 'https://escoutd.azurewebsites.net/':
                const resource2: AadConfiguration = {
                    authority: 'https://login.microsoftonline.com/earthscout.onmicrosoft.com',
                    resourceId: '4d811ab5-42d4-4d59-8607-0837a4f0c2df',
                    clientId: '938bb782-2f82-4b38-90a4-ded620c32ed1',
                    redirectUri: 'https://escoutd.azurewebsites.net/.auth/login/done'
                };
                return resource2;
            default:
                this.config = null;
                throw console.error('Unknown app service - cannot generate Aad info for it.');
        }
    }
}
@Injectable()
export class ConfigurationAAD {
    url = '';
    config: any;
    constructor(
        private _constService: ConstantService
    ) {
        this.url = this._constService.CONST.URL;
        this.config = this.getConfiguration;
    }

    get getConfiguration(): any {
        return {
            tenant: 'earthscout.onmicrosoft.com',
            clientId: '938bb782-2f82-4b38-90a4-ded620c32ed1',
            redirectUri: window.location.origin + '/auth-callback',
            postLogoutRedirectUri: window.location.origin + '/login',
            cacheLocation: 'localStorage',
            navigateToLoginRequestUrl: true,
            endpoints: {
                'https://escoutd.azurewebsites.net/': 'abc03a69-303c-4653-84a0-fceea9fdf655',
                'http://localhost:4200': 'abc03a69-303c-4653-84a0-fceea9fdf655'
            },
            anonymousEndpoints: [],
            isAngular: true
        };
    }
}
