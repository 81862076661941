// export * from './authentication/admin.authentication.guard';
// export * from './authentication/authentication.guard';
// export * from './authentication/authentication.service';
export * from './authentication/manufacturing.authentication.guard';
export * from './authentication/implcitMSALAuthenticationProvider';
export * from './authentication/msal.service';
export * from './authentication/azure-configuration.service';

export * from './local-storage/local-storage.service';

export * from './error-pages/not-found/not-found.component';
export * from './error-pages/server-error/server-error.component';

export * from './material/mat.module';

export * from './interceptors/authHttp.interceptor';

export * from './components/nav-item.interface';
export * from './components/menu-top-item/menu-top-item.component';
export * from './components/menu-list-item/menu-list-item.component';

// typescript will read the 'index.ts' file so we can condense our imports, in other modules.
// Ex: referencing ./core directory will read 'index.ts' file and import all of these exports
