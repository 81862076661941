import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import {
  AppScout, AppReadingType, AppBody, AppDataDerivedGdu,
  AppDataDerivedProvider, AppHolding, AppSensor, AppSensorData,
  AppSubject, AppUser
} from '../../interfaces/app';
import {AppCustomer} from '../../interfaces/app/app-customer';
import {AppOccasion} from '../../interfaces/app/app-occasion';
import { ConstantService } from '../constant.service';
import { AppSeason } from '../../interfaces/app/app-season';
import { AppRegion } from '../../interfaces/app/app-region';
import { AppPeriod } from '../../interfaces/app/app-period';
import { AppPeriodTemplate } from '../../interfaces/app/app-period-template';
import { AppPeriodEvent } from '../../interfaces/app/app-period-event';
import { AppPeriodNotification } from '../../interfaces/app/app-period-notification';
import { AppScoutInstallation } from '../../interfaces/app/app-scout-installation';
import { NicoUtils } from '../..';

@Injectable()
export class AppService {
  params: any = {
    'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
    'userId': this._constService.CONST.userId
  };
  url = `${this._constService.CONST.URL}api/`;
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService
  ) {
  }

  public getAppBody(): Observable<AppBody[]> {
    const endpoint = `${this.url}app/body`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppBody(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppBody`)),
        catchError(this._constService.handleError<AppBody[]>(`get_AppBody`))
      );
  }

  // get array of data derived gdu
  public getAppDataDerivedGdu(): Observable<AppDataDerivedGdu[]> {
    const endpoint = `${this.url}app/dataderived`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppDataDerivedGdu(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppDataDerivedGdu`)),
        catchError(this._constService.handleError<AppDataDerivedGdu[]>(`get_AppDataDerivedGdu`))
      );
  }

  // get array of data derived providers
  public getAppDataDerivedProvider(): Observable<AppDataDerivedProvider[]> {
    const endpoint = `${this.url}app/dataprovider`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppDataDerivedProvider(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppDataDerivedProvider`)),
        catchError(this._constService.handleError<AppDataDerivedProvider[]>(`get_AppDataDerivedProvider`))
      );
  }

  // get app holdings for given scout
  public getAppHolding(scoutId: string): Observable<AppHolding> {
    const endpoint = `${this.url}app/holding`;
    this.params.scoutIds = scoutId;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return res;
      }))
      .pipe(
        tap(_ => console.log(`fetched AppHolding`)),
        catchError(this._constService.handleError<AppHolding[]>(`get_AppHolding`))
      );
  }

  // get array of reading types
  public getAppReadingType(params?: any): Observable<AppReadingType[]> {
    if (params === null || params === undefined) {
      params = this.params;
    }
    const endpoint = `${this.url}app/readingtype`;
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return this.parse_AppReadingTypes(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppReadingType`)),
        catchError(this._constService.handleError<AppReadingType[]>(`get_AppReadingType`))
      );
  }

  public getAppCustomer(): Observable<AppCustomer[]> {
    const endpoint = `${this.url}app/customer`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppCustomer(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppReadingType`)),
        catchError(this._constService.handleError<AppCustomer[]>(`get_AppCustomer`))
      );
  }

  // get array of scouts
  public getAppScout(id?: string): Observable<AppScout[]> {
    const endpoint = `${this.url}app/scout`;
    let params;
    if(id !== null){
      params = {
        'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
        'scoutId': id
      };
    } else{
      params = {
        'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
      };
    }
    return this._http
      .get<AppScout[]>(endpoint, {params: params})
      .pipe(map((res: any) => {
        return this.parse_AppScout(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppScout`)),
        catchError(this._constService.handleError<AppScout[]>(`get_AppScout`))
      );
  }

  // get array of sensors
  public getAppSensor(): Observable<AppSensor[]> {
    const endpoint = `${this.url}app/sensor`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppSensor(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppSensor`)),
        catchError(this._constService.handleError<AppSensor[]>(`get_AppSensor`))
      );
  }

  // get array of sensors
  public getAppSensorById(id: string): Observable<AppSensor[]> {
    const endpoint = `${this.url}app/sensor`;
    const params: any = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'scoutId': id
    };
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return this.parse_AppSensor(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppSensor`)),
        catchError(this._constService.handleError<AppSensor[]>(`get_AppSensor`))
      );
  }

  // get array of sensors data
  public getAppSensorData(baseScoutIds?: string): Observable<AppSensorData[]> {
    const endpoint = `${this.url}app/sensordata`;
    let params;
    params = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'basescoutids': baseScoutIds,
    };
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return this.parse_AppSensorData(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppSensorData`)),
        catchError(this._constService.handleError<AppSensorData[]>(`get_AppSensorData`))
      );
  }

  // get the current user & basic ids
  public getAppUser(): Observable<AppUser> {
    const endpoint = `${this.url}app/user`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppUser(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppUser`)),
        catchError(this._constService.handleError<AppUser>(`get_AppUser`))
      );
  }

  // save app user
  public saveAppUser(data: AppUser): Observable<AppUser> {
    const endpoint = `${this.url}app/user`;
    return this._http.post(endpoint, data, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppUser(res);
      }));
  }

  public getAppOccasion(): Observable<AppOccasion[]> {
    const endpoint = `${this.url}app/occasion`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return this.parse_AppOccasion(res);
      }))
      .pipe(
        tap(_ => console.log(`fetched AppOccasion`)),
        catchError(this._constService.handleError<AppOccasion[]>(`get_AppOccasion`))
      );
  }

  // get seasons
  public getAppSeason(scoutIds: string[]): Observable<AppSeason[]> {
    const endpoint = `${this.url}app/season`;
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'scoutId': scoutIds
    };
    return this._http.get(endpoint, {params: queryParams})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // post season
  public postAppSeason(body, params): Observable<AppSeason[]> {
    const endpoint = `${this.url}app/season`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // delete season
  public deleteAppSeason(params): Observable<any> {
    const endpoint = `${this.url}app/season`;
    return this._http.delete(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // get array of subjects
  public getAppSubject(): Observable<AppSubject[]> {
    const endpoint = `${this.url}app/subject`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return res;
      }))
      .pipe(
        tap(_ => console.log(`fetched AppSubject`)),
        catchError(this._constService.handleError<AppSubject[]>(`get_AppSubject`))
      );
  }

  // post subject
  public postAppSubject(body, params): Observable<AppSubject[]> {
    const endpoint = `${this.url}app/subject`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // delete subject
  public deleteAppSubject(params): Observable<any> {
    const endpoint = `${this.url}app/subject`;
    return this._http.delete(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // get app regions
  public getAppRegions(): Observable<AppRegion[]> {
    const endpoint = `${this.url}app/region`;
    return this._http.get(endpoint, {params: this.params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // post region
  public postAppRegion(body, params): Observable<AppRegion[]> {
    const endpoint = `${this.url}app/region`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }


  // get Period
  public getAppPeriod(params?: any): Observable<AppPeriod[]> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/period`;
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }


  // post Period
  public postAppPeriod(body, params?: any): Observable<AppPeriod> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/period`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // delete Period
  public deleteAppPeriod(id: string): Observable<any> {
    const params = this.params;
    params.id = id;
    const endpoint = `${this.url}app/period`;
    return this._http.delete(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // get Period Template
  public getAppPeriodTemplate(params?: any): Observable<AppPeriodTemplate[]> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/periodtemplate`;
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }


  // post Period Template
  public postAppPeriodTemplate(body, params?: any): Observable<AppPeriodTemplate> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/periodtemplate`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // delete Period Template
  public deleteAppPeriodTemplate(id: string): Observable<any> {
    const params = this.params;
    params.id = id;
    const endpoint = `${this.url}app/periodtemplate`;
    return this._http.delete(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // get App Period Events
  public getAppPeriodEvents(params?: any): Observable<AppPeriodEvent[]> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/periodevent`;
    return this._http.get(endpoint, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // post AppPeriodNotificationEvents
  public postAppPeriodEvents(body: any, params?: any): Observable<AppPeriodEvent> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/periodevent`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }


  // get AppPeriodNotifications
  public getAppPeriodNotifications(scoutIds?: string): Observable<AppPeriodNotification[]> {
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'scoutIds': scoutIds
    };
    const endpoint = `${this.url}app/periodnotification`;
    return this._http.get(endpoint, {params: queryParams})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // post AppPeriodNotification
  public postAppPeriodNotification(body: any, params?: any): Observable<AppPeriodNotification> {
    if (NicoUtils.isNullOrUndefined(params)) {
      params = this.params;
    }
    const endpoint = `${this.url}app/periodnotification`;
    return this._http.post(endpoint, body, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // post multiple notification read
  public setPeriodNotificationConfirmed(ids: string): Observable<any> {
    const endpoint = `${this.url}app/periodnotification/confirm`;
    const params = this.params;
    params.ids = ids;
    return this._http.post(endpoint, null, {params: params})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  // get app scout tree
  public getTableScout(id: string): Observable<any> {
    const URL = `${this._constService.CONST.URL}tables/Scout/${id}`;
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'scoutId': id
    };
    return this._http.get(URL, {params: queryParams}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app scout tree
  public getAppScoutWithFirmwareModel(): Observable<any> {
    const endpoint = `${this.url}app/scout/scoutwithfirmwaremodel`;
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
    };
    return this._http.get(endpoint, {params: queryParams}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app scout tree
  public getAppScoutTreeFwmById(id: string): Observable<any> {
    const endpoint = `${this.url}app/scout/treefwm`;
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'ids': id
    };
    return this._http.get(endpoint, {params: queryParams}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app scout tree
  public getAppScoutTreeById(id: string): Observable<any> {
    const endpoint = `${this.url}app/scout/tree`;
    const queryParams = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'scoutIds': id,
      'gduIsOn': 'true'
    };
    return this._http.get(endpoint, {params: queryParams}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app scout tree
  public getAppScoutTree(): Observable<any> {
    const endpoint = `${this.url}app/scout/tree`;
    return this._http.get(endpoint, {params: this.params}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app info
  public getSystemInfo(): Observable<any> {
    const endpoint = `${this.url}sys/metainfo`;
    return this._http.get(endpoint, {params: this.params}).pipe(map((res: any) => {
      return res;
    }));
  }

  // get app scout installation
  public getAppScoutInstallation(): Observable<any> {
    const endpoint = `${this.url}app/scoutinstallation`;
    return this._http.get(endpoint, {params: this.params}).pipe(map((res: AppScoutInstallation) => {
      return res;
    }));
  }

  // <-------------------------------------------------- Parsers --------------------------------------------------> //
  parse_AppScout(resp: any): AppScout[] {
    const scouts: AppScout[] = [];
    resp.forEach((appScout: AppScout) => {
      const scout: AppScout = <AppScout>{
        assetOwnerShipId: appScout.assetOwnerShipId,
        healthSensorId: appScout.healthSensorId,
        content: appScout.content,
        code: appScout.code,
        name: appScout.name,
        description: appScout.description,
        id: appScout.id
      };
      scouts.push(scout);
    });
    return scouts;
  }

  parse_AppOccasion(resp: any): AppOccasion[] {
    const occasions: AppOccasion[] = [];
    if (resp) {
      resp.forEach((occasion: AppOccasion) => {
        const occ: AppOccasion = {
          occasionTypeId: occasion.occasionTypeId,
          regionId: occasion.regionId,
          customerId: occasion.customerId,
          ownerId: occasion.ownerId,
          where: occasion.where,
          when: occasion.when,
          duration: occasion.duration,
          priority: occasion.priority,
          isPrivate: occasion.isPrivate,
          isAllDay: occasion.isAllDay,
          audienceScope: occasion.audienceScope,
          colour: occasion.colour,
          bodyId: occasion.bodyId,
          code: occasion.code,
          name: occasion.name,
          description: occasion.description,
          id: occasion.id
        };
        occasions.push(occ);
      });
      return occasions;
    }
  }

  parse_AppReadingTypes(resp: any): AppReadingType[] {
    const readings: AppReadingType[] = [];
    if (resp) {
      resp.forEach((reading: AppReadingType) => {
        const readingType = {
          categoryCode: reading.categoryCode,
          unitSymbol: reading.unitSymbol,
          levelId: reading.levelId,
          code: reading.code,
          name: reading.name,
          description: reading.description,
          id: reading.id
        };
        readings.push(readingType);
      });
      return readings;
    }
  }

  parse_AppCustomer(resp: any): AppCustomer[] {
    const appCustomers: AppCustomer[] = [];
    resp.forEach((customer: AppCustomer) => {
      const cust: AppCustomer = <AppCustomer>{
        customerTypeId: customer.customerTypeId,
        regionId: customer.regionId,
        languageTag: customer.languageTag,
        code: customer.code,
        name: customer.name,
        description: customer.description,
        id: customer.id
      };
      appCustomers.push(cust);
    });
    return appCustomers;
  }

  parse_AppDataDerivedGdu(resp: any): AppDataDerivedGdu[] {
    const derivedGdus: AppDataDerivedGdu[] = [];
    resp.forEach((dataDerived: AppDataDerivedGdu) => {
      const dataDerivedGdu: AppDataDerivedGdu = <AppDataDerivedGdu>{
        value: dataDerived.value,
        when: dataDerived.when
      };
      derivedGdus.push(dataDerivedGdu);
    });
    return derivedGdus;
  }

  parse_AppDataDerivedProvider(resp: any): AppDataDerivedProvider[] {
    const derivedProviders: AppDataDerivedProvider[] = [];
    resp.forEach((data: AppDataDerivedProvider) => {
      const dataDerived: AppDataDerivedProvider = <AppDataDerivedProvider>{
        dataProviderTypeCode: data.dataProviderTypeCode,
        code: data.code,
        name: data.name,
        description: data.description,
        id: data.id
      };
      derivedProviders.push(dataDerived);
    });
    return derivedProviders;
  }

  parse_AppHolding(resp: any): AppHolding[] {
    const holdings: AppHolding[] = [];
    resp.forEach((appHolding: AppHolding) => {
      const holding: AppHolding = <AppHolding>{
        code: appHolding.code,
        name: appHolding.name,
        description: appHolding.description,
        id: appHolding.id
      };
      holdings.push(holding);
    });
    return holdings;
  }

  parse_AppBody(resp: any): AppBody[] {
    const bodies: AppBody[] = [];
    resp.forEach((appBody: AppBody) => {
      const body: AppBody = <AppBody>{
        holdingId: appBody.holdingId,
        subjectId: appBody.subjectId,
        whenBegin: appBody.whenBegin,
        whenEnd: appBody.whenEnd,
        baseTemperature: appBody.baseTemperature,
        code: appBody.code,
        name: appBody.name,
        description: appBody.description,
        id: appBody.id
      };
      bodies.push(body);
    });
    return bodies;
  }

  parse_AppSensor(resp: any): AppSensor[] {
    const sensors: AppSensor[] = [];
    resp.forEach((appSensor: AppSensor) => {
      const sensor: AppSensor = <AppSensor>{
        assetCode: appSensor.assetCode,
        assetName: appSensor.assetName,
        assetOwnershipId: appSensor.assetOwnershipId,
        code: appSensor.code,
        content: appSensor.content,
        defaultReadingTypeId: appSensor.defaultReadingTypeId,
        description: appSensor.description,
        id: appSensor.id,
        name: appSensor.name,
        scoutId: appSensor.scoutId,
        scoutCode: appSensor.scoutCode,
        scoutName: appSensor.scoutName,
        statistic: appSensor.statistic
      };
      sensors.push(sensor);
    });
    return sensors;
  }

  parse_AppSensorData(resp: any): AppSensorData[] {
    const sensorDatas: AppSensorData[] = [];
    resp.forEach((data: AppSensorData) => {
      const sensor: AppSensorData = <AppSensorData>{
        value: data.value,
        when: data.when
      };
      sensorDatas.push(sensor);
    });
    return sensorDatas;
  }

  parse_AppSubject(resp: any): AppSubject[] {
    const subjects: AppSubject[] = [];
    resp.forEach((appSubject: AppSubject) => {
      const subject: AppSubject = <AppSubject>{
        baseTemperature: appSubject.baseTemperature,
        code: appSubject.code,
        name: appSubject.name,
        description: appSubject.description,
        id: appSubject.id
      };
      subjects.push(subject);
    });
    return subjects;
  }

  parse_AppUser(resp: any): AppUser {
    if (resp) {
      const user: AppUser = {
        userTypeId: resp.userTypeId,
        customerId: resp.customerId,
        authUsername: resp.authUsername,
        languageTag: resp.languageTag,
        code: resp.code,
        name: resp.name,
        description: resp.description,
        id: resp.id,
        nameInfo: resp.nameInfo,
        contactInfo: resp.contactInfo,
        userSettingsInfo: resp.userSettingsInfo,
        userDevices: resp.userDevices,
        userGroups: resp.userGroups,
        regionId: resp.regionId,
        content: resp.content
      };
      return user;
    }
  }
}
