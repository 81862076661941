import { Component, Input } from "@angular/core";

@Component({
    selector: "unit-loading",
    templateUrl: "./unit-loading.component.html",
    styleUrls: ["./unit-loading.component.scss"]
  })
export class UnitLoadingComponent {
    _loading: boolean;
    
    @Input("loading")
    public get loading() : boolean {
        return this._loading;
    }
    public set loading(loading : boolean) {
        this._loading = loading;
    }
    
    
}