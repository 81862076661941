
  <div class="menu back menu--3" (click)="clickEvent($event)">
    <label>
      <input type="checkbox">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="30" />
        <path class="line--1" d="M0 40h62c18 0 18-20-17 5L31 55" />
        <path class="line--2" d="M0 50h80" />
        <path class="line--3" d="M0 60h62c18 0 18 20-17-5L31 45" />
      </svg>
    </label>
  </div>