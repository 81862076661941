<button class="action-menu-btn" mat-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<!-- <roots-dots-menu></roots-dots-menu> -->
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let item of items"
  (click)="emitAction(item)">
    <mat-icon>{{ item.icon }}</mat-icon>
    <span>{{ item.name }}</span>
  </button>
</mat-menu>