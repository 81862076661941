import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subscription, throwError } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { SearchBarService } from "../../shared/services/searchbar.service";
import { AdminService } from "../../shared/services/admin.service";
import { RootsActionMenuItem, RootsPageComponentConfig } from "../../shared";

@Component({
  selector: "app-scouts",
  templateUrl: "./scouts.component.html"
})
export class ScoutsComponent implements OnInit, AfterViewInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      'firmwareVersion',
      'name',
      'code',
      'content',
      'statistics'
    ],
    message: "",
  };
  items: RootsActionMenuItem[] = [
    {
      name: "Scout Details",
      toolTip:
        "Navigate to this Scout details",
      icon: "edit",
      type: 9,
    },
  ];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  scouts: any[] = [];
  scoutId = "";
  firmwares: any[];
  initLoad = true;
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    private _route: ActivatedRoute,
    private _router: Router,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "Scouts";
    this._config.toolTip =
      "Find details regarding all scout's and their configurations. Click to refresh.";
  }

  ngOnInit(): void {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.scoutId = id;
          return id;
        }),
        mergeMap((id) => {
          const getScouts = this._adminService.getAdminScout(null);
          const getFirmwares = this._adminService.getFirmwareModels();
          return forkJoin([getScouts, getFirmwares]);
        })
      )
      .subscribe(
        (results) => {
          const scouts = results[0];
          const firmwares = results[1];
          this.scouts = scouts;
          this.firmwares = firmwares.reverse();
          this._config.dataSource.data = this.scouts;

          this._config.loading = false;
          this.initLoad = false;
        },
        throwError,
        () => {
          console.log("an error has occurred");
        }
      );
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this._config.dataSource);
      }
    });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  refresh() {
    this._config.loading = true;
    const getScouts = this._adminService.getAdminScout(null);
    this.componentSubscription = getScouts.subscribe(scouts => {
      this._config.dataSource.data = scouts;
      this._config.loading = false;
    })
  }

  navigateToFirmwareVersion(scout: any) {
    for (let i = 0; i < this.firmwares.length; i++) {
      const f = this.firmwares[i];
      if (scout.firmwareVersion === f.firmwareVersion) {
        this._router.navigate([`/firmwares/${f.id}`])
      }
    }
  }

  actionsClicked(action) {
    const type = action[0];
      const item = action[1];
      switch (type.type) {
        case 9:
          this._config.loading = true;
          this.navigateToDetails(item);
        break;
      }
    }

  navigateToDetails(scout: any) {
    this._router.navigate([`scouts/${scout.id}`])
  }

  getScouts(scout: any) {
    this.componentSubscription = this._adminService.getFirmwareInstance(scout.id).subscribe(results => {
      console.log(results);
    })
  }
}
