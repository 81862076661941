import {Component, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AdminService } from '../../../shared/services/admin.service';
import { FirmwareModel } from '../../../shared/interfaces/firmware-model';
import { ConstantService } from '../../../shared';
import { Subscription } from 'rxjs';
const { v1: uuidv4 } = require('uuid');

@Component({
    selector: 'app-firmware-form',
    templateUrl: './firmware-form.component.html',
    styleUrls: ['./firmware-form.component.scss']
  })
  export class FirmwareFormComponent implements OnDestroy {
    d = new Date();
    year = this.d.getFullYear();
    month = this.d.getMonth();
    day = this.d.getDate();
    c = new Date(this.year + 1, this.month, this.day);
    loading = true;
    instance = '';
    createdInstance = '';
    componentSubscription: Subscription;
    constructor(
      public dialogRef: MatDialogRef<FirmwareFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _adminService: AdminService,
      private _constService: ConstantService
      ) {
        this.getFirmwareInstance();
        this.instance = data.id;
    }
    instances: any[];
    currentDate = new Date(Date.now());
    firmwarePost: FirmwareModel = {
      firmwareInstanceId: "",
      firmwareVersion: "X.X.X",
      whenBegin: this._constService.convertDateUTC(this.currentDate)
    }
    public event: EventEmitter<any> = new EventEmitter();
    submitted = false;

    onNoClick(): void {
      this.dialogRef.close();
    }

    ngOnDestroy() {
      // if (this.componentSubscription) {
      //   this.componentSubscription.unsubscribe();
      // }
    }

    getFirmwareInstance() {
      this.componentSubscription = this._adminService.getFirmwareInstance().subscribe(results => {
        this.instances = results;
        console.log(results);
        this.populateInstances(results);
        this.loading = false;
      })
    }

    populateInstances(instances: any[]): object[] {
      this.instances = [];
      for (let i = 0; i < instances.length; i++) {
        const r = instances[i];
        const instance = {key: r.name , value: r.id, file: r.file };
        if (r.id !== this.instance) {
          this.firmwarePost.firmwareInstanceId = this.instance;
        }
        this.instances.push(instance);
      }
      return this.instances.reverse();
    }

    onSubmit(): void {
      this.submitted = true;
      const firmware = JSON.stringify(this.firmwarePost);
      this.componentSubscription = this._adminService.postFirmwareModels(firmware).subscribe(c => {
        console.log('firmware has been created succesfully: ' + c);
        this.event.emit({data: firmware});
      });
      this.dialogRef.close();
    }

    removeHyphens(code: string): string {
      const replaced = code.replace(/-/g, '');
      return replaced;
    }
  }
