import { UtilityRunRequest } from "./utility-run-request";
import { UtilityRunResponse } from "./utility-run-response";
import { Guid } from "guid-typescript";

export class UtilityRun {
    constructor(utilityId: string, userId: string, whenBegin: Date, whenEnd: Date, 
        timeout: number, importance: string, description: string, request: UtilityRunRequest) {
            this.utilityId = utilityId;
            this.userId = userId;
            this.whenBegin = whenBegin;
            this.whenEnd = whenEnd;
            this.timeout = timeout;
            this.importance = importance;
            this.description = description;
            this.request = request;
            this.response = null;
            this.id = Guid.create().toString();
    }

    utilityId: string;
    userId: string;
    whenBegin: Date;
    whenEnd: Date;
    timeout: number;
    importance: string;
    description: string;
    request: UtilityRunRequest;
    response: UtilityRunResponse;
    id: string
}