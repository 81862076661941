<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh($event)"
  (actionListClicked)="topMenuActionsClicked($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
<!-- <section>
  <mat-table
    #sort="matSort"
    matSort
    [dataSource]="dataSource"
    class="main"
  >
    <ng-container matColumnDef="assetName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Asset Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let scoutLogs"
        matTooltip="Latitude: {{ scoutLogs.scoutLatitude }} Longitude: {{
          scoutLogs.scoutLongitude
        }} Altitude: {{ scoutLogs.scoutAltitude }}"
        matTooltipPosition="right"
        (click)="openMap(scoutLogs.scoutLatitude, scoutLogs.scoutLongitude)"
      >
        {{ scoutLogs.assetName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="authUsername">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Username
      </mat-header-cell>
      <mat-cell
        *matCellDef="let scoutLogs"
        matTooltip="Latitude: {{ scoutLogs.scoutLatitude }} Longitude: {{
          scoutLogs.scoutLongitude
        }} Altitude: {{ scoutLogs.scoutAltitude }}"
        matTooltipPosition="right"
        (click)="navigateToCustomer(scoutLogs.uuid)"
      >
        {{ scoutLogs.authUsername }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastPayLoadDate">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Last Payload Date
      </mat-header-cell>
      <mat-cell
        class="center"
        *matCellDef="let scoutLogs"
        matTooltip="Latitude: {{ scoutLogs.scoutLatitude }} Longitude: {{
          scoutLogs.scoutLongitude
        }} Altitude: {{ scoutLogs.scoutAltitude }}"
        matTooltipPosition="right"
      >
        {{ scoutLogs.lastPayLoadDate | date: "short" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let scoutLogs"
        matTooltip="Latitude: {{ scoutLogs.scoutLatitude }} Longitude: {{
          scoutLogs.scoutLongitude
        }} Altitude: {{ scoutLogs.scoutAltitude }}"
        matTooltipPosition="right"
      >
        {{ scoutLogs.customerName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastBatteryVoltage">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Last Voltage
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let scoutLogs">
        {{ scoutLogs.lastBatteryVoltage }} V
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scoutSerial">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Scout Serial
      </mat-header-cell>
      <mat-cell *matCellDef="let scoutLogs">
        {{ scoutLogs.scoutSerial }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="20" [pageSizeOptions]="[2, 4, 6, 10, 20]">
  </mat-paginator>
</section> -->