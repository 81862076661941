import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../shared/services/admin.service';
import { read } from 'fs';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  scout: any;
  airTemp: any;
  barometricPressure: any;
  precipitation: any;
  shortwaveRadiation: any;
  windDirection: any;
  windSpeedCombinedNE: any;
  

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.getScout("EARTHS-000295");
  }

  assignValues() {
    let values = this.scout.sensors.find(sensor => (sensor.code as string).includes("METERATM")).readingTypes;
    this.airTemp = values[1].statistics.value + " °" + values[1].unit;
    this.barometricPressure = values[2].statistics.value + " " + values[2].unit;
    this.precipitation = values[6].statistics.value + " " + values[6].unit;
    this.shortwaveRadiation = values[8].statistics.value + " " + values[8].unit;
    this.windDirection = values[10].statistics.value + "°";
    this.windSpeedCombinedNE = values[12].statistics.value + " " + values[12].unit;
  }

  getScout(scoutName: string) {
    this.adminService.getAppScoutTreeFwmByName(scoutName).subscribe(scouts => {
      if (scouts && scouts.length == 1) {
        this.scout = scouts[0];
        console.log(this.scout);
        this.assignValues();
      }
    });
  }

}
