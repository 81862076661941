import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { LocalStorageService } from "../../../core";
import { SearchLogs } from "../../interfaces/search-log";
import { AssetsService } from "../../services/assets.service";
import { ConstantService } from "../../services/constant.service";
import { CustomerService } from "../../services/customer.service";
import { SnackService } from "../../services/extra/snack.service";
import { ScoutLogService } from "../../services/scout-log.service";
import { SearchBarService } from "../../services/searchbar.service";
import { AssetLookupListDialogComponent } from "../dialogs/asset-lookup-dialog/user-lookup-list-dialog/asset-lookup-list-dialog.component";
import { ConfirmationDialogComponent } from "../dialogs/confirmation-dialog/confirmation-dialog.component";
import { SearchbarDialogComponent } from "../dialogs/searchbar-dialog/searchbar-dialog.component";
import { NoUserDialogComponent } from "../dialogs/user-lookup-dialog/no-user-dialog/no-user-dialog.component";
import { UserLookupDialogComponent } from "../dialogs/user-lookup-dialog/user-lookup-dialog.component";
import { UserLookupListDialogComponent } from "../dialogs/user-lookup-dialog/user-lookup-list-dialog/user-lookup-list-dialog.component";

@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SearchbarComponent implements OnInit, OnDestroy {
  @Output() loadEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('tabGroup') tabGroup;
  @Input() currentData: any[];
  sortCurrentData = false;
  request: Request;
  @Output() pageLink: string;
  filter: string;
  serverSearchText: string;
  assetSearchText: string;
  localSearchText: string;
  message: string;
  searchLogText: string;
  searchUsersText: string;
  saveFilter: boolean = false;
  logs: any[];
  private searchURL = environment.scoutLogApiUrl + "SearchLog";

  get search(): string {
    return this.searchBarService.searchTerm;
  }
  set search(value: string) {
    this.searchBarService.searchTerm = value;
  }
  componentSubscription: Subscription;
  searchSubscription: Subscription;

  constructor(
    public searchBarService: SearchBarService,
    public _dialog: MatDialog,
    public _scoutLogService: ScoutLogService,
    public _constService: ConstantService,
    public _snack: SnackService,
    private _router: Router,
    private _localStorage: LocalStorageService,
    private _customerService: CustomerService,
    private _assetService: AssetsService
  ) {
    this.componentSubscription = _router.events.subscribe((val) => {
      const clear = "";
      if (val instanceof NavigationEnd) {
        if (!this.saveFilter) {
          this.searchBarService.searchTerm = clear;
          this.message = clear;
          this.searchBarService.changeMessage(clear);
        }
        if(this.message.length < 1) {
          this.saveFilter = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this.message = message;
    });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  public checkChanged = (event) => {
    this.saveFilter = event.checked;
    this._localStorage.setItem('saveFilter', this.saveFilter);
  }

  // All CRUD functions
  searchServer = (value: string) => {
    const queryLoc = "";
    const dataHolder: any[] = [];
    this.serverSearchText = value;
    this.filter = value;
  };

  searchLogs = (value: string) => {
    this.loadEmitter.emit({loading: true});
    if (value.length > 0) {
      let results: SearchLogs[] = [];
      this.request = new Request(this.searchURL + "/" + value, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": this._constService.CONST.pvtKey,
        }),
      });
      fetch(this.request)
        .then((response) => {
          response.json().then((res) => {
            results = <SearchLogs[]>res;
            //const logs = this._scoutLogService.searchLogs(value);
            if (results.length > 0) {
              const dialogRef = this._dialog.open(SearchbarDialogComponent, {
                width: "70vw",
                height: "85vh",
                data: results,
              });
              dialogRef.componentInstance.event.subscribe((result) => {
                if (result.data) {
                  console.log(result);
                }
              });
            }
          });
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }
    else{
      this._snack.displaySearchError('Please provide search value')
    }
  };


  searchAdminAsset(assetName: string) {
    this.componentSubscription = this._assetService.getAdminAssetByName(assetName).subscribe(results => {
        if (results.length > 0) {
          const dialogRef = this._dialog.open(AssetLookupListDialogComponent, {
            width: "100%",
            height: "100%",
            data: results,
          });
          dialogRef.componentInstance.event.subscribe((result) => {
            if (result.data) {
              console.log(result);
            }
          });
        } else {
          this._snack.displayError('No assets found containing: ' + assetName);
          const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
            width: "60vw",
            data: `No asset found with name of ${assetName}. Would you like to create a new asset?`,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this._snack.displayError('Generate dialog for new asset: ' + assetName);
              // const dialogRef = this._dialog.open(NoUserDialogComponent, {
              //   width: "100%",
              //   height: "100%",
              //   data: assetName,
              // });
              // dialogRef.componentInstance.event.subscribe((result) => {
              //   if (result.data) {
              //     console.log(result);
              //   }
              // });
            }
          });
        }
      })
  }
  
  searchAdminUser(username: string, customerId?: string) {
    //this.componentSubscription = this._customerService.getAdminUserDetailsByName(username).subscribe(results => {  
    this.componentSubscription = this._customerService.getAdminUserDetails(username, customerId).subscribe(results => {
      if (results.length > 0) {
          const dialogRef = this._dialog.open(UserLookupListDialogComponent, {
            width: "100%",
            height: "100%",
            data: results,
          });
          dialogRef.componentInstance.event.subscribe((result) => {
            if (result.data) {
              console.log(result);
            }
          });
        } else {
        this._snack.displayError('No user email found for ' + username);
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
          width: "60vw",
          data: `No user found for the login email of ${username}. Would you like to create a new user?`,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const dialogRef = this._dialog.open(NoUserDialogComponent, {
              width: "100%",
              height: "100%",
              data: username,
            });
            dialogRef.componentInstance.event.subscribe((result) => {
              if (result.data) {
                console.log(result);
              }
            });
          }
        });
      }
    })
  }

  searchLocal = (value: string) => {
    this.loadEmitter.emit({loading: true});
    const dataHolder: any[] = [];
    this.localSearchText = value;
    this.search = value;
    this.searchBarService.searchTerm = value;
    this.searchBarService.changeMessage(value);
    if (value.length < 1) {
      const event = {checked: false}
      this.checkChanged(event);
    }
  };

  changeSettings() {
    console.log("settings changed");
  }
}
