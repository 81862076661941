<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh()"
  (actionListClicked)="topActionsClicked($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
<!-- <roots-actionable-table
  [actionColumns]="actionColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  (actionsClicked)="actionsClicked($event)">
</roots-actionable-table> -->
<!-- <section>
  <mat-table
    #sort="matSort"
    matSort
    [dataSource]="dataSource"
    class="main example-container"
  >
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Code
      </mat-header-cell>
      <mat-cell *matCellDef="let reference">
        {{ reference.code }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let reference">
        {{ reference.basicName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scope">
      <mat-header-cell class="scope" *matHeaderCellDef mat-sort-header>
        Scope
      </mat-header-cell>
      <mat-cell class="scope" *matCellDef="let reference">
        {{ reference.scope }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="content">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Link
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let reference">
        <a href="{{ reference.content }}">{{ reference.content }}</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="edit">
      <mat-header-cell class="align-mid" *matHeaderCellDef>
        Details
      </mat-header-cell>
      <mat-cell class="align-mid" *matCellDef="let reference">
        <button
          mat-icon-button
          color="primary"
          (click)="navigateToReference(reference)"
        >
          <mat-icon class="mat-18">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell class="align-mid" *matHeaderCellDef>
        Delete
      </mat-header-cell>
      <mat-cell class="align-mid" *matCellDef="let reference">
        <button mat-icon-button color="warn" (click)="deleteDialog(reference)">
          <mat-icon class="mat-18">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator [pageSize]="20" [pageSizeOptions]="[2, 4, 6, 10, 20]">
  </mat-paginator>
</section> -->
