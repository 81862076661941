import * as moment from 'moment';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Scout} from '../../interfaces/scout';
import {Observable, Subscription} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ReadingData} from '../../interfaces/reading-data';
import {Sensor} from '../../interfaces/sensor';
import {SensorData} from '../../interfaces/sensor-data';
import {ScoutSensor} from '../../interfaces/scout-sensor';
import {Health} from '../../interfaces/health';
import {GmtOffset} from '../../classes/gmt-offset';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {AppSensor} from '../../interfaces/app';
import { ConstantService } from '../constant.service';
import { AppService } from './app.service';

@Injectable()
export class ScoutService implements OnDestroy {
  public options: any;
  params: any = {
    'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
    'userId': this._constService.CONST.userId
  };
  componentSubscription: Subscription;
  url = 'https://escoutd.azurewebsites.net/';
  public customDateRange: any;

  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _authService: AuthenticationService,
    private _appService: AppService
  ) {
    // this.deviceUrl = 'api/devices';
    this.options = {
      range: {
        defaultValue: 'week',
        options: [
          {
            label: 'Week',
            value: 'week'
          },
          {
            label: '30 Days',
            value: '30days'
          },
          {
            label: 'Custom date range',
            value: 'CDR'
          }
        ]
      },
      display: {
        defaultValue: 'graph',
        options: [
          {
            label: 'Graph',
            value: 'graph'
          },
          {
            label: 'Table',
            value: 'table'
          }
        ]
      }
    };
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  /** GET devices from the server */
  public getScouts(): Observable<Scout[]> {
    const params: any = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      'userId': this._constService.CONST.userId
    };
    const URL = `${this._constService.CONST.URL}api/ReadingScoutSensor`;
    return this._http
      .get<Scout[]>(URL, {params: params}).pipe(
      map((resp: any) => {
        console.log('getScouts:', resp);
        return this.parseScoutAndSensor(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched Scouts`)),
        catchError(this._constService.handleError<Scout[]>(`getScouts`))
      );
  }

  /** GET device by id. Will 404 if id not found */
  public getScout(scoutId: string): Observable<Scout> {
    // const url = `${this.deviceUrl}/${id}`;
    // const url = `assets/data/api/device/${scoutId}.json`;
    const URL = `${this._constService.CONST.URL}tables/Scout/${scoutId}`;

    return this._http
      .get<Scout>(URL).pipe(
      map((resp: Scout) => {
        const sensorDatas: any = {};
        resp.sensors.forEach((sensor: Sensor) => {
          sensor.sensorData.forEach((sensorData: SensorData) => {
            if (!sensorDatas[sensorData.readingTypeName]) {
              sensorDatas[sensorData.readingTypeName] = JSON.parse(
                JSON.stringify(sensorData)
              );
              delete sensorDatas[sensorData.readingTypeName].ReadingData;
            }
          });
        });
        resp.sensorDatas = <SensorData[]>Object.values(sensorDatas).sort(
          function (a: SensorData, b: SensorData) {
            const nameA = a.readingTypeBasicName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.readingTypeBasicName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            // names must be equal
            return 0;
          }
        );
        return <Scout>resp;
      }))
      .pipe(
        tap(_ => console.log(`fetched Scout scoutId=${scoutId}`)),
        catchError(
          this._constService.handleError<Scout>(`getScout scoutId=${scoutId}`)
        )
      );
  }

  /** GET device by id. Will 404 if id not found */
  getReadingData(
    sensorId: string,
    readingType: string,
    range: string
  ): Observable<ReadingData[]> {
    const readingRange: any = {};
    const params: any = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion,
      sensorId: sensorId,
      readingTypeName: readingType,
      whenTo: moment()
        .add(1, 'day')
        .startOf('day')
        .add(-(moment().utcOffset()), 'm')
        .format('YYYY-MM-DDTHH:mm:ss.00')
    };

    if (range === 'week') {
      params.whenFrom = moment()
        .subtract(7, 'days')
        .add(-(moment().utcOffset()), 'm')
        .format('YYYY-MM-DDTHH:mm:ss.00');
    } else if (range === '30days') {
      params.whenFrom = moment()
        .subtract(30, 'days')
        .add(-(moment().utcOffset()), 'm')
        .format('YYYY-MM-DDTHH:mm:ss.00');
    } else {
      params.whenFrom = this.customDateRange.startDate;
      params.whenTo = this.customDateRange.endDate;
    }
    const URL = `${this._constService.CONST.URL}api/ReadingData`;

    // const URL = `/api/ReadingData/`;
    // const url = `assets/data/api/device/${scoutId}/sensor/${sensorId}/readingType/${readingType}/range/${range}.json`;
    return this._http.get<ReadingData[]>(URL, {params: params}).pipe(
      tap(_ =>
        console.log(
          `fetched Reading Data sensorId=${sensorId} readingType=${readingType} range=${range}`
        )
      ),
      // tslint:disable-next-line:max-line-length
      catchError(
        this._constService.handleError<ReadingData[]>(
          `getReadingData sensorId=${sensorId} readingType=${readingType} range=${range}`
        )
      )
    );
  }

  /**
   * Parse Scout and Sensor
   */
  private parseScoutAndSensor(data): Scout[] {
    const scouts: Scout[] = [];

    data.forEach((scoutSensor: ScoutSensor) => {
      switch (scoutSensor.entityTypeName.toLowerCase()) {
        case 'scout':
          const scout: Scout = <Scout>{
            id: scoutSensor.id,
            basicName: scoutSensor.basicName,
            sensors: [],
            sensorDatas: {},
            scoutSensor: scoutSensor
          };
          scouts.push(this.parseBasicDescription(scout, 'scout'));
          break;
      }
    });

    data.forEach((scoutSensor: ScoutSensor) => {
      switch (scoutSensor.entityTypeName.toLowerCase()) {
        case 'sensor':
            case 'season':
          const sensor: Sensor = <Sensor>{
            id: scoutSensor.id,
            assetType: scoutSensor.assetTypeName,
            assetTypeName: scoutSensor.assetTypeName,
            basicName: scoutSensor.basicName,
            sensorData: [],
            scoutSensor: scoutSensor
          };
          scouts.forEach(scout => {
            if (scout.id === scoutSensor.scoutId) {
              const parsedSensor: Sensor = this.parseBasicDescription(
                sensor,
                'sensor'
              );
              scout.sensors.push(parsedSensor);
              if (!scout.lastScoutDateTime) {
                scout.lastScoutDateTime = parsedSensor.lastDateTime;
              } else {
                if (
                  moment(scout.lastScoutDateTime).isBefore(
                    parsedSensor.lastDateTime
                  )
                ) {
                  scout.lastScoutDateTime = parsedSensor.lastDateTime;
                }
              }
              parsedSensor.sensorData.forEach((sensorData: SensorData) => {
                scout.sensorDatas[sensorData.readingTypeName] = sensorData;
              });
            }
          });
          break;
      }
    });

    scouts.forEach(scout => {
      scout.sensors.sort(function (a: Sensor, b: Sensor) {
        const nameA = a.basicName ? a.basicName.toUpperCase() : null; // ignore upper and lowercase
        const nameB = b.basicName ? b.basicName.toUpperCase() : null; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      scout.sensorDatas = (<any>Object).values(scout.sensorDatas);
      scout.sensorDatas.sort(function (a: SensorData, b: SensorData) {
        const nameA = a.readingTypeBasicName
          ? a.readingTypeBasicName.toUpperCase()
          : null; // ignore upper and lowercase
        const nameB = b.readingTypeBasicName
          ? b.readingTypeBasicName.toUpperCase()
          : null; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    });

    scouts.sort(function (a: Scout, b: Scout) {
      const nameA = a.basicName ? a.basicName.toUpperCase() : null; // ignore upper and lowercase
      const nameB = b.basicName ? b.basicName.toUpperCase() : null; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // names must be equal
      return 0;
    });

    this.convertGmtTime(scouts);
    return scouts;
  }

  private parseBasicDescription(entity: any, type: string) {
    let entityToParse: any;
    if (type === 'scout') {
      entityToParse = <Scout>entity;
    } else {
      entityToParse = <Sensor>entity;
    }

    let basicDescriptionArr: string[] = [];
    if (entityToParse.scoutSensor.basicDescription) {
      basicDescriptionArr = entityToParse.scoutSensor.basicDescription.split(',');
    }

    if (basicDescriptionArr.length > 0) {
      entity.lastDateTime = basicDescriptionArr.shift();
      if (basicDescriptionArr.length > 0) {
        const basicDescriptionArrGroup: any = [[]];
        switch (type) {
          case 'scout':
            basicDescriptionArr.forEach((value: string, index: number) => {
              const objIndex = Math.trunc(index / 2);
              if (basicDescriptionArrGroup.length > objIndex) {
                basicDescriptionArrGroup[objIndex].push(value);
              } else {
                basicDescriptionArrGroup.push([value]);
              }
            });
            entityToParse.health = [];
            basicDescriptionArrGroup.forEach(group => {
              entityToParse.health.push(<Health>{
                readingType: group[0],
                valueValue: group[1]
              });
            });
            break;

          case 'sensor':
            basicDescriptionArr.forEach((value: string, index: number) => {
              const objIndex = Math.trunc(index / 5);
              if (basicDescriptionArrGroup.length > objIndex) {
                basicDescriptionArrGroup[objIndex].push(value);
              } else {
                basicDescriptionArrGroup.push([value]);
              }
            });
            entityToParse.sensorData = [];
            basicDescriptionArrGroup.forEach(group => {
              entityToParse.sensorData.push(<SensorData>{
                readingTypeName: group[0],
                readingTypeBasicName: group[1],
                trend: group[2],
                valueValue: group[3],
                unit: group[4]
              });
            });
            break;
        }
      }
    }
    // console.log(entityToParse);
    return entityToParse;
  }

  public updateScout(scoutId: string, scout: any): Observable<Scout> {
    // const body = JSON.stringify(scout);
    const URL = `${this._constService.CONST.URL}tables/Scout/${scoutId}`;
    return this._http
      .patch(URL, scout, {params: this._constService.defaultParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable),);
  }

  public updateSensor(sensorId: string, sensor: any): Observable<Sensor> {
    // const body = JSON.stringify(scout);
    const URL = `${this._constService.CONST.URL}tables/Sensor/${sensorId}`;
    return this._http
      .patch(URL, sensor, {params: this._constService.defaultParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable),);
  }

  private convertGmtTime(scouts: Scout[]) {
    scouts.forEach(scout => {
      // console.log('GMT: ', scout.lastDateTime, ' ', scout.lastScoutDateTime, ' ',
      // scout.scoutSensor.updatedAt, ' ', scout.scoutSensor.createdAt);
      // log before
      scout.lastDateTime = GmtOffset(scout.lastDateTime);
      scout.lastScoutDateTime = GmtOffset(scout.lastScoutDateTime);
      scout.scoutSensor.updatedAt = GmtOffset(scout.scoutSensor.updatedAt);
      scout.scoutSensor.createdAt = GmtOffset(scout.scoutSensor.createdAt);
      // console.log('Calculated: ', scout.lastDateTime, ', ', scout.lastScoutDateTime, ', ',
      // scout.scoutSensor.updatedAt, ', ', scout.scoutSensor.createdAt);
      // log before
    });
  }

  public getAppReadingType(params) {

    return this._appService.getAppReadingType(params);
  }

  public getAppSensor() {
    const appSensor: AppSensor[] = [];
    this.componentSubscription = this._appService.getAppSensor().subscribe((res) => {
      res.forEach(sensor => {
        appSensor.push(sensor);
      });
    });
    return appSensor;
  }


  public getSensorDataById(id: String) {
    const endpoint = `${this.url}tables/SensorData/${id}`;
    return this._http.get(endpoint, {params: this.params}).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }


  public setCustomDateRange(startDate, endDate) {
    this.customDateRange = {startDate: startDate, endDate: endDate};
  }
}

export class ReadingTypes {
  basicDescription: string;
  basicName: string;
  categoryId: string;
  code: string;
  id: string;
  unitId: string;
}
