<div class = "rotated-centered" *ngIf="loading">	
	<div class = "sensor-1-base"></div>
	<div class = "sensor-2"></div>
	<div class = "sensor-3"></div>
	<div class = "sensor-4"></div>
</div>
<div class = "centered" *ngIf="loading">
<div class="flower">
	<div class="stem"></div>
	<div class="leaf"></div>
	<div class="petal petal-1"></div>
	<div class="petal petal-2"></div>
	<div class="petal petal-3"></div>
	<div class="petal petal-4"></div>
	<div class="petal petal-5"></div>
	<div class="petal petal-6"></div>
	<div class="dot"></div>
</div>
</div>