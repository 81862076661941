import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
@Component({
  templateUrl: "./confirm-asset-model-dialog.component.html"
})
export class ConfirmAssetModelDialogComponent {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  loading = false;
  _confirmation_1 = false;
  _confirmation_2 = false;
  asset;
  get confirmation_1() : boolean {
    return this._confirmation_1;
  }
  set confirmation_1(confirmation_1 : boolean) {
    this._confirmation_1 = confirmation_1;
  }
  get confirmation_2() : boolean {
    return this._confirmation_2;
  }
  set confirmation_2(confirmation_2 : boolean) {
    this._confirmation_2 = confirmation_2;
  }
  constructor(
    public dialogRef: MatDialogRef<ConfirmAssetModelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.asset = data;
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }

  confirmation1() {
    // var result = !this.confirmation_1;
    // this.confirmation_1 = result;
    return this.confirmation_1;
  }

  confirmation2() {
    // var result = !this.confirmation_2;
    // this.confirmation_2 = result;
    return this.confirmation_2;
  }

  yesClicked() {
    if (this.confirmation_1 && this.confirmation_2) {
      this.event.emit(true);
    }
  }
}
