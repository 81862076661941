import { Component, EventEmitter, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { AdminService } from "../../../services/admin.service";
import { SnackService } from "../../../services/extra/snack.service";
@Component({
  selector: "app-confirm-firmware-delete-dialog",
  templateUrl: "./confirm-firmware-delete-dialog.component.html",
  styleUrls: ["./confirm-firmware-delete-dialog.component.scss"],
})
export class ConfirmaFirmwareDeleteDialogComponent implements OnDestroy {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  removalAvailable = false;
  loading = false;
  componentSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<ConfirmaFirmwareDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    private _snack: SnackService
  ) {
    this.removal();
  }
  
  ngOnDestroy() {
    // if (this.componentSubscription) {
    //   this.componentSubscription.unsubscribe();
    // }
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }

  yesClicked() {
    this.event.emit(true);
  }

  removal() {
    if (this.data.firmwares.length < 1) {
      this.removalAvailable = true;
    }
  }

  removeAssetModelRelationship(asset: any) {
    console.log(asset);
    this.loading = true;
    this.componentSubscription = this._adminService.deleteAssetModelFirmwareModel(asset.assetModelId).subscribe(result => {
      this._snack.displaySuccess('Successfully removed AssetModel Relationship');
      console.log(result);
      const index = this.data.firmwares.indexOf(asset, 0);
      if (index > -1) {
        this.data.firmwares.splice(index, 1);
      }
      this.loading = false;
      this.removal();
    }, err => {
      this._snack.displayError('Error, cannot remove the Asset Model Relationship');
      console.log(err);
      this.loading = false;
    })
  }
}
