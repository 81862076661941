<roots-page-header [toolTip]="_config.toolTip" [title]="_config.title" [loading]="_config.loading"
  [items]="actionButtons" (refresh)="refresh()" (actionListClicked)="actionsClicked($event)"
  (reportBuilder)="reportBuilderEnabled">
</roots-page-header>
<!-- <roots-actionable-table
  [actionColumns]="actionColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  (actionsClicked)="actionsClicked($event)"
  [reportBuilder]="reportBuilderEnabled">
</roots-actionable-table> -->
<div class="table-container">
  <mat-table #sort="matSort" matSort [dataSource]="_config.dataSource" class="main example-container">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        {{ asset.name }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="scoutOrSensorName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Scout/Sensor Name
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        <div *ngIf="asset.currentAssetOwnership &&
           asset.currentAssetOwnership.scoutName != null && 
           asset.currentAssetOwnership.scoutName != asset.name">
          {{ asset.currentAssetOwnership.scoutName }}
        </div>
        <div *ngIf="asset.currentAssetOwnership &&
           asset.currentAssetOwnership.sensorName != null && 
           asset.currentAssetOwnership.sensorName != asset.name">
          {{ asset.currentAssetOwnership.sensorName }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer Name
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        <button mat-button color="primary" (click)="navigateToCustomerAssets(asset)"
          matTooltip="Navigate to find more customer assets">
          {{ asset.customerName }}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Type
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        {{ asset.type }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Status
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let asset">
        <button class="btn btn-secondary" mat-raised-button color="primary" *ngIf="asset.ownership === false"
          (click)="navigateToDialog(asset)"
          matTooltip="Click to initiate the asset assignment dialog to create ownership for a given customer"
          matTooltipPosition="right" style="background-color: #f44336">
          {{ asset['status'] }}
        </button>
        <button class="btn btn-secondary" mat-raised-button color="primary" *ngIf="asset.ownership === true"
          (click)="navigateToCustomerDetails(asset)"
          matTooltip="Navigate to the customer that currently has ownership of this asset" matTooltipPosition="right">
          {{ asset['status'] }}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <mat-header-cell *matHeaderCellDef class="center">
        Details
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let asset">
        <button mat-icon-button color="primary" (click)="navigateToAsset(asset)"
          matTooltip="Navigate to find more asset details" matTooltipPosition="above">
          <mat-icon class="mat-12">edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="assetStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Asset Status
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        {{ asset['assetStatus'] }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Code
      </mat-header-cell>
      <mat-cell *matCellDef="let asset">
        {{ asset.code }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="_config.displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: _config.displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="15" [pageSizeOptions]="[5, 10, 15, 20]">
  </mat-paginator>
</div>