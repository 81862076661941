<h3 mat-dialog-title
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>{{data.title}}</h3>
<div mat-dialog-content>
    
    <form class="user-group-form" #userGroupForm="ngForm" (ngSubmit)="onSubmit()">
        
        <!-- <mat-form-field>
            <input matInput placeholder="Code" type="text" required [(ngModel)]="userGroupPost.code" name="code">
        </mat-form-field> -->
        <mat-form-field>
            <input matInput placeholder="Name" type="text" required [(ngModel)]="userGroupPost.basicName" name="basicName">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Description" type="text" required [(ngModel)]="userGroupPost.basicDescription" name="basicDescription">
        </mat-form-field> 
        <!-- <mat-form-field>
            <input matInput placeholder="Content" type="text" required [(ngModel)]="userGroupPost.content" name="content">
        </mat-form-field> -->
        <!-- <mat-form-field>
            <input matInput placeholder="Id" type="text" required [(ngModel)]="userGroupPost.id" name="id">
        </mat-form-field> -->
        <!-- <mat-form-field>
            <input matInput placeholder="Username" type="text" required [(ngModel)]="userGroupPost.username" name="username">
        </mat-form-field> -->
        <!-- [disabled]="formFilled()" -->
        <button mat-raised-button type="submit" [disabled]="!userGroupForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
    <!-- <button mat-raised-button style="max-width: min-content;" (click)="defaultUserGroup()" color="primary">Defaults</button> -->
</div>