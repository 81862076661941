import { Component, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AdminService } from "../../../shared/services/admin.service";
import { FirmwareInstance } from "../../../shared/interfaces/firmware-instance";
import { ConstantService } from "../../../shared";
import { environment } from "../../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { SnackService } from "../../../shared/services/extra/snack.service";
const { v1: uuidv4 } = require("uuid");

@Component({
  selector: "app-firmware-instance-form",
  templateUrl: "./firmware-instance-form.component.html",
  styleUrls: ["./firmware-instance-form.component.scss"],
})
export class FirmwareInstanceFormComponent {
  d = new Date();
  year = this.d.getFullYear();
  month = this.d.getMonth();
  day = this.d.getDate();
  c = new Date(this.year + 1, this.month, this.day);
  loading = true;
  fileToUpload: File = null;
  constructor(
    public dialogRef: MatDialogRef<FirmwareInstanceFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    private _constService: ConstantService,
    private _http: HttpClient,
    private adalService: MsAdalAngular6Service,
    private _snack: SnackService
  ) {}
  instances: any[];
  currentDate = new Date(Date.now());
  firmwareInstancePost: FirmwareInstance = {
    file: "no file has been set yet ",
    firmwareVersion: "X.X.Xtest",
  };
  public event: EventEmitter<any> = new EventEmitter();
  submitted = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onChange(event: any) {
    console.log(event);
  }

  onChangeFile(file: any) {
    console.log(file);
  }

  onSubmit(): void {
    const formData: FormData = new FormData();
    const firmwareInstance = {
      firmwareVersion: this.firmwareInstancePost.firmwareVersion,
      description: this.firmwareInstancePost.description,
    };
    this.submitted = true;
    // formData.append("firmwareInstance", JSON.stringify(firmwareInstance));
    formData.append("firmwareInstance", JSON.stringify(firmwareInstance));
    if(this._constService.fileToUpload !== null) {
      formData.append(
        "firmwareFile",
        this._constService.fileToUpload,
        this.firmwareInstancePost.firmwareVersion
      );
      this.postFirmwareInstanceAlt(formData);
    }
    else{
      this._snack.displayError("Please attach firmware file");
      // this.postFirmwareInstanceAlt(formData);
    }
    this.dialogRef.close();
  }

  postFirmwareInstance(firmwareInstance: any) {
    const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    const params: any = {
      "Content-Type": "multipart/form-data",
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
    };
    return this._http.post(URL, firmwareInstance, { params: params }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  private postFirmwareInstanceAlt(firmwareInstance: any) {
    const access_token = this.adalService.accessToken;
    const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    const headers = new HttpHeaders();
    // headers.append('Authorization', token_type + ' ' + access_token);
    // headers.append('Content-Type', 'application/json');
    const token_type = 'Bearer'
    const request = new Request(URL, {
      method: "POST",
      headers: new Headers({
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        'Authorization': token_type + ' ' + access_token
      }),
      body: firmwareInstance
    });
    fetch(request)
      .then((response) => {
        response.json().then((res) => {
          console.log(res);
          this.event.emit({ data: res.id });
        });
      })
      .catch((err) => {
        console.log(err);
        // this._snack.displayError(err);
      });
  }
}
