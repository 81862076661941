import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { AssetOwnership } from "../../../shared/interfaces/asset-ownership";
import { AdminService } from "../../../shared/services/admin.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminAssetOwnership } from "../../../shared/interfaces/admin/admin-asset-ownership";
import { Observable, forkJoin, Subscription } from "rxjs";
import {
  ActionType,
  Customer,
  RootsActionMenuItem,
  RootsPageComponentConfig,
  RootsTableInterface,
} from "../../../shared";
import { map, tap, mergeMap } from "rxjs/operators";
import { CustomerService } from "../../../shared/services/customer.service";
import { MatDateRangePicker } from "@angular/material/datepicker";
import { Asset } from "../../../shared/interfaces/asset";
import { MatTableDataSource } from "@angular/material/table";
import { SearchBarService } from "../../../shared/services/searchbar.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SnackService } from "../../../shared/services/extra/snack.service";

@Component({
  selector: "app-customer-ownership",
  templateUrl: "./customer-ownership.component.html",
})
export class CustomerOwnershipComponent
  implements RootsTableInterface, OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() selectEmitt = new EventEmitter();
  @Input() asset: Asset;
  assets?: AssetOwnership[];
  customerId = "";
  customerArray: AdminAssetOwnership[] = [];
  hasAssets = true;
  customer: Customer;
  start: Date;
  end: Date;
  year = new Date().getFullYear();
  beginYear = new Date(this.year, 0, 1).toString();
  endYear = new Date(this.year, 11, 31).toString();
  val = new Date(this.year, 6, 13).toString();
  min = Date.parse(this.beginYear) / 1000;
  max = Date.parse(this.endYear) / 1000;
  data: object[] = [];
  selectedDate: Date;
  @ViewChild("picker") dateRangePicker: MatDateRangePicker<Date>;
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: ["assetName", "userAssetName", "assetCode", "whenBegin", "whenEnd"],
    message: "",
  };
  actionButtons: RootsActionMenuItem[] = [

    {
      name: "Open Calendar View",
      icon: "add",
      toolTip:
        "Open a timeline for all the listed Asset ownership",
      type: ActionType.Dialog,
    },
  ];
  items: RootsActionMenuItem[] = [

    {
      name: "Show in Calendar",
      toolTip:
        "Show this ownership in a calendar view",
      icon: "calendar_today",
      type: 9,
    },
    {
      name: "Status",
      toolTip:
        "Navigate to find the status of this asset",
      icon: "timelapse",
      type: 10,
    },
    {
      name: "Asset Details",
      toolTip:
        'Navigate to this Assets details',
      icon: "edit",
      type: 11,
    },
    {
      name: "Scout Details",
      toolTip:
        "Navigate to this Scout details",
      icon: "edit",
      type: 12,
    },
  ];
  searchSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    private _snack: SnackService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _customerService: CustomerService,
    public searchBarService: SearchBarService
  ) { }

  ngOnInit() {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.customerId = id;
          return id;
        }),
        mergeMap((id) => {
          const getCustomerDetails =
            this._customerService.getCustomerDetails(id);
          const getScoutCustomerOwnershipHistory =
            this._adminService.getScoutCustomerOwnershipHistory(id);
          const getCustomerAssets = this._adminService.getCustomersAssets(id);

          return forkJoin([
            getCustomerDetails,
            getScoutCustomerOwnershipHistory,
            getCustomerAssets,
          ]);
        })
      )
      .subscribe((results) => {
        const details = results[0];
        const asoHistory = results[1];
        const assets = results[2];

        this.customer = details;
        this.timelineOwnership(asoHistory);
        this.customerArray = asoHistory;
        console.log(this.customerArray);
        this._config.toolTip =
          "Find all historical asset ownerships for this customer.";
        this._config.loading = false;
        this.customerArray = this.customerArray.sort(
          (a: AdminAssetOwnership, b: AdminAssetOwnership) => {
            return (
              new Date(a.whenBegin).getUTCDate() -
              new Date(b.whenBegin).getUTCDate()
            );
          }
        );

        this.customerArray.forEach(c => {
          c.whenBegin = (new Date(c.whenBegin)).toLocaleString();
          c.whenEnd = (new Date(c.whenEnd)).toLocaleString();

          if (c['sensorId'] == null) {
            c.userAssetName = c['scoutName'];
          }
          else {
            c.userAssetName = c['sensorName'];
          }
        });

        this._config.dataSource.data = this
          .customerArray as AdminAssetOwnership[];
        if (assets.length < 1) {
          this.hasAssets = false;
        }
        this.hasScoutId();
        //this._config.displayedColumns.push('scout_found');
        this._config.title = `${this.customer.basicName}'s Historical Ownerships (${this._config.dataSource.data.length})`;
      });
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(
      (message) => {
        this._config.message = message;
        if (message.length > 0) {
          this.searchBarService.doFilter(message, this._config.dataSource);
        }
      }
    );
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this._config.loading = true;
        this.navigateToInUse(item);
        break;
      case 11:
        this.navigateToAsset(item.assetId);
        break;
      case 12:
        if (item.scoutId !== null) {
          const url = `/scouts/${item.scoutId}`;
          this._router.navigate([url]);
        } else {
          this._snack.displayError("No Scout Id for this asset")
        }
        break;
      case 9:
        this.openASO(item);
        break;
      default:
        break;
    }
  }


  topActionsClicked(action) {
    switch (action.type) {
      case 2:
        this.openAllInCalendar();
        break;
      default:
        break;
    }
  }

  openAllInCalendar() {
    console.log(this.data)
  }

  refresh($event: any) {
    this._config.loading = true;
    const getCustomerDetails = this._customerService.getCustomerDetails(this.customerId);
    const getScoutCustomerOwnershipHistory =
      this._adminService.getScoutCustomerOwnershipHistory(this.customerId);
    const getCustomerAssets = this._adminService.getCustomersAssets(this.customerId);

    return forkJoin([
      getCustomerDetails,
      getScoutCustomerOwnershipHistory,
      getCustomerAssets,
    ]).subscribe((results) => {
      const details = results[0];
      const asoHistory = results[1];
      const assets = results[2];

      this.customer = details;
      this.timelineOwnership(asoHistory);
      this.customerArray = asoHistory;
      this._config.toolTip =
        "Find all historical asset ownerships for this customer.";
      this._config.loading = false;
      this.customerArray = this.customerArray.sort(
        (a: AdminAssetOwnership, b: AdminAssetOwnership) => {
          return (
            new Date(a.whenBegin).getUTCDate() -
            new Date(b.whenBegin).getUTCDate()
          );
        }
      );
      this._config.dataSource.data = this
        .customerArray as AdminAssetOwnership[];

      if (assets.length < 1) {
        this.hasAssets = false;
      }
      this._config.title = `${this.customer.basicName}'s Historical Ownerships (${this._config.dataSource.data.length})`;
    });
  }

  hasScoutId() {
    for (let i = 0; i < this._config.dataSource.data.length; i++) {
      const data = this._config.dataSource.data[i];
      if (data.scoutId === null) {
        data.scout_found = false;
      } else {
        data.scout_found = true;
      }
    }
  }

  timelineOwnership(ownerships: any[]) {
    let viewsDate: Date = new Date(Date.now());
    let dd = new Date().toJSON();
    for (let i = 0; i < ownerships.length; i++) {
      const o = ownerships[i];
      if (o.whenEnd === null || o.whenEnd === undefined) {
        this.data.push({
          Id: i,
          EventName: o.assetName,
          StartTime: new Date(o.whenBegin),
          EndTime: new Date(Date.now()),
          IsAllDay: false,
        });
      } else {
        this.data.push({
          Id: i,
          EventName: o.assetName,
          StartTime: new Date(o.whenBegin),
          EndTime: new Date(o.whenEnd),
          IsAllDay: false,
        });
      }
    }
    if (ownerships.length === 0) {
      var today = new Date(dd);
      this.data.push({
        Id: 1,
        EventName: "NO OWNERSHIP HISTORY",
        StartTime: today,
        EndTime: today,
        IsAllDay: true,
      });
      viewsDate = today;
    }
    this.selectedDate = viewsDate;
  }

  openASO(asset: any) {
    this.start = new Date(asset.whenBegin);
    this.end = new Date(asset.whenEnd);
    console.log(asset);
    if (asset.whenEnd !== null) {
      this.dateRangePicker.select(this.start);
      this.dateRangePicker.open();
    } else {
      this.end = new Date(Date.now());
      this.dateRangePicker.open();
    }
  }

  navigateToAsset = (id: string) => {
    const url = `/assets/${id}`;
    this._router.navigate([url]);
  };

  navigateToCustomer = (id: string) => {
    const url = `/customers/${id}`;
    this._router.navigate([url]);
  };

  navigateToInUse = (asset: any) => {
    const url = `/customers/${this.customerId}/assets`;
    this._router.navigate([url]);
  }
}
