<h3 mat-dialog-title 
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle>{{data}}</h3>
<div mat-dialog-content>

    <form class="customer-form" #customerForm="ngForm" (ngSubmit)="onSubmit()">
        <!-- <mat-form-field>
            <input matInput placeholder="Code" type="text" required [(ngModel)]="customerPost.code" name="code">
        </mat-form-field> -->
        <mat-form-field>
            <input matInput placeholder="Contact Email (Used for default User)" type="text"
                [(ngModel)]="customerPost.contact_Email" name="contact_Email">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Customer Name" type="text" required [(ngModel)]="customerPost.name"
                name="basicName">
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Customer Description" required [(ngModel)]="customerPost.description"
                name="basicDescription"></textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-select matInput placeholder="Region" required [(ngModel)]="customerPost.regionId" 
            name="region">
                <mat-option *ngFor="let reg of regions | sort:'key'" [value]="reg.value">
                    {{ reg.key }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select matInput placeholder="Language" required [(ngModel)]="customerPost.languageTag"
                name="languageTag">
                <mat-option *ngFor="let lang of customerLanguage | sort:'key'" [value]="lang.value">
                    {{ lang.key }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Contact Name" type="text" required [(ngModel)]="customerPost.contact_Name"
                name="contact_Name">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Contact Address" type="text"
                [(ngModel)]="customerPost.contact_Address" name="contact_Address">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Contact Telephone" type="tel"
                [(ngModel)]="customerPost.contact_Telephone" name="contact_Telephone">
        </mat-form-field>

        <mat-form-field>
            <mat-select matInput placeholder="Customer Type" required [(ngModel)]="customerPost.customerTypeId"
                name="customerType">
                <mat-option *ngFor="let cat of types" [value]="cat.value">
                    {{ cat.key }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- [disabled]="formFilled()" -->
        <button mat-raised-button type="submit" [disabled]="!customerForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
    <button mat-raised-button (click)="defaultCustomer()" color="primary" style="max-width: min-content; float: right">Defaults</button>

</div>