import { Component, Input, OnInit, Output } from "@angular/core";
@Component({
  selector: "app-health-percentage-bar",
  templateUrl: "./health.component.html",
  styleUrls: ["./health.component.scss"],
})
export class HealthComponent implements OnInit {
   private _percent:number;
   private _total:number;
   private _successful:number;
   private _failed:number;
   private _steps:any;
   initTotal: number;
    minmax:number;
    mx:number;
    my:number;
    health:1000;
    public set steps(steps : any) {
      this._steps = steps;
   }
   public get steps() : any {
      return this._steps; 
   }

    public set percent(percent : number) {
      this._percent = percent;
   }
   public get percent() : number {
      return this._percent; 
   }

    @Input('successful')
    public set successful(successful : number) {
      this._successful = successful;
   }
   public get successful() : number {
      return this._successful; 
   }

    @Input('failed')
    public set failed(failed : number) {
      this._failed = failed;
   }
   public get failed() : number {
      return this._failed; 
   }

    @Input('total') 
    public set total(total : number) {
       this._total = total;
       if(total !== this.initTotal) {
          this.setNewHealthBar();
          this.initTotal = total;
       }
    }
    public get total() : number {
       return this._total; 
    }
    
  constructor(
  ) {
    this.minmax = 20;
  }

  ngOnInit(): void {
    this.update();
  }

  setNewHealthBar() {
   var $hs = $('.health .stat');// Calculate damage

   this.percent = ((this.successful / this.total) * 100);
   $hs.find('.left').text(this.successful); //aka current
   $hs.find('.right').text(this.total); // aka health
  }

  update() {
      this.steps = this.get_steps('rgb(255, 0, 0)', 'rgb(0, 128, 0)' , 100);
      var $hs = $('.health .stat');// Calculate damage
      
      // Update Progress Bar
      var p = this.ubar($('.health .bar'), this.successful, this.total);

      $('.health').find('.bar').css({
         background : Object.keys(this.steps)[Math.floor(p)]
      });
      this.percent = ((this.successful / this.total) * 100);
      $hs.find('.left').text(this.successful); //aka current
      $hs.find('.right').text(this.total); // aka health
  }
  
ubar($e, val1, val2){
    var p = (val1 / val2) * 100;
    $e.css({ width: p + '%' });
    // if (p == 0) {
    //     p = val2;
    // }
    return p;
 }
 
 convert_color(c){
    var color;
    if(c.indexOf('rgb') == -1){
       color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c);
       color = color ? [
         parseInt(color[1], 16),
         parseInt(color[2], 16),
         parseInt(color[3], 16)
       ] : [255,255,255];
    } else {
       color = c.split('(')[1].split(')')[0].split(',');
       for(var i = 0; i < color.length; i++){
          color[i] = parseInt(color[i]);
       }
    }
    return color;
 };
 
 get_steps(c1, c2, st){
    c1 = this.convert_color(c1);
    c2 = this.convert_color(c2);
    var s_r = Math.floor((c1[0] - c2[0]) / st);
    var s_g = Math.floor((c1[1] - c2[1]) / st);
    var s_b = Math.floor((c1[2] - c2[2]) / st);
    var steps = {};
    var cth = function(c) {
       var h = c.toString(16);
       return h.length == 1 ? "0" + h : h;
    };
    var toHEX = function(v){
       return "#" + cth(v[0]) + cth(v[1]) + cth(v[2]);
    };
    var toRGB = function(v){
       return 'rgb(' + v.join(',') +  ')';
    };
    steps[toRGB(c1)] = {
       hex : toHEX(c1).toUpperCase(),
       rgb : toRGB(c1)
    };
    for(var i = 0; i < st; i++){
       if((c1[0] - s_r) > 0) c1[0] -= s_r;
       if((c1[1] - s_g) > 0) c1[1] -= s_g;
       if((c1[2] - s_b) > 0) c1[2] -= s_b;
       c1[0] = (c1[0] > 255) ? 255 : c1[0];
       c1[1] = (c1[1] > 255) ? 255 : c1[1];
       c1[2] = (c1[2] > 255) ? 255 : c1[2];
       if(!steps[toRGB(c1)]) steps[toRGB(c1)] = {
          hex : toHEX(c1).toUpperCase(),
          rgb : toRGB(c1)
       };
    }
    return steps;
 }; 
}
