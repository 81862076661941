import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatModule } from "../../core";
import { D3CreateDialogComponent } from "../process/d3-generate/d3-create-dialog/d3-create-dialog.component";
import { D3InputDialogComponent } from "../process/d3-generate/d3-input-dialog/d3-input-dialog.component";
import { HealthComponent, ScatterComponent } from "./";

@NgModule({
    imports: [
      CommonModule,
      MatModule
    ],
    declarations: [
      ScatterComponent,
      HealthComponent,
      D3InputDialogComponent,
      D3CreateDialogComponent
    ],
    exports: [
      ScatterComponent,
      HealthComponent,
      D3InputDialogComponent,
      D3CreateDialogComponent
    ],
    entryComponents: [
      ScatterComponent,
      D3InputDialogComponent,
      D3CreateDialogComponent
    ],
  })
  export class ChartsModule {}