import {FormGroup} from '@angular/forms';

export class NicoUtils {

  public static isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * Replace a string in the given subject
   * @param subject The subject
   * @param replace The string searching for
   * @param replaceWith The string to replaceWith
   */
  static replaceString(subject: string, replace?: string, replaceWith?: string): string {
    if (!replace) {
      replace = '';
    }
    if (!replaceWith) {
      replaceWith = '';
    }
    const regex = new RegExp('\\b' + replace + '\\b');
    subject = subject.replace(regex, replaceWith);
    return subject;
  }

  /**
   * Append text to a string, if the string doesn't exists yet
   */
  static appendTextIfDoesntExist(text: string, str: string): string {
    text = NicoUtils.replaceString(text, str);
    text = text.trim();
    text += ' ' + str;
    return text;
  }

  static getRandomString(chars?: number): string{
    if (isNaN(chars) || !chars) {
      chars = 10;
    }
    const charSets = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let ret = '';
    for (let i = 0; i < chars; i++) {
      ret += charSets.charAt((Math.random() * 100 ) % charSets.length);
    }

    return ret;

  }

  static urlIsFriendly(urlToTest: string, urlCollection: string[]): boolean {
    let isFriendly = false;
    // remove the http(s):// from the url
    urlToTest = urlToTest.replace(/http(s)?:\/\//, '');

    urlToTest = (urlToTest.split( '/'))[0];
    urlCollection.forEach(url => {
      if ( typeof(url) === typeof(' ') && url.indexOf(urlToTest) >= 0) {
        isFriendly = true;
      }
    });

    return isFriendly;
  }

  /**
   * errorsObj: {
   *             formField:
   *              {
   *                errorName: error message
   *              }
   *          }
   * formGroup: formGroup
   *
   * returns an error object to use with ValidationMessenger
   *
   */
  static getErrorsObject(errosObj, formGroup: FormGroup): any {
    // deep clone the formErrors object so deleting properties won't affect the original object
    const validationErrors: any = JSON.parse(JSON.stringify(errosObj));
    for (const field in errosObj) {
      if (errosObj.hasOwnProperty(field)) {
        const errors = formGroup.controls[field].errors;
        validationErrors[field] = [];

        if (errors) {
          for (const errorName in  errosObj[field]) {
            if (errosObj[field].hasOwnProperty(errorName)) {
              if (errors[errorName]) {
                validationErrors[field].push(errosObj[field][errorName]);
              }
            }
          }
        } else {
          delete  validationErrors[field];
        }

      }

    }

    return validationErrors;

  }

  static arrayHasItem(heap: Array<any>, itm: any): boolean {

    if (typeof itm === 'function') {
      for (let i = 0; i < heap.length; i++) {
        if (itm(heap[i])) {
          return true;
        }
      }

    }
    return heap.indexOf(itm) > -1;
  }


}
