<roots-page-header
[toolTip]="toolTip"
[title]="title"
(refresh)="navigateBack()"
>
</roots-page-header>
<section>
  <mat-card class="col-12">
    <mat-card-header>
      <mat-card-title>
        <h3>404</h3>
      </mat-card-title>
      <mat-card-subtitle>We are searching for your page...</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxFlex>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <p>... But we can not find it.</p>
      <hr>
      <h4>Looking for a page in the list below?</h4>
      <br>
      <hr>
      <mat-list role="list" style="max-height: 60vh; overflow: auto">
        <mat-list-item *ngFor="let data of pageData"
        (click)="navigateToPage(data)">
        <p style="color: green; width: 30%;">
            {{data.section}}
        </p>
        <br>
        <p style=" width: 30%;" class="text label-text">{{data.name}}</p>
        <p style=" width: 30%;" class="text small-text">{{data.link}}</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</section>
