import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { RootsActionMenuItem } from "../..";
import { ActionType } from "../../interfaces/roots-action-menu-item";
import { menuAnimation } from "./roots-action-menu.animations";

@Component({
  selector: "roots-action-menu",
  templateUrl: "./roots-action-menu.component.html",
  styleUrls: ["./roots-action-menu.component.scss"],
  animations: [menuAnimation()],
  encapsulation: ViewEncapsulation.None
})
export class RootsActionMenuComponent {
  private _items: RootsActionMenuItem[] = [];
  @Input("items") set items(items: RootsActionMenuItem[]) {
    if (items === undefined) {
      items = [];
      items.push({
        name: "Info",
        icon: "info",
        type: ActionType.Info,
      });
    } else {
      items.push({
        name: "Info",
        icon: "info",
        type: ActionType.Info,
      });
    }
    this._items = items;
  }
  get items() {
    return this._items;
  }

  @Output("onItemClick") onItemClick = new EventEmitter<void>();
  @Output("formClicked") formEmit = new EventEmitter();

  emitAction($event) {
    this.onItemClick.emit($event);
  }
}
