import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FwmEditComponent } from './edit/fwm-edit.component';
import { FwiEditComponent } from './edit/fwi-edit.component';
import { FirmwareInstancesComponent } from './firmware-instances/firmware-instances.component';
import { FirmwareModelsComponent } from './firmware-models/firmware-models.component';
import { FirmwareComponent } from './firmware.component';
import { FirmwareAssetRelationshipComponent } from './firmware-asset-relationship/firmware-asset-relationship.component';

const routes: Routes = [
  { path: '',  component: FirmwareComponent },
  { path: 'relationships',  component: FirmwareAssetRelationshipComponent },
  { path: 'models',  component: FirmwareModelsComponent },
  { path: "models/:id", component: FwmEditComponent },
  { path: 'instances',  component: FirmwareInstancesComponent },
  { path: "instances/:id", component: FwiEditComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FirmwareRoutingModule { }
