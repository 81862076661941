
import {tap, map} from 'rxjs/operators';
import {Component, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ConstantService, KnowledgeReference } from '../../../shared';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// const uuidv1 = require('uuid/v1');
// const uuidv4 = require('uuid/v4');
const { v1: uuidv1 } = require('uuid');
const { v1: uuidv4 } = require('uuid');

@Component({
    selector: 'app-reference-form',
    templateUrl: './reference-form.component.html',
    styleUrls: ['./reference-form.component.scss']
  })
  export class ReferenceFormComponent implements OnDestroy {
    constructor(
      public dialogRef: MatDialogRef<ReferenceFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _http: HttpClient,
      private _router: Router,
      private _constService: ConstantService
      ) {
    }
    componentSubscription: Subscription;
    currentDate = new Date().getTime();
    referencePost: KnowledgeReference = {
      content: '',
      scope: '',
      username: '',
      code: 'KR' + this.removeHyphens(uuidv1()).substring(0, 4),
      basicName: '',
      basicDescription: '',
      isAvailable: 0,
      createdAt: null,
      updatedAt: '',
      version: '',
      id: uuidv4(),
      deleted: false
    };
    public event: EventEmitter<any> = new EventEmitter();
    submitted = false;
    scope = [
      'All',
      'Corn',
      'Hop',
      'Apple',
      'Turf',
      'Soybean'
    ];

    onNoClick(): void {
      this.dialogRef.close();
    }

    ngOnDestroy() {
      // if (this.componentSubscription) {
      //   this.componentSubscription.unsubscribe();
      // }
    }
  

    defaultReference(): KnowledgeReference {
      this.referencePost = {
        basicName: 'Default Crop Reference',
        basicDescription: 'New defaulted crop reference',
        code: 'KR' + this.removeHyphens(uuidv1()).substring(0, 4),
        createdAt: null,
        updatedAt: null,
        content: '',
        scope: 'All',
        username: 'Default Username',
        isAvailable: 0,
        id: uuidv4(),
        deleted: false,
        version: null
        };
        return this.referencePost;
    }

    onSubmit(): void {
      this.submitted = true;
      this.componentSubscription = this.createReference(this.referencePost).subscribe(c => {
        console.log(c);
        this.event.emit({data: this.referencePost});
        // window.location.reload();
        // this._router.navigate(['/references']);
      });
      this.dialogRef.close();
    }

    removeHyphens(code: string): string {
      const replaced = code.replace(/-/g, '');
      return replaced;
    }

    public createReference(ref: KnowledgeReference) {
      ref.username = this._constService.system_username;
      const params: any = {
        'ZUMO-API-VERSION': '2.0.0'
      };
        return this._http
        .post(`${environment.apiUrl}tables/KnowledgeReference`, ref, { params: params }).pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('Reference created:', data)),);
    }
  }
