<div class="col-12" 
mat-dialog-title
cdkDrag
cdkDragRootElement=".cdk-overlay-pane" 
cdkDragHandle
*ngIf="customer">
  <h5>Create {{username}} user for {{customer.name}}</h5>
  <p [hidden]="!reqLoading"> <i class="fas fa-cog fa-spin"></i> Creating user and sending invite...</p>
</div>
<div mat-dialog-content *ngIf="customer">
    <div class="col-12">
      <form class="user-form" #userForm="ngForm" (ngSubmit)="onSubmit()">

        <mat-form-field>
            <input matInput placeholder="User Email" type="email" required [(ngModel)]="username" name="username">
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Name" type="text" required [(ngModel)]="userPost.basicName"
                name="basicName">
        </mat-form-field>

        <mat-form-field>
            <textarea matInput placeholder="Description" required [(ngModel)]="userPost.basicDescription"
                name="basicDescription">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-select matInput placeholder="User Type" required [(ngModel)]="userPost.userTypeId"
                name="userType">
                <mat-option *ngFor="let cat of types | sort:'basicName'" [value]="cat.id">
                    {{ cat.basicName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field>
          <mat-select matInput placeholder="Customer to assign to" required [(ngModel)]="_customer"
              name="customer">
              <mat-option *ngFor="let customer of customerList | sort:'name'" [value]="customer">
                  {{ customer.name }}
              </mat-option>
          </mat-select>
      </mat-form-field>
      
        <button mat-raised-button type="submit" [disabled]="!userForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
</div>
