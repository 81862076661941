import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../../../shared/services/admin.service";
import { forkJoin, of, Subscription } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { CustomerService } from "../../../../shared/services/customer.service";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { MatDialog } from "@angular/material/dialog";
import { AssignAssetFormComponent } from "../../assets/assign/assign-asset-form.component";
import { SnackService } from "../../../../shared/services/extra/snack.service";
@Component({
  selector: "app-user-group-manage",
  templateUrl: "./user-group-manage.component.html",
  styleUrls: ["./user-group-manage.scss"]
})
export class UserGroupManageComponent implements OnInit, OnDestroy {
  members: any[];
  scouts: any[];
  availScouts: any[];
  customerGroups: any[] = [];
  customers: any[] = [];
  selectedGroup: any;
  selectedCustomer: any;
  selected = false;
  users: any[] = [];
  customerId: string;
  userGroupId: string;
  loading = true;
  message = "";
  searchSubscription: Subscription;
  adminSubscription: Subscription;
  //init for search
  init_members: any;
  init_users: any;
  init_scouts: any;
  init_avail: any;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _customerService: CustomerService,
    private searchBarService: SearchBarService,
    private _snack: SnackService,
  ) { }

  ngOnInit(): void {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          const uid = params.get("uid");
          this.userGroupId = uid;
          this.customerId = id;
          const ids = [uid, id];
          return ids;
        }),
        mergeMap((ids) => {
          const memberCall = this._adminService
            .getAdminUserGroupMember(ids[0])
            .pipe(catchError((err) => of(undefined)));
          const scoutCall = this._adminService
            .getAdminUserGroupScout(ids[0])
            .pipe(catchError((err) => of(undefined)));
          const getCustomersAssets = this._adminService
            .getAllOfACustomersAssets(ids[1])
            .pipe(catchError((err) => of(undefined)));
          const getCustomersUsers = this._adminService.getCustomersUsers(
            ids[1]
          );
          const getUserGroupDetails = this._customerService.getUserGroupDetails(
            ids[0]
          );
          const getCustomersUserGroups =
            this._adminService.getCustomersUserGroups(ids[1]);
          const getAllCustomers = this._customerService.getAdminCustomers();
          return forkJoin([
            memberCall,
            scoutCall,
            getCustomersUsers,
            getUserGroupDetails,
            getCustomersUserGroups,
            getAllCustomers,
            getCustomersAssets,
          ]);
        })
      )
      .subscribe(
        (results) => {
          const members = results[0];
          const scouts = results[1];
          const customerUsers = results[2];
          const details = results[3];
          const custGroups = results[4];
          const getAllCustomers = results[5];
          const customerAssets = results[6];
          this.members = members;
          this.scouts = scouts;
          this.filterOutScouts(customerAssets);
          this.users = customerUsers;
          this.selectedGroup = details;
          this.customerGroups = custGroups;
          this.customers = getAllCustomers;
          this.assignInits(members, customerUsers, scouts, this.availScouts);
          this.checkIfDefined();
          //this.checkIfAssigned();
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this.message = message;
      if (message.length > 0) {
        const members = this.init_members,
          users = this.init_users,
          scouts = this.init_scouts,
          availScouts = this.init_avail;
        this.members = this.searchBarService.filterArrayOnProperty(
          message,
          members,
          "userName"
        );
        this.users = this.searchBarService.filterArrayOnProperty(
          message,
          users,
          "name"
        );
        this.scouts = this.searchBarService.filterArrayOnProperty(
          message,
          scouts,
          "scoutName"
        );
        this.availScouts = this.searchBarService.filterArrayOnProperty(
          message,
          availScouts,
          "scoutName"
        );
      } else {
        this.members = this.init_members;
        this.users = this.init_users;
        this.scouts = this.init_scouts;
        this.availScouts = this.init_avail;
      }
    });
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  assignInits(members, users, scouts, availScouts) {
    this.init_members = members;
    this.init_users = users;
    this.init_scouts = scouts;
    this.init_avail = availScouts;
  }

  serviceCalls(groupId: string) {
    this._customerService
      .getUserGroupDetails(groupId)
      .pipe(
        map((params) => {
          const result = params;
          this.selectedGroup = result;
          return result;
        }),
        mergeMap((id) => {
          const memberCall = this._adminService
            .getAdminUserGroupMember(groupId)
            .pipe(catchError((err) => of(undefined)));
          const scoutCall = this._adminService
            .getAdminUserGroupScout(groupId)
            .pipe(catchError((err) => of(undefined)));
          return forkJoin([scoutCall, memberCall]);
        })
      )
      .subscribe(
        (results) => {
          const scouts = results[0];
          const members = results[1];
          this.scouts = scouts;
          this.members = members;
          // console.log(scouts);
          this.checkIfDefined();
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  refresh() {
    const memberCall = this._adminService
      .getAdminUserGroupMember(this.userGroupId)
      .pipe(catchError((err) => of(undefined)));
    const scoutCall = this._adminService
      .getAdminUserGroupScout(this.userGroupId)
      .pipe(catchError((err) => of(undefined)));
    const getCustomersAssets = this._adminService
      .getAllOfACustomersAssets(this.customerId)
      .pipe(catchError((err) => of(undefined)));
    const getCustomersUsers = this._adminService.getCustomersUsers(
      this.customerId
    );
    const getUserGroupDetails = this._customerService.getUserGroupDetails(
      this.userGroupId
    );
    const getCustomersUserGroups =
      this._adminService.getCustomersUserGroups(this.customerId);
    const getAllCustomers = this._customerService.getAdminCustomers();
    forkJoin([memberCall,
      scoutCall,
      getCustomersUsers,
      getUserGroupDetails,
      getCustomersUserGroups,
      getAllCustomers,
      getCustomersAssets,])
      .subscribe(
        (results) => {
          const members = results[0];
          const scouts = results[1];
          const customerUsers = results[2];
          const details = results[3];
          const custGroups = results[4];
          const getAllCustomers = results[5];
          const customerAssets = results[6];
          this.members = members;
          this.scouts = scouts;
          this.filterOutScouts(customerAssets);
          this.users = customerUsers;
          this.selectedGroup = details;
          this.customerGroups = custGroups;
          this.customers = getAllCustomers;
          this.assignInits(members, customerUsers, scouts, this.availScouts);
          this.checkIfDefined();
          //this.checkIfAssigned();
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  navigateUrl(type: string, entity: any) {
    this.loading = true;
    switch (type) {
      case "users":
        this.adminSubscription = this._adminService.getAdminUser(entity.userName).subscribe((result) => {
          const details = result;
          for (let i = 0; i < details.length; i++) {
            const user = details[i];
            if (user.id.includes(entity.userId)) {
              this._router.navigate([
                `customers/${user.customerId}/users/${user.id}`,
              ]);
            }
          }
        });
        break;
      case "customer-users":
        this._router.navigate([
          `customers/${entity.customerId}/users/${entity.id}`,
        ]);
        break;
      case "scouts":
        this._router.navigate([`/scouts/${entity.scoutId}`]);
        break;
      default:
        break;
    }
  }

  openAssetDialog() {
    var customer: any = {
      id: this.customerId,
      name: this.selectedCustomer.name
    };
    const title = "Available Assets";
    const dialogRef = this._dialog.open(AssignAssetFormComponent, {
      width: "50vw",
      height: "70vh",
      data: { title, customer },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        this._snack.displaySuccess('Assets successfully assigned to ' + customer.name);
        // this.refresh();
      }
    });
  }

  checkIfDefined() {
    if (!this.scouts) {
      this.scouts = [];
      // this.scouts.push({
      //   id: "NA",
      //   scoutCode: "No Scouts Yet",
      //   scoutId: "NA",
      //   scoutName: "No Scouts Assigned to this UserGroup",
      //   userGroupId: "NA",
      // });
    }
    if (!this.members) {
      this.members = [];
      // this.members.push({
      //   content: null,
      //   id: "NA",
      //   userCode: "No Users Yet",
      //   userGroupId: "NA",
      //   userId: "NA",
      //   userName: "No Users Assigned to this UserGroup",
      // });
    }
    if (!this.users) {
      this.users = [];
      // this.users.push({
      //   content: null,
      //   id: "NA",
      //   userCode: "No Users Yet",
      //   userGroupId: "NA",
      //   userId: "NA",
      //   userName: "No Users Assigned to this Customer",
      // });
    }
    if (!this.availScouts || this.availScouts.length < 1) {
      this.availScouts = [];
      // this.availScouts.push({
      //   id: "NA",
      //   scoutCode: "No Scouts on Customer",
      //   scoutId: "NA",
      //   scoutName: "No Scouts Assigned to this Customer",
      //   userGroupId: "NA",
      // });
    }
  }

  customerChanged($event) {
    this.loading = true;
    // console.log($event);
    this.selectedCustomer = $event.value;
    const id = $event.value.id;
    this.getCustomersScoutsAndUsers(id);
  }

  getCustomersScoutsAndUsers(id: string) {
    const getCustomersUsers = this._adminService
      .getCustomersUsers(id)
      .pipe(catchError((err) => of(undefined)));
    const getCustomersAssets = this._adminService
      .getAllOfACustomersAssets(id)
      .pipe(catchError((err) => of(undefined)));
    forkJoin([getCustomersUsers, getCustomersAssets]).subscribe((result) => {
      const customerUsers = result[0];
      const customerAssets = result[1];
      this.filterOutScouts(customerAssets);
      this.users = customerUsers;
      this.checkIfDefined();
      this.loading = false;
    });
  }

  getCustomersScoutsAndUsersAndDetails(id: string) {
    const getCustomersUsers = this._adminService
      .getCustomersUsers(id)
      .pipe(catchError((err) => of(undefined)));
    const getCustomersAssets = this._adminService
      .getAllOfACustomersAssets(id)
      .pipe(catchError((err) => of(undefined)));
    const getUserGroupDetails = this._customerService.getUserGroupDetails(
      this.userGroupId
    );
    const getCustomersUserGroups = this._adminService
      .getCustomersUserGroups(id)
      .pipe(catchError((err) => of(undefined)));
    const getAllCustomers = this._customerService.getAdminCustomers();
    forkJoin([
      getCustomersUsers,
      getCustomersAssets,
      getUserGroupDetails,
      getCustomersUserGroups,
      getAllCustomers,
    ]).subscribe((result) => {
      const customerUsers = result[0];
      const customerAssets = result[1];
      const details = result[2];
      const custGroups = result[3];
      const getAllCustomers = result[4];
      this.selectedGroup = details;
      this.customerGroups = custGroups;
      this.customers = getAllCustomers;
      this.filterOutScouts(customerAssets);
      this.users = customerUsers;
      this.checkIfDefined();
    });
  }

  filterOutScouts(data: any[]) {
    const scoutArray = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      if (d.assetTypeCode.includes("Scout")) {
        scoutArray.push({
          id: d.id,
          assetName: d.currentAssetOwnership.assetName,
          scoutCode: d.code,
          scoutId: d.currentAssetOwnership.scoutId,
          scoutName: d.currentAssetOwnership.scoutName,
          userGroupId: "NA",
          type: d.assetTypeCode,
        });
      }
    }
    this.availScouts = scoutArray;
  }

  navigateOwnership(scout: any) {
    this._router.navigate([`/assets/${scout.id}`]);
  }

  groupChanged($event) {
    this.loading = true;
    this.scouts = [];
    this.members = [];
    console.log($event);
    const id = $event.value;
    this.serviceCalls(id);
  }
  //remove user or scout
  removeScoutFromGroup(id: string) {
    this.loading = true;
    this._adminService
      .deleteAdminUserGroupScout(id)
      .pipe(
        map((params) => {
          const result = params;
          console.log(result);
          return id;
        }),
        mergeMap((id) => {
          const scoutCall = this._adminService
            .getAdminUserGroupScout(this.selectedGroup.id)
            .pipe(catchError((err) => of(undefined)));

          return forkJoin([scoutCall]);
        })
      )
      .subscribe(
        (results) => {
          const scouts = results[0];
          this.scouts = scouts;
          this.checkIfDefined();
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  removeUserFromGroup(id: string) {
    this.loading = true;
    this._adminService
      .deleteAdminUserGroupMember(id)
      .pipe(
        map((params) => {
          const result = params;
          console.log(result);
          return id;
        }),
        mergeMap((id) => {
          const memberCall = this._adminService
            .getAdminUserGroupMember(this.selectedGroup.id)
            .pipe(catchError((err) => of(undefined)));
          return forkJoin([memberCall]);
        })
      )
      .subscribe(
        (results) => {
          const members = results[0];
          this.members = members;
          this.checkIfDefined();
          console.log(members);
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  addScoutToGroup(id: string) {
    this.loading = true;
    const ids = [id, this.selectedGroup.id];
    this._adminService
      .postAdminUserGroupScout(ids)
      .pipe(
        map((params) => {
          const result = params;
          // console.log(result);
          return id;
        }),
        mergeMap((id) => {
          const scoutCall = this._adminService
            .getAdminUserGroupScout(this.selectedGroup.id)
            .pipe(catchError((err) => of(undefined)));
          return forkJoin([scoutCall]);
        })
      )
      .subscribe(
        (results) => {
          const scouts = results[0];
          this.scouts = scouts;
          // console.log(scouts);
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  addUserToGroup(id: string) {
    this.loading = true;
    const ids = [id, this.selectedGroup.id];
    this._adminService
      .postAdminUserGroupMember(ids)
      .pipe(
        map((params) => {
          const result = params;
          // console.log(result);
          return id;
        }),
        mergeMap((id) => {
          const memberCall = this._adminService.getAdminUserGroupMember(
            this.selectedGroup.id
          );
          return forkJoin([memberCall]);
        })
      )
      .subscribe(
        (results) => {
          const members = results[0];
          this.members = members;
          this.loading = false;
        },
        (err) => {
          console.log(err);
        }
      );
  }
}