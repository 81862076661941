import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subscription } from "rxjs";
import { SearchBarService } from "../../shared/services/searchbar.service";
import { RootsActionMenuItem, ActionType } from "../../shared";
import { ConfirmaFirmwareDeleteDialogComponent } from "../../shared/components/dialogs/confirm-firmware-delete/confirm-firmware-delete-dialog.component";
import { AdminService } from "../../shared/services/admin.service";
import { AssetsService } from "../../shared/services/assets.service";
import { SnackService } from "../../shared/services/extra/snack.service";
import { FirmwareInstanceFormComponent } from "./create-instance/firmware-instance-form.component";
import { FirmwareFormComponent } from "./create/firmware-form.component";
import { FwmFwiDialogComponent } from "./fwm-fwi-dialog/fwm-fwi-dialog.component";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-firmware",
  templateUrl: "./firmware.component.html",
  styleUrls: ["./firmware.component.scss"],
})
export class FirmwareComponent implements OnInit, OnDestroy {
  firmwares: any[];
  loading = true;
  initLoad = true;
  firmwareToPost: any;
  firmwareDeleted: any;
  assetModels: any[];
  instances: any[];
  selected = false;
  origAssetModels: any[];
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create Firmware",
      icon: "add",
      toolTip:
        "Add New Instance and Model for Firmware - once created you may select a model from the right to assign it to",
      type: ActionType.Create,
    },
    // {
    //   name: "Create FWM",
    //   icon: "add",
    //   toolTip:
    //     "Add New Instance and Model for Firmware - once created you may select a model from the right to assign it to",
    //   type: ActionType.Dialog,
    // },
  ];
  
  firmwaresDataSource = new MatTableDataSource<any>();//firmwares
  assetModelsDataSource = new MatTableDataSource<any>();//assetModels
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  message: string;
  selectCount = 0;
  componentSubscription: Subscription;
  dialogSubscription: Subscription;
  constructor(
    private _adminService: AdminService,
    private _assetService: AssetsService,
    private _snack: SnackService,
    public searchBarService: SearchBarService,
    public _dialog: MatDialog,
    private _router: Router
  ) {
    this.title = "Firmwares";
    this.toolTip =
      "Find details regarding firmware instances and firmware models";
  }

  ngOnInit(): void {
    const getFirmware = this._adminService.getFirmwareModels();
    const getAssetModels = this._assetService.getAssetModels();
    const getFirmwareInstances = this._adminService.getFirmwareInstance();
    forkJoin([getFirmware, getAssetModels, getFirmwareInstances]).subscribe(
      (results) => {
        const firmwares = results[0];
        const assetModels = results[1];
        const firmwareInstances = results[2];
        this.firmwares = firmwares.reverse();
        this.assetModels = assetModels;
        this.origAssetModels = assetModels;
        this.instances = firmwareInstances;
        this.loading = false;
        this.initLoad = false;
        this.filterOutScouts(assetModels);
        this.firmwaresDataSource = new MatTableDataSource(firmwares.reverse());
        this.assetModelsDataSource = new MatTableDataSource(this.assetModels);
        this.firmwaresDataSource.sort = this.sort;
        this.assetModelsDataSource.sort = this.sort;
        this.searchBarService.currentMessage.subscribe((message) => {
          this.message = message;
          if (message.length > 0) {
            this.loading = true;
            this.onSearchInput(message);
          }
        });
      });
  }

  
  ngAfterViewInit() {
    this.firmwaresDataSource.sort = this.sort;
    this.assetModelsDataSource.sort = this.sort;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }
  
  onSearchInput(message: string) {
    const searchTarget = message;
    this.assetModelsDataSource.filter = searchTarget.trim().toLowerCase();
    this.firmwaresDataSource.filter = searchTarget.trim().toLowerCase();
    this.loading = false;
  }

  firmwareAssign(model) {
    if (model.assignFirmware == undefined || model.assignFirmware == null) {
      model.assignFirmware = true;
      this.selected = true;
      this.selectCount ++;
    } else {
      this.selected = true;
      model.assignFirmware = !model.assignFirmware;
      if (model.assignFirmware) {
      this.selectCount ++;
      } else {
        this.selectCount --;
      }
      if (this.selectCount < 1) {
        this.selectCount = 0;
      this.selected = false;
      }
    }
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.openInstanceDialog();
        break;
      case 3:
        this.fwmFwiDialog();
        break;
      default:
        break;
    }
  }

  createFirmwareAssetModel(firmware) {
    const ids = [];
    for (let i = 0; i < this.assetModels.length; i++) {
      const asset = this.assetModels[i];
      if (asset.assignFirmware == true) {
        ids.push(asset.id);
      }
    }
    ids.push(firmware.id);
    for (let j = 0; j < this.assetModels.length; j++) {
      const asset = this.assetModels[j];
      asset.assignFirmware = false;
    }
    this.selected = false;
    this.componentSubscription = this._adminService.postAssetModelFirmwareModel(ids).subscribe(
      (results) => {
        console.log(results);
        this._snack.displaySuccess(
          "Successfully created Firmware-Asset Relationship"
        );
      },
      (err) => {
        this._snack.displayError(
          "An error occurred. Please check if Firmware is already assigned to this Asset"
        );
      }
    );
  }

  getFirmwares() {
    this.loading = true;
    this.componentSubscription = this._adminService.getFirmwareModels().subscribe((models) => {
      this.firmwares = models.reverse();
      this.loading = false;
      this.initLoad = false;
    });
  }

  getAssetFirmwareModel(asset: any) {
    let ids: string[] = [];
    ids.push(asset.id);
    this.loading = true;
    this.componentSubscription = this._adminService.getAssetModelFirmwareModel(ids).subscribe((results) => {
      console.log(results);
      const types = [];
      for (let i = 0; i < results.length; i++) {
        const firmware = results[i];
        for (let j = 0; j < this.firmwares.length; j++) {
          const firm = this.firmwares[j];
          if (firmware.firmwareModelId.includes(firm.id)) {
            types.push(firm);
          }
        }
      }
      asset.expanded = true;
      asset.firmwares = types;
      this.loading = false;
      // this.styleModelCards(types);
    });
  }

  styleModelCards(models) {
    for (let i = 0; i < models.length; i++) {
      const model = models[i]; 
      for (let j = 0; j < this.firmwaresDataSource.filteredData.length; j++) {
        const filteredData = this.firmwaresDataSource.filteredData[j];
        if (filteredData) {
          
        }
      }
    }
  }

  deleteFirmwares(obj: any) {
    this.loading = true;
    const id = obj.id;
    let ids: string[] = [];
    ids.push(id);
    this.componentSubscription = this._adminService.getAssetModelFirmwareModel(ids).subscribe(
      (firmwares) => {
        console.log(firmwares);
        const firmwareAssetModels = this.findFirmwareAsset(firmwares);
        const dialogRef = this._dialog.open(
          ConfirmaFirmwareDeleteDialogComponent,
          {
            width: "60vw",
            data: { ids: ids, firmwares: firmwareAssetModels },
          }
        );
        dialogRef.componentInstance.event.subscribe((result) => {
          if (result) {
            this.dialogSubscription = this._adminService.deleteFirmwareModels(id).subscribe((results) => {
              this._snack.displaySuccess(
                "Firmware Model Succesfully Deleted... Repopulating"
              );
              const index = this.firmwares.indexOf(obj, 0);
              if (index > -1) {
                this.firmwares.splice(index, 1);
              }
              setTimeout(() => {
                this.loading = false;
                this.getFirmwares();
              }, 1000);
            });
          } else {
            this.loading = false;
          }
        });
      },
      (err) => {
        console.log(err);
        const noData = [];
        const dialogRef = this._dialog.open(
          ConfirmaFirmwareDeleteDialogComponent,
          {
            width: "60vw",
            data: { ids: ids, firmwares: noData },
          }
        );
        dialogRef.componentInstance.event.subscribe((result) => {
          if (result) {
            this.dialogSubscription = this._adminService.deleteFirmwareModels(id).subscribe((results) => {
              this._snack.displaySuccess(
                "Firmware Model Succesfully Deleted... Repopulating"
              );
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        });
      }
    );
  }
  
  fwmFwiDialog(){
    const dialogRef = this._dialog.open(FwmFwiDialogComponent, {
      width: "60vw"
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
        this.openInstanceDialog();  
      }
    });
  }

  openDialog(id: string): void {
    const obj = { title: "Create Firmware Version", id: id };
    const dialogRef = this._dialog.open(FirmwareFormComponent, {
      width: "60vw",
      data: obj,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result);
        var that = this;
        this._snack.displaySuccess("Firmware Versions created... Repopulating");
        setTimeout(function () {
          that.getFirmwares();
        }, 1000);
      }
    });
  }

  openInstanceDialog(): void {
    const dialogRef = this._dialog.open(FirmwareInstanceFormComponent, {
      width: "60vw",
      data: "Create Firmware Instance",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        this.openDialog(result.data);
      }
    });
  }

  grabFirmwareDetails(firmware: any) {
    this._router.navigate([`/firmwares/models/${firmware.id}`]);
  }

  fwiDetails(firmware: any) {
    this._router.navigate([`/firmwares/instances/${firmware.id}`]);
  }

  findFirmwareAsset(data: any[]) {
    const scoutArray = [];
    for (let i = 0; i < this.origAssetModels.length; i++) {
      const model = this.origAssetModels[i];
      for (let j = 0; j < data.length; j++) {
        const d = data[j];
        if (model.id === d.assetModelId) {
          model.assetModelId = d.id;
          scoutArray.push(model);
        }
      }
    }
    return scoutArray;
  }

  filterOutScouts(data: any[]) {
    console.log(data);
    const scoutArray = [];
    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      if (d.basicName.toLowerCase().includes("scout")) {
        scoutArray.push(d);
      }
    }
    this.assetModels = scoutArray;
  }
}
