<mat-toolbar
  *ngIf="!isLoginPage"
  class="bg-dark-green mat-elevation-z8"
  style="flex-direction: inherit"
>
  <mat-toolbar-row *ngIf="sidenav">
    <roots-main-menu-button
      class="dropdownImg"
      [isOpen]="sidenav.opened"
      (click)="sidenav.toggle()"
    >
    </roots-main-menu-button>
    <app-searchbar class="app-searchbar"></app-searchbar>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container class="sidenav-sizer">
  <mat-sidenav
    #sidenav="matSidenav"
    class="mat-elevation-z8"
    style="margin: 0px; padding: 0px; border-radius: 0px"
  >
    <img
      src="../../../assets/svgs/single-root.svg"
      style="
        position: absolute;
        z-index: -1000;
        opacity: 20%;
        transform: scale(2.3);
        width: 300%;
      "
    />
    <div *ngIf="userDetails; else noDetails">
      <app-avatar
        [username]="userDetails.basicName"
        style="padding: 2vw; color: #ffa500"
      ></app-avatar>
      <h4 class="name">{{ userDetails.basicName }}</h4>
      <p class="designation" style="margin: 0px">
        {{ time | date: "hh:mm:ss a" }}
      </p>
      <p class="designation" style="margin: 0px">TYPE: {{ userType }}</p>
      <p class="designation" style="margin: 0px">IP: {{ currentIp }}</p>
    </div>
    <ng-template #noDetails>
      <div>
        <app-avatar
          [username]="'ER ROR'"
          style="padding: 2vw; color: #99552b"
        ></app-avatar>
        <h4 class="name">Scout Profile Disconnected</h4>
        <p class="designation" style="margin: 0px">
          Your profile has been disconnected due to your session expiring.
        </p>
        <p class="designation" style="margin: 0px">
          Please refresh to validate for api useage.
        </p>

        <div>
          <a
            mat-list-item
            class="disconnected"
            (click)="refresh()"
            matTooltip="Refresh your Scout profile"
            matTooltipPosition="below"
          >
            Refresh </a
          ><a
            mat-list-item
            class="disconnected"
            (click)="logout()"
            matTooltip="Logout from Roots"
            matTooltipPosition="below"
          >
            Logout
          </a>
        </div>
      </div>
    </ng-template>
    <mat-divider></mat-divider>
    <mat-nav-list *ngIf="navItems">
      <app-menu-list-item *ngFor="let item of navItems" [item]="item" (selectedTabChange)="tabChanged($event)">
      </app-menu-list-item>
      <hr style="margin-bottom: 0px; background-color: lightgrey;" />
      <div>
        <!-- <div class="support-ticket-section"> -->
          <!-- <h4 class="jira-ticket-title">Support Ticket Creation</h4>
          <hr style="margin: 0px; background-color: lightgrey;" />
          <a
            mat-list-item
            id="feedback_button"
            class="mid"
            matTooltip="Provide feedback regarding any of the apps or environments"
            matTooltipPosition="below"
          >
            <i class="material-icons"> question_answer </i>
            Give Feedback
          </a>
          <a
            mat-list-item
            id="bug_button"
            class="mid"
            matTooltip="Create a Bug ticket to identify and fix the problem"
            matTooltipPosition="below"
          >
            <i class="material-icons"> bug_report </i>
            Report Bug
          </a>
        </div>
        <hr style="margin: 0px; background-color: lightgrey;" />
        <a
            mat-list-item
            class="mid"
            (click)="checkServer()"
            matTooltip="Provide feedback regarding any of the apps or environments"
            matTooltipPosition="below"
          >
            <i class="material-icons"> question_answer </i>
            Server Status
          </a> -->
        <a
          mat-list-item
          class="mid"
          (click)="logout()"
          matTooltip="Logout from Roots"
          matTooltipPosition="below"
        >
          <i class="material-icons">eject</i>
          Logout
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <!--Main content goes here-->
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
