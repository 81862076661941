<div class="subnav">
  <a mat-button class="nav-item nav-link link-active subnavbtn" matTooltip="{{item.toolTip}}" matTooltipPosition="below"
    routerLink="{{item.route}}" routerLinkActive="active">
    <mat-icon class="routeIcon">{{item.iconName}}</mat-icon>
    {{item.displayName}}
    <span fxFlex *ngIf="item.children && item.children.length">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </span>
  </a>
  <div *ngIf="expanded">
    <app-menu-top-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">
    </app-menu-top-item>
  </div>
</div>