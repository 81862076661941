<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  [items]="actionButtons"
  (refresh)="getFirmwares()"
  (actionListClicked)="topMenuActionsClicked($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="displayedColumns"
  [dataSource]="dataSource"
  [filter]="message"
  [csv]="title"
  [clickingEnabled]="true"
  [items]="items"
  (actionsClicked)="actionsClicked($event)">
</roots-table>