import {Component, OnInit, ViewChild, Injectable} from '@angular/core';
import {environment} from '@env/environment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AppService } from '../../../../shared/services/api/app.service';

declare var cordova: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
@Injectable()
export class AboutComponent implements OnInit {
  readonly dpiRatio = 96 / 72;
  public zoom = 1;
  public pdfSrc = '/assets/pdfs/IndustrialSmartDisclaimer.pdf';
  public toggled: boolean;
  public legendSettings: Object;
  public title: string;
  public isOnline: boolean;
  public scoutEarthURL: string;
  public chartData: Object[] = [];
  public dataValues: Object[] = [];
  public appDbVersion: string;
  public appApiVersion: string;
  public angularAppVersion: string;
  public loading: boolean = false;

  constructor(
    private _appService: AppService,
    private _adalService: MsAdalAngular6Service
  ) {
  }

  ngOnInit() {
    this.toggled = false;
    this.isOnline = true;
    this.scoutEarthURL = 'assets/svg/EarthScout_Logo_Horizontal_Horizontal.svg';
    this.loading = true;
    this.angularAppVersion = environment.version;
    this._appService.getSystemInfo().subscribe(res => {
      this.appDbVersion = res.versionDbSchemaLastMigration;
      this.appApiVersion = res.version;
      this.loading = false;
    });
  }

  earthScoutLink() {
    const url = 'http://www.earthscout.com/';
    if (window.hasOwnProperty('cordova')) {
      const ref = cordova.InAppBrowser.open(url, '_blank', 'location=no');
    } else {
      window.open(url);
    }
  }

  logout() {
    this._adalService.logout();
  }

  showDisclaimer() {
    // this.toggled = !this.toggled;
    setTimeout(() => {
      window.alert('Redirecting to IndustrialSmart');
      window.location.href = 'http://industrialsmart.com/privacy-policy';
    }, 1000);
  }
}
