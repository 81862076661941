import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  templateUrl: "./report-builder-dialog.component.html"
})
export class ReportBuilderDialogComponent {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  loading = false;
  currentCustomData;
  dataToAdd;
  dataSource: MatTableDataSource<any>;
  
  constructor(
    public dialogRef: MatDialogRef<ReportBuilderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataToAdd = data;
    this.dataSource = new MatTableDataSource<any>(data);
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }

  yesClicked() {
    this.event.emit(true);
  }
}
