


import {catchError, map, tap} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from '..';
import { ScoutLogExtended } from '../interfaces/scout-log-extended';
import { SnackService } from './extra/snack.service';
import { Observable } from 'rxjs';
import { VerifyLogDialogComponent } from '../components/dialogs/verify-log-dialog/verify-log-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { SearchLogs } from '../interfaces/search-log';
import { ConfirmationDialogComponent } from '../components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { VerifyDialogComponent } from '../components/dialogs/verify-dialog/verify-dialog.component';
import { CubVerifyDialogComponent } from '../components/dialogs/cub-verify-dialog/cub-verify-dialog.component';

@Injectable()
export class ScoutLogService implements OnDestroy {
request: Request;
loading: boolean = true;
logs: ScoutLogExtended[];
logById: ScoutLogExtended;
httpHeaders: HttpHeaders = new HttpHeaders();
private functionURL = environment.scoutLogApiUrl + "ScoutLog";
private cubFunctionURL = environment.scoutLogApiUrl + "CubScoutLog";
private searchURL = environment.scoutLogApiUrl + "SearchLog";
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _snack: SnackService,
    private _dialog: MatDialog
    ) { }

    ngOnDestroy() {
      if (this._dialog) {
        this._dialog.ngOnDestroy();
      }
    }

    public getScoutLogRequestById(id: string) {
      this.request = new Request(
        this.functionURL + '/' + id,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
          }),
        }
      );
      fetch(this.request)
        .then((response) => {
          response.json().then((res) => {
            this.logById = <ScoutLogExtended>res;
            if (this.logById) {
              this.loading = false;
              this._snack.displaySuccess('Logs loaded');
            }
          });
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }

    public searchLogs(searchTerm: string): SearchLogs[]  {
      let results: SearchLogs[] = [];
      this.request = new Request(
        this.searchURL + '/' + searchTerm,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
          }),
        }
      );
      fetch(this.request)
          .then((response) => {
            response.json().then((res) => {
                results = <SearchLogs[]>res;
              return <SearchLogs[]>res;
            });
          })
        .catch((err) => {
          this._snack.displayError(err);
        });
        return results;
    }

    //returns array of logs based on serial
    public getScoutLogsBySerialId(serialId: string): ScoutLogExtended[]  {
      this.request = new Request(
        this.functionURL + '/serials/' + serialId,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
          }),
        }
      );
      fetch(this.request)
          .then((response) => {
            response.json().then((res) => {
                this.logs = <ScoutLogExtended[]>res;
              return <ScoutLogExtended[]>res;
            });
          })
        .catch((err) => {
          this._snack.displayError(err);
        });
        return this.logs;
    }

    public getGetLatestScoutLogBySerialId(serialId: string) {
      this.request = new Request(
        this.functionURL + '/latest/' + serialId,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
          }),
        }
      );
      fetch(this.request)
        .then((response) => {
          response.json().then((res) => {
            this.logById = <ScoutLogExtended>res;
            if (this.logById) {
              this.loading = false;
              this._snack.displaySuccess('Logs loaded');
            }
          });
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }

    public validateCubScoutLog(id: string, serial: string) {
      // params
    const url = this.cubFunctionURL + '/validate/' + id;
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    params.set('serial', serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type","application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(
        url,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({
            serial: serial,
            validatedBy: localStorage.getItem('userEmail')
        })
      }
    );
    fetch(this.request)
      .then((response) => {
          this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

    public updateValidated(id: string, serial: string) {
        // params
      const url = this.functionURL + '/validate/' + id;
      const headers = new Headers();
      let params: URLSearchParams = new URLSearchParams();
      params.set('serial', serial);
      // params.set('validatedBy', localStorage.getItem('userEmail'));
      headers.set("Content-Type","application/json");
      headers.set("x-functions-key", this._constService.CONST.pvtKey);

      this.request = new Request(
          url,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify({
              serial: serial,
              validatedBy: localStorage.getItem('userEmail')
          })
        }
      );
      fetch(this.request)
        .then((response) => {
            this._snack.displaySuccess(response.status.toString());
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }

    public updateCubRename(id: string, cubName: string) {
      // params
    const url = this.cubFunctionURL + '/name/' + id;
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    params.set('serial', cubName);
    headers.set("Content-Type","application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(
        url,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({
          cubScoutId: cubName
        })
      }
    );
    fetch(this.request)
      .then((response) => {
          this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

    public updateRename(id: string, sqNumber: string) {
        // params
      const url = this.functionURL + '/name/' + id;
      const headers = new Headers();
      let params: URLSearchParams = new URLSearchParams();
      params.set('serial', sqNumber);
      headers.set("Content-Type","application/json");
      headers.set("x-functions-key", this._constService.CONST.pvtKey);

      this.request = new Request(
          url,
        {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify({
            sequenceNumber: sqNumber
          })
        }
      );
      fetch(this.request)
        .then((response) => {
            this._snack.displaySuccess(response.status.toString());
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
    }

    sendValidationUpdate(id: string, serial: string): Observable<any> {
        const params: any = {
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
        };
        const headers = new HttpHeaders();
        headers.set("Content-Type", "application/json");
        headers.set("x-functions-key", this._constService.CONST.pvtKey);

        const url = this.functionURL + '/validate/' + id;
          return this._http
          .patch(url , {serial: serial}, {headers}).pipe(
            map((resp: any) => {
              return resp;
            }),
            catchError(this._constService.handleErrorObservable)
          );
    }

    public getScoutLogRequest(): ScoutLogExtended[] {
        this.request = new Request(
          environment.scoutLogApiUrl + "ScoutLog",
          {
            method: "GET",
            headers: new Headers({
              "Content-Type": "application/json",
              "x-functions-key": this._constService.CONST.pvtKey,
            }),
          }
        );
        fetch(this.request)
          .then((response) => {
            response.json().then((res) => {
                this.logs = <ScoutLogExtended[]>res;
              return <ScoutLogExtended[]>res;
            });
          })
          .catch((err) => {
            this._snack.displayError(err);
          });
          return this.logs;
    }

    public getLatestScoutLogRequest(): ScoutLogExtended[] {
      this.request = new Request(
        environment.scoutLogApiUrl + "ScoutLog/latest",
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            "x-functions-key": this._constService.CONST.pvtKey,
          }),
        }
      );
      fetch(this.request)
        .then((response) => {
          response.json().then((res) => {
              this.logs = <ScoutLogExtended[]>res;
            return <ScoutLogExtended[]>res;
          });
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
        return this.logs;
  }

public httpGetTestSensors(): Observable<any[]> {
  let headers = new HttpHeaders();
  headers = headers.set("Content-Type", "application/json").set("x-functions-key", this._constService.CONST.pvtKey)
  const URL = `${environment.scoutLogApiUrl}TestSensors`;
  return this._http
    .get(URL, { headers: headers })
    .pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
}

  cubLogDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(CubVerifyDialogComponent, {
      width: "40vw",
      height: "40vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          // this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  logDialog(log: any): void {
      const dialogRef = this._dialog.open(VerifyDialogComponent, {
        width: "40vw",
        height: "40vh",
        data: log,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          setTimeout(() => {
            // this.refresh();
            this._snack.displaySuccess("Reloading... One moment");
          }, 1000);
        }
      });
  }

  createAssetsDialog(log: any): void {
    if (log.serial == null || log.serial == undefined) {
      log.serial = log['link'];
      log.sequenceNumber = log['name'];
    }
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Would you like to create assets for Scout: ${log.sequenceNumber}?`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.postScoutLogCreate(log);
      }
    });
  }

  createAssetsForCubDialog(log: any): void {
    if (log.serial == null || log.serial == undefined) {
      log.serial = log['link'];
      log.sequenceNumber = log['serial'];
    }
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Would you like to create assets for Cub: ${log.serial}?`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        this.postCubLogCreate(log);
      }
    });
  }

  public unValidateCubHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "CubScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    serial = "UNDO_" + serial;
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  public unValidateScoutHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "ScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    serial = "UNDO_" + serial;
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  //returns array of logs based on serial
  public getCubScoutLogsBySerialId(serialId: string) {
    this.request = new Request(
      environment.scoutLogApiUrl + "CubScoutLog/serials/" + serialId,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": environment.SCOUTLOG_API_KEY,
        }),
      }
    );
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <ScoutLogExtended[]>res;
          this._snack.displaySuccess("Scout Log history loaded");
            return <ScoutLogExtended[]>res;
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  public validateCubHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "CubScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  public validateScoutHistory(serial: string) {
    // params
    const url =
      environment.scoutLogApiUrl +
      "ScoutLog/serials/" +
      serial +
      "/validatehistory";
    const headers = new Headers();
    let params: URLSearchParams = new URLSearchParams();
    params.set("serial", serial);
    // params.set('validatedBy', localStorage.getItem('userEmail'));
    headers.set("Content-Type", "application/json");
    headers.set("x-functions-key", this._constService.CONST.pvtKey);

    this.request = new Request(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify({
        serial: serial,
        validatedBy: localStorage.getItem("userEmail"),
      }),
    });
    fetch(this.request)
      .then((response) => {
        this._snack.displaySuccess(response.status.toString());
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

public postTestSensor(serialName: string): any[] {
  this.request = new Request(
    environment.scoutLogApiUrl + "TestSensors",
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": this._constService.CONST.pvtKey,
      }),
      body: JSON.stringify({
        "name": serialName
      })}
  );
  fetch(this.request)
    .then((response) => {
      response.json().then((res) => {
        return <any[]>res;
      });
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
    return;
}

public postCubLogCreateOriginal(cub: any): any[] {
  //updated
  const sensors = this.findSensors(cub.sensors);
  const accesToken = localStorage.getItem('adal.idtoken');
  const URL = `${this._constService.CONST.URL}api/admin/prodlogimport`;
  this.request = new Request(
    URL,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        "Authorization": 'Bearer ' + accesToken
      }),
      body: JSON.stringify({
        "sequenceNumber": cub.name,
        "serial": cub.link,
        "boardVersion": cub.boardVersion,
        "productLine": cub.productLine,
        "connectivityCode": cub.connectivityCode,
        "modelNumber": cub.modelNumber,
        "firmwareVersion": cub.firmwareVersion,
        sensors
      })
    }
  );
  fetch(this.request)
    .then((response) => {
      response.json().then((res) => {
        this.responseDialog(res);
        return <any[]>res;
      });
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
    return;
}

public postCubLogCreate(cub: any): any[] {
  //updated
  const sensors = this.findSensors(cub.sensors);
  const accesToken = localStorage.getItem('adal.idtoken');
  const URL = `${this._constService.CONST.URL}api/admin/prodlogimport`;
  // console.log(cub);
  this.request = new Request(
    URL,
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        "Authorization": 'Bearer ' + accesToken
      }),
      body: JSON.stringify({
        "sequenceNumber": cub.sequenceNumber,
        "serial": cub.serial,
        "boardVersion": cub.boardVersion,
        "productLine": cub.productLine,
        "connectivityCode": cub.connectivityCode,
        "modelNumber": cub.modelNumber,
        "firmwareVersion": cub.firmwareVersion,
        sensors
      })
    }
  );
  fetch(this.request)
    .then((response) => {
      response.json().then((res) => {
        this.responseDialog(res);
        return <any[]>res;
      });
    })
    .catch((err) => {
      this._snack.displayError(err);
    });
    return;
}

    public postScoutLogCreate(scout: ScoutLogExtended): any[] {
      //updated
      const sensors = this.findSensors(scout.sensors);
      const accesToken = localStorage.getItem('adal.idtoken');
      const URL = `${this._constService.CONST.URL}api/admin/prodlogimport`;
      this.request = new Request(
        URL,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
            "Authorization": 'Bearer ' + accesToken
          }),
          body: JSON.stringify({
            "sequenceNumber": scout.sequenceNumber,
            "serial": scout.serial,
            "boardVersion": scout.boardVersion,
            "productLine": scout.productLine,
	          "connectivityCode": scout.connectivityCode,
            "modelNumber": scout.modelNumber,
            "firmwareVersion": scout.firmwareVersion,
            sensors
          })
        }
      );
      fetch(this.request)
        .then((response) => {
          response.json().then((res) => {
            this.responseDialog(res);
            return <any[]>res;
          });
        })
        .catch((err) => {
          this._snack.displayError(err);
        });
        return;
    }

    responseDialog(response: any): void {
      const dialogRef = this._dialog.open(VerifyLogDialogComponent, {
        width: '65vw',
        height: '80vh',
        data: response
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){
          console.log(result)
        }
      });
    }

    findSensors(sensors: any[]): any {
      let sensorsArray = [];
      for (let i = 0; i < sensors.length; i++) {
        const sensor = sensors[i];
        if (!sensor.testSensor) {
          if (sensor.sensor == undefined || sensor.sensor == null) {
            sensorsArray.push({"sensor": sensor.name})
          } else {
            sensorsArray.push({"sensor": sensor.sensor})
          }
        }
      }
      return sensorsArray;
    }
}

