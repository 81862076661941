import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { UserService } from '../../shared/services/api/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  defaultClass: boolean;
  public options: any;
  public changesSaved: true;
  private user: any;
  constructor(
    private _authService: AuthenticationService,
    private _userService: UserService
  ) {
  }

  ngOnInit() {
    this.user = this._authService.getUser();
    this.defaultClass = true;
  }
}
