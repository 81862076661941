import {
  Component,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  HostListener,
  Inject
} from '@angular/core';
// import { ZXingScannerComponent } from '@zxing/ngx-scanner';
// import {
//   BrowserQRCodeReader,
//   FormatException,
//   NotFoundException,
//   ChecksumException
// } from '@zxing/library';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetFormComponent } from '../create/asset-form.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements AfterViewInit {
  // @ViewChild('scanner') scanner: ZXingScannerComponent;
  @Output() selectEmitt = new EventEmitter();
  event: EventEmitter<any> = new EventEmitter();
  barcodeValue: string = null;
  // codeReader: BrowserQRCodeReader;
  permitted = false;
  selectedDeviceId;
  constructor(
    private _dialog: MatDialog,
    private _data: MatDialogRef<ScannerComponent>
  ) {}

  ngAfterViewInit() {
    // let selectedDeviceId;
    // this.scanner = new ZXingScannerComponent();
    // this.codeReader = new BrowserQRCodeReader();
    // console.log('scanner: ' + this.scanner);
    // this.codeReader
    //   .getVideoInputDevices()
    //   .then((result: any) => {
    //     console.log(result);
    //     selectedDeviceId = result[0].deviceId;
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     selectedDeviceId = err;
    //   });
    // this.decodeContinuously(this.codeReader, selectedDeviceId);
  }

  decodeOnce(codeReader, selectedDeviceId) {
    codeReader
      .decodeFromInputVideoDevice(selectedDeviceId, 'video')
      .then(result => {
        console.log(result);
        document.getElementById('result').textContent = result.text;
      })
      .catch(err => {
        console.error(err);
        document.getElementById('result').textContent = err;
      });
  }

  // processCode(result: string) {
  //   this.codeReader.stopContinuousDecode();
  //   const dialogRef = this._dialog.open(AssetFormComponent, {
  //     width: '600px',
  //     data: {title: 'Create Asset', result: result},
  //   });
  //   dialogRef.componentInstance.event.subscribe((afterwards) => {
  //     console.log(afterwards);
  //     this.codeReader.stopContinuousDecode();
  //   });
  // }

  // cancel() {
  //   this.codeReader.reset();
  //   this._data.close();
  //   // this._router.navigate(['/assets']);
  // }

  // decodeContinuously(codeReader, selectedDeviceId) {
  //   codeReader.decodeFromInputVideoDeviceContinuously(
  //     selectedDeviceId,
  //     'video',
  //     (result, err) => {
  //       if (result) {
  //         // properly decoded qr code
  //         const code = result.text;
  //         console.log('Found QR code!', result);
  //         this.barcodeValue = result.text;
  //         this.processCode(code);
  //       }
  //       if (err) {
  //         if (err instanceof NotFoundException) {
  //           console.log('No QR code found.');
  //         }
  //         if (err instanceof ChecksumException) {
  //           console.log('A code was found, but the read value was not valid.');
  //         }
  //         if (err instanceof FormatException) {
  //           console.log('A code was found, but it was in a invalid format.');
  //         }
  //       }
  //     }
  //   );
  // }
}

export class VideoInputDevice {
  /**
   * Creates an instance of VideoInputDevice.
   *
   * @param deviceId the video input device id
   * @param label the label of the device if available
   */
  public constructor(public deviceId: string, public label: string) { }
}
