<div mat-dialog-content class="dialog-box">
  <div class="col-12" style="max-height: 40%; height: fit-content;">
    <p class="text label-text">To send {{data.name}} an email invite please click the 'Send Email' button</p>
    <hr>
    <h4>
      Send Invite to: <b>{{data.name}}</b> @ <b>{{userEmailToSendTo}}</b>
      <button class="btn btn-secondary scanner" style="right: 0px; position: absolute;" mat-raised-button
        color="primary" (click)="sendEmail(data)">
        <p class="text btn-text big-btn-text">
          Send Email
        </p>
      </button>
    </h4>
  </div>

  <div class="col-12 link-field">
    <mat-form-field id="form-input">
      <input matInput placeholder="User Email" [(ngModel)]="userEmailToSendTo" disabled>
    </mat-form-field>
    <mat-form-field id="form-input">
      <input matInput placeholder="User Email" [(ngModel)]="username">
    </mat-form-field>
    <p class="text small-text">
      Example template to send to: {{userEmailToSendTo}}
    </p>
    <email-template [name]="username" [email]="userEmailToSendTo"></email-template>
  </div>
</div>