import { catchError, map } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, HostBinding,
         Output, EventEmitter, ViewChild, AfterViewInit
       } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { Scout, ConstantService, Customer, User } from '../../shared';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from '../../core';
import { ScoutLogExtended } from '../../shared/interfaces/scout-log-extended';
import { SnackService } from '../../shared/services/extra/snack.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent {
  customers: Customer[];
  customersHolder: Customer[];
  scouts: Scout[];
  isCollapsed = false;
  showInactive = true;
  showSettings = false;
  count = 0;
  private currentUser: any;
  regions: any[];
  types: any[];
  // Material Table init
  loading = true;
  dashboardId = '';
  logs: ScoutLogExtended[];
  
  constructor(
    private _router: Router,
    private _localStorage: LocalStorageService,
    private _snack: SnackService
  ) {
    if (this.loading) {
      this._snack.displayLoading('Logs loading...');
    }
  }

  // navigation --------------------------------------------------------------->

  navigateToHistory(customer: Customer) {
    console.log(customer);
    this._router.navigate([`/customers/${customer.id}/ownership`]);
  }

  navigateToCustomers(customer: Customer) {
    console.log(customer);
    // this._localStorage.saveFilter(this.dataSource.filter);
    this._router.navigate([`/customers/${customer.id}`]);
  }

  navigateToCustomerAssets(customer: Customer) {
    console.log(customer);
    // this._localStorage.saveFilter(this.dataSource.filter);
    this._router.navigate([`/customers/${customer.id}/assets`]);
  }

  navigateToCustomerUsers(customer: Customer) {
    console.log(customer);
    // this._localStorage.saveFilter(this.dataSource.filter);
    this._router.navigate([`/customers/${customer.id}/users`]);
  }

  navigateToCustomerUserGroups(customer: Customer) {
    console.log(customer);
    // this._localStorage.saveFilter(this.dataSource.filter);
    this._router.navigate([`/customers/${customer.id}/usergroups`]);
  }

  navigateToReport(num: number) {
    if (num === 1) {
      console.log(num);
      this._router.navigate(['/reports/packets'])
    }
    if (num === 2) {
      console.log(num);
      this._router.navigate(['/reports/sensors'])
    }
    if (num === 3) {
      console.log(num);
      this._router.navigate(['/reports/scouts'])
    }
    if (num === 4) {
      console.log(num);
      this._router.navigate(['/reports/users'])
    }
  }
}

