import { Injectable } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject } from "rxjs";
import { ReportNode } from "../../modules/reports/tree";
import { ScoutLogExtended } from "../interfaces/scout-log-extended";

@Injectable()
export class SearchBarService {
  searchTerm: string = "";
  dataSource: any[];
  filterTerm: string;

  private search = new BehaviorSubject("");
  currentMessage = this.search.asObservable();
  constructor() {}

  doFilter = (value: string, data: MatTableDataSource<any>) => {
    if (value.length > 0) {
      return (data.filter = value.trim().toLocaleLowerCase());
    }
  };

  // All CRUD functions
  doFilterLogs = (
    value: string,
    dataSource: MatTableDataSource<any>,
    logsLoaded: ScoutLogExtended[]
  ) => {
    const dataHolder: any[] = [];
    this.filterTerm = value;
    if (value.length > 0) {
      for (let i = 0; i < logsLoaded.length; i++) {
        const data = logsLoaded[i];
        const result = dataSource.filterPredicate(data, value);
        if (result) {
          dataHolder.push(data);
        }
        return result;
      }
      dataSource.filter = value.trim().toLocaleLowerCase();
      dataSource.data = dataHolder as ScoutLogExtended[];
      return logsLoaded;
    } else {
      dataSource.data = logsLoaded as ScoutLogExtended[];
      return logsLoaded;
    }
  };

  changeMessage(message: string) {
    this.search.next(message);
  }

  filterStandardDataArray = (value: string, data: any[]) => {
    if (value.length > 0) {
      let newArray = [];
      data.filter((x: any) => {
        if (x.level > 0) {
          if (x.name.toLowerCase().includes(value.toLowerCase())) {
            newArray.push(x);
          }
        } else {
          newArray.push(x);
        }
      });
      return newArray;
    }
    return data;
  };

  filterArrayOnProperty = (value: string, data: any[], prop: string) => {
    if (value.length > 0) {
      let newArray = [];
      data.filter((x: any) => {
        if (x[prop].length > 0) {
          if (x[prop].toLowerCase().includes(value.toLowerCase())) {
            newArray.push(x);
          }
        } else {
          newArray.push(x);
        }
      });
      return newArray;
    }
    return data;
  };

  filter = (value: string, data: ReportNode[]) => {
    if (value.length > 0) {
      let filters = { name: value };
      let newArray = [];
      data.filter((x: any) => {
        if (x.level > 0) {
          if (x.name.toLowerCase().includes(value.toLowerCase())) {
            newArray.push(x);
          }
        } else {
          newArray.push(x);
        }
      });
      return newArray;
    }
    return data;
  };
}
