import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScoutsEditComponent } from './edit/scouts-edit.component';
import { ScoutsFirmwareComponent } from './scouts-firmware/scouts-firmware.component';
import { ScoutsComponent } from './scouts.component';

const routes: Routes = [
  { path: '',  component: ScoutsComponent },
  { path: ":id", component: ScoutsEditComponent },
  { path: ":id/firmware", component: ScoutsFirmwareComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScoutsRoutingModule { }
