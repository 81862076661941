import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UsersUserGroupsComponent } from "../..";
@Component({
  templateUrl: "./show-groups-dialog.component.html"
})
export class ShowGroupDialogComponent {
  public event: EventEmitter<any> = new EventEmitter();
  groups;
  user;
  constructor(
    public dialogRef: MatDialogRef<ShowGroupDialogComponent>,
    public _dialog: MatDialog,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.user = data;
  }

  details(item){
    this.router.navigate([`/customers/${this.user.customerId}/usergroups/${item.id}`])
    // this.router.navigate([`/groups/${item.id}`])
    //customers/84e41590-2043-11eb-931e-01e3e7a06faa/usergroups/658b8df1f85f48a995028b2015a4a5dc/manage
    console.log(item, this.user)
  }

  assign(user: any): void {
    console.log(user);
    const dialogRef = this._dialog.open(UsersUserGroupsComponent, {
      width: "75vw",
      height: "85vh",
      data: {
        groups: user.userGroups,
        customerId: this.user.customerId,
        user: user,
        title: "Quick Edit on User Groups",
        customerGroups: this.user.userGroups,
      }
    });
    dialogRef.componentInstance.event.subscribe((result) => {
    });
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }
}

// code: "84e3ee80204311eb931e01e3e7a06faa_defaultusergroup"
// entity: null
// id: "658b8df1f85f48a995028b2015a4a5dc"
// name: "Applegate Valley Organics Default Usergroup"
// [[Prototype]]: Object