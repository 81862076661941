import { catchError, map, mergeMap } from "rxjs/operators";
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, of, Subscription } from "rxjs";
import { ActionType, RootsActionMenuItem, User, UserType } from "../../../../shared";
import { Region } from "../../../../shared/interfaces/region";
import { CustomerService } from "../../../../shared/services/customer.service";
import { AdminService } from "../../../../shared/services/admin.service";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { MatDialog } from "@angular/material/dialog";
import { EmailDialogComponent } from "../../../../shared/components/dialogs";
import { Notes } from "../../../../shared/interfaces/notes";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
})
export class UserEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() user: any;
  users: any[];
  userInitState: User;
  notes: Notes[] = [];
  event: EventEmitter<any> = new EventEmitter();
  types = [];
  regions = [];
  customerId = "";
  userId = "";
  userGroupMembers: any[];
  loading = true;
  customer: any;
  username: string;
  title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Send Email",
      icon: "email",
      toolTip: "Send another email to this user for invite",
      type: ActionType.Dialog,
    },
  ];
  componentSubscription: Subscription;
  customerUrl = "";
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _customerService: CustomerService,
    private _adminService: AdminService,
    private _snack: SnackService,
    private _dialog: MatDialog
  ) {
    this._route.queryParams.subscribe((params) => {
      this.username = params["username"];
    });
  }

  ngOnInit() {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          const uid = params.get("uid");
          this.customerId = id;
          this.userId = uid;
          return uid;
        }),
        mergeMap((uid) => {
          // const getUserDetails = this._customerService.getUserDetails(uid);
          const getUserDetails = this._customerService.getAdminUserByCustomerId(this.customerId);
          const getRegions = this._customerService.getRegions();
          const getUserTypes = this._customerService.getUserTypes();
          const getCustomerDetails = this._customerService.getCustomerDetails(
            this.customerId
          );
          const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.userId)
            .pipe(catchError((err) => of(undefined)));
          return forkJoin([
            getUserDetails,
            getRegions,
            getUserTypes,
            getCustomerDetails,
            getNotes
            // getAdminUserDetails
          ]);
        })
      )
      .subscribe((results) => {
        const users = results[0];
        const regions = results[1];
        const types = results[2];
        const customer = results[3];
        const notes = results[4];
        // const admin = results[5];

        this.toolTip =
          "Find details and entities that this User has relations to. Click to refresh.";
        this.users = users;
        this.regions = regions;
        this.types = types;
        this.customer = customer;
        this.notes = notes;
        this.customerUrl = `/customers/${this.customerId}`;
        this.populateRegions(regions);
        this.populateTypes(types);
        this.matchUser(this.username, this.userId);
        this.title = `User: ${this.user.authUsername}`;
        this.userGroupMembers = this.user.userGroups;
        this.loading = false;
      });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }


  noteAdded($event) {
    if ($event) {
      this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.userId).subscribe(result => {
        this.notes = result;
      })
    }
  }

  matchUser(name?: string, id?: string) {
    if (name && name.length > 0) {
      for (let i = 0; i < this.users.length; i++) {
        const u = this.users[i];
        if (u.authUsername === name) {
          this.user = u;
        }
      }
    } else if (id && id.length > 0) {
      for (let i = 0; i < this.users.length; i++) {
        const u = this.users[i];
        if (u.id === id) {
          this.user = u;
        }
      }
    }
  }

  refresh() {
    this.loading = true;
    this.componentSubscription = this._customerService
      .getUserDetails(this.userId)
      .subscribe((result) => {
        this.loading = false;
        this.user = result;
        if (this.user.basicName) {
          this.user.name = this.user.basicName;
        }
        if (this.user.basicDescription) {
          this.user.description = this.user.basicDescription;
        }
      });
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 3:
        this.emailDialog(this.user);
        break;
      default:
        break;
    }
  }

  //this is where email dialog
  emailDialog(user: any): void {
    const dialogRef = this._dialog.open(EmailDialogComponent, {
      width: "70vw",
      height: "90vh",
      data: {
        to: user.authUsername,
        from: "support@earthscout.com",
        name: user.name,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 202) {
        setTimeout(() => {
          this._snack.displaySuccess("Email Sent");
        }, 1000);
      }
    });
  }

  onNoClick() {
    this._router.navigate([`/customers/${this.customerId}/users`]);
  }

  pullUserGroup(group: any) {
    this.loading = true;
    this.componentSubscription = this._customerService
      .getUserGroupDetails(group.id)
      .subscribe((details) => {
        group.details = details;
        this.loading = false;
      });

    const groupDetails = this._customerService.getUserGroupDetails(
      group.id
    );
    const scoutCall = this._adminService.getAdminUserGroupScout(
      group.id
    );
    // const getCustomersUsers = this._adminService.getCustomersUsers(this.customerId)

    return forkJoin([groupDetails, scoutCall]).subscribe(
      (result) => {
        const details = result[0];
        const scouts = result[1];
        //const customerUsers = result[2];

        group.details = details;
        group.scouts = scouts;
        this.loading = false;
        // console.log(scouts, users)
      },
      (err) => {
        if (err) {
          this.loading = false;
        }
      }
    );
  }

  onUpdate(user: any): void {
    const patchCustomer: any = {
      basicDescription: user.description,
      basicName: user.name,
      regionId: user.regionId,
      languageTag: user.languageTag,
      userSettingsInfoContent: user.userSettingInfoContent,
      // contact_Address: user.contact_Address,
      // contact_Telephone: user.contact_Telephone,
      // contact_Email: user.contact_Email,
      // contact_Name: user.contact_Name,
      code: user.code,
      userTypeId: user.userTypeId,
      //   customerTypeId: user.customerTypeId
    };
    this.componentSubscription = this._customerService
      .updateUser(user.id, patchCustomer)
      .subscribe((update) => {
        console.log(update);
        this.refresh();
        this._router.navigate([`/customers/${this.customerId}/users`]);
      });
  }

  addGroups() {

  }

  navigateCustomer() {
    this._router.navigate([`/customers/${this.customerId}`]);
  }

  public onChange = (event) => {
    this.selectEmitt.emit(event.value);
  };

  populateRegions(regions: Region[]): object[] {
    this.regions = [];
    for (let i = 0; i < regions.length; i++) {
      const r = regions[i];
      const region = { key: r.basicName, value: r.id };
      this.regions.push(region);
    }
    return this.regions;
  }

  populateTypes(types: UserType[]): object[] {
    this.types = [];
    for (let i = 0; i < types.length; i++) {
      const r = types[i];
      const type = { key: r.basicName, value: r.id };
      this.types.push(type);
    }
    return this.types;
  }
}
