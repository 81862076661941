import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewEncapsulation,
  OnDestroy,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { ReportsService } from "../../../../shared/services/reports.service";
import { ConstantService, RootsActionMenuItem, RootsPageComponentConfig, RootsTableInterface } from "../../../../shared";
import { ScoutHealthLogs } from "../../../../shared/interfaces/scout-health-logs";
import { MatDialog } from "@angular/material/dialog";
import { MapDialogComponent } from "../../../../shared/components/dialogs";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { VoltageDialogComponent } from "./dialog/voltage-dialog.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-health-logs",
  templateUrl: "./health-logs.component.html",
  encapsulation: ViewEncapsulation.None
})
export class HealthLogsComponent implements RootsTableInterface, OnInit, AfterViewInit, OnDestroy {
  scoutLogs: ScoutHealthLogs[] = [];
  dateNow = new Date(Date.now());
  min: number = 3.75;
  max: number = 4.5;
  hasGeo = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() scoutDirect = new EventEmitter<any>();
  @Output() selectEmitt = new EventEmitter();
  event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  actionButtons: RootsActionMenuItem[] = [
    {
      name: `Geolocation: ${this.hasGeo}`,
      icon: 'done',
      toolTip: 'Enable has geo for units that support geo location (GPS)',
      type: 0
    },
    {
      name: "Paramaters",
      icon: 'category',
      toolTip: 'Click to open dialog for sending different query paramaters to search for units.',
      type: 1
    },
    {
      name: "Query",
      icon: 'send',
      toolTip: 'Send paramaters within query.',
      type: 2
    },
  ];
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<ScoutHealthLogs>(),
    displayedColumns: [
      "assetName",
      "authUsername",
      "lastPayLoadDate",
      "customerName",
      "lastBatteryVoltage",
      "scoutSerial"
    ],
    message: ''
  };
  items: RootsActionMenuItem[] = [
    {
      name: "View Scout's reliability info",
      toolTip:
        'View scout info regarding reliability and packet sending success/failure',
      icon: "search",
      type: 10,
    }
  ];
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _snack: SnackService,
    private _reportService: ReportsService,
    public _dialog: MatDialog,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "Scouts Health Report"
    this._config.toolTip = "Scout health report shows the most recent health status for a given scout - whether an error has occurred or packets are not sending, health logs will show information for a deeper dive. Click to refresh."
  }

  ngOnInit(): void {
    this.showScoutLogList(this.hasGeo, this.min, this.max);
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }


  refresh($event) {
    this._config.loading = true;
    this.showScoutLogList(this.hasGeo, this.min, this.max);
  }

  geoCheck() {
    this.hasGeo = !this.hasGeo;
  }

  onSubmit() {
    this._config.loading = true;
    this.submitted = true;
    console.log(this.max + " " + this.min + " " + this.hasGeo);
    this.showScoutLogList(this.hasGeo, this.min, this.max);
  }

  openMap(lat: number, long: number): void {
    const dialogRef = this._dialog.open(MapDialogComponent, {
      width: "75wv",
      data: { lat, long },
    });
    // dialogRef.componentInstance.event.subscribe(result => {
    //   console.log(result);
    // });
  }

  
  topMenuActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.geoCheck();
        break;
      case 1:
        const min = this.min;
        const max = this.max;
        const geo = this.hasGeo;
        const dialogRef = this._dialog.open(VoltageDialogComponent, {
          width: "85wv",
          data: { min, max, geo},
        });
        dialogRef.componentInstance.event.subscribe(result => {
          console.log(result);
          this.min = result.min;
          this.max = result.max;
          this.hasGeo = result.geo;
          this.showScoutLogList(this.hasGeo, this.min, this.max);
        });
        break;
      case 2:
      this.onSubmit();
        break;
      default:
      break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        // this._config.loading = true;
        this.openMap(item.scoutLatitude, item.scoutLongitude);
      break;
      default:
        break;
    }
  }


  showScoutLogList(geo: boolean, min: number, max: number) {
    this.componentSubscription = this._reportService.getAdminScoutHealth(geo, min, max).subscribe((logs) => {
      this.scoutLogs = logs;
      if (logs !== null || logs !== undefined) {
        this._config.dataSource.data = this.scoutLogs as ScoutHealthLogs[];
        this._config.loading = false;
        this._snack.displaySuccess("Scout report loaded");
      }
    });
    setTimeout(() => {
      this._config.dataSource.paginator = this.paginator;
    });
  }
}
