import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
@Component({
  selector: 'app-asset-lookup-list-dialog',
  templateUrl: './asset-lookup-list-dialog.component.html'
})
export class AssetLookupListDialogComponent implements OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  user: any;
  allAssets: any[];
  url = '';
  loading = false;
  availableGroups: any[] = [];
  componentSubscription: Subscription;
  constructor(
    private _router: Router,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<AssetLookupListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.allAssets = data;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  navigate(asset: any) {
    // console.log(asset);
    this._router.navigate([`/assets/${asset.id}`]);
  }
  
  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
