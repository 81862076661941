import { Component, OnInit, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoutLogExtended } from '../../../interfaces/scout-log-extended';
import { ScoutLogService } from '../../../services/scout-log.service';

@Component({
  selector: 'app-rename-log-dialog',
  templateUrl: './rename-log-dialog.component.html',
  styleUrls: ['./rename-log-dialog.component.scss']
})
export class RenameLogDialogComponent {
  input = '';
  searchedAsset: any;
  selectionMade = false;
  @Output() renamed = false;
  initSqNumber = '';
  constructor(
    public dialogRef: MatDialogRef<RenameLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public log: ScoutLogExtended,
    @Inject(MAT_DIALOG_DATA) public serial: string,
    private _logService: ScoutLogService,
    ) {
      this.input = this.log.serial;
      this.initSqNumber = this.log.sequenceNumber;

  }

  renameScout(log: any): boolean {
    this.selectionMade = true;
    this._logService.updateRename(this.log.id, this.log.sequenceNumber);
    if (!this.initSqNumber.includes(this.log.sequenceNumber)) {
      this.renamed = true;
      console.log(true);
      this.dialogRef.close(this.renamed);
      return this.renamed;
    }
    this.dialogRef.close();
    return this.renamed;
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
