<mat-table #sort="matSort" matSort [dataSource]="dataSource" multiTemplateDataRows>

  <!--Primary Table Columns on main Datasource-->
  <ng-container *ngFor="let columnName of tableCols; let i = index" matColumnDef="{{columnName}}">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{columnName}}
    </mat-header-cell>
    <mat-cell *matCellDef="let entity" 
    style="max-height: 100px; overflow: auto; display: table-row;">
      <span class="mobile-label">{{columnName}}:</span>
      {{ entity[columnName] }}
    </mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let entity" [attr.colspan]="tableCols.length"
      [@detailExpand]="checkExpanded(entity) ? 'expanded' : 'collapsed'"
      style="max-height: fit-content; 
      word-wrap: initial;
      display: table-cell;
      vertical-align: middle;
      ">
      <div class="example-element-detail">
        <div class="example-element-description" *ngIf="entity.additionalData; else noAdditionalData">
          <div *ngIf="entity.additionalDataIsArray; else notArray">
            {{entity.additionalData}}
          </div>
          <ng-template #notArray>
            {{entity.additionalData[entity.additionalProperty]}}
          </ng-template>
        </div>
        <ng-template #noAdditionalData>
          <div class="example-element-description">
            <button mat-button *ngFor="let item of items" (click)="emitAction(item, entity)">
              <mat-icon>{{ item.icon }}</mat-icon>
              <span>{{ item.name }}</span>
            </button>
            <!-- No additional entity data -->
          </div>
        </ng-template>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="tableCols"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableCols" matRipple class="example-element-row"
    [class.example-expanded-row]="checkExpanded(row)" (click)="pushPopElement(row)">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
    [@detailExpand]="checkExpanded(row) ? 'expanded' : 'collapsed'"></mat-row>
</mat-table>

<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 2, 3, 5, 10, 20]" showFirstLastButtons>
</mat-paginator>