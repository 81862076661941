
import {map,  tap, catchError } from 'rxjs/operators';
import { Injectable, OnInit, EventEmitter } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';



import { ConfigurationAAD } from '..';
import { } from 'adal-angular';
import User = adal.User;
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Router } from '@angular/router';
import { ConstantService } from '../../shared/services/constant.service';


@Injectable()
export class AuthenticationService {
  scoutUser: ScoutUser;
  currentUser: User;
  loggedIn = false;
  userLoadedEvent: EventEmitter<User> = new EventEmitter<User>();
  protected usersRef: ScoutUser[];
  protected aadConfiguration: ConfigurationAAD;
  private authHeaders: HttpHeaders;
  private tokenResource: string;
  private _config: adal.Config;
  // CTOR
  constructor(
    protected http: HttpClient,
    private router: Router,
    private _constService: ConstantService,
    private _adalService: MsAdalAngular6Service
  ) {
    this.aadConfiguration = new ConfigurationAAD(this._constService);
    this._config = this.aadConfiguration.config;
    this.tokenResource = this._config.clientId;
    if (typeof this._adalService.isAuthenticated === 'string'
    && (<string>this._adalService.isAuthenticated).length > 0) {
      this._setTokenHeaders(this._adalService.accessToken);
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  // GETTERS FOR PROPERTIES

  get config(): adal.Config {
    return this._config;
  }

  get userInfo() {
    return this._adalService.userInfo;
  }

  get accessToken() {
    return this._adalService.accessToken;
  }

  get isAuthenticated(): boolean {
    const user = this._adalService.userInfo;
    const token = this._adalService.accessToken;
    return !!user && !!token;
  }

  getUser() {
    if (typeof this._adalService.isAuthenticated === 'string'
      && (<string>this._adalService.isAuthenticated).length > 0) {
      this.currentUser = this._adalService.userInfo;
      this.loggedIn = true;
      this.userLoadedEvent.emit(this.currentUser);
    }
  }
  //#endregion AUTHENTICATION

  testLogin(context: LoginContext): boolean {
    this.loggedIn = true;
    this.router.navigate(['/dashboard']);
    return true;
  }

  // HELPER METHODS FOR MANAGING SESSION
  acquireToken(): Observable<string> {
    return this._adalService.acquireToken(this.tokenResource);
  }

  sendToken(token: string) {
    // localStorage.setItem('userId', this._adalService.)
    localStorage.setItem('Bearer', token);
  }

  getToken() {
    return localStorage.getItem('Bearer');
  }

  login() {
    window.localStorage.clear();
    this._adalService.login();
  }

  logout() {
    window.localStorage.clear();
    this._adalService.logout();
  }

  private _setUserIdHeader(user: ScoutUser) {
    this.authHeaders = new HttpHeaders();
    this.authHeaders = this.authHeaders.set('Uid', user.id);
  }

  private _setTokenHeaders(access_token, token_type = 'Bearer') {
    localStorage.setItem(token_type, access_token);
    access_token = access_token || this._adalService.accessToken;
    this.authHeaders = new HttpHeaders();
    this.authHeaders = this.authHeaders.set('Authorization', token_type + ' ' + access_token);
  }

  public _setRequestOptions(headers?: HttpHeaders) {
    if (headers) {
      headers.set('Authorization',
        this.authHeaders.get('Authorization'));
      headers.set('Uid',
        this.authHeaders.get('Uid'));
    } else {
      return this.authHeaders;
    }
    return headers;
  }

  AuthGet(url: string, headers?: HttpHeaders): Observable<HttpResponse<Object>> {
    if (headers) {
      headers = this._setRequestOptions(headers);
    } else {
      headers = this._setRequestOptions();
    }
    return this.http.get<HttpResponse<Object>>(url, { headers: headers });
  }
  //#endregion OfflineAuthguard
}

export interface ScoutUser {
  deleted: boolean;
  updatedAt: string;
  createdAt: string;
  version: string;
  id: string;
  isAvailable: number;
  basicDescription: string;
  basicName: string;
  code: string;
  username: string;
  contact_Email: string;
  contact_Cellphone: string;
  contact_Telephone: string;
  contact_Address: string;
  contact_Name: string;
  languageTag: string;
  systemUser_Username: string;
  regionID: string;
  password: string;
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}
