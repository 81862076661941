<mat-card *ngIf="scout != null" class="weather-overview">
    <mat-card-header class="weather-header">Weather from {{scout.name}}</mat-card-header>

    <mat-card-header class="header">
        Starbuck, MN, USA
    </mat-card-header>
    <div class="grid-container">
        <div class="temperature grid-item">{{airTemp}}</div>
        <!-- <div class="high-low grid-item">
            <span>High: 45&deg;F</span>
            <span class="right">at 3:30pm</span>
            <br>
            <span>Low: 33&deg;F</span>
            <span class="right">at 8:30am</span>
        </div> -->
    </div>
    <div class="grid-container">
        <div class="grid-item list-item">
            <span>Wind:</span>
            <span class="right">{{windSpeedCombinedNE}} {{windDirection}}</span>
        </div>
        <div class="grid-item list-item">
            <span>Precipitation:</span>
            <span class="right">{{precipitation}}</span>
        </div>
        <div class="grid-item list-item">
            <span>Barometric Pressure:</span>
            <span class="right">{{barometricPressure}}</span>
        </div>
        <div class="grid-item">
            <span>Shortwave Radiation:</span>
            <span class="right">{{shortwaveRadiation}}</span>
        </div>
    </div>
</mat-card>