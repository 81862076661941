<span
  [@firstLine]="open"
  [style.backgroundColor]="color"
  class="__line  __line--first">
</span>
<span
  [style.backgroundColor]="color"
  class="__line  __line--second">
</span>
<span
  [@thirdLine]="open"
  [style.backgroundColor]="color"
  class="__line  __line--third">
</span>
