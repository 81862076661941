import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { SnackService } from "../../../../services/extra/snack.service";
import { AdminService } from "../../../../services/admin.service";
import { CustomerService } from "../../../../services/customer.service";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
@Component({
  selector: "app-assign-group-dialog",
  templateUrl: "./assign-group-dialog.component.html",
})
export class AssignGroupDialogComponent {
  event: EventEmitter<any> = new EventEmitter();
  _user: any;
  public get user(): any {
    return this._user;
  }

  public set user(user: any) {
    this._user = user;
  }
  _groupState: any[];
  public get groupState(): any[] {
    return this._groupState;
  }

  public set groupState(groupState: any[]) {
    this._groupState = groupState;
  }
  loading = true;
  addedGroups: any[] = [];
  removedGrouped: any[] = [];
  availableGroups: any[] = [];
  mergedAvailableGroups: any[] = [];
  mergedGroupDetails = [];
  constructor(
    public dialogRef: MatDialogRef<AssignGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackService: SnackService,
    private _adminService: AdminService,
    private _customerService: CustomerService
  ) {
    this.user = data.user;
    this.availableGroups = data.groups;
    this.loading = true;
    //calls
    const memberCall = this._adminService
      .getAdminUserGroupMember(this.user.id)
      .pipe(catchError((err) => of(undefined)));
    const allGroups = this._adminService
      .getCustomersUserGroups()
      .pipe(catchError((err) => of(undefined)));

    forkJoin([memberCall, allGroups]).subscribe((results) => {
      //assignment and merged details
      const members = results[0];
      const groups = results[1];

      const mergedGroups = this.matchUserGroupsDetails(
        members,
        groups
      );

      this.mergedGroupDetails = mergedGroups;
      this.mergedAvailableGroups = this.spliceGroups(groups, mergedGroups);

      this.loading = false;
      this._snackService.displaySuccess("Entities refreshed");
    });
  }

  matchUserGroupsDetails(results: any[], groups: any[]): any[] {
    const combinedData = [];
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      for (let j = 0; j < groups.length; j++) {
        const group = groups[j];
        if (result.userGroupId === group.id) {
          const newGroup = {
            id: result.id,
            name: group.name,
            groupId: group.id,
          };
          combinedData.push(newGroup);
        }
      }
    }
    return combinedData;
  }

  addGroupToUserAsync(group: any) {
    this.loading = true;
    group.loading = true;
    const ids = [this.user.id, group.id];
    this._adminService.postAdminUserGroupMember(ids)
    
    .pipe(
      map((params) => {
        const result = params;
        console.log(result);
        return params;
      }),
      mergeMap((params) => {
        this.loading = true;
        //calls
        const memberCall = this._adminService
          .getAdminUserGroupMember(this.user.id)
          .pipe(catchError((err) => of(undefined)));
        const allGroups = this._adminService
          .getCustomersUserGroups()
          .pipe(catchError((err) => of(undefined)));

        return forkJoin([memberCall, allGroups]);
      })
    )
    .subscribe(
      (results) => {
        //assignment and merged details
        const members = results[0];
        const groups = results[1];

        const mergedGroups = this.matchUserGroupsDetails(
          members,
          groups
        );

        this.mergedGroupDetails = mergedGroups;
        this.mergedAvailableGroups = this.spliceGroups(groups, mergedGroups);

        this.loading = false;
        this._snackService.displaySuccess("Entities refreshed");
      },
      (err) => {
        console.log(err);
      }
    );
  }

  spliceGroups(groups: any[], passedUserGroups: any[]): any[] {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      for (let j = 0; j < passedUserGroups.length; j++) {
        const userGroups = passedUserGroups[j];
        if (userGroups.groupId == group.id) {
          groups.splice(i, 1);
        }
      }
    }
    return groups;
  }

  removeUserFromGroupAsync(group: any) {
    this.loading = true;
    const id = group.id;
    group.loading = true;
    this._adminService
      .deleteAdminUserGroupMember(id)
      .pipe(
        map((params) => {
          const result = params;
          console.log(result);
          return id;
        }),
        mergeMap((id) => {
          this.loading = true;
          //calls
          const memberCall = this._adminService
            .getAdminUserGroupMember(this.user.id)
            .pipe(catchError((err) => of(undefined)));
          const allGroups = this._adminService
            .getCustomersUserGroups()
            .pipe(catchError((err) => of(undefined)));
          const details = this._customerService
          .getAdminUserDetails(this.user.authUsername, null)
          .pipe(catchError((err) => of(undefined)));

          return forkJoin([memberCall, allGroups, details]);
        })
      )
      .subscribe(
        (results) => {
          //assignment and merged details
          const members = results[0];
          const groups = results[1];
          const details = results[2];

          const mergedGroups = this.matchUserGroupsDetails(
            members,
            groups
          );

          this.mergedGroupDetails = mergedGroups;
          this.mergedAvailableGroups = this.spliceGroups(groups, mergedGroups);

          this.loading = false;
          this._snackService.displaySuccess("Entities refreshed");
        },
        (err) => {
          console.log(err);
        }
      );
  }

  okayClick(): void {
    this.event.emit(this.addedGroups);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
