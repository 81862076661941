import { Component, OnInit, Inject, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../../services/customer.service';
import { SnackService } from '../../../services/extra/snack.service';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnDestroy{
  userEmailToSendTo = '';
  username = '';
  componentSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _customerService: CustomerService,
    private _snack: SnackService
    ) {
      this.userEmailToSendTo = data.to;
      this.username = data.name;
  }
  
  ngOnDestroy() {
    // if (this.componentSubscription) {
    //   this.componentSubscription.unsubscribe();
    // }
  }

  sendEmail(data: any) {
    this.componentSubscription = this._customerService.sendGridEmail(this.userEmailToSendTo , data.from, this.username).subscribe(email => {
      if (email.statusCode == 202) {
        this._snack.displaySuccess(email.statusCode);
      } else {
        this._snack.displayError(email.statusCode);
      }
      this.dialogRef.close(email.statusCode);
    })
    this.dialogRef.close();
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }
}
