<div mat-dialog-content>
  <div class="col-12">
    <button disabled="available" class="col-6" style="float: left;" *ngFor="let opt of options" (click)="optionSelected(opt)">
      <mat-card>
        {{opt.title}}
      </mat-card>
    </button>
  </div>
  <br>
  <hr>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <div id="myData"></div>
    <!-- {{data}} -->
  </div>