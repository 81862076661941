import { EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { RootsActionMenuItem } from "../..";
import { headerAnimation } from "./roots-page-header.animations";
@Component({
  selector: "roots-page-header",
  templateUrl: "./roots-page-header.component.html",
  styleUrls: ["./roots-page-header.component.scss"],
  animations: [headerAnimation()],
  encapsulation: ViewEncapsulation.None
})
export class RootsPageHeaderComponent {
  private _items: RootsActionMenuItem[] = [];
  private _selections: any[] = [];
  private _icon: string = "";
  private _titleTooltip: string = "";
  private _pageTitle: string = "";
  selection = false;

  currentSelection: any;
  open = false;
  expanded: boolean = false;
  reportBuilder = false;

  @Output("reportBuilder") reportBuilderEnabled = new EventEmitter();
  @Output("actionListClicked") actionListEmit = new EventEmitter();
  @Output("refresh") refreshEmit = new EventEmitter();
  @Output("iconClicked") iconEmit = new EventEmitter();

  @Input("loading") loading: boolean;
  @Input("items") set items(items: RootsActionMenuItem[]) {
    this._items = items;
  }
  get items() {
    return this._items;
  }
  @Input("toolTip") set titleTooltip(titleTooltip: string) {
    if (titleTooltip.length < 1) {
      titleTooltip = "Click to refresh entities";
    }
    this._titleTooltip = titleTooltip;
  }
  get titleTooltip() {
    return this._titleTooltip;
  }
  @Input("title") set pageTitle(pageTitle: string) {
    this._pageTitle = pageTitle;
  }
  get pageTitle() {
    return this._pageTitle;
  }
  @Input("selections") set selections(selections: any[]) {
    this._selections = selections;
    if (selections.length > 0) {
      this.selection = true;
    }
  }
  get selections() {
    return this._selections;
  }

  @Input("icon") set icon(icon: string) {
    this._icon = icon;
  }
  get icon() {
    return this._icon;
  }
  constructor(private _router: Router) {
    
  }

  refresh() {
    this.refreshEmit.emit({ refresh: true });
  }

  iconBtnClicked($event){
    this.iconEmit.emit(true);
    console.log($event);
  }

  optionsClicked() {
    this.expanded = !this.expanded;
  }

  toggleOpen() {
    this.open = !this.open;
  }

  enableReportBuilding() {
    this.reportBuilderEnabled.emit(true);
  }

  toggleAction(item: any) {
    switch (item.type) {
      case 5:
        //type of info button clicked
        console.log("Provide template for page info");
        break;
      case 99:
        //enable report building function via header
        this.enableReportBuilding();
        break;
      default:
        break;
    }
    if (item.link !== undefined) {
      this._router.navigate([`${item.link}`]);
    } else {
      //send action back to parent component for toggling dynamic action
      this.actionListEmit.emit(item);
    }
  }
}
