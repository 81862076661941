import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../shared/services/admin.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { AdminUser } from '../../../../shared/interfaces/admin/admin-user';
import { forkJoin, Subscription } from 'rxjs';
import { SnackService } from '../../../../shared/services/extra/snack.service';

@Component({
  selector: 'app-users-user-groups',
  templateUrl: './users-user-groups.component.html'
})
export class UsersUserGroupsComponent implements OnInit, OnDestroy {
  @Output() event = new EventEmitter();
  customerId = '';
  userId = '';
  userGroups: any[];
  userGroupsToAssign: Grouping[];
  allGroups: Grouping[];
  groupsNotAssigned: Grouping[];
  submitted = false;
  targetGroup = '';
  targets: Grouping[];
  groups = new FormControl();
  groupsTwo = new FormControl();
  selected: Grouping[] = [];
  selectedTwo: Grouping[] = [];
  user: AdminUser;
  customersUserGroups = [];
  groupingForm: FormGroup;
  mostRecent = '';
  allChanged = false;
  customerChanged = false;
  componentSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<UsersUserGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _router: Router,
    private _snack: SnackService
  ) {
    this.userGroups = this.data.groups;
    this.user = this.data.user;
    this.targets = this.userGroups;
    this.customersUserGroups = this.data.customerGroups;
    this.customerId = this.data.customerId;
    this.serviceCalls();
  }

  ngOnInit() {
    // this.serviceCalls();
  }

  ngOnDestroy() {
    // if (this.componentSubscription) {
    //   this.componentSubscription.unsubscribe();
    // }
  }

  navigateToCustomer(id: string) {
    this._router.navigate([`/customer/${this.customerId}/users/${id}`])
  }

  serviceCalls() {
    const getCustomerUG = this._adminService.getCustomersUserGroups(this.data.customerId);
    const getAllUG = this._adminService.getCustomersUserGroups('');
    const getUserDetails = this._adminService.getAdminUser(this.data.user.authUsername)

    forkJoin([getCustomerUG, getAllUG, getUserDetails])
    .subscribe(result => {
      const customer = result[0];
      const all = result[1];
      const details = result[2];
      this.groupsNotAssigned = customer;
      this.allGroups = all;
      const values: any[] = [];
      const values2: any[] = [];
      for (let i = 0; i < this.userGroups.length; i++) {
        const ugroup = this.userGroups[i];
        const value = this.groupsNotAssigned.filter((item) => item.id === ugroup.id);
        const value2 = this.allGroups.filter((item) => item.id === ugroup.id);
        values.push(<Grouping>value[0]);
        values2.push(<Grouping>value2[0]);
        if (value2.length > value.length) {
          console.log('has external groups')
          this.allChanged = true;
        }
      }
      this._snack.displaySuccess('Groups pulled');
      this.selected = values;
      this.selectedTwo = values2;
    })
  }
  
  refreshCustomerGroups() {
    this.allChanged = false;
    this.customerChanged = false;
    this.targets = this.userGroups;
    const values: any[] = [];
    const values2: any[] = [];
    for (let i = 0; i < this.userGroups.length; i++) {
      const ugroup = this.userGroups[i];
      const value = this.groupsNotAssigned.filter((item) => item.id === ugroup.id);
      const value2 = this.allGroups.filter((item) => item.id === ugroup.id);
      values.push(<Grouping>value[0]);
      values2.push(<Grouping>value2[0]);
    }
    this.selected = values;
    this.selectedTwo = values2;
    this._snack.displaySuccess('Groups Refreshed');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  change($change) {
    this.mostRecent = $change.source.value;
  }

  changeAll($change) {
    this.mostRecent = $change.source.value;
  }

  setAllCustomerGroups() {
    this.allChanged = true;
    this.customerChanged = true;
    console.log(this.allChanged, this.customerChanged)
    this.targets = this.customersUserGroups;
    const values: any[] = [];
    const values2: any[] = [];
    for (let i = 0; i < this.userGroups.length; i++) {
      const ugroup = this.userGroups[i];
      const value = this.groupsNotAssigned.filter((item) => item.id === ugroup.id);
      const value2 = this.allGroups.filter((item) => item.id === ugroup.id);
      values.push(<Grouping>value[0]);
      values2.push(<Grouping>value2[0]);
    }
    this.selected = values;
    this.selectedTwo = values2;
  }

  navigateToManage() {
    this._router.navigate([`/customers/${this.customerId}/usergroups`])
    this.dialogRef.close();
  }

  selectEach(groups: any[]) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const select = this.groupsNotAssigned.find(g => g.id === group.id);
      const select2 = this.allGroups.find(g => g.id === group.id)
      this.groupingForm.get('id').setValue(select);
      this.groupingForm.get('id').setValue(select2);
    }
  }

  selectedGrouped($event) {
    this.customerChanged = true;
    console.log(this.allChanged, this.customerChanged)
    this.targets = $event.value;
    console.log(this.targetGroup);
  }

  selectedGrouped2($event) {
    this.allChanged = true;
    console.log(this.allChanged, this.customerChanged)
    this.targets = $event.value;
    console.log(this.targetGroup);
  }

  removeAll():void {
    this.customerChanged = false;
    this.allChanged = false;
    console.log(this.allChanged, this.customerChanged)
    this.targets = [];
    this.selected = [];
  }

  getGroups(): any[] {
    const newUserGroups: Grouping[] = [];
    for (let i = 0; i < this.targets.length; i++) {
      const s = this.targets[i];
      const ug: Grouping = {
        id: s.id,
        name: s.name
      };
      newUserGroups.push(ug);
    }
    return newUserGroups;
  }

  changedUser(groups: any[]): any {
    let userToUpdate = <any>this.user;
    userToUpdate.userGroups = <any>groups;
    delete userToUpdate['isExpanded'];
    delete userToUpdate['contactInfo'];
    delete userToUpdate['nameInfo'];
    delete userToUpdate['userDevices'];
    delete userToUpdate['content'];
    delete userToUpdate['userSettingsInfo'];
    userToUpdate.userGroups = this.findNulls(userToUpdate.userGroups);

    return userToUpdate;
  }

  onSubmit($event): void {
    this.submitted = true;
    let newUserGroups = this.getGroups();
    let userToUpdate = this.changedUser(newUserGroups);
    this.componentSubscription = this._adminService.postAdminUser(userToUpdate)
    .subscribe(result => {
      console.log(result);
      if (result !== null) {
        this._snack.displaySuccess(`${result.userGroups.length} Groups successfully assigned to ${result.code}`);
        this.user.userGroups = <any>newUserGroups;
      } else {
        this._snack.displayError('Result returned null - Please refresh and check payload')
        // this.user.userGroups = this.customersUserGroups
      }
    }, err => {
      this._snack.displayError(`An error has occurred: ${err}`);
    });
    this.dialogRef.close();
  }

  findNulls(groups: any[]) {
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group.id === null || group.name === null) {
        groups.splice(i, 1);
      }
    }
    return groups;
  }
}

export class Grouping {
  id: string;
  // code: string;
  name: string;
}
