import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConstantService } from '../..';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {
@ViewChild('myInput')
myInputVariable: ElementRef;
// Variable to store shortLink from api response 
shortLink: string = ""; 
loading: boolean = false; // Flag variable 
@Input()selectedFile: File = null;
@Output()file: File = null; // Variable to store file 
@Output() onNewFileEvent = new EventEmitter<File>();
public event: EventEmitter<any> = new EventEmitter();
// Inject service  
constructor(
  private fileUploadService: FileUploadService,
  private _constService: ConstantService) { } 

ngOnInit(): void { 
} 

// On file Select 
onChange(event) { 
    this.file = event.target.files[0]; 
    const selectedFile = this.file;
    this.onNewFileEvent.emit(selectedFile);
    this._constService.fileToUpload = selectedFile;
} 

onChangeFile(event) {
  console.log(event);
}

remove() {
  this.file = null;
  this._constService.fileToUpload = this.file;
  console.log(this.myInputVariable.nativeElement.files);
  this.myInputVariable.nativeElement.value = "";
  console.log(this.myInputVariable.nativeElement.files);
}

// OnClick of button Upload 
onUpload() { 
    this.loading = !this.loading; 
    console.log(this.file); 
    this.fileUploadService.output(this.file);
    this.event.emit(this.file);
  }
}
