import { Component, Input, OnInit } from '@angular/core';
import * as d3 from "d3";
import {} from "d3-time-format";

@Component({
  selector: 'app-scatter',
  templateUrl: './scatter.component.html'
})
export class ScatterComponent implements OnInit {
  @Input('data') data: any[];
  @Input('xLabel') xLabel: string;
  @Input('yLabel') yLabel: string;
  @Input('dataLabel') dataLabel: string;
  @Input('dateMin') dateMin: Date;
  @Input('dateMax') dateMax: Date;
  private svg;
  private margin = 50;
  private width = 750 - (this.margin * 2);
  private height = 400 - (this.margin * 2);
  
  constructor() { 

  }
  ngOnInit(): void {
    this.createSvg();
    this.drawPlot();
  }
  
  multiFormat(date) {
    var formatMillisecond = d3.timeFormat(".%L"),
    formatSecond = d3.timeFormat(":%S"),
    formatMinute = d3.timeFormat("%I:%M"),
    formatHour = d3.timeFormat("%I %p"),
    formatDay = d3.timeFormat("%a %d"),
    formatWeek = d3.timeFormat("%b %d"),
    formatMonth = d3.timeFormat("%B"),
    formatYear = d3.timeFormat("%Y");
    return (d3.timeSecond(date) < date ? formatMillisecond
        : d3.timeMinute(date) < date ? formatSecond
        : d3.timeHour(date) < date ? formatMinute
        : d3.timeDay(date) < date ? formatHour
        : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
        : d3.timeYear(date) < date ? formatMonth
        : formatYear)(date);
  }

  private drawPlot(): void {
    const x = d3.scaleTime()
    .clamp(true)
    .domain([this.dateMin, this.dateMax])
    .range([ 0, this.width ]);
    this.svg.append("g")
    .attr("transform", "translate(0," + this.height + ")")
    .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%Y")));

    // Add Y axis
    const y = d3.scaleLinear()
    .domain([0, 1])
    .range([ this.height, 0]);
    this.svg.append("g")
    .call(d3.axisLeft(y));

    // Add dots
    const dots = this.svg.append('g');
    dots.selectAll("dot")
    .data(this.data)
    .enter()
    .append("circle")
    .attr("cx", d => x(new Date(d[this.xLabel]))) //"2021-10-05T17:26:17Z"
    .attr("cy", d => y(d[this.yLabel]))
    .attr("r", 7)
    .style("opacity", .5)
    .style("fill", "#69b3a2");

    // Add labels
    dots.selectAll("text")
    .data(this.data)
    .enter()
    .append("text")
    .text(d => d[this.dataLabel])
    .attr("x", d => x(new Date(d[this.xLabel])))
    .attr("y", d => y(d[this.yLabel]))
}

  private createSvg(): void {
    this.svg = d3.select("figure#scatter")
    .append("svg")
    .attr("width", this.width + (this.margin * 2))
    .attr("height", this.height + (this.margin * 2))
    .append("g")
    .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }
}
