import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { SnackService } from '../../../../services/extra/snack.service';
import { AdminService } from '../../../../services/admin.service';
import { CustomerService } from '../../../../services/customer.service';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '../../../../../shared';
import { Subscription } from 'rxjs';
const { v1: uuidv1 } = require('uuid');
const { v1: uuidv4 } = require('uuid');
@Component({
  selector: 'app-no-user-dialog',
  templateUrl: './no-user-dialog.component.html'
})
export class NoUserDialogComponent implements OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  _user: any;
  _customer: any;
  _customerId: string;
  public get user(): any {
    return this._user;
  }
  public set user(user: any) {
    this._user = user;
  }

  public get customer(): any {
    return this._customer;
  }
  public set customer(customer: any) {
    this._customer = customer;
  }

  public get customerId(): any {
    return this._customerId;
  }
  public set customerId(customerId: any) {
    this._customerId = customerId;
  }

  reqLoading = false;
  submitted = false;
  currentDate = new Date().getTime();
  username: string = '';
  types: any[];
  customerList: any[] = [];
  userPost: User = {
    basicName: '',
    basicDescription: '',
    code: this.removeHyphens(uuidv1()),
    contact_Address: '',
    contact_Cellphone: '',
    contact_Email: '',
    contact_Name: '',
    contact_Telephone: '',
    createdAt: null,
    customerId: '',
    updatedAt: null,
    isAvailable: 0,
    id: uuidv4(),
    deleted: false,
    username: '',
    version: null,
    languageTag: 'en-US',
    regionId: '',
    userTypeId: '',
    systemUser_Username: this.username,
    userSettingsInfoContent: {
      languageTag: "en-US",
      scoutVisibility: null,
      sensorVisibility: null,
      regions: [],
      subjects: [],
      units: [
        {
          name: "Temperature",
          value: "F"
        },
        {
          name: "SoilEC",
          value: "uS/cm"
        }
      ],
      scoutSettings: [],
      sensorSettings: []
    }
  };
  componentSubscription: Subscription;
  emailSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<NoUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackService: SnackService,
    private _adminService: AdminService,
    private _customerService: CustomerService
  ) {
    this.username = data;
    this.userPost.systemUser_Username = this.username;
    const getUserTypes = this._customerService.getUserTypes();
    const getCustomers = this._customerService.getAdminCustomers();
    forkJoin([
      getUserTypes,
      getCustomers
    ]).subscribe(results => {
      const type = results[0];
      const customers = results[1];
      this.types = type;
      this.customerList = customers;
      this.customer = customers[0];
      this.customerId = this.customer.id;
      this.userPost.userTypeId = this.types[this.types.length - 1].id;
      this.userPost.regionId = this.customer.regionId;
      this.userPost.customerId = this.customer.id;
      this.userPost.username = this.customer.username;
    })
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
  }

  onSubmit(): void {
    this.submitted = true;
    this.reqLoading = true;
    this.userPost.customerId = this.customer.id;
    this.componentSubscription = this._customerService.createUser(this.userPost).subscribe(c => {
      console.log(c);
      this.event.emit({ data: this.userPost });
      if (c.systemUser_Username) {
        this.emailSubscription = this._customerService.sendGridEmail(
          this.userPost.systemUser_Username,
          'support@earthscout.com',
          this.userPost.basicName)
          .subscribe(e => {
            console.log(e);
            this.reqLoading = false;
            this._snackService.displaySuccess('User created successfully');
            this.dialogRef.close(e);
          })
      }
    });
  }

  okayClick(): void {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeHyphens(code: string): string {
    const replaced = code.replace(/-/g, '');
    return replaced;
  }
}
