<div class="holder">
    <header>
      <div class="overlay">
        <h3 matTooltip="Manage scouts logs, widgets, and other helpful tools from the dashboard. Providing convenience of use tools across global app functions" matTooltipPosition="above">Dashboard</h3>
      </div>
    </header>
    <div class="container data-wrapper gray" style="height: 90vh; max-width: 100%;">
      <dashboard [dashboardId]="dashboardId"></dashboard>
      <!-- <app-scout-logs [logs]="logs" [loading]="loading" ></app-scout-logs> -->
      <!-- style="overflow: auto;  max-height: 25em;" -->
    </div>
  </div>