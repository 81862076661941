<div mat-dialog-content>
  <img src="../../../assets/images/earthscoutIcon.PNG" class="img-icon" style="max-height: 30px" />
  <b class="list-inline-item font-weight-bold text-uppercase">
    {{log.sequenceNumber}}
  </b>
  <b style="float: right;">{{ log.boardVersion }}</b>
  <div>
    <div>
      <b class="text dashboard-text font-weight-bold">Serial: {{ log.serial }}</b>
      <br />
      <i class="material-icons log-icon">timer</i>
      <b class="text dashboard-text">Log Recorded At:
        <b class="font-weight-bold">{{log.testResult.time | date: "MMM d, y, h:mm a"}}</b>
      </b>
      <br />
      <i class="material-icons log-icon" style="color: yellow">flash_on</i>
      <b class="text dashboard-text">
        Battery Voltage:
        <b class="font-weight-bold">{{ log.batteryVoltage }} V</b>
      </b>
      <br />
      <i class="material-icons log-icon" style="color: yellow">flash_on</i>
      <b class="text dashboard-text">
        Sensor Voltage:
        <b class="font-weight-bold">{{ log.sensorVoltage }} V</b>
      </b>
      <br />
      <i class="material-icons log-icon" style="color: yellow">flash_on</i>
      <b class="text dashboard-text">
        System Voltage:
        <b class="font-weight-bold">{{ log.systemVoltage }} V</b>
      </b>
      <br />
      <i class="material-icons log-icon" style="color: turquoise">invert_colors</i>
      <b class="text dashboard-text">
        Humidity:
        <b class="font-weight-bold">{{ log.humidity }} %</b>
      </b>
      <br />
      <i class="material-icons log-icon" style="color: red">device_thermostat</i>
      <b class="text dashboard-text">
        Temperature:
        <b class="font-weight-bold">{{ log.temperature }} C</b>
      </b>
      <br />
      <hr />
      <div style="max-height: 60px">
        <i *ngIf="log.validated" class="material-icons" style="color: #78be20; float: right;">done</i>
        <i *ngIf="!log.validated" class="material-icons" style="color: red; float: right">clear</i>
        <div *ngIf="!log.validatedBy" class="text dashboard-text">
          Not previously verified
        </div>
        <div *ngIf="log.validatedBy" class="text dashboard-text">
          Last verified by: {{ log.validatedBy }}
        </div>
      </div>
    </div>
  </div>
  <div>
    <p class="list-inline-item font-weight-bold text-uppercase" style="padding-left: 2.5vw">
      Sensors ({{ log.sensors.length }})
    </p>
    <br/>
    <li *ngFor="let sensor of log.sensors | sort: 'port'; let i = index" class="list-inline-item" id="grow">
      <div class="text dashboard-text">
        CubScoutBaseId:
        <b *ngIf="sensor.cubScoutBaseId; else naBase" class="font-weight-bold">{{ sensor.cubScoutBaseId }}</b>
        <ng-template #naBase>
          <b class="font-weight-bold">N/A</b>
        </ng-template>
      </div>
      <div *ngIf="sensor.testSensor === true; else prodSensor" class="text dashboard-text"
        style="padding-left: 5vw; color: orangered">
        Id: <b>{{ sensor.sensor }}</b>
      </div>
      <ng-template #prodSensor>
        <div class="text dashboard-text" style="padding-left: 5vw; color: #78be20">
          Id: <b>{{ sensor.sensor }}</b>
        </div>
      </ng-template>
      <div class="text dashboard-text" style="padding-left: 5vw">
        Data: <b>{{ sensor.data }}</b>
      </div>
    </li>
  </div>
  <hr style="margin-top: 0px" />
  <div (click)="clickTestResult(log)">
    <p class="list-inline-item font-weight-bold text-uppercase" style="padding-left: 2.5vw">
      Test Result
    </p>
    <div class="text dashboard-text">
      <b>{{ log.testResult.message }}</b>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <span>Actions</span>
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button style="color: #78be20" mat-menu-item [ngClass]="{ enabled: log.validatedBy }"
        (click)="createAssetsDialog(log)">
        <span>Create Assets</span>
      </button>
      <button style="color: #78be20" *ngIf="!log.validated; else unVerified" mat-menu-item (click)="logDialog(log)">
        <mat-icon style="color: #78be20">done</mat-icon>
        <span>Verify Unit</span>
      </button>
      <ng-template #unVerified>
        <button style="color: red" mat-menu-item (click)="logDialog(log)">
          <mat-icon style="color: red">clear</mat-icon>
          <span>Return</span>
        </button>
      </ng-template>
      <button mat-menu-item [ngClass]="{ enabled: !log.validatedBy && !log.validated }">
        <mat-icon>search</mat-icon>
        <span>Search</span>
      </button>
    </mat-menu>
  </div>
</div>