import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import * as d3 from "d3";
import { ReportsService } from "../../../shared/services/reports.service";
import { MatDatepicker } from "@angular/material/datepicker";
import { ConstantService } from "../../../shared";
import { FormGroup, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
export interface PacketScout {
  assetName: string;
  customerName: string;
  expectedPacketCount: number;
  failedPacketCount: number;
  interval: number;
  lastPayLoadDate: string;
  successfulPacketCount: number;
  totalPacketCount: number;
  uuid: string;
  percentage?: number;
}
@Component({
  selector: "app-pie",
  templateUrl: "./pie.component.html",
})
export class PieComponent implements OnInit, OnDestroy {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  private _data: any;
  @Input("data")
  public get data(): any {
    return this._data;
  }
  public set data(v: any) {
    this._data = v;
  }
  private svg;
  private margin = 50;
  private width = 750;
  private height = 600;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;
  private colors;
  availableScouts: any[] = [];
  scout: any;
  @Output() loadingEvent = new EventEmitter();
  startDate: Date;
  endDate: Date;
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  daysBack = 30;
  daysBackOptions = [
    { name: "1 Month Back", value: 30 },
    { name: "2 Months Back", value: 60 },
    { name: "3 Months Back", value: 90 },
    { name: "4 Months Back", value: 120 },
  ];
  minDate = new Date(2015, 2, 1);
  maxDate = new Date(Date.now());
  title: string;
  selectedDate: string;
  toolTip: string;
  lastPayloadDate: string;
  @ViewChild("picker") datePicker: MatDatepicker<Date>;
  xLabel: string;
  yLabel: string;
  dataLabel = "";
  componentSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    private _reportService: ReportsService,
    private _router: Router
  ) {
    this.title = `Scouts ${this.minDate.toLocaleDateString(
      "en-US"
    )} - ${this.maxDate.toLocaleDateString("en-US")}`;
    this.toolTip =
      "Scout reliability report shows the packet success rates for a given unit and the interval with last payload date. Click to refresh.";
  }

  ngOnInit(): void {
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    this.startDate = f;
    this.endDate = this.dateNow;
    const sDate = this._constService.convertDateUTC(this.startDate);
    const eDate = this._constService.convertDateUTC(this.endDate);
    this.initCall(sDate, eDate);
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  getPercentage = (p: PacketScout): number => {
    return Math.round((p.successfulPacketCount / p.totalPacketCount) * 100);
  };

  daysBackSelect(days: number) {
    this.loadingEvent.emit(true);
    this.daysBack = days;
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - days);
    const f = new Date(from);
    this.startDate = f;
    this.endDate = this.dateNow;
    const sDate = this._constService.convertDateUTC(this.startDate);
    const eDate = this._constService.convertDateUTC(this.endDate);
    this.initCall(sDate, eDate);
  }

  navigateToScout(scout: any) {
    this._router.navigate([`/scouts`]);
  }

  initCall(ut: any, utc: any) {
    this.selectedDate = `${this.startDate.toLocaleDateString( "en-US")} - ${this.endDate.toLocaleDateString("en-US")}`;
    // this.componentSubscription = this._reportService
    //   .getAdminScoutReliability(ut.toString(), utc.toString())
    //   .subscribe((r) => {
    //     this.availableScouts = r;
    //     const scoutDefault = r[0];
    //     const selectedScout = this.findScout(scoutDefault, r);
    //     const array = Object.getOwnPropertyNames(selectedScout);
    //     this.yLabel = array[9];
    //     this.xLabel = array[8];
    //     this.dataLabel = "assetName";
    //     this.scoutSelect(selectedScout);
    //     this.loadingEvent.emit(false);
    //   });
  }

  packetCall(ut: any, utc: any) {
    // this.componentSubscription = this._reportService
    //   .getAdminScoutReliability(ut.toString(), utc.toString())
    //   .subscribe((r) => {
    //     this.availableScouts = r;
    //     const selectedScout = this.findScout(this.scout, r);
    //     this.scoutSelect(selectedScout);
    //     this.loadingEvent.emit(false);
    //   });
  }

  findScout(scout: PacketScout, retrievedData: PacketScout[]): PacketScout {
    for (let i = 0; i < retrievedData.length; i++) {
      const r = retrievedData[i];
      r.percentage = this.getPercentage(r);
      if (scout.assetName == r.assetName) {
        return r;
      }
    }
    return scout;
  }

  reBuild() {
    this.deleteSvg();
    this.createSvg();
    this.createColors();
    this.drawChart();
    this.loadingEvent.emit(false);
    // ${this.scout.assetName} 
    this.title = `${this.startDate.toLocaleDateString( "en-US")} - ${this.endDate.toLocaleDateString("en-US")}`;
  }

  scoutSelect(scout: PacketScout) {
    this.loadingEvent.emit(true);
    this.lastPayloadDate = new Date(scout.lastPayLoadDate).toLocaleDateString(
      "en-US"
      );
      this.findScout(scout, this.availableScouts);
      this.scout = scout;
    if (scout.expectedPacketCount !== null) {
      console.log(scout.assetName);
    } else {
      scout.expectedPacketCount = 0;
    }
    const dataSet = [
      // { Name: 'expected', Value: scout.expectedPacketCount },
      {
        Name: `${scout.failedPacketCount} Failed`,
        Value: scout.failedPacketCount,
      },
      {
        Name: `${scout.successfulPacketCount} Successful`,
        Value: scout.successfulPacketCount,
      },
      // { Name: 'total', Value: scout.totalPacketCount},
    ];
    this.data = dataSet;
    this.reBuild();
  }

  private deleteSvg(): void {
    if (this.svg) {
      d3.select("figure#pie").select("svg").remove();
    }
  }

  private createSvg(): void {
    this.svg = d3
      .select("figure#pie")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr(
        "transform",
        "translate(" + this.width / 2 + "," + this.height / 2 + ")"
      );
  }

  private drawChart(): void {
    // Compute the position of each group on the pie:
    const pie = d3.pie<any>().value((d: any) => Number(d.Value));

    // Build the pie chart
    this.svg
      .selectAll("pieces")
      .data(pie(this.data))
      .enter()
      .append("path")
      .attr("d", d3.arc().innerRadius(0).outerRadius(this.radius))
      .attr("fill", (d, i) => this.colors(i))
      .attr("stroke", "#121926")
      .style("stroke-width", "1px");

    // Add labels
    const labelLocation = d3.arc().innerRadius(100).outerRadius(this.radius);

    this.svg
      .selectAll("pieces")
      .data(pie(this.data))
      .enter()
      .append("text")
      .text((d) => d.data.Name)
      .attr("transform", (d) => "translate(" + labelLocation.centroid(d) + ")")
      .style("text-anchor", "middle")
      .style("font-size", 15);
  }

  private createColors(): void {
    this.colors = d3
      .scaleOrdinal()
      .domain(this.data.map((d) => d.Value.toString()))
      .range(["#9BCBEB", "#99552B", "#FFA500", "#509E2F", "#666666"]);
  }

  changeEvent(event) {
    this.startDate = event.value;
  }

  changeEndEvent(event) {
    if (event.value !== null || event.value !== undefined) {
      this.endDate = event.value;
      if (this.endDate) {
        this.loadingEvent.emit(true);
        // this.daysBack = event.daySpan;
        const sDate = this._constService.convertDateUTC(this.startDate);
        const eDate = this._constService.convertDateUTC(this.endDate);
        this.selectedDate = `${this.startDate.toLocaleDateString( "en-US")} - ${this.endDate.toLocaleDateString("en-US")}`;
        this.packetCall(sDate, eDate);
      }
    }
  }
}
