import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { ConstantService } from '../../shared/services/constant.service';

@Injectable()
export class ManufacturingAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private _adalService: MsAdalAngular6Service,
        private _constantService: ConstantService,
        private _router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this._constantService._isSupport || this._constantService._isHardware) {
            return true;
        } else if (this._constantService._isTypeAdmin && this._adalService.isAuthenticated) {
            return true;
        } {
            return true;
        }
    }


    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(childRoute, state);
    }
}
