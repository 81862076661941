import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import { ConstantService, RootsActionMenuItem, RootsPageComponentConfig, RootsTableInterface } from "../../../shared";
import { ReportsService } from "../../../shared/services/reports.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SearchBarService } from "../../../shared/services/searchbar.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-generalreport",
  templateUrl: "./generalreport.component.html"
})
export class GeneralreportComponent implements RootsTableInterface, OnInit, OnDestroy {
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  daysBack = 30;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() scoutDirect = new EventEmitter<any>();
  @HostBinding("class.component-wrapper") defaultClass: boolean;
  auditLogs: AuditLogs[] = [];
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<AuditLogs>(),
    displayedColumns: [
      "customerName",
      "name",
      "email",
      "maxHitEndPoint",
      "endPointHitCount",
      "pings"
    ],
    message: ''
  };
  items: RootsActionMenuItem[] = [
    {
      name: "View User info",
      toolTip:
        'Navigate to more user details to expand on this user.',
      icon: "search",
      type: 10,
    }
  ];
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    public _dialog: MatDialog,
    private _reportService: ReportsService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "Users Report";
    this._config.toolTip = "Users report is for finding app useage for a given user to find out areas of interest. Click to refresh.";
  }

  ngOnInit() {
    //from
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    // to
    const utc = this._constService.convertDateUTC(this.dateNow);
    this.showAuditLogList(ut.toString(), utc.toString());

    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  showAuditLogList(dFrom: string, dTo: string) {
    this.componentSubscription = this._reportService
      .getAdminReportAuditLogs(dFrom, dTo)
      .subscribe((auditLog) => {
        console.log(auditLog);
        this.auditLogs = auditLog;
        if (auditLog !== null || auditLog !== undefined) {
          this._config.dataSource.data = this.auditLogs as AuditLogs[];
          this._config.loading = false;
        }
        setTimeout(() => (this._config.dataSource.paginator = this.paginator));
        //reFilter();
      });
  }

  refresh($event) {
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    // to
    const utc = this._constService.convertDateUTC(this.dateNow);
    this._config.loading = true;
    this.showAuditLogList(ut.toString(), utc.toString());
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.selectedTime = event.value;
  }

  topMenuActionsClicked(action) {
    switch (action.type) {
      case 3:
        break;
      default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        // this._config.loading = true;
      break;
      default:
        break;
    }
  }  

  dateChange(event: any) {
    console.log(event);
    this.daysBack = event.daySpan;
    const sDate = this._constService.convertDateUTC(event.startDate);
    const eDate = this._constService.convertDateUTC(event.endDate);
    this.showAuditLogList(sDate, eDate);
  }
}

export interface AuditLogs {
  username: string;
  email: string;
  totalPayload: number;
}
