import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { KnowledgeReference } from "../../shared/interfaces/knowledge-reference";
import { Router } from "@angular/router";
import { ReferenceFormComponent } from "./create/reference-form.component";
import { ReferencesService } from "../../shared/services/references.service";
import { SnackService } from "../../shared/services/extra/snack.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { SearchBarService } from "../../shared/services/searchbar.service";
import { ConfirmationDialogComponent } from "../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { RootsActionMenuItem, ActionType, RootsPageComponentConfig, RootsTableActionColumn } from "../../shared";
import { Subscription } from "rxjs";
import { PageInfoDialogComponent } from "../../shared/components/dialogs/page-info-dialog/page-info-dialog.component";

@Component({
  selector: "app-references",
  templateUrl: "./references.component.html"
})
export class ReferencesComponent implements OnInit, AfterViewInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "code",
      "basicName",
      "basicDescription",
      "scope",
      //'id',
      "content",
      "createdAt"
    ],
    message: "",
  };
  items: RootsActionMenuItem[] = [
    {
      name: "Reference Details",
      toolTip:
        "Navigate to this Reference's details",
      icon: "edit",
      type: 9,
    },
    {
      name: "URL Content",
      toolTip:
        "Navigate to this Reference's content",
      icon: "search",
      type: 10,
    },
    {
      name: "Delete Reference",
      toolTip:
        "Click to initiate reference deletion dialog",
      icon: "search",
      type: 11,
    },
  ];
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create Reference",
      icon: "add",
      toolTip:
        "Add New Knowledge reference",
      type: ActionType.Create,
    }
  ]
  actionColumns: RootsTableActionColumn[];
  references: KnowledgeReference[];
  reference: KnowledgeReference;
  referencesHolderArray: KnowledgeReference[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    componentSubscription: Subscription;
    searchSubscription: Subscription;
  constructor(
    private _referenceService: ReferencesService,
    public _dialog: MatDialog,
    private _router: Router,
    private _snack: SnackService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "References";
    this._config.toolTip =
      "Find details regarding knowledge references - subscription based and non-subscription based";
  }

  ngOnInit() {
    this.getReferences();
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this._config.dataSource);
      }
    });
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  refresh() {
    this._config.loading = true;
    this.getReferences();
  }

  getReferences() {
    this.componentSubscription = this._referenceService
      .getAppKnowledgeReferences(1, 7)
      .subscribe((references) => {
        this.referencesHolderArray = references;
        this.references = references;
        if (this.references.length > 0) {
          this._config.loading = false;
          this._snack.displaySuccess("References loaded");
          this.actionColumns = [
            {
              name: "code",
              textButton: false
            },
            {
              name: "content",
              textButton: true,
              type: 5,
              class: "mat-8",
              toolTip: "Navigate to this content's link",
            },
            {
              name: "basicName",
              textButton: false
            },
            {
              name: "scope",
              textButton: false
            },
            {
              name: "details",
              icon: "edit",
              type: ActionType.Link,
              class: "mat-18",
              toolTip: "Navigate to find more reference details",
            },
            {
              name: "delete",
              icon: "delete",
              type: ActionType.Delete,
              class: "mat-18",
              toolTip: "Click to initiate delete dialog",
            },
          ];
        }
        this._config.dataSource.data = references as KnowledgeReference[];
        this._config.dataSource.data = this._config.dataSource.data.sort((a, b) => {
          return a.scope < b.scope ? -1 : a.scope > b.scope ? 1 : 0;
        });
        console.log(this.references);
      });
  }

  deleteDialog(ref: KnowledgeReference): void {
    // console.log(ref);
    this._config.loading = true;
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Delete ${ref.basicName}?`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.componentSubscription = this._referenceService
          .deleteKnowledgeReference(ref.id)
          .subscribe((d) => {
            this._snack.displaySuccess(
              "Reference deleted: " + ref.basicDescription
            );
            this.componentSubscription = this._referenceService
              .getKnowledgeReferences()
              .subscribe((references) => {
                this.referencesHolderArray = references;
                this.references = references;
                this._config.dataSource.data = references as KnowledgeReference[];
                this._config.loading = false;
              });
          });
      }
    });
  }

  openDialog(): void {
    this._config.loading = true;
    const dialogRef = this._dialog.open(ReferenceFormComponent, {
      width: "60vw",
      data: "Create Reference",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        var that = this;
        // console.log(result);
        this._snack.displaySuccess("Reference created... Repopulating");
        setTimeout(function () {
          that.rePopulate();
        }, 5000);
      }
      this._config.loading = false;
    });
  }

  openInfoDialog(): void {
    const dialogRef = this._dialog.open(PageInfoDialogComponent, {
      width: "60vw",
      data: {
        name: this._config.title,
        description: 'This page allows you to create, remove, update, and receive references',
      },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
    });
  }

  rePopulate() {
    this._config.loading = true;
    this.componentSubscription = this._referenceService.getKnowledgeReferences().subscribe((references) => {
      console.log(this.references);
      this.referencesHolderArray = references;
      this.references = references;
      this._config.dataSource.data = references as KnowledgeReference[];
      this._config.loading = false;
    });
  }

  
  topActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.openDialog();
        break;
      case 5:
        this.openInfoDialog();
        break;
        default:
        break;
    }
  }

  actionsClicked(action) {
    const type = action[0];;
      const item = action[1];
    switch (type.type) {
      case 2:
        this.deleteDialog(item);
      case 4:
        this.navigateToReference(item);
      case 5:
        this.navigateToReferenceContent(item);
        break;
      default:
        break;
    }
    // const type = action.type;
    //   const item = action.entity;
    // switch (type) {
    //   case 2:
    //     this.deleteDialog(item);
    //   case 4:
    //     this.navigateToReference(item);
    //   case 5:
    //     this.navigateToReferenceContent(item);
    //     break;
    //   default:
    //     break;
    // }
  }

  navigateToReference(reference: KnowledgeReference) {
    this._router.navigate([`/references/${reference.id}`]);
  }

  navigateToReferenceContent(reference: KnowledgeReference) {
    window.open(reference.content);
  }
}
