<!doctype html>
<html>

<head>
    <meta name="viewport" content="width=device-width" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Simple Transactional Email</title>
    <style>
        /* -------------------------------------
            GLOBAL RESETS
        ------------------------------------- */

        /*All the styling goes here*/

        img {
            border: none;
            -ms-interpolation-mode: bicubic;
            max-width: 100%;
        }

        body {
            background-color: #f6f6f6;
            background: #ffffff !important;
            font-family: 'Rubik', sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.4;
            margin: 0;
            padding: 0;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            max-width: 100%;
            overflow: visible; 
            height: fit-content;
            align-items: center;
        }

        table {
            border-collapse: separate;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            width: 100%;
        }

        table td {
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            vertical-align: top;
        }

        /* -------------------------------------
            BODY & CONTAINER
        ------------------------------------- */
        
        .body {
            background-color: #f6f6f6;
            width: fit-content;
            padding-left: 5%;
            padding-right: 5%;
            margin: 10px;
        }

        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
            display: flex;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            /* makes it centered */
            max-width: 100%;
            width: 100%;
        }

        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
            box-sizing: border-box;
            display: block;
            margin: 0 auto;
            max-width: 100%;
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 10px;
            height: 100%;
        }

        /* -------------------------------------
            HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
        }

        .wrapper {
            box-sizing: border-box;
            padding: 20px;
        }

        .content-block {
            padding-bottom: 10px;
            padding-top: 10px;
        }

        .footer {
            clear: both;
            margin-top: 10px;
            text-align: center;
            width: 100%;
        }

        .footer td,
        .footer p,
        .footer span,
        .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center;
        }

        /* -------------------------------------
            TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
            color: #000000;
            font-family: 'Rubik', sans-serif;
            font-weight: 400;
            line-height: 1.4;
            margin: 0;
            margin-bottom: 30px;
        }

        h1 {
            font-size: 35px;
            font-weight: 300;
            text-align: center;
            text-transform: capitalize;
        }

        p,
        ul,
        ol {
            font-family: 'Rubik', sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
            margin-bottom: 15px;
        }

        p li,
        ul li,
        ol li {
            list-style-position: inside;
            /* margin-left: 5px; */
        }

        a {
            color: #3498db;
            text-decoration: underline;
        }

        /* -------------------------------------
            BUTTONS
        ------------------------------------- */
        .butn {
            box-sizing: border-box;
            width: 100%;
        }

        .butn>tbody>tr>td {
            padding-bottom: 15px;
        }

        .butn table {
            width: auto;
        }

        .butn table td {
            background-color: #ffffff;
            border-radius: 5px;
            text-align: center;
        }

        .butn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize;
        }

        .butn-primary table td {
            background-color: #3498db;
        }

        .butn-primary a {
            background-color: #3498db;
            border-color: #3498db;
            color: #ffffff;
        }

        /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .no-margin {
            margin: 0 !important;
        }

        .last {
            margin-bottom: 0;
        }

        .first {
            margin-top: 0;
        }

        .align-center {
            text-align: center;
        }

        .align-right {
            text-align: right;
        }

        .align-left {
            text-align: left;
        }

        .clear {
            clear: both;
        }

        .mt0 {
            margin-top: 0;
        }

        .mb0 {
            margin-bottom: 0;
        }

        .preheader {
            color: transparent;
            display: none;
            height: 0;
            max-height: 0;
            max-width: 0;
            opacity: 0;
            overflow: hidden;
            mso-hide: all;
            visibility: hidden;
            width: 0;
        }

        .powered-by a {
            text-decoration: none;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #f6f6f6;
            margin: 20px 0;
        }

        /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
            table[class=body] h1 {
                font-size: 28px !important;
            }

            table[class=body] p,
            table[class=body] ul,
            table[class=body] ol,
            table[class=body] td,
            table[class=body] span,
            table[class=body] a {
                font-size: 16px !important;
            }

            table[class=body] .wrapper,
            table[class=body] .article {
                padding: 10px !important;
            }

            table[class=body] .content {
                padding: 0 !important;
            }

            table[class=body] .container {
                padding: 0 !important;
                width: 100% !important;
            }

            table[class=body] .main {
                border-left-width: 0 !important;
                border-radius: 0 !important;
                border-right-width: 0 !important;
            }

            table[class=body] .butn table {
                width: 100% !important;
            }

            table[class=body] .butn a {
                width: 100% !important;
            }

            table[class=body] .img-responsive {
                height: auto !important;
                max-width: 100% !important;
                width: auto !important;
            }
        }

        /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
            .ExternalClass {
                width: 100%;
            }

            .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
                line-height: 100%;
            }

            .apple-link a {
                color: inherit !important;
                font-family: inherit !important;
                font-size: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
                text-decoration: none !important;
            }

            #MessageViewBody a {
                color: inherit;
                text-decoration: none;
                font-size: inherit;
                font-family: inherit;
                font-weight: inherit;
                line-height: inherit;
            }

            .butn-primary table td:hover {
                background-color: #34495e !important;
            }

            .butn-primary a:hover {
                background-color: #34495e !important;
                border-color: #34495e !important;
            }
        }
    </style>
</head>

<body class="">
    <span class="preheader">You have been invited to join <b>EarthScout.</b></span>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
            <td class="container">
                <div class="content">

                    <!-- START CENTERED WHITE CONTAINER -->
                    <table role="presentation" class="main">

                        <!-- START MAIN CONTENT AREA -->
                        <tr>
                            <td class="wrapper">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                        <td>
                                            <!-- inviter-tag: who sent invite from admin -->
                                            <p id="inviter-tag"><b>{{name}}</b></p>
                                            <!-- invitee-tag: user being invited -->
                                            <p>You have been invited to join <b>EarthScout.</b>
                                                Please click on the link below to complete sign-up process.
                                            </p>
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0"
                                                class="butn butn-primary">
                                                <tbody>
                                                    <tr>
                                                        <td align="left">
                                                            <table role="presentation" border="0" cellpadding="0"
                                                                cellspacing="0" style="background-color: grey;">
                                                                <tbody>
                                                                    <tr>
                                                                        <td> <a style="background-color:rgb(111,192,64)"
                                                                                href="https://scout.earthscout.com"
                                                                                target="_blank">
                                                                                Sign-up
                                                                            </a>
                                                                            <!-- href="https://scout.industrialsmart.com"
                                                                            href="https://dev.scout.earthscout.com"
                                                                            href="https://scout.earthscout.com" -->
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <hr>
                                            <p style="font-size:10pt;font-family:Roboto;color:rgb(102,102,102);">
                                                With EarthScout account, you will be able to manage,
                                                visualize and analyze your EarthScout(s) and their data in <a
                                                    href="https://scout.industrialsmart.com">web</a>,
                                                <a
                                                    href="https://apps.apple.com/us/app/earth-scout-is/id1448035880">iOS</a>
                                                and
                                                <a
                                                    href="https://play.google.com/store/apps/details?id=com.industrialsmart.scoutmobile">Android</a>
                                                apps.
                                            </p>
                                            <p class="MsoNormal"
                                                style="background-image:initial;background-position:initial;background-repeat:initial">
                                                <b>
                                                    <span
                                                        style="font-size:10pt;font-family:Trebuchet;color:rgb(111,192,64);background-image:initial;
                                                                                background-position:initial;background-repeat:initial">
                                                        Thank you
                                                        <!-- <a href="http://www.earthscout.com/"
                                                            style="color:rgb(17,85,204)" target="_blank"
                                                            data-saferedirecturl="https://www.google.com/url?q=http://www.earthscout.com/&amp;source=gmail&amp;ust=1584625080244000&amp;usg=AFQjCNFTaHhhBXQ8J-KqKjg43H5sm145IQ">
                                                            www.earthscout.com</a> -->
                                                    </span></b>
                                            </p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <!-- END MAIN CONTENT AREA -->
                    </table>
                    <!-- END CENTERED WHITE CONTAINER -->
                    <!-- START FOOTER -->
                    <div class="footer">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                            <tr>
                                <td class="content-block">
                                    <div dir="ltr">
                                        <p class="MsoNormal"
                                            style="background-image:initial;background-position:initial;background-repeat:initial">
                                            <b><span
                                                    style="font-family:Trebuchet;color:rgb(111,192,64);background-image:initial;background-position:initial;background-repeat:initial">
                                                    EarthScout Support Team</span></b><span
                                                style="color:black"><u></u><u></u></span>
                                        </p>
                                        <p class="MsoNormal"
                                            style="background-image:initial;background-position:initial;background-repeat:initial">
                                            <span
                                                style="font-size:10pt;font-family:Trebuchet;color:rgb(102,102,102);background-image:initial;background-position:initial;background-repeat:initial">
                                                575 9th Street SE, Suite 205, Minneapolis, MN 55414
                                                <br>
                                                https://www.earthscout.com/contact/</span>
                                            <span
                                                style="font-size:10.5pt;font-family:Roboto;background-image:initial;background-position:initial;background-repeat:initial"><br>
                                            </span>
                                        </p>
                                        <p class="MsoNormal no-margin"
                                            style="background-image:initial;background-position:initial;background-repeat:initial">
                                            <span
                                                style="font-size:10pt;font-family:Trebuchet;background-image:initial;background-position:initial;background-repeat:initial">
                                                <font color="#6fc040"><b>C&nbsp;</b></font>
                                            </span><span
                                                style="font-size:10pt;font-family:Trebuchet;color:rgb(102,102,102);background-image:initial;background-position:initial;background-repeat:initial">
                                                1-877-443-7632</span><span
                                                style="color:rgb(33,33,33)"><u></u><u></u></span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- END FOOTER -->

                </div>
            </td>
        </tr>
    </table>
</body>

</html>