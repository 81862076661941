<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
>
</roots-page-header>
<hr>
<div class="data-wrapper gray">
  <!-- basicDescription: "Firmware Instance file for Cub v1.1.0"
basicName: "FirmwareInstance Cub v1.1.0"
code: "fwi1.1.0"
content: null
createdAt: "2021-07-02T19:47:37.003Z"
deleted: false
file: ""
fileBin: ""
firmwareModelId: "DAB5644A-DC70-458C-8A1E-F9FABB6DEDAD"
id: "CD447E7E-8A3B-4554-BF11-43A1C47CF72E"
isAvailable: 1
updatedAt: "2021-10-18T16:38:23.94Z"
username: "setup"
version: "AAAAAAHRdKw=" -->
  <mat-card class="col-12" style="float: left;" *ngIf="firmware">
    <h3 class="text label-header-text">Details</h3>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" [(ngModel)]="firmware.basicName" required />
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input
            matInput
            placeholder="Description"
            [(ngModel)]="firmware.basicDescription"
            required
          />
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>Code</mat-label>
          <input matInput placeholder="Code" [(ngModel)]="firmware.code" />
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>File</mat-label>
          <input
            matInput
            placeholder="Firmware file"
            [(ngModel)]="firmware.file"
            required
            disabled
          />
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>Created At</mat-label>
          <input
            matInput
            placeholder="Created At"
            disabled
            [(ngModel)]="firmware.createdAt"
            required
          />
        </mat-form-field>
        
        <!-- <mat-form-field>
          <mat-label>Scouts Assigned to this Firmware</mat-label>
          <mat-select matInput placeholder="Current Scouts (Assets)" required [(ngModel)]="assetModels[0].id" 
                  name="assetModel">
                  <mat-option *ngFor="let model of assetModels" [value]="model.id">
                      {{ model.basicName }}
                  </mat-option>
              </mat-select>
        </mat-form-field> -->
        
        <mat-form-field>
          <mat-label>Model Id</mat-label>
          <input
            matInput
            disabled
            placeholder="FWM"
            [(ngModel)]="firmware.whenEnd"
            required
          />
        </mat-form-field>
        
        <button
          mat-raised-button
          type="submit"
          style="max-width: fit-content"
          color="warn"
          (click)="onNoClick()"
        >
          Cancel
        </button>
        <button
          mat-raised-button
          type="submit"
          style="max-width: fit-content"
          color="primary"
          (click)="onUpdate(firmware)"
        >
          Update
        </button>
    </mat-card>
    <mat-label>Firmwares Models</mat-label>
    <roots-table
    class="col-12" style="float: left;"
    [tableColumns]="_config.displayedColumns"
    [dataSource]="_config.dataSource"
    [filter]="_config.message"
    [csv]="_config.title"
    [clickingEnabled]="true">
    </roots-table>
</div>
