import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ScoutLogs } from '../../../../shared/interfaces/scout-logs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormControl } from '@angular/forms';
import { ConstantService, RootsPageComponentConfig } from '../../../../shared';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SnackService } from '../../../../shared/services/extra/snack.service';
import { ReportsService } from '../../../../shared/services/reports.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SearchBarService } from '../../../../shared/services/searchbar.service';
import { RootsActionMenuItem, ActionType } from '../../../../shared/interfaces/roots-action-menu-item';
import { RootsTableActionColumn } from '../../../../shared/interfaces/roots-table-action-column';
import { RootsTableInterface } from '../../../../shared/components/roots-table/roots-table.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scout-logs',
  templateUrl: './scout-logs.component.html'
})
export class ScoutLogsComponent implements RootsTableInterface, OnInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<ScoutLogs>(),
    displayedColumns: [
      'indsma',
      // 'email',
      'username',
      'lastPayLoadDate',
      'lastBatteryVoltage',
      'internalBoxTemperature',
      'relativeHumidity'
    ],
    message: ''
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Query DateTime",
      icon: "add",
      toolTip:
        "Create new TestSensor in db - this will be flagged for incoming scout logs",
      type: ActionType.Dialog,
    }
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "View Last Sensor Data",
      toolTip:
        'View last sensor data received foreach sensor',
      icon: "search",
      type: 10,
    }
  ];
  // actionButtons: RootsActionMenuItem[] = [
  //   {
  //     name: "Choose Date",
  //     icon: "calendar_today",
  //     toolTip:
  //       "Choose a query date for packet report",
  //     type: ActionType.Dialog,
  //   },
  // ];
  actionColumns: RootsTableActionColumn[];
  dateNow = new Date((Date.now()));
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() scoutDirect = new EventEmitter<any>();
  daysBack = 30;
  scoutLogs: ScoutLogs[] = [];
  events: string[] = [];
  loading = true;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  minDate = new Date(2015, 2, 1);
  maxDate = new Date(Date.now());
  startDate: Date;
  endDate: Date;
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    private _router: Router,
    public _dialog: MatDialog,
    private _snack: SnackService,
    private _reportService: ReportsService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = `Scouts ${this.minDate.toLocaleDateString('en-US')} - ${this.maxDate.toLocaleDateString('en-US')}`;
    this._config.toolTip =
      "Scout general reports shows last payload date with general information for the unit. Click to refresh.";
   }

  ngOnInit(): void {
    //from 
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    // to
    const utc = this._constService.convertDateUTC(this.dateNow);
    this.showScoutLogList(ut.toString(), utc.toString());
    if (this.loading) {
      this._snack.displayLoading('Scout report loading...');
    }
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }
  
  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  topMenuActionsClicked(action) {
    switch (action.type) {
      case 3:
        break;
      default:
        break;
    }
  }
  
  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 3:
        this.datePicker.open();
        break;
      default:
        break;
    }
  }

  exportCsv(){
    this._constService.exportCsv(this._config.dataSource.data, 'Scout General Report')
  }

  changeEndEvent(event) {
    if (event.value !== null || event.value !== undefined) {
      this.endDate = event.value;
      const sDate = this._constService.convertDateUTC(this.startDate);
      const eDate = this._constService.convertDateUTC(this.endDate);
      this._config.title = `Scouts ${this.startDate.toLocaleDateString('en-US')} - ${this.endDate.toLocaleDateString('en-US')}`;
      this.showScoutLogList(sDate, eDate);
    }
  }

  changeEvent(event){
      this.startDate = event.value;
  }

  showScoutLogList(dFrom: string, dTo: string) {
    this.actionColumns = [
      {
        name: "indsma",
      },
      {
        name: "authUsername",
      },
      {
        name: "lastPayLoadDate",
      },
      {
        name: "lastBatteryVoltage",
      },
      {
        name: "internalBoxTemperature",
      },
      {
        name: "relativeHumidity",
      }
    ];
    this.componentSubscription = this._reportService.getAdminReportScoutLogs(dFrom, dTo)
    .subscribe(auditLog => {
      console.log(auditLog);
      this.scoutLogs = auditLog;
      if (auditLog !== null || auditLog !== undefined) {
        this._config.dataSource.data = this.scoutLogs as ScoutLogs[];
        this.loading = false;
        if (this._config.dataSource.data.length < 1) {
          this._snack.displayError('No reports found');
        } else {
          this._snack.displaySuccess('Scout report loaded');
        }
      }
      setTimeout(() => (this._config.dataSource.paginator = this.paginator));
      //reFilter();
    })
  }

  refresh($event) {
    this.loading = true;
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    const utc = this._constService.convertDateUTC(this.dateNow);

    this.showScoutLogList(ut.toString(), utc.toString());
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.selectedTime = event.value;
  }

  dateChange(event: any) {
    console.log(event);
    this.daysBack = event.daySpan;
    const sDate = this._constService.convertDateUTC(event.startDate);
    const eDate = this._constService.convertDateUTC(event.endDate);
    this.showScoutLogList(sDate, eDate);
  }

  valueChanged(event: any) {
    console.log(event);
  }

  navigateToCustomer(customerId: string) {
    console.log(customerId);
    this._router.navigate([`/customers/${customerId}/assets`]);
  }
}
