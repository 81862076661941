import env from './.env';

// navigate to https://www.twilio.com/console/project/settings
// Twilio Credentials: developer@industrialsmart.com, password: Donotforget12#
export const environment = {
  production: false,
  test: true,
  protectedRoutes: false,
  version: env.package_version,
  supportedLanguages: [
  'en',
  'ne',
  'ru'
  ],
  config: {
    tenant: 'earthscout.onmicrosoft.com',
    clientId: '938bb782-2f82-4b38-90a4-ded620c32ed1',
    redirectUri: 'https://dev.admin.earthscout.com' + '/auth-callback',
    postLogoutRedirectUri: 'https://dev.admin.earthscout.com' + '/login'
    // redirectUri: 'https://eadmin-dev.azurewebsites.net' + '/auth-callback',
    // postLogoutRedirectUri: 'https://eadmin-dev.azurewebsites.net' + '/login'
  },
  scoutLogApiUrl: 'https://efproductionscoutlogsdevelopment.azurewebsites.net/api/',
  SCOUTLOG_API_KEY: 'rWnjenR73Dcif9TRaABOYT6CueQWUqrBWvdIYvXDBmK4M///sIBysQ==',
  apiUrl: 'https://escoutd.azurewebsites.net/',
  reportsApiUrl: 'https://escoutpreports.azurewebsites.net/',
  mobile: false,
  SENDGRID_API_KEY: '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'
};
