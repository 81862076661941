import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-liquid-title',
  templateUrl: './liquid-title.component.html',
  styleUrls: ['./liquid-title.component.scss']
})
export class LiquidTitleComponent implements OnInit {
  @Input('text') text: string;
  constructor() {
  }
  
  ngOnInit(): void {
  }
}
