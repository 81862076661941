<div class="row">
    <span>
        <!-- <button mat-raised-button color="primary" (click)="cancel()">
            Cancel
        </button> -->
    </span>
</div>
<div class="example-container container data-wrapper gray" style="height: 50vw">
    <video id="video"></video>
    <div [hidden]="!barcodeValue">
        {{barcodeValue}}
    </div>
</div>