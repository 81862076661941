import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { XferFormComponent } from '../../../../modules/customer';
import { AdminService } from '../../../services/admin.service';
import { ConstantService } from '../../../services/constant.service';
import { CustomerService } from '../../../services/customer.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-verify-log-dialog',
  templateUrl: './verify-log-dialog.component.html',
  styleUrls: ['./verify-log-dialog.component.scss']
})
export class VerifyLogDialogComponent implements OnDestroy {
  selectedAsset: any;
  loading = false;
  componentSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<VerifyLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public response: any,
    public _dialog: MatDialog,
    private _router: Router,
    private _adminService: AdminService,
    private _customerService: CustomerService,
    private _constService: ConstantService
    ) {
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  navigateToAsset(asset: any) {
    this._router.navigate([`/assets/${asset.id}`]);
  }

  pullOwnership(asset: any) {
    this.loading = true;
    asset.isHealthSensor = false;
    this.componentSubscription = this._adminService.getScoutAssetOwnershipHistory(asset.id).subscribe(results => {
      console.log(results);
      asset.history = results.reverse();
      if (asset.code.includes('_HS')) {
        asset.isHealthSensor = true;
      }
      if (results.length < 1) {
        asset.noOwnership = true;
        asset.openForAssignment = true;
      } else {
        asset.noOwnership = false;
        if (asset.history[0].whenEnd === null) {
          asset.openForAssignment = false;
        } else {
          asset.openForAssignment = true;
        }
      }
        this.loading = false;
    })
  }

  navigateToDialog (asset: any) {
    this.loading = true;
    if (asset) {
      const dialogRef = this._dialog.open(XferFormComponent, {
        width: "60vw",
        data: { asset }
      });
      dialogRef.componentInstance.event.subscribe(result => {
        if (result.data) {
          this.pullOwnership(asset);
          this.loading = false;
        }
      });
      console.log('change asset: ' + asset);
    }
  }

  deleteDialog(assetOwnership: any, customerName: string): void {
    this.loading = true;
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `You are about to terminate an assets ownership from customer: ${customerName}.
             Please confirm ownership termination for asset: ${assetOwnership.name}.`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
        if (assetOwnership.history[0].whenEnd === null) {
          assetOwnership.currentAssetOwnershipId = assetOwnership.history[0].id;
          this.endOwnership(assetOwnership);
        }
      }
    });
  }
  
  endOwnership(assetOwnership: any) {
    const healthSensor = this.findHealthSensor(assetOwnership.code);
    const patchAsset: any = {
      whenEnd: this._constService.convertDateUTC(new Date()),
    };
    if (healthSensor === null || healthSensor === undefined) {
      this.componentSubscription = this._customerService
      .removeOwnership(assetOwnership.currentAssetOwnershipId, patchAsset)
      .subscribe((update) => {
        console.log(update);
          this.pullOwnership(assetOwnership);
          this.loading = false;
        });
    } else {
      const removeScout = this._customerService.removeOwnership(
        assetOwnership.currentAssetOwnershipId,
        patchAsset
      );
      healthSensor.currentAssetOwnershipId = healthSensor.history[0].id;
      const removeHealth = this._customerService.removeOwnership(
        healthSensor.currentAssetOwnershipId,
        patchAsset
      );

      forkJoin([removeScout, removeHealth]).subscribe((results) => {
        const scoutRemoved = results[0];
        const healthRemoved = results[1];
        this.pullOwnership(assetOwnership);
        this.loading = false;
      });
    }
  }

  findHealthSensor(code: string): any {
    const healthSensor = code + "_HS";
    let sensorFound: any;
    if (this.response.existingAssets.length > 0) {
      this.response.existingAssets.find((asset) => {
        if (asset.code.includes(healthSensor)) {
          sensorFound = asset;
          return sensorFound;
        } else {
          sensorFound = null;
          return sensorFound;
        }
      });
    }
    if (this.response.createdAssets.length > 0) {
      this.response.createdAssets.find((asset) => {
        if (asset.code.includes(healthSensor)) {
          sensorFound = asset;
          return sensorFound;
        } else {
          sensorFound = null;
          return sensorFound;
        }
      });
    }
    return sensorFound;
  }

  navigateToCustomer(aso: any) {
    this._router.navigate([`/customers/${aso.customerId}`]);
  }

  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
