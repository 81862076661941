<div class="form-container">
  <div class="form-box" style="position: fixed;">
    <img src="../../assets/svgs/logo.svg" class="col-12" style="width: 25%;"
    width="25%" height="25%" alt="earthscout logo"/>
    <img id="roots-back-image" width="100%" height="100%" src="../../assets/svgs/plants-original.svg" 
    style="position: absolute;" class="col-12" alt="experimental plant roots background"/>
    <div>
    </div>
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h1 id="title" class="char" style="color: #F1F2F2; margin: auto;
          line-height: normal; text-align: center;">
          ROOTS
        </h1>
          <div *ngIf="userName">
            <p class="designation" style="margin: 0px">EMAIL: {{ userName }}</p>
            <p class="designation" style="margin: 0px">IP: {{ currentIp }}</p>
          </div>
          <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
            <div class="alert alert-danger" [hidden]="!error || isLoading">
              Username or password incorrect.
            </div>
            <br>
            <button class="btn btn-primary w-100 mb-3" type="button" (click)="azureLogin()" style="background-color: #007041">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span>Login</span>
            </button>
            <button class="btn btn-primary w-100 mb-3" type="button" (click)="logout()" style="background-color: #007041">
              <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
              <span>Clear Cache</span>
            </button>
            <br>
            <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            <p *ngIf="userName" class="designation alert alert-danger" style="margin: 0px">
              Not able to login with your account? Please contact a 
              <a (click)="sendNotification()"
                matTooltip="Click to send a notification to an Admin"
                matTooltipPosition="below">
                software Admin.
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>