import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { ActionType, ConstantService, RootsActionMenuItem, RootsPageComponentConfig } from "../../../shared";
import { SnackService } from "../../../shared/services/extra/snack.service";
import { ReportsService } from "../../../shared/services/reports.service";
import { PacketLogs } from "../../../shared/classes/packetlogs";
import { PacketDialogComponent } from "../../../shared/components/dialogs";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { SearchBarService } from "../../../shared/services/searchbar.service";
import { RootsTableInterface } from "../../../shared/components/roots-table/roots-table.interface";
import { Subscription } from "rxjs";

@Component({
  selector: "app-packetreport",
  templateUrl: "./packetreport.component.html"
})
export class PacketreportComponent implements RootsTableInterface, OnInit, OnDestroy {
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<PacketLogs>(),
    displayedColumns: [
      "assetName",
      "createdAt",
      "when",
      "parserMessage",
      "value"
    ],
    message: ''
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Choose Date",
      icon: "calendar_today",
      toolTip:
        "Choose a query date for packet report",
      type: ActionType.Dialog,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "View Packet Value",
      toolTip:
        'View the packet value in an expanded dialog',
      icon: "search",
      type: 10,
    }
  ];
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  selectedTime;
  datePickerTime: Date;
  customerId = "";
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() scoutDirect = new EventEmitter<any>();
  daysBack = 90;
  packets: PacketLogs[] = [];
  selectedDate: string;
  refreshDate = "";
  @ViewChild('picker') datePicker: MatDatepicker<Date>;
  componentSubscription: Subscription;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    public _dialog: MatDialog,
    private _snack: SnackService,
    private _reportService: ReportsService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "Packets Report";
    this._config.toolTip =
      "Packets report show recent packages from units that contain issues. Click to refresh.";
  }

  ngOnInit() {
    //from
    const from = this.dateFrom.setDate(this.dateFrom.getDate() - this.daysBack);
    const f = new Date(from);
    const ut = this._constService.convertDateUTC(f);
    this.refreshDate = ut.toString();
    this.showPacketList(ut.toString());
    if (this._config.loading) {
      this._snack.displayLoading("Packet report loading...");
    }
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  showPacketList(dFrom: string) {
    this.componentSubscription = this._reportService.getAdminPacketsReport(dFrom).subscribe((packets) => {
      this.packets = packets;
      if (packets !== null || packets !== undefined) {
        this._config.dataSource.data = this.packets as PacketLogs[];
        this._config.loading = false;
        const from = new Date(dFrom);
        this._config.title = `(${this._config.dataSource.data.length}) Packets - ${from.toLocaleDateString('en-US')}`;
        this._snack.displaySuccess("Packet report loaded");
      }
      setTimeout(() => (this._config.dataSource.paginator = this.paginator));
    });
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 10:
        this.logDialog(item)
        break;
      default:
        break;
    }
  }

  
topMenuActionsClicked(action) {
  switch (action.type) {
    case 3:
      this.datePicker.open();
      break;
    default:
      break;
  }
}

  valueChanged(event: any) {
    const sDate = this._constService.convertDateUTC(event.value);
    this.selectedDate = event.value.toString();
    this.showPacketList(sDate);
    this._config.loading = true;
  }

  refresh($event) {
    this._config.loading = true;
    this.componentSubscription = this._reportService
      .getAdminPacketsReport(this.refreshDate)
      .subscribe((packets) => {
        console.log(packets);
        // this._snack.displaySuccess('Packet loading');
        this.packets = packets;
        if (packets !== null || packets !== undefined) {
          this._config.dataSource.data = this.packets as PacketLogs[];
          this._config.loading = false;
          this._snack.displaySuccess("Packet report loaded");
        }
        setTimeout(() => (this._config.dataSource.paginator = this.paginator));
      });
  }

  logDialog(log: PacketLogs): void {
    const dialogRef = this._dialog.open(PacketDialogComponent, {
      width: "75vw",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        console.log(result + " : report viewed");
      }
    });
  }
}
