import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { CubVerifyDialogComponent } from "../../../../shared/components/dialogs/cub-verify-dialog/cub-verify-dialog.component";
import { environment } from "../../../../../environments/environment";
import { ConstantService, RootsActionMenuItem, RootsPageComponentConfig } from "../../../../shared";
import { ScoutLogExtended } from "../../../../shared/interfaces/scout-log-extended";
import { SnackService } from "../../../../shared/services/extra/snack.service";
import { ScoutLogService } from "../../../../shared/services/scout-log.service";
import { SearchBarService } from "../../../../shared/services/searchbar.service";
import { ProdLogCubDialogComponent } from "../../dialogs/prodlog-cub-dialog/prodlog-cub-dialog.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-cub-scout-logs",
  templateUrl: "./cub-scout-logs.component.html"
})
export class CubScoutLogsComponent implements OnInit, OnDestroy {
  scoutLogsForSerial: [] = [];
  server = environment.scoutLogApiUrl;
  serial: string;
  request: Request;
  logs: any[] = [];
  _config: RootsPageComponentConfig = {
    loading: true,
    title:'',
    toolTip:'',
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: ["time", "sequenceNumber", "productLine", "serial", "boardVersion", "validated",  "validatedBy" ],
    message: ''
  };
  items: RootsActionMenuItem[] = [
    {
      name: "Show Log",
      toolTip:
        'Expand details of this log',
      icon: "search",
      type: 10,
    },
    {
      name: "More Unit Logs",
      toolTip: "Navigate to a page table showing all the other logs for this unit",
      icon: "search",
      type: 14,
    },
    {
      name: "Create Assets",
      toolTip:
        'Create assets based on this unit and its sensors',
      icon: "add",
      type: 11,
    },
    {
      name: "Validate/ Un-Validate Log",
      toolTip:
        'Confirm validation over this log',
      icon: "check",
      type: 12,
    },
    {
      name: "Validate All",
      toolTip:
        'Confirm validation for all logs containing this same serial',
      icon: "check_box",
      type: 13,
    }
  ];
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Get validated",
      icon: "done",
      toolTip: "Get validated cub scout logs",
      type: 0,
    },
    // {
    //   name: "Get non-validated",
    //   icon: "clear",
    //   toolTip: "Get non-validated cub scout logs",
    //   type: 1,
    // },
    // {
    //   name: "Get Latest",
    //   icon: "today",
    //   toolTip: "Pull latest cub scout logs",
    //   type: 2,
    // }
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchSubscription: Subscription;
  constructor(
    private _constService: ConstantService,
    private _http: HttpClient,
    private _router: Router,
    private _dialog: MatDialog,
    private _snack: SnackService,
    private _logService: ScoutLogService,
    public searchBarService: SearchBarService
  ) {
    this._config.title = "Cub Scout Logs";
    this._config.toolTip = `Manage cub scout logs ${ this.server }`;
    this.getCubLogs();
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(message => {
      this._config.message = message;
    })
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  getCubLogs() {
    this._config.loading = true;
    const URL = `${environment.scoutLogApiUrl}CubScoutLog`;
    this.request = new Request(URL, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "x-functions-key": environment.SCOUTLOG_API_KEY,
      }),
    });
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <any[]>res;
          this._config.dataSource.data = this.logs;
          this._config.loading = false;
          this._snack.displaySuccess("Cub Scout Logs loaded");
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  //returns array of logs based on serial
  public getCubScoutLogsBySerialId(serialId: string) {
    this.request = new Request(
      environment.scoutLogApiUrl + "CubScoutLog/serials/" + serialId,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          "x-functions-key": environment.SCOUTLOG_API_KEY,
        }),
      }
    );
    fetch(this.request)
      .then((response) => {
        response.json().then((res) => {
          this.logs = <ScoutLogExtended[]>res;
          console.log(this.logs);
          this._snack.displaySuccess("Scout Log history loaded");
        });
      })
      .catch((err) => {
        this._snack.displayError(err);
      });
  }

  refresh() {
    this._config.loading = true;
    this.getCubLogs();
  }

actionsClicked(action) {
  const type = action[0];
  const item = action[1];
  switch (type.type) {
    case 10:
      this.logDialog(item);
    break;
    case 11:
      this._logService.createAssetsDialog(item);
    break;
    case 12:
      this.verifyLogDialog(item);
    break;
    case 13:
      this._logService.validateCubHistory(item.serial);
      this.refresh();
    break;
    case 14:
      this.navigateToCub(item);
    break;
    default:
      break;
  }
}

  topMenuActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.getCubLogs();
        break;
        case 1:
          break;
        case 2: 
          break;
      default:
        break;
    }
  }

  

  verifyLogDialog(log: any): void {
    const dialogRef = this._dialog.open(CubVerifyDialogComponent, {
      width: "40vw",
      height: "40vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  navigateToCub(cub: any) {
    this._router.navigate(['/manufacturinglogs/cubs/', cub.serial]);
  }

  findCount(logs: ScoutLogExtended[]): any {
    logs.forEach((log) => {
      log.count = 0;
      log.sensors.forEach((sensor) => {
        if (sensor.testSensor === false) {
          log.count++;
        }
      });
    });
    return logs;
  }
  
  logDialog(log: ScoutLogExtended): void {
    const dialogRef = this._dialog.open(ProdLogCubDialogComponent, {
      width: "90vw",
      height: "90vh",
      data: log,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
        setTimeout(() => {
          this.refresh();
          this._snack.displaySuccess("Reloading... One moment");
        }, 1000);
      }
    });
  }

  createAssetsDialog(log: any): void {
    this._logService.createAssetsForCubDialog(log);
  }
}
