<div mat-dialog-content>
  <div class="col-12">
    <h5>
      Roots User settings
    </h5>
    <button class="btn btn-secondary scanner" style="right: 0px; position: absolute;"
        mat-raised-button color="primary" (click)="saveSettings()">
          <p class="text btn-text">
            Save
          </p>
    </button>
  </div>
  <br>
  <hr>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <mat-form-field id="form-input" style="width: 90%;">
      <input matInput placeholder="User's Status" type="text" required [(ngModel)]="currentSettings.userStatus" name="userStatus">
    </mat-form-field>
    <i style="width: 10%;" (click)="clearStatus()"
      matTooltip="Clear Reminder" matTooltipPosition="below" class="material-icons">close</i>
    <mat-form-field id="form-input" style="width: 90%;">
      <input matInput placeholder="Current User Reminder" type="text" required [(ngModel)]="currentSettings.userReminder" name="userReminder">
    </mat-form-field>
    <i style="width: 10%;" (click)="clearReminder()"
      matTooltip="Clear Reminder" matTooltipPosition="below" class="material-icons">close</i>
    <br>
    <mat-slide-toggle class="third-button" [(ngModel)]="currentSettings.overlord" (change)="swapTheme()" disabled>
      Root Access
    </mat-slide-toggle>
    <mat-slide-toggle class="third-button" [(ngModel)]="currentSettings.tutorialCompleted" (change)="swapTheme()" disabled>
      Tutorial Completed
    </mat-slide-toggle>
    <mat-slide-toggle class="third-button" [(ngModel)]="currentSettings.darkTheme" (change)="swapTheme()" disabled>
      Dark Theme
    </mat-slide-toggle>
    <hr>
    <div *ngIf="currentSettings.overlord">
      <h5>Root Admin Controls</h5>
      <button  class="btn btn-secondary"
          mat-raised-button color="primary" (click)="resetToNull()">
            <p class="text btn-text">
              Reset to Null
            </p>
        </button>
    </div>
  </div>

<!-- <div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">No</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div> -->