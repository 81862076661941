import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';
import { SnackService } from './extra/snack.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Alert } from '../interfaces/alert';

@Injectable()
export class AlertService {
  params: any;
  private authHeaders: HttpHeaders;
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _snackService: SnackService
  ) { }

  public getAlerts(): Observable<Alert[]> {
    const params: any = {
      //"ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.reportsApiUrl}api/GetAlerts`;
    return this._http.get<Alert[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public clearAlerts(ids: string[]): Observable<Alert[]> {
    const URL = `${this._constService.CONST.reportsApiUrl}api/ClearAlerts`;
    return this._http.post<any[]>(URL, ids.toString()).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

}
