import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { UserAgentApplication } from 'msal';
import { MSALAuthenticationProviderOptions } from '@microsoft/microsoft-graph-client/lib/src/MSALAuthenticationProviderOptions';
import { Client } from '@microsoft/microsoft-graph-client';
import { ImplicitMSALAuthenticationProvider } from './implcitMSALAuthenticationProvider';

export function callback (errorDesc: any, token, error, tokenType) {}

@Injectable()
export class MsalService {
    url = 'https://graph.microsoft.com/v1.0/me/messages';
    request: Request;
    accessToken: string;
    promiseToken: any;
    constructor() {
        this.accessToken = localStorage.getItem('adal.idtoken');
        const msalConfig = {
            auth: {
                clientId: '938bb782-2f82-4b38-90a4-ded620c32ed1',
                redirectUri: window.location.origin + '/login'
            },
        };
        const graphScopes = ['user.read', 'mail.send'];
        const msalApplication = new UserAgentApplication(msalConfig);
        const options = new MSALAuthenticationProviderOptions(graphScopes);
        const authProvider = new ImplicitMSALAuthenticationProvider(msalApplication, options);
        this.promiseToken = authProvider.getAccessToken();
    }

    azureGraphApi() {
        this.request = new Request(this.url, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + this.accessToken
            })
        });
        fetch(this.request)
        .then((response) => {
            response.json().then((res) => {
                const messages: [MicrosoftGraph.Message] = res.value;
                for (const msg of messages) {
                    console.log(msg.subject);
                    console.log(msg.toRecipients[0].emailAddress.address);
                }
            });
        })
        .catch((err) => {
            console.log(err);
        });
    }
}
