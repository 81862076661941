<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  (refresh)="refresh($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="displayedColumns"
  [dataSource]="dataSource"
  [filter]="message"
  [csv]="title">
</roots-table>