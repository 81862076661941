import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Icon } from '../../interfaces/icon';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss']
})
export class SubNavigationComponent implements OnInit {
@Input()customerId?: string;
@Input()referenceId?: string;
@Input() icons: Icon[] = [];

  constructor(
      private _router: Router
      ) { }

  ngOnInit() {
  }

  navigate(command: string, object?: any) {
      console.log()
    switch (command) {
      case 'users':
        this._router.navigate([`/customers/${this.customerId}/users`]);
        break;
    case 'assets':
        this._router.navigate([`/customers/${this.customerId}/assets`]);
        break;
    case 'ownership':
        this._router.navigate([`/customers/${this.customerId}/ownership`]);
        break;
    case 'usersGroup':
        this._router.navigate([`/customers/${this.customerId}/usergroups`]);
        break;
    case 'customer-details':  
        this._router.navigate([`/customers/${this.customerId}`]);
        break;
    case 'reference-details':  
        this._router.navigate([`/references/${this.referenceId}`]);
        break;
    case 'reference-back':
        this._router.navigate([`/references`]);
        break;   
    case 'report-packets':
        this._router.navigate([`/reports/packets`]);
        break; 
    case 'report-sensors':
        this._router.navigate([`/reports/sensors`]);
        break;
    case 'report-scouts':
        this._router.navigate([`/reports/scouts`]);
        break;
    case 'report-users':
        this._router.navigate([`/reports/users`]);
        break;
    case 'report-back':
        this._router.navigate([`/reports`]);
        break;
    case 'back':
        window.history.back()
        break;
      default:
        break;
    }
  }

}
