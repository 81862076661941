<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  (refresh)="refresh($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [clickingEnabled]="true"
  (pulledRowData)="navigate($event)">
</roots-table>
