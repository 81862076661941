import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConstantService } from '../../..';
import { Settings } from '../../../interfaces/settings';
import { AdminService } from '../../../services/admin.service';
import { SnackService } from '../../../services/extra/snack.service';

@Component({
  selector: 'app-verify-user-reminder',
  templateUrl: './verify-user-reminder-dialog.component.html',
  styleUrls: ['./verify-user-reminder-dialog.component.scss']
})
export class VerifyUserReminderDialogComponent {
  currentSettings: Settings;
  userDetails: any;
  constructor(
    public dialogRef: MatDialogRef<VerifyUserReminderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public reminder: any,
    private _router: Router,
    private _constService: ConstantService,
    private _adminService: AdminService,
    private _snack: SnackService
    ) {
      this.currentSettings = this._constService.userSettings;
      this.userDetails = this._constService.userDetails;
  }

  clearReminder(){
    this.currentSettings.userReminder = '';
    this.saveSettings();
    this.dialogRef.close();
  }

  saveSettings() {
    const settingsObject: Settings = {
      overlord: this.currentSettings.overlord,
      darkTheme: this.currentSettings.darkTheme,
      tutorialCompleted: this.currentSettings.tutorialCompleted,
      userStatus: this.currentSettings.userStatus,
      userReminder: this.currentSettings.userReminder
    };
    const stringifiedSettings = JSON.stringify(settingsObject);
    //const content = JSON.stringify({content: stringifiedSettings});
    this._adminService.patchTableUsersSettings(this.userDetails.id, stringifiedSettings).subscribe(result => {
      console.log(result);
      this._snack.displaySuccess('Reminder cleared successfully')
    }, err => {
      this._snack.displayError('An error occurred while saving settings')
    })
  }

  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
