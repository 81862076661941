
import {tap, map} from 'rxjs/operators';
import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserGroup } from '../../../../shared/interfaces/user-group';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
// const uuidv1 = require('uuid/v1');
// const uuidv4 = require('uuid/v4');
const { v1: uuidv1 } = require('uuid');
const { v1: uuidv4 } = require('uuid');

@Component({
    selector: 'app-user-group-create-form',
    templateUrl: './user-group-create-form.component.html',
    styleUrls: ['./user-group-create-form.component.scss']
  })
  export class UserGroupCreateFormComponent implements OnInit, OnDestroy {
    customerId = '';
    userGroupSubscription: Subscription;
    constructor(
      public dialogRef: MatDialogRef<UserGroupCreateFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _http: HttpClient,
      private _route: ActivatedRoute
      ) {
        console.log(this.data);
    }
    event: EventEmitter<any> = new EventEmitter();
    submitted = false;
    userGroupPost: UserGroup = {
      basicName: '',
      basicDescription: '',
      code: this.createCode(this.data.dataKey.basicName),
      content: null,
      createdAt: null,
      updatedAt: null,
      isAvailable: 0,
      id: uuidv4(),
      deleted: false,
      username: this.createUsername(this.data.dataKey.basicName),
      version: null,
      customerId: this.data.id
    };

    ngOnInit() {
      console.log('Passed data: ' + this.data.dataKey);
      this.customerId = this.data.id;
    }
    
  ngOnDestroy() {
    // if (this.userGroupSubscription) {
    //   this.userGroupSubscription.unsubscribe();
    // } 
  }

    onNoClick(): void {
      this.dialogRef.close();
    }

    createUsername(name: string): string {
      const newName = name + ' User';
      return newName;
    }

    defaultUserGroup(): UserGroup {
      this.userGroupPost = {
      basicName: this.data.dataKey.basicName + ' UserGroup',
      basicDescription: this.data.dataKey.basicName + ' Description',
      code: this.createCode(this.data.dataKey.basicName),
      content: null,
      createdAt: null,
      updatedAt: null,
      isAvailable: 0,
      id: uuidv4(),
      deleted: false,
      username: this.createUsername(this.data.dataKey.basicName),
      version: null,
      customerId: this.customerId
      };
      return this.userGroupPost;
    }

    onSubmit(): void {
      this.submitted = true;
      // this.event.emit({data: this.userGroupPost});
      if (this.userGroupPost.code.length > 50) {
          this.userGroupPost.code = 'UserGroup_' + uuidv1();
        // this.userGroupPost.code = this.userGroupPost.code.replace(' ', '');
        // this.userGroupPost.code = this.userGroupPost.code.substr(0, this.userGroupPost.code.length / 2);
      }
      this.userGroupSubscription = this.createUserGroupForCustomer(this.userGroupPost).subscribe(c => {
        console.log(c);
        this.event.emit({data: this.userGroupPost})
        // window.location.reload();
      });
      this.dialogRef.close();
    }

    removeHyphens(code: string): string {
      const replaced = code.replace(/-/g, '');
      return replaced;
    }

    createCode(code: string): string {
      let created = code.toLowerCase();
      created = created + 'UserGroup_' + uuidv1();
      return created;
    }

    createUserGroupForCustomer(userGroup: UserGroup) {
      const params: any = {
        'ZUMO-API-VERSION': '2.0.0',
        'Content-Type': 'application/json'
      };
        return this._http
        .post(`${environment.apiUrl}tables/UserGroup`, userGroup, { params: params }).pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('UserGroup created:', data)),);
    }
  }
