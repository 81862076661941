
<div mat-dialog-content>
    <h3 mat-dialog-title 
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" 
    cdkDragHandle>{{data.title}}</h3>
    <div class="row">
        <button mat-raised-button style="float: right; padding: auto; margin:auto" (click)="defaultAsset()" color="primary">Scout Defaults</button>
        <button mat-raised-button style="float: right; padding: auto; margin:auto" (click)="defaultHealth()" color="primary">Health Defaults</button>
        <button mat-raised-button style="float: right; padding: auto; margin:auto" (click)="defaultSensor()" color="primary">Sensor Defaults</button>
    </div>
    
    <form class="asset-form" #assetForm="ngForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <input matInput placeholder="Code" type="text" required [(ngModel)]="assetPost.code" name="code">
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Name" type="text" required [(ngModel)]="assetPost.basicName" name="basicName">
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Description" required [(ngModel)]="assetPost.basicDescription" name="basicDescription"></textarea>
        </mat-form-field>
        
        <mat-form-field>
            <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)" placeholder="Begin date" [formControl]="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <!-- <textarea matInput placeholder="Begin:" required [(ngModel)]="assetPost.whenBegin" name="whenBegin"></textarea> -->
        </mat-form-field>
        
        <mat-form-field>
            <mat-select matInput placeholder="Select Asset Model" required [(ngModel)]="assetPost.assetModelId" 
            name="assetModel">
            <mat-option *ngFor="let model of assetModels" [value]="model.id">
                {{ model.basicName }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
    <!-- ngif its a scout2.2 -->
    <mat-form-field *ngIf="assetPost.assetModelId === '25A57B26-D5C9-4669-BDBF-C0AB80AF2635'">
        <input matInput placeholder="Modem" type="text" required [(ngModel)]="modem" name="modem">
    </mat-form-field>
    
    <mat-form-field *ngIf="assetPost.assetModelId === '25A57B26-D5C9-4669-BDBF-C0AB80AF2635'">
        <input matInput placeholder="SIM" type="text" required [(ngModel)]="sim" name="sim">
    </mat-form-field>
    
    
        <mat-form-field>
            <mat-select matInput placeholder="Select Asset Status" required [(ngModel)]="assetPost.assetStatuslId" 
            name="assetType">
                <mat-option *ngFor="let status of assetStatus" [value]="status.id">
                    {{ status.basicName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- [disabled]="formFilled()" -->
        <button mat-raised-button type="submit" [disabled]="!assetForm.form.valid" color="primary" style="max-width: min-content;">Create</button>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content;">Cancel</button>
</div>