<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh()"
  (actionListClicked)="topMenuActionsClicked($event)"
>
</roots-page-header>
<roots-table
[tableColumns]="_config.displayedColumns"
[dataSource]="_config.dataSource"
[filter]="_config.message"
[csv]="_config.title"
[items]="items"
(actionsClicked)="actionsClicked($event)"
[clickingEnabled]="true">
</roots-table>