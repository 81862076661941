import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminScoutInstallation } from '../../../interfaces/admin/admin-scout-installation';
import { Router } from '@angular/router';
import { LngLat } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent {
  installs: AdminScoutInstallation[] = [];
  initInstalls: AdminScoutInstallation[] = [];
  locations: any[] = [];
  @Input()latitude: number;
  @Input()longitude: number;
  @Input()altitude?: number;

  mMap: mapboxgl.Map;
  mMarkers: mapboxgl.Marker[] = [];
  mPopUp: mapboxgl.Popup;
  mAccessToken: string;
  style: string;
  icon: any;
  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router
    ) { 
      this.latitude = this.data.lat;
      this.longitude = this.data.long;
      
      console.log('Lat: ' + this.latitude)
      console.log('Long: ' + this.longitude)
      console.log('Alt: ' + this.altitude)
    }

    ngOnInit() {
      this.buildMap();
      // this.buildMapControls(this.mMap);
    }
  
    init() {
      this.style = 'mapbox://styles/mapbox/outdoors-v9';
      // this.icon = {
      //   iconScoutUrl: '/assets/images/ScoutMarker.png',
      //   iconJournalUrl: '',
      //   iconSize: [40, 50],
      //   iconAnchor: [0, 0],
      //   popupAnchor: [0, -25]
      // };
      this.mAccessToken = 'pk.eyJ1IjoiY29ubmVyMjIiLCJhIjoiY2pkYnU2aXFvMGFyczJ3czQ4OWE2b2J6YyJ9.aYqjWv3vWY-UQ5bNBwHQoQ';
    }
  
    buildMap() {
      this.mMap = new mapboxgl
        .Map({
          container: 'map',
          style: this.style,
          zoom: 6,
          center: [this.longitude, this.latitude],
          attributionControl: false,
          logoPosition: 'bottom-right'
        });
        this.mMap.on('load', event => {
          if (this.longitude !== null || this.longitude !== undefined) {
            const c : LngLat = new LngLat(this.longitude, this.latitude)
            this.mMap.setCenter(c);
            
          } else {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                position => {
                  this.mMap.flyTo({
                    center: [position.coords.longitude, position.coords.latitude]
                  });
              });
            } else {
              this.mMap.flyTo({
                center: [-93.2761, 44.9765]
              });
            }
          }
      });
    }
  
    refresh() {
      for (let i = 0; i < this.mMarkers.length; i++) {
        const marker = this.mMarkers[i];
        marker.remove();
      }
    }

    buildMarker() {
      let div: HTMLElement;
      const marker = new mapboxgl.Marker();
      // div = this.buildDialogBox();
    }

    buildMarkers(installs: AdminScoutInstallation[]) {
      let div: HTMLElement;
      for (let i = 0; i < installs.length; i++) {
        const install = installs[i];
        const marker = new mapboxgl.Marker();
        const coordinates: number[] = [install.coordinateLongitude, install.coordinateLatitude];
        div = this.buildDialogBox(install);
        const c : LngLat = new LngLat(install.coordinateLongitude, install.coordinateLatitude)
        marker.setLngLat(c);
        marker.setPopup(
          (this.mPopUp = new mapboxgl.Popup({ offset: 25 }).setDOMContent(div))
        );
        marker.addTo(this.mMap);
        this.mMarkers.push(marker);
      }
    }
  
    buildMapControls(map: mapboxgl.Map) {
      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true
          },
          trackUserLocation: true
        })
      );
    }
  
    buildDialogBox(m: any): HTMLElement {
      const div = window.document.createElement('div');
      const br = window.document.createElement('br');
      const id = m.id;
      const button = window.document.createElement('button');
      div.innerText += m.whenBegin;
      div.appendChild(br);
      div.appendChild(button);
      button.className = 'e-btn';
      button.innerText = `${m.coordinateLatitude} ,${m.coordinateLongitude}`;
      // navigation
      const navigatorString = '/detail';
      // button.addEventListener('click', () => this.navigateToScoutDetails(navigatorString), false);
      return div;
    }

onNoClick(): void {
    this.dialogRef.close();
  }
}
