import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.view.html'
})
export class AuthCallbackComponent implements OnInit {
  message: string;
  constructor(
    private _router: Router,
    private _adalService: MsAdalAngular6Service
  ) {
  }

  ngOnInit(): void {
    this._adalService.handleCallback();
    if (typeof this._adalService.isAuthenticated === 'string'
    && (<string>this._adalService.isAuthenticated).length > 0) {
      // Debugging through this code - this top conditional is never hit.
      // Please check if casting as string is appropriate
      console.log('authenticated...');
      this._router.navigate(['customers']);
    } else {
      console.log('not authenticated... ');
      this._router.navigate(['']);
    }
  }
}
