import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  LoadingComponent,
  ReferencesService,
} from "@app/shared";
import { AuthCallbackComponent } from "@app/shared/components/auth-callback/auth-callback.component";
import { ProfileCardComponent } from "./components/profile-card/profile-card.component";
import { AppService } from "./services/api/app.service";
import { CustomerService } from "./services/customer.service";
import { AdminService } from "./services/admin.service";
import { UserService } from "./services/api/user.service";
import { ConstantService } from "./services/constant.service";
import { ScoutService } from "./services/api/scout.service";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { CounterComponent } from "./components/counter/counter.component";
import { ArraySortPipe } from "./pipes/arraysort.pipe";
import { SnackService } from "./services/extra/snack.service";
import { ScoutLogService } from "./services/scout-log.service";
import { UtilityService } from "./services/utility.service";
import { AlertService } from "./services/alert.service";
import {
  EmailDialogComponent,
  GroupsDialogComponent,
  SensorDialogComponent,
  PacketDialogComponent,
  MapDialogComponent,
} from "./components/dialogs";
import { SubNavigationComponent } from "./components/sub-nav/sub-navigation.component";
import { NewUserDialogComponent } from "./components/dialogs/new-user-dialog/new-user-dialog.component";
import { VerifyUserReminderDialogComponent } from "./components/dialogs/verify-user-reminder-dialog/verify-user-reminder-dialog.component";
import { SearchbarDialogComponent } from "./components/dialogs/searchbar-dialog/searchbar-dialog.component";
import { ConfirmaFirmwareDeleteDialogComponent } from "./components/dialogs/confirm-firmware-delete/confirm-firmware-delete-dialog.component";
import { CubVerifyDialogComponent } from "./components/dialogs/cub-verify-dialog/cub-verify-dialog.component";
import { ConfirmationDialogComponent } from "./components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { VerifyLogDialogComponent } from "./components/dialogs/verify-log-dialog/verify-log-dialog.component";
import { RenameLogDialogComponent } from "./components/dialogs/rename-log-dialog/rename-log-dialog.component";
import { EmailTemplate } from "./components/dialogs/email-dialog/EmailTemplate.component";
import { RootsPageHeaderComponent } from "./components/roots-page-header/roots-page-header.component";
import { RootsTableComponent } from './components/roots-table/roots-table.component';
import { RootsDetailsPageComponent } from './components/roots-details-page/roots-details-page.component';
import { RootsNavigationComponent } from './components/roots-navigation/roots-navigation.component';
import { RootsActionMenuComponent } from './components/roots-action-menu/roots-action-menu.component';
import { RootsBurgerComponent } from './roots-burger/roots-burger.component';
import { RootsCardListComponent } from './components/roots-card-list/roots-card-list.component';
import { VerifyDialogComponent } from "./components/dialogs/verify-dialog/verify-dialog.component";
import { ScoutLogDialogService } from "./services/scout-log-dialog.service";
import { RootsSimpleTableComponent } from "./components/roots-simple-table/roots-simple-table.component";
import { RootsActionableTableComponent } from "./components/roots-actionable-table/roots-actionable-table.component";
import { UserLookupDialogComponent } from "./components/dialogs/user-lookup-dialog/user-lookup-dialog.component";
import { ReportBuilderDialogComponent } from "./components/dialogs/report-builder-dialog/report-builder-dialog.component";
import { AssignGroupDialogComponent } from "./components/dialogs/user-lookup-dialog/assign-group-dialog/assign-group-dialog.component";
import { NoUserDialogComponent } from "./components/dialogs/user-lookup-dialog/no-user-dialog/no-user-dialog.component";
import { RootsLoadingComponent } from "./components/roots-loading/roots-loading.component";
import { UnitLoadingComponent } from "./components/unit-loading/unit-loading.component";
import { SensorLoadingComponent } from "./components/sensor-loading/sensor-loading.component";
import { MatModule } from "../core";
import { ItemsListDialogComponent } from "./components/dialogs/items-list-dialog/items-list-dialog.component";
import { RootsDotsMenuComponent } from './components/roots-dots-menu/roots-dots-menu.component';
import { AssetViewComponent } from './components/asset-view/asset-view.component';
import { D3SelectDialogComponent } from "./components/roots-table/dialogs/d3-select-dialog.component";
import { UserLookupListDialogComponent } from "./components/dialogs/user-lookup-dialog/user-lookup-list-dialog/user-lookup-list-dialog.component";
import { AssetMapComponent } from "../modules/assets";
import { AssetLookupListDialogComponent } from "./components/dialogs/asset-lookup-dialog/user-lookup-list-dialog/asset-lookup-list-dialog.component";
import { RootsDetailsDataListComponent } from "./components/roots-details-data-list/roots-details-data-list.component";
import { PageInfoDialogComponent } from "./components/dialogs/page-info-dialog/page-info-dialog.component";
import { ServerInfoDialogComponent } from "./components/dialogs/server-info-dialog/server-info-dialog.component";
import { NotesComponent } from './components/notes/notes.component';
import { NotesCreationDialogComponent } from "./components/dialogs/notes-creation-dialog/notes-creation-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatModule
  ],
  declarations: [
    ProfileCardComponent,
    LoadingComponent,
    AuthCallbackComponent,
    ConfirmationDialogComponent,
    PageInfoDialogComponent,
    ServerInfoDialogComponent,
    SensorDialogComponent,
    VerifyDialogComponent,
    CubVerifyDialogComponent,
    VerifyLogDialogComponent,
    VerifyUserReminderDialogComponent,
    ConfirmaFirmwareDeleteDialogComponent,
    PaginatorComponent,
    CounterComponent,
    ArraySortPipe,
    RenameLogDialogComponent,
    ReportBuilderDialogComponent,
    EmailDialogComponent,
    EmailTemplate,
    GroupsDialogComponent,
    NewUserDialogComponent,
    MapDialogComponent,
    SubNavigationComponent,
    PacketDialogComponent,
    NotesCreationDialogComponent,
    SearchbarDialogComponent,
    UserLookupDialogComponent,
    UserLookupListDialogComponent,
    AssetLookupListDialogComponent,
    AssignGroupDialogComponent,
    NoUserDialogComponent,
    RootsPageHeaderComponent,
    RootsLoadingComponent,
    UnitLoadingComponent,
    SensorLoadingComponent,
    RootsTableComponent,
    RootsDetailsDataListComponent,
    RootsActionableTableComponent,
    RootsSimpleTableComponent,
    RootsDetailsPageComponent,
    RootsNavigationComponent,
    RootsActionMenuComponent,
    RootsBurgerComponent,
    RootsCardListComponent,
    ItemsListDialogComponent,
    RootsDotsMenuComponent,
    AssetViewComponent,
    D3SelectDialogComponent,
    AssetMapComponent,
    NotesComponent
  ],
  providers: [
    ScoutService,
    CustomerService,
    ConstantService,
    ReferencesService,
    UserService,
    AppService,
    AdminService,
    SnackService,
    ScoutLogService,
    ScoutLogDialogService,
    UtilityService,
    AlertService,
    DatePipe
  ],
  exports: [
    ProfileCardComponent,
    LoadingComponent,
    PaginatorComponent,
    CounterComponent,
    ConfirmationDialogComponent,
    PageInfoDialogComponent,
    ServerInfoDialogComponent,
    SensorDialogComponent,
    VerifyDialogComponent,
    CubVerifyDialogComponent,
    VerifyLogDialogComponent,
    VerifyUserReminderDialogComponent,
    ConfirmaFirmwareDeleteDialogComponent,
    RenameLogDialogComponent,
    ReportBuilderDialogComponent,
    EmailDialogComponent,
    UserLookupDialogComponent,
    AssignGroupDialogComponent,
    NoUserDialogComponent,
    AssetLookupListDialogComponent,
    UserLookupListDialogComponent,
    EmailTemplate,
    GroupsDialogComponent,
    NewUserDialogComponent,
    MapDialogComponent,
    SubNavigationComponent,
    PacketDialogComponent,
    NotesCreationDialogComponent,
    ArraySortPipe,
    RootsPageHeaderComponent,
    RootsLoadingComponent,
    UnitLoadingComponent,
    SensorLoadingComponent,
    RootsTableComponent,
    RootsDetailsDataListComponent,
    RootsActionableTableComponent,
    RootsSimpleTableComponent,
    RootsNavigationComponent,
    ItemsListDialogComponent,
    D3SelectDialogComponent,
    AssetMapComponent,
    NotesComponent
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    PageInfoDialogComponent,
    ServerInfoDialogComponent,
    SensorDialogComponent,
    VerifyDialogComponent,
    VerifyLogDialogComponent,
    VerifyUserReminderDialogComponent,
    UserLookupDialogComponent,
    UserLookupListDialogComponent,
    AssetLookupListDialogComponent,
    AssignGroupDialogComponent,
    NoUserDialogComponent,
    ConfirmaFirmwareDeleteDialogComponent,
    EmailDialogComponent,
    EmailTemplate,
    GroupsDialogComponent,
    NewUserDialogComponent,
    MapDialogComponent,
    PacketDialogComponent,
    NotesCreationDialogComponent,
    RenameLogDialogComponent,
    ReportBuilderDialogComponent,
    RootsPageHeaderComponent,
    RootsLoadingComponent,
    SensorLoadingComponent,
    UnitLoadingComponent,
    RootsTableComponent,
    RootsDetailsDataListComponent,
    RootsActionableTableComponent,
    RootsSimpleTableComponent,
    RootsNavigationComponent,
    ItemsListDialogComponent,
    D3SelectDialogComponent,
    AssetMapComponent,
    NotesComponent
  ],
})
export class SharedModule {}
