import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SearchLogs, SearchLogSensors } from "../../../interfaces/search-log";

@Component({
  selector: "app-searchbar-dialog",
  templateUrl: "./searchbar-dialog.component.html",
  styleUrls: ["./searchbar-dialog.component.scss"],
})
export class SearchbarDialogComponent {
  event: EventEmitter<any> = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<SearchbarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SearchLogs[],
    private _router: Router
  ) {}

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  navigateLog(log: SearchLogs) {
    console.log(log);
    switch (log.type) {
      case "Cub":
        //this._router.navigate(['']);
        break;
      case "Scout":
        this._router.navigate([`/assets/${log.link}`]);
        break;
      default:
        break;
    }
  }
}
