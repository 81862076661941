import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActionType, RootsActionMenuItem } from "../../../shared";
import { LocalStorageService } from "../../../core/local-storage/local-storage.service";

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html'
})
export class BuilderComponent implements OnInit {
customerReport: MatTableDataSource<any>;
loading = true;
title = "";
  toolTip = "";
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Choose Date",
      icon: "calendar_today",
      toolTip:
        "Choose a query date for packet report",
      type: ActionType.Dialog,
    },
  ];
  dateNow = new Date(Date.now());
  dateFrom = new Date();
  datasource: any;
  constructor(
    private _localStorageService: LocalStorageService
  ) { 
    this.title = "Report Builder";
    this.toolTip =
      "Report builder is where you can congregate information regarding different sets of data throughtout the Roots app. Click to refresh.";
    this.datasource = this._localStorageService.getCurrentCustomDataTable();
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.loading = false;
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 3:
        break;
      default:
        break;
    }
  }

}
