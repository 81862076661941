import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from '../../../shared';
import { AdminService } from '../../../shared/services/admin.service';
import { FirmwareModel } from '../../../shared/interfaces/firmware-model';
import { FirmwareInstanceFormComponent } from '../create-instance/firmware-instance-form.component';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fwm-fwi-dialog',
  templateUrl: './fwm-fwi-dialog.component.html'
})
export class FwmFwiDialogComponent implements OnInit, OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  loading = false;
  d = new Date();
  year = this.d.getFullYear();
  month = this.d.getMonth();
  day = this.d.getDate();
  c = new Date(this.year + 1, this.month, this.day);
  submitted = false;
  instanceAssigned = false;
  newInstance = false;
  currentDate = new Date(Date.now());
  firmwarePost: FirmwareModel = {
    firmwareInstanceId: null,
    firmwareVersion: "X.X.X",
    whenBegin: this._constService.convertDateUTC(this.currentDate)
  }
  instances: any[] = [];
  instanceIds: string[] = [];
  selectedInstance: string;
  fwi = new FormControl();
  selected: string[] = this.instances.filter((item, i) => i % 2 === 0);
  componentSubscription: Subscription;
  constructor(
    public _dialog: MatDialog,
    private _adminService: AdminService,
    private _constService: ConstantService,
    public dialogRef: MatDialogRef<FwmFwiDialogComponent>
  ) { }

  ngOnInit(): void {
    this.getInstances();
  }

  ngOnDestroy() {
    // if (this.componentSubscription) {
    //   this.componentSubscription.unsubscribe();
    // }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  /*checkbox change event*/
  onChange(event) {
    this.instanceIds.push(event.id);
    this.selectedInstance = event.id;
    this.firmwarePost.firmwareInstanceId = this.selectedInstance;
    this.instanceAssigned = true;
  }

  getInstances() {
    this.loading = true;
    this.componentSubscription = this._adminService.getFirmwareInstance().subscribe((instances) => {
      this.instances = instances.reverse();
      this.loading = false;
    });
  }

  openInstanceDialog(): void {
    const dialogRef = this._dialog.open(FirmwareInstanceFormComponent, {
      width: "60vw",
      data: "Create Firmware Instance",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        console.log(result.data);
        this.getInstances();
      } else {
        console.log(result);
        this.newInstance = false;
      }
    });
  }

  startNewInstance(){
    this.newInstance = true;
    this.openInstanceDialog();
  }

  noInstanceAttached() {
    this.newInstance = true;
  }

  okayClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;
    let firmware;
    firmware = JSON.stringify(this.firmwarePost);
    this.event.emit({data: this.firmwarePost});
    this.componentSubscription = this._adminService.postFirmwareModels(firmware).subscribe(c => {
      console.log('firmware has been created succesfully: ' + c);
      this.event.emit({})
    });
    this.dialogRef.close();
  }

  //create firmware model
  //pull the available Firmware Instances
  //Allow for creating a firmware instance if needed
  //if firmware instance is created than link it to the firmware model
  //Once FWM has been created and a FWI has been assigned or created for it, then it is ready

    //"{"firmwareInstanceId":"","firmwareVersion":"data.test.firmware","whenBegin":"2021-11-22T16:20:08.00"}"
    // if(this.firmwarePost.firmwareInstanceId.length < 1) {
    //   const withoutInstanceId = {
    //     firmwareVersion: this.firmwarePost.firmwareVersion,
    //     whenBegin:this.firmwarePost.whenBegin
    //   }
    //   firmware = JSON.stringify(withoutInstanceId);

    // } else {
    //   firmware = JSON.stringify(this.firmwarePost);
    // }
}
