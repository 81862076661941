<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading">
</roots-page-header>
<div class="col-12">
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left; box-sizing: border-box;">
    <mat-card *ngIf="userGroup">
      <h3 class="text label-header-text">Details</h3>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="userGroup.basicName" required>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput placeholder="Description" [(ngModel)]="userGroup.basicDescription" required>
      </mat-form-field>
      <button mat-raised-button type="submit" style="width: fit-content" (click)="onUpdate(userGroup)"
        color="primary">Update
      </button>
    </mat-card>
  </div>
  
  <app-notes *ngIf="userGroup" [entityId]="userGroupId" [entityCode]="userGroup.code" [entityType]="'UserGroup'" [notes]="notes" (noteAdded)="noteAdded($event)">
  </app-notes>
  <app-roots-details-data-list [data]="users" [prop_1]="'userName'" [prop_2]="'userCode'" [prop_3]="'userId'"
    [prop_4]="'userGroupId'" [prop_5]="'id'" [type]="0" [icon]="'widgets'" [title]="'Users in Group'">
  </app-roots-details-data-list>
  <!-- <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Cancel</button> -->
</div>