<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading">
</roots-page-header>
<!-- <roots-loading (click)="clickEvent(1)" class="col-12 col-sm-12" style="z-index: 1000; position: absolute; left: 12%; top: 40%;" [loading]="loadingIcon_1"></roots-loading> -->
<!-- <unit-loading (click)="clickEvent(2)" style="z-index: 1000; position: absolute; left: 25%; top: 40%;" [loading]="loadingIcon_2"></unit-loading> -->
<!-- <sensor-loading (click)="clickEvent(3)" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-4" style="z-index: 1000; position: absolute; left: 50%; top: 50%;" [loading]="loadingIcon_3"></sensor-loading> -->
<!-- <img class="col-12" src="../../../assets/images/admin_Background.png"/> -->
<mat-card>
  <p class="text small-text">
    Open the menu in the top left. At the bottom of the menu you will find "Give Feedback" and "Report Bug" buttons to
    create a JIRA ticket. If no action happens when you click the buttons, please check your adblocker and disable it
    for this page.
  </p>
  <!-- <div *ngIf="scouts; else noData" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12" style="float: left">
    <p class="text label-header-text">
      ({{ scouts.length }}) Scouts
    </p>
  </div>
  <ng-template #noSelectedScout>
    <p class="text small-text" style="margin: 0px;">
      No Scout selected
    </p>
  </ng-template>
  <mat-select matInput placeholder="Scouts found" required name="name">
    <mat-option *ngFor="let scout of scouts" [value]="scout.name" (click)="getScout(scout)">
      <p>
        <b>{{ scout.name }} </b>
      </p>
    </mat-option>
  </mat-select> -->
</mat-card>
<!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12" style="float: left;" *ngIf="selectedScout">
  <hr />
  <mat-form-field>
    <mat-label>Firmware Version</mat-label>
    <input matInput placeholder="Firmware Version" [(ngModel)]="selectedScout.firmwareVersion" disabled />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput placeholder="Name" [(ngModel)]="selectedScout.name" disabled />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Asset Name</mat-label>
    <input matInput placeholder="Asset" [(ngModel)]="selectedScout.assetName" disabled />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Description</mat-label>
    <input matInput placeholder="Description" [(ngModel)]="selectedScout.description" disabled />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Code</mat-label>
    <input matInput placeholder="Scout Code" [(ngModel)]="selectedScout.code" disabled />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Statistics</mat-label>
    <input matInput placeholder="Statistics" [(ngModel)]="selectedScout.statistics" disabled />
  </mat-form-field>
</div>
<div *ngIf="selectedScout; else noSelectedScout" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12"
  style="float: left">
  <h3 class="text label-header-text">
    Scouts
  </h3>
</div>
<ng-template #noSelectedScout>
  <p class="text small-text" style="margin: 0px;">
    No Scouts found.
  </p>
</ng-template>
<ng-template #noData>
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xxl-12" style="float: left">
    <h3 class="text label-header-text">
      <mat-icon> close </mat-icon>
      Scouts
    </h3>
    <hr />
    <p class="text small-text" style="margin: 0px;">
      No Scouts found.
    </p>
  </div>
</ng-template> -->
<app-pie (loadingEvent)="loadingEvent($event)"></app-pie>