import { Component, Input, OnInit, ViewChild, ElementRef, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { Router } from '@angular/router';
import { ConstantService, Scout } from '../../../../shared';
import { UserService } from '../../../../shared/services/api/user.service';
import { ScoutService } from '../../../../shared/services/api/scout.service';
import { Settings } from '../../../../shared/interfaces/settings';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html'
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @Input() user: any;
  @ViewChild('container', { read: ElementRef }) container: ElementRef;
  scouts: Scout[] = [];
  scoutsHolder: Scout[] = [];
  scoutSelected: Scout;
  isExpanded = false;
  dialogAnimation: Object = { effect: 'Zoom', duration: 400, delay: 0 };
  targetElement: HTMLElement;
  buttons: Object = [];
  selected: any;
  currentLanguage: any;
  languageOptions = [
    { key: 'English-US', value: 'en' },
    { key: 'Nepali', value: 'ne' },
    { key: 'Russian', value: 'ru' }
  ];
  width = '80%';
  target = '.control-section';
  userDetails: any;
  userSettings: Settings;
  userPermission: any;
  source: string;
  loading: boolean;
  hierarchyStructure: Object[] = [];
  // editor = ClassicEditor;
  field: Object = {
    dataSource: this.hierarchyStructure,
    id: 'id',
    text: 'name',
    child: 'subChild'
  };
  cssClass = 'custom';
  collapsed = false;
  ip = '';
  title = '';
  toolTip = '';
  componentSubscription: Subscription;
  constructor(
    private _authService: AuthenticationService,
    private _scoutService: ScoutService,
    private _router: Router,
    private _constService: ConstantService
  ) {
    // this.currentLanguage = this.translate.currentLang;
  }

  ngOnInit() {
    this.userDetails = this._constService.userDetails;
    this.userPermission = this._constService.permissionType;
    this.userSettings = this._constService.userSettings;
    this.user = this._authService.userInfo;
    this.ip = this._constService.ipAddress;
    this.toolTip = 'Find all details regarding your user account. Roots app is secured for users with higher leveled permissions.'
    if (this.userDetails) {
      this.title = `Welcome, ${ this.userDetails.basicName }`
      this.showDeviceList();
    } else {
      this._router.navigate(['/customers']);
    }
  }
  
  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
  }

  showDeviceList() {
    this.loading = true;
    this.componentSubscription = this._scoutService.getScouts()
      .subscribe(scouts => {
        this.loading = false;
        this.scouts = scouts;
        this.scoutsHolder = scouts;
        const tempHierarchy = this.parseHierarchyData(this.scouts);
        this.hierarchyStructure = tempHierarchy;
        this.field['dataSource'] = this.hierarchyStructure;
        this.field = {
          dataSource: this.hierarchyStructure,
          id: 'id',
          text: 'name',
          child: 'subChild'
        };
        // console.log('finished hierarchy: ', tempHierarchy);
        // console.log('hierarchy structure: ', this.hierarchicalData);
      });
  }

  parseHierarchyData(scouts: Scout[]): Object[] {
    const finishedHierarchy: Object[] = [];
    let i = 0;
    let j = 0;
    scouts.forEach(scout => {
      i++;
      const scoutHierarchy = {
        // id: '0' + i.toString(),
        id: scout.id,
        name: scout.basicName,
        subChild: []
      };
      scout.sensors.forEach(sensor => {
        j++;
        if (sensor.assetType) {
          scoutHierarchy.subChild.push(
            {
              // id: '0' + i.toString() + '-0' + j,
              id: sensor.id,
              name: sensor.basicName
            });
        }
      }),
        j = 0;
      finishedHierarchy.push(scoutHierarchy);
    });
    return finishedHierarchy;
  }

  expandDetails(): boolean {
    const coll = document.getElementsByClassName('collapsible');
    if (coll[0].classList.contains('active'.toLowerCase())) {
      coll[0].classList.remove('active');
      this.collapsed = false;
      return this.collapsed;
    } else {
      coll[0].classList.add('active');
      this.collapsed = true;
      return this.collapsed;
    }
  }

  nodeEdited($event) {
    const oldName = $event.oldText;
    let newName = $event.newText;
    const id = $event.nodeData.id;
    if (newName.length > 0 && newName !== oldName) {
      const parentId = $event.nodeData.parentID;
      if (parentId) {
        const sensorRef: object = {
          basicName: newName
        };
        this.componentSubscription = this._scoutService.updateSensor(id, sensorRef)
        .subscribe(
          (data) => {
            console.log('Sensor updated!: ', data);
          }, (err) => {
            if (err) {
            console.log(err);
              newName = oldName;
            }
          }
        );
      } else {
        const scoutRef: object = {
          basicName: newName
        };
        this.componentSubscription = this._scoutService.updateScout(id, scoutRef)
        .subscribe(
          (data) => {
            console.log('Scout updated!: ', data);
          }, (err) => {
            if (err) {
            console.log(err);
              newName = oldName;
            }
          }
        );
      }
    }
    console.log($event);
  }
  
  public language_Changed(event, language: any): void {
    console.log(localStorage.getItem('languagePreference'));
    this.selected = language;
    switch (language.value) {
      case 'en':
        // this.translate.use('en');
        localStorage.setItem('languagePreference', 'en');
        Intl.Collator('EN-US').compare('EN-US', language.value);
        break;
      case 'ne':
        // this.translate.use('ne');
        localStorage.setItem('languagePreference', 'ne');
        Intl.Collator('ne').compare('ne', language.value);
        break;
      default:
        break;
    }
    localStorage.setItem('languagePreference', language.value);
  }

  logout() {
    this._authService.logout();
  }
}
