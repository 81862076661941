import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roots-details-page',
  templateUrl: './roots-details-page.component.html'
})
export class RootsDetailsPageComponent implements OnInit {
@Input('header')header = 'Entity Details';
@Input('entity')entity: any;
@Input('fields')fields: DetailPageInput[];
  constructor() { 

  }

  ngOnInit(): void {
  }

}

export interface DetailPageInput
{
  isSelectComponent: boolean;
  name: string;
  options?: DetailSelectOptions[];
}
export interface DetailSelectOptions{
  value: any;
  key: any;
}