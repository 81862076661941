import { environment } from "./../environments/environment";
import { AuthHttpInterceptor } from "./core/interceptors/authHttp.interceptor";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { SettingsModule } from "./modules";
import { CoreModule } from "../app/core/core.module";
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { AuthGuard } from "../app/core/authentication/authentication.guard";
import { MsAdalAngular6Module } from "microsoft-adal-angular6";
import { AssetModule } from "./modules/assets/asset.module";
import { ReferencesModule } from "./modules/references/references.module";
import { CustomerModule } from "./modules/customer/customer.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AdminAuthGuard } from "./core/authentication/admin.authentication.guard";
import { ManufacturingAuthGuard } from "./core/authentication/manufacturing.authentication.guard";
import { ReportsModule } from "./modules/reports/reports.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";
import { AccountModule } from "./modules/account/account.module";
import { LogsModule } from "./modules/logs/logs.module";
import { SearchbarComponent } from "./shared/components/searchbar/searchbar.component";
import { GridsterModule } from "angular-gridster2";
import { FirmwareModule } from "./modules/firmware/firmware.module";
import { ScoutModule } from "./modules/scouts/scouts.module";
import { SearchBarService } from "./shared/services/searchbar.service";
import { CdkDetailRowDirective } from "./shared/components/roots-table/cdk-detail-row.directive";
import { GroupsModule } from "./modules/groups/groups.module";
import { AvatarComponent } from "./shared/components/avatar/avatar.component";
import { MenuListItemComponent, MenuTopItemComponent, NotFoundComponent, ServerErrorComponent } from "./core";
import { IssuesModule } from "./modules/issues/issues.module";
import { EditorModule } from "@tinymce/tinymce-angular";
import { RootsMainMenuButtonComponent } from './shared/components/roots-main-menu-button/roots-main-menu-button.component';
import { NavService } from "./core/components/menu-list-item/nav.service";
import * as $ from 'jquery'; 
import { ServiceWorkerModule } from "@angular/service-worker";
import { UtilitiesModule } from "./modules/utilities/utilities.module";
import { AlertsModule } from "./modules/alerts/alerts.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
  suppressScrollY: false,
};
const msAdalConfig = {
  tenant: environment.config.tenant,
  clientId: environment.config.clientId,
  redirectUri: environment.config.redirectUri,
  postLogoutRedirectUri: environment.config.postLogoutRedirectUri,
  cacheLocation: "localStorage",
  navigateToLoginRequestUrl: false,
  endpoints: {
    "https://escoutd.azurewebsites.net/": "abc03a69-303c-4653-84a0-fceea9fdf655",
    "https://admin.earthscout.com": "938bb782-2f82-4b38-90a4-ded620c32ed1",
    "https://eadmin-dev.azurewebsites.net/": "938bb782-2f82-4b38-90a4-ded620c32ed1",
    "https://eadmin-staging.azurewebsites.net/": "938bb782-2f82-4b38-90a4-ded620c32ed1",
    "http://localhost:4202": "abc03a69-303c-4653-84a0-fceea9fdf655",
  },
  anonymousEndpoints: [],
  isAngular: true,
};

@NgModule({
  declarations: [
    AppComponent, 
    SearchbarComponent, 
    MenuListItemComponent,
    MenuTopItemComponent,
    CdkDetailRowDirective,
    AvatarComponent,
    NotFoundComponent, 
    ServerErrorComponent,
    RootsMainMenuButtonComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MsAdalAngular6Module.forRoot(msAdalConfig),
    BrowserTransferStateModule,
    CoreModule,
    AppRoutingModule,
    SharedModule,
    SettingsModule,
    UtilitiesModule,
    DashboardModule,
    LogsModule,
    AccountModule,
    FirmwareModule,
    ReportsModule,
    ReferencesModule,
    CustomerModule,
    AssetModule,
    AlertsModule,
    ScoutModule,
    IssuesModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    GroupsModule,
    GridsterModule,
    BrowserAnimationsModule,
    EditorModule
  ],
  providers: [
    AuthGuard,
    AdminAuthGuard,
    ManufacturingAuthGuard,
    SearchBarService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    NavService
  ],
  entryComponents: [SearchbarComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}