<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  (refresh)="refresh($event)"
>
</roots-page-header>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
<!-- <section>
  <mat-table
    #sort="matSort"
    matSort
    [dataSource]="dataSource"
    class="main example-container"
  >
    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Customer
      </mat-header-cell>
      <mat-cell *matCellDef="let auditLogs">
        {{ auditLogs.customerName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let auditLogs">
        {{ auditLogs.username }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let auditLogs">
        {{ auditLogs.authUsername }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="maxHitEndPoint">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Most Called Service
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let auditLogs">
        {{ auditLogs.maxHitEndPoint }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="endPointHitCount">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Calls
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let auditLogs">
        {{ auditLogs.endPointHitCount }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pings">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Total Calls
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let auditLogs">
        {{ auditLogs.totalPayload }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="20" [pageSizeOptions]="[2, 4, 6, 10, 20]">
  </mat-paginator>
</section> -->
