import {
  Component,
  Input
} from "@angular/core";
import { Router } from "@angular/router";
import { SnackService } from "../../services/extra/snack.service";

@Component({
  selector: "app-roots-details-data-list",
  templateUrl: "./roots-details-data-list.component.html",
})
export class RootsDetailsDataListComponent {
  @Input("data") data: any[];
  //========================
  @Input("title") title: string;
  @Input("prop_1") prop_1: string;
  @Input("prop_2") prop_2: string;
  @Input("prop_3") prop_3: string;
  @Input("prop_4") prop_4: string;
  @Input("prop_5") prop_5: string;
  @Input("prop_6") prop_6: string;
  @Input("icon") icon: string;
  @Input("type") type: number;
  /**
   *
   */
  constructor(private _router: Router,
    private _snack: SnackService
    ) {}
  clickEvent($event) {
    console.log(this.data);
    console.log($event);
  }

  navigateToType(data: any){
    switch (this.type) {
      case 0:
        //asset
      this._router.navigate([`/assets/${data.id}`])
        break;
      case 1:
        //scouts
        this._router.navigate([`/scouts/${data.id}`])
        break;
      case 2:
        //users
        this._router.navigate([`customers/${data.customerId}/users/${data.id}`])
        break;
      case 3:
        //asset ownership
        this._router.navigate([`/assets/${data.assetId}`])
        break;
      case 4:
        //installs
        this._router.navigate([`/scouts/${data.scoutId}`])
        break;
        case 5:
        //users
        this._snack.displayInfo("User entity selected - need more info to navigate.")
        // this._router.navigate([`/scouts/${data.scoutId}`])
          break;
          case 6:
          //usergroups
        this._snack.displayInfo("UserGroup entity selected - need more info to navigate.")
          // this._router.navigate([`/scouts/${data.scoutId}`])
            break;
      default:
        break;
    }
  }
}
