import { Component, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SnackService } from "../../../../../shared/services/extra/snack.service";
@Component({
  selector: "app-voltage-dialog",
  templateUrl: "./voltage-dialog.component.html"
})
export class VoltageDialogComponent {
  json: any;
  public event: EventEmitter<any> = new EventEmitter();
  submitted = false;
  loading = false;
  min: number;
  max: number;
  hasGeo : boolean;
  constructor(
    public dialogRef: MatDialogRef<VoltageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snack: SnackService
  ) {
      this.min = data.min;
      this.max = data.max;
      this.hasGeo = data.geo;
  }

  onNoClick(): void {
    this.event.emit(false);
    this.dialogRef.close();
  }

  yesClicked() {
    this.event.emit({min: this.min, max: this.max, geo: this.hasGeo});
  }

  geoCheck() {
    this.hasGeo = !this.hasGeo;
  }


  changeVoltage(min: number, max: number) {
    this.loading = true;
    console.log('min: ' + min);
    console.log('max: ' + max);
    //change volt values
  }
}
