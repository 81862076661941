import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SnackService } from "../../services/extra/snack.service";

export interface RootsTableActions<T> {
  name: string;
  icon: string;
  clicked: EventEmitter<T>;
}

export class RootsTableDataSource<T> extends MatTableDataSource<T> {
  hasAdditionalData = false;
  hasActionButtons = true;
  actionButtons: RootsTableActions<T>[];
}

@Component({
  selector: "roots-simple-table",
  templateUrl: "./roots-simple-table.component.html",
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RootsSimpleTableComponent implements AfterViewInit {
  // private variables for component that get and set
  private _dataSource = new RootsTableDataSource<any>();
  private _tableCols: string[] = []
  private _message: string;
  private _clickingEnabled: boolean;

  // local variables for table
  dateNow = new Date(Date.now());
  additionalColumns: string[] = ["expandedDetail"];
  expandedData: any[] = [];
  additionalDataIsarray = false;
  representAColumnAsButtons = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  //For additional actions on entities - CRUD, dialogs, modals
  @Output("actionsClicked") actionsEmit = new EventEmitter();
  @Output("pulledRowData") pulledRowDataEmitter = new EventEmitter();
  
  @Input("clickingEnabled") 
  public set clickingEnabled(clickingEnabled : boolean) {
    this._clickingEnabled = clickingEnabled;
  }
  public get clickingEnabled() : boolean {
    return this._clickingEnabled;
  }
  @Input("tableColumns") 
  public set tableCols(tableCols : string[]) {
    for (let i = 0; i < tableCols.length; i++) {
      const col = tableCols[i];
      if (col.includes('buttons')) {
        this.representAColumnAsButtons = true;
      }
    }
    this._tableCols = tableCols;
  }
  public get tableCols() : string[] {
    return this._tableCols;
  }
  @Input("filter")
  set message(message: string) {
    if (message.length > 0) {
      this.onSearchInput(message);
    } else {
      this.onSearchInput(message);
    }
    this._message = message;
  }
  get message() {
    return this._message;
  }
  @Input("dataSource")
  set dataSource(dataSource: RootsTableDataSource<any>) {
    if (dataSource.data.length > 0) {
      dataSource.sort = this.sort;
      dataSource.paginator = this.paginator;
      this._snack.displaySuccess("Entity data found");
    } else {
      this._snack.displayError("No entity data found");
    }
    this._dataSource = dataSource;
  }
  get dataSource() {
    return this._dataSource;
  }

  constructor(private _snack: SnackService) {}
  ngAfterViewInit(): void {
    this.initTable();
  }


  private initTable() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onSearchInput(message: string) {
    const searchTarget = message;
    this.dataSource.filter = searchTarget.trim().toLowerCase();
    if (this.dataSource.filteredData.length == 0) {
      this._snack.displaySearchError("No search results found");
    } else if (
      this.dataSource.filteredData.length > 0 &&
      this.dataSource.filter.length > 3
    ) {
      this._snack.displaySearchSuccess(
        `(${this.dataSource.filteredData.length}) Results found`
      );
    }
  }

  isExpansionDetailRow = (index, row) => row.hasOwnProperty("detailRow");

  checkExpanded(data): boolean {
    if (this.clickingEnabled) {
      let flag = false;
      for (let i = 0; i < this.expandedData.length; i++) {
        const e = this.expandedData[i];
        if (e === data) {
          flag = true;
        }
      }
      return flag;
    } else {
      return false;
    }
  }

  pushPopElement(data) {
    if (this.clickingEnabled) {
      const index = this.expandedData.indexOf(data);
      console.log(index);
      if (index === -1) {
        this.pulledRowDataEmitter.emit(data);
        this.expandedData.push(data);
        console.log(data);
      } else {
        this.expandedData.splice(index, 1);
      }
    } else {
      console.log('Do not push elements')
    }
  }
}
