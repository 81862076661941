import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from '../../../services/admin.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-groups-dialog',
  templateUrl: './groups-dialog.component.html',
  styleUrls: ['./groups-dialog.component.scss']
})
export class GroupsDialogComponent {
  members: any[];
  scouts: any[];
  customerGroups: any[] = [];
  customers: any[] = [];
  selectedGroup: any;
  selected = false;
  users: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<GroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _adminService: AdminService
    ) {
      this.selectedGroup = this.data.group;
      this.customerGroups = this.data.customerGroups;
      this.customers = this.data.customers;
      this.serviceCalls(this.selectedGroup.id);
  }

  serviceCalls(groupId: string) {
    const memberCall = this._adminService.getAdminUserGroupMember(groupId);
    const scoutCall = this._adminService.getAdminUserGroupScout(groupId);
    const getCustomersUsers = this._adminService.getCustomersUsers(groupId)

    forkJoin([memberCall, scoutCall, getCustomersUsers])
    .subscribe(result => {
      const members = result[0];
      const scouts = result[1];
      const customerUsers = result[2];

      this.members = members;
      this.scouts = scouts;
      this.users = customerUsers;
      // console.log(scouts, members, customerUsers)
    })
  }

  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    console.log(this.data);
    this.dialogRef.close();
  }

  groupChanged($event) {
    console.log($event);
    const id = $event.value;
    this.serviceCalls(id);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
