<div mat-dialog-content>
  <div class="col-12">
    <div *ngIf="reminder.length > 0; else noReminder">
      <p class="list-inline-item font-weight-bold text-uppercase">Reminder</p>
      <!-- list of buttons to navigate -->
      <!-- <i  (click)="clearReminder()"
      matTooltip="Clear Reminder" matTooltipPosition="below" class="material-icons">close</i> -->
      <button class="btn btn-secondary scanner" style="right: 0x; position: absolute; margin-right: 100px;"
        mat-raised-button color="primary" (click)="clearReminder()"
        matTooltip="Clear Reminder" matTooltipPosition="below" >
          <p class="text btn-text">
            Clear Reminder
          </p>
      </button>
      <button class="btn btn-secondary scanner" style="right: 0px; position: absolute;"
        mat-raised-button color="primary" (click)="okayClick()">
          <p class="text btn-text">
            Dismiss
          </p>
      </button>
      <br>
      <hr>
      <h3>{{reminder}}</h3>
    </div>
    <ng-template #noReminder>
      <h3>No user reminder</h3>
    </ng-template>
    <hr>
  </div>
</div>

<!-- <div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">No</button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div> -->