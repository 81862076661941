<roots-page-header [toolTip]="toolTip" [title]="title" [loading]="loading" (refresh)="getAssetData(assetId)">
</roots-page-header>
<div class="map-panel" *ngIf="installs.length > 0">
    <mat-accordion class="col-12">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title class="panel-title"> Map </mat-panel-title>
                <mat-panel-description class="panel-desc">
                    Scout Location
                    <mat-icon>map</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-asset-map class="map" [installs]="installs" [coordinates]="coordinates"></app-asset-map>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<div class="col-12">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left;" *ngIf="ownershipHistory">
        <mat-card *ngIf="scoutId">
            <mat-card-actions class="edges">
                <app-scout-interval [scoutId]="scoutId" [counterValue]="interval" [scout]="scout">
                </app-scout-interval>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="asset">
            <!-- <app-asset-view [asset]="asset"></app-asset-view> -->
            <h3 class="text label-header-text">Details</h3>
            <mat-form-field>
                <mat-label> Asset</mat-label>
                <input class="text dashboard-text" matInput placsseholder="Asset" [(ngModel)]="model.basicName"
                    disabled>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Asset Name</mat-label>
                <input class="text dashboard-text" matInput placeholder="Name" [(ngModel)]="asset.basicName" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="activeScout">
                <mat-label>Scout Name</mat-label>
                <input class="text dashboard-text" matInput placeholder="Scout Name" [(ngModel)]="activeScout.name"
                    required>
            </mat-form-field>

            <mat-form-field *ngIf="activeSensorName">
                <mat-label>Sensor Name</mat-label>
                <input class="text dashboard-text" matInput placeholder="Sensor Name" [(ngModel)]="activeSensorName"
                    disabled>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Description</mat-label>
                <input class="text dashboard-text" matInput placeholder="Description"
                    [(ngModel)]="asset.basicDescription" required>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Code</mat-label>
                <input class="text dashboard-text" matInput placeholder="Code" [(ngModel)]="asset.code" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="activeScout">
                <mat-label>Firmware Version</mat-label>
                <input class="text dashboard-text" matInput placeholder="Firmware Version"
                    [(ngModel)]="asset.firmwareVersion" disabled>
            </mat-form-field>

            <mat-label class="col-12 text dashboard-text">Edit Asset model ONLY if you know what you're doing.
            </mat-label>
            <br>
            <mat-label>
                <button class="col-12 text dashboard-text" style="color: red;" mat-icon-button
                    (click)="enableModelChange()" *ngIf="!MODEL_CHANGE">
                    Unlock Asset Model Edit
                    <mat-icon>lock</mat-icon>
                </button>
                <button class="col-12 text dashboard-text" style="color: green;" mat-icon-button
                    (click)="enableModelChange()" *ngIf="MODEL_CHANGE">
                    Asset Model Edit Unlocked
                    <mat-icon>lock_open</mat-icon>
                </button>
            </mat-label>
            <hr>

            <mat-form-field
                *ngIf="asset.connectivityBaseScoutConnectivityCode && asset.connectivityBaseScoutConnectivityCode !== null"
                disabled>
                <mat-label> Base Connectivity Code</mat-label>
                <input class="text dashboard-text" matInput placeholder="Code"
                    [(ngModel)]="asset.connectivityBaseScoutConnectivityCode">
            </mat-form-field>

            <mat-form-field>
                <mat-select matInput placeholder="Asset Model" [(ngModel)]="asset.assetModelId" name="model"
                    [disabled]="MODEL_CHANGE == false">
                    <mat-option *ngFor="let model of assetModel | sort: 'code'" [value]="model.id"
                        (click)="modelChanged(model)" class="text dashboard-text">
                        {{ model.basicName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select matInput placeholder="Asset Status" [(ngModel)]="asset.assetStatuslId" name="status">
                    <mat-option *ngFor="let status of assetStatus | sort: 'code'" [value]="status.id"
                        class="text dashboard-text">
                        {{ status.basicName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div class="sideBySide">
                <mat-form-field class="left">
                    <mat-label> Created At</mat-label>
                    <input class="text dashboard-text" matInput placeholder="Created At"
                        [ngModel]="asset.createdAt | date: 'short'" disabled>
                </mat-form-field>

                <mat-form-field class="right">
                    <mat-label> Updated At</mat-label>
                    <input class="text dashboard-text" matInput placeholder="Updated At"
                        [ngModel]="asset.updatedAt | date: 'short'" disabled>
                </mat-form-field>
            </div>

            <button class="text dashboard-text" mat-raised-button type="submit" style="width: fit-content"
                (click)="onUpdate(asset)" color="primary">Update
            </button>
            <!-- <button class="text dashboard-text" mat-raised-button type="submit" style="width: fit-content"
                (click)="deleteDialog(asset)" color="warn" class="close">Delete
            </button> -->
        </mat-card>
    </div>

    <app-notes *ngIf="asset" [entityId]="asset.id" [entityCode]="asset.code" [entityType]="'Asset'" [notes]="notes"
        (noteAdded)="noteAdded($event)">
    </app-notes>

    <!--Scout configurations-->
    <app-roots-details-data-list [data]="scouts" [prop_1]="'assetName'" [prop_2]="'content'" [type]="1"
        [icon]="'widgets'" [title]="'Scouts'">
    </app-roots-details-data-list>

    <!--Asset ownership history-->
    <app-roots-details-data-list [data]="ownershipHistory" [prop_1]="'customerName'" [prop_2]="'assetName'"
        [prop_3]="'whenBegin'" [prop_4]="'whenEnd'" [type]="3" [icon]="'widgets'" [title]="'Ownership History'">
    </app-roots-details-data-list>

    <!--Installations-->
    <app-roots-details-data-list [data]="formattedInstalls" [prop_1]="'whenBegin'" [prop_2]="'whenEnd'"
        [prop_3]="'coordinateLatitude'" [prop_4]="'coordinateLongitude'" [prop_5]="'coordinateAltitude'" [type]="4"
        [icon]="'widgets'" [title]="'Installs'">
    </app-roots-details-data-list>

</div>