<div mat-dialog-content>
  <h3 mat-dialog-title 
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" 
  cdkDragHandle>{{title}}</h3>
  <div *ngFor="let item of _items">
    <p (click)="navigateToItem(item)">
      {{item[property_1]}} - {{item[property_2]}}
    </p>
    <br>
    <p>
      {{item[property_3]}}
    </p>
    <hr>
  </div>
</div>
<div mat-dialog-actions>
  <div class="col-12">
    <button
      class="btn btn-secondary scanner"
      style="right: 0px; position: absolute"
      mat-raised-button
      color="primary"
      (click)="okayClick()"
    >
      <p class="text btn-text">Close</p>
    </button>
  </div>
</div>
