
import {map,  catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Holding } from '../../interfaces/holding';
import { Body } from '../../interfaces/body';
import { Subject } from '../../interfaces/subject';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, UserType, ConstantService } from '../..';
import { UserGroup } from '../../interfaces/user-group';
import { Scout } from '../../interfaces/scout';

@Injectable()
export class UserService {
  URL = `${this._constService.CONST.URL}tables`;
  private userURL = `${this._constService.CONST.URL}api`;
  private token: any;
  constructor(
    private http: HttpClient,
    private _constService: ConstantService
  ) {
    this.token = localStorage.getItem('id_token');
    this.retreiveUserDetails();
  }

  public getScouts(): Observable<Scout[]> {
    return this.http
      .get<Scout[]>(this.URL + '/Scout', { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return resp;
      }))
      .pipe(
        tap(_ => console.log(`fetched Scout`)),
        catchError(this._constService.handleError<Scout[]>(`getScout`))
      );
  }

  public getUserGroups(): Observable<UserGroup[]> {
    return this.http
      .get<UserGroup[]>(this.URL + '/UserGroup', { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return resp;
      }))
      .pipe(
        tap(_ => console.log(`fetched UserGroup`)),
        catchError(this._constService.handleError<UserGroup[]>(`getUserGroup`))
      );
  }

  checkServer(){
    let params: any = {
      "ZUMO-API-VERSION": "2.0.0"
    };
    return this.http
      .get<any>(this.userURL + `/sys/metainfo`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }


  public getUserGroup(id: string): Observable<UserGroup[]> {
    return this.http
      .get<UserGroup[]>(this.URL + '/UserGroup' + `/${id}`, { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return resp;
      }))
      .pipe(
        tap(_ => console.log(`fetched UserGroup`)),
        catchError(this._constService.handleError<UserGroup[]>(`getUserGroup`))
      );
  }

  public retreiveUserDetails(): Observable<ReadingUser> {
    return this.http
    .get<ReadingUser>(this.userURL + '/ReadingUser', { params: this._constService.defaultParams }).pipe(
    map((resp: any) => {
      return this.parseUserDetails(resp);
    }))
    .pipe(tap(_ => console.log(`fetched ReadingUser`)),
    catchError(this._constService.handleError<ReadingUser>(`getReadingUser`))
    );
  }

  private parseUserDetails(data: any): ReadingUser {
    const currentUser: ReadingUser = <ReadingUser> {
      basicDescription: data.basicDescription,
      basicName: data.basicName,
      code: data.code,
      createdAt : data.createdAt,
      deleted : data.deleted,
      id : data.id,
      isAvailable : data.isAvailable,
      updatedAt : data.updatedAt,
      username : data.username,
      version : data.version,
      healthSensorId : data.healthSensorId,
      assetOwnershipId : data.assetOwnershipId,
      content : data.content,
      contact_Address : data.contact_Address,
      contact_Cellphone : data.contact_Cellphone,
      contact_Email : data.contact_Email,
      contact_Telephone : data.contact_Telephone,
      contact_Name : data.contact_Name,
      systemUser_Username : data.systemUser_Username,
      languageTag : data.languageTag,
      customerId : data.customerId,
      userTypeId : data.userTypeId,
      regionId : data.regionId
    };
    return currentUser;
  }

  public getUser(id: string): Observable<User> {
    return this.http
      .get<User>(this.URL + '/User' + '/' + id, { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return this.parseUser(resp);
      }))
      .pipe(tap(_ => console.log(`fetched User`)),
        catchError(this._constService.handleError<User>(`getUser`))
      );
  }

  private parseUser(data): User {
    const currentUser: User = <User>{
      basicDescription : data.basicDescription,
      basicName : data.basicName,
      code : data.code,
      contact_Address : data.contact_Address,
      contact_Cellphone : data.contact_Cellphone,
      contact_Email : data.contact_Email,
      contact_Name : data.contact_Name,
      contact_Telephone : data.contact_Telephone,
      createdAt : data.createdAt,
      deleted : data.deleted,
      id : data.id,
      isAvailable : data.isAvailable,
      languageTag : data.languageTag,
      regionId : data.regionID,
      systemUser_Username : data.systemUser_Username,
      updatedAt : data.updatedAt,
      username : data.username,
      version : data.version
    };
    return currentUser;
  }

  public getHoldings(): Observable<Holding[]> {
    return this.http
      .get<Holding[]>(this.URL + '/tables', { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return this.parseHolding(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched Holdings`)),
        catchError(this._constService.handleError<Holding[]>(`getHolding`))
      );
  }

  private parseHolding(data): Holding[] {
    const holdings: Holding[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const holding: Holding = <Holding>{
        holdingTypeId: item.holdingTypeId,
        customerId: item.customerId,
        hierarchySuperId: item.hierarchySuperId,
        hierarchyLevel: item.hierarchyLevel,
        hierarchyMaterialisedPath: item.hierarchyMaterialisedPath,
        username: item.username,
        code: item.code,
        basicName: item.basicName,
        basicDescription: item.basicDescription,
        isAvailable: item.isAvailable,
        id: item.isAvailable,
        version: item.version,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        deleted: item.deleted
      };
      holdings.push(holding);
    }
    return holdings;
  }

  public getBodies(): Observable<Body[]> {
    return this.http
      .get<Body[]>(this.URL + '/Body', { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return this.parseBody(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched Bodies`)),
        catchError(this._constService.handleError<Body[]>(`getBody`))
      );
  }

  private parseBody(data): Body[] {
    const bodies: Body[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const body: Body = <Body>{
        holdingId: item.holdingId,
        subjectId: item.subjectId,
        whenBegin: item.whenBegin,
        whenEnd: item.whenEnd,
        content: item.content,
        username: item.username,
        code: item.code,
        basicName: item.basicName,
        basicDescription: item.basicDescription,
        isAvailable: item.isAvailable,
        id: item.id,
        version: item.version,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        deleted: item.deleted
      };
      bodies.push(body);
    }
    return bodies;
  }

  getUserType(id: string): Observable<UserType> {
    return this.http
    .get<UserType>(this.URL + '/UserType/' + id, {params: this._constService.defaultParams}).pipe(
    map((resp: any) => {
      return this.parseUserType(resp);
    }))
    .pipe(
      tap(_ => console.log(`fetched UserType`)),
      catchError(this._constService.handleError<UserType>(`getUserType`))
    );
  }

  private parseUserType(resp: any): UserType {
    const userType: UserType = {
      username: resp.username,
      code: resp.code,
      basicName: resp.basicName,
      basicDescription: resp.basicDescription,
      id: resp.id,
      createdAt: resp.createdAt,
      updatedAt: resp.updatedAt,
      isAvailable: resp.isAvailable,
      version: resp.version,
      deleted: resp.deleted
    };
    this._constService.userType = userType;
    return userType;
  }

  public getUserTypes(): Observable<UserType[]> {
    return this.http
    .get<UserType[]>(this.URL + '/UserType', {params: this._constService.defaultParams}).pipe(
    map((resp: any) => {
      return this.parseUserTypes(resp);
    }))
    .pipe(
      tap(_ => console.log(`fetched UserTypes`)),
      catchError(this._constService.handleError<UserType[]>(`getUserTypes`))
    );
  }

  private parseUserTypes(data): UserType[] {
    const userTypes: UserType[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const type: UserType = <UserType>{
        username: item.username,
        code: item.code,
        basicName: item.basicName,
        basicDescription: item.basicDescription,
        isAvailable: item.isAvailable,
        id: item.id,
        version: item.version,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        deleted: item.deleted
      };
      userTypes.push(type);
    }
    return userTypes;
  }

  public getSubjects(): Observable<Subject[]> {
    return this.http
      .get<Subject[]>(this.URL + '/Subject', { params: this._constService.defaultParams }).pipe(
      map((resp: any) => {
        return this.parseSubject(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched Subjects`)),
        catchError(this._constService.handleError<Subject[]>(`getSubject`))
      );
  }

  private parseSubject(data): Subject[] {
    const subjects: Subject[] = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const subject: Subject = <Subject>{
        content: item.content,
        username: item.username,
        code: item.code,
        basicName: item.basicName,
        basicDescription: item.basicDescription,
        isAvailable: item.isAvailable,
        id: item.id,
        version: item.version,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        deleted: item.deleted
      };
      subjects.push(subject);
    }
    return subjects;
  }

  isUserPermitted(userType: string): boolean {
    switch (userType) {
      case 'sme':
        return true;
      case 'super':
        return true;
      case 'admin':
        return false;
      case 'user':
        return false;
      case 'advanced':
        return true;
      case 'devadmin':
        return true;
      case 'productionadmin':
        return false;
      case 'supportadmin':
        return true;
      default:
        break;
    }
  }
}

export class ReadingUser {
  deleted: boolean;
  updatedAt: string;
  createdAt: string;
  version: string;
  id: string;
  isAvailable: number;
  basicDescription?: string;
  basicName: string;
  code: string;
  username: string;
  healthSensorId: string;
  assetOwnershipId: string;
  content: string;
  contact_Address: string;
  contact_Email: string;
  contact_Cellphone: string;
  contact_Name: string;
  contact_Telephone: string;
  customerId: string;
  languageTag: string;
  regionId: string;
  systemUser_Username: string;
  userTypeId: string;
}

