import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CustomerAssetsComponent } from "./assets/customer-assets.component";
import { CustomerComponent } from "./customer.component";
import { CustomerEditComponent } from "./edit/customer-edit.component";
import { CustomerOwnershipComponent } from "./ownership/customer-ownership.component";
import { CustomerScoutsComponent } from "./scouts/customer-scouts.component";
import { UserGroupEditComponent } from "./user-groups/edit/user-group-edit.component";
import { UserGroupManageComponent } from "./user-groups/manage/user-group-manage.component";
import { UserGroupsComponent } from "./user-groups/user-groups.component";
import { UserEditComponent } from "./users/edit/user-edit.component";
import { UsersComponent } from "./users/users.component";

const routes: Routes = [
  { path: "", component: CustomerComponent },
  { path: ":id", component: CustomerEditComponent },
  { path: ":id/ownership", component: CustomerOwnershipComponent },
  { path: ":id/assets", component: CustomerAssetsComponent },
  { path: ":id/scouts", component: CustomerScoutsComponent },
  { path: ":id/users", component: UsersComponent },
  { path: ":id/users/:uid", component: UserEditComponent },
  { path: ":id/usergroups", component: UserGroupsComponent },
  { path: ":id/usergroups/:uid", component: UserGroupEditComponent },
  { path: ":id/usergroups/:uid/manage", component: UserGroupManageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {}
