<div class="roots-table-container mat-elevation-z8">
  <mat-table 
  #sort="matSort"
  matSort
  [dataSource]="dataSource"
  style="flex: 1 1 auto;"
>
  <ng-container
    *ngFor="let actionCol of actionCols; index as i"
    matColumnDef="{{ actionCol.name }}"
  >
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ actionCol.name | titlecase }}
    </mat-header-cell>
    <mat-cell *matCellDef="let entity; let e = index">
      <ng-container *ngIf="actionCol.icon; else noIcon">
        <!-- If there is a switch present, put its switch values here base on prop name-->
      <ng-container *ngIf="actionCol.switch; else noSwitch"> 
        <ng-container *ngIf="entity[actionCol.switch[0].PROP_name]; else falseValue" class="center">
          <button
            mat-raised-button
            color="primary"
            (click)="switchClicked(actionCol.switch[0], entity)"
            matTooltip="{{actionCol.switch[0].toolTip}}"
          > 
          {{actionCol.switch[0].name}}
          </button>
        </ng-container>
        <ng-template #falseValue
        class="center">
          <button
            mat-raised-button
            color="warn"
            (click)="switchClicked(actionCol.switch[1], entity)"
            matTooltip="{{actionCol.switch[1].toolTip}}"
          > 
          {{actionCol.switch[1].name}}
          </button>
        </ng-template>
      </ng-container>
      <ng-template #noSwitch
      class="center">
        <button
          mat-icon-button
          color="primary"
          (click)="actionClicked(entity, actionCol)"
          matTooltip="{{actionCol.toolTip}}"
          matTooltipPosition="above"
        > 
          <mat-icon class="mat-18">{{actionCol.icon}}</mat-icon>
        </button>
      </ng-template>
      </ng-container>
      <ng-template #noIcon>
        <ng-container *ngIf="actionCol.textButton === true; else noTextButton">
          <button mat-button 
          color="primary"
          (click)="actionClicked(entity, actionCol)"
          matTooltip="{{actionCol.toolTip}}"
          matTooltipPosition="above">
          {{ entity[actionCol.name] }}
        </button>

        </ng-container>
        <ng-template #noTextButton>
          {{ entity[actionCol.name] }}
        </ng-template>
        <!-- {{ entity[actionCol.name] }} -->

      </ng-template>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="actionNameList"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: actionNameList"
    matRipple
    class="example-element-row"
    (click)="addDataToCustomDataSet(row)"
  >
  </mat-row>
</mat-table>

<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[1, 2, 3, 5, 10, 20]" showFirstLastButtons>
</mat-paginator>
</div>