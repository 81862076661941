import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConstantService } from './constant.service';
import { KnowledgeReference } from '../interfaces/knowledge-reference';

@Injectable()
export class ReferencesService {
  URL = `${this.constService.CONST.URL}tables/KnowledgeReference`;
  params: any = {
    'ZUMO-API-VERSION': this.constService.CONST.ZUMOAPIVersion
  };
  constructor(
    private _http: HttpClient,
    private constService: ConstantService
  ) { }

  public getKnowledgeReferences(): Observable<KnowledgeReference[]> {
    const params: any = {
      'ZUMO-API-VERSION': this.constService.CONST.ZUMOAPIVersion
    };
    return this._http
      .get<KnowledgeReference[]>(this.URL, { params: params }).pipe(
      map((resp: any) => {
        return this.parseKnowledgeReference(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched KnowledgeReference`)),
        catchError(this.constService.handleError<KnowledgeReference[]>(`getKnowledgeReference`))
      );
  }

  public getAppKnowledgeReferences(pageNumber: number, pageSize: number): Observable<KnowledgeReference[]> {
    const params: any = {
      'ZUMO-API-VERSION': this.constService.CONST.ZUMOAPIVersion
    };
    const loc = `${this.constService.CONST.URL}api/app/KnowledgeReference?pagenumber=${pageNumber}&pagesize=${pageSize}`;
    const loc2 = `${this.constService.CONST.URL}api/app/KnowledgeReference`;
    return this._http
      .get<KnowledgeReference[]>(this.URL, { params: params }).pipe(
      map((resp: any) => {
        return this.parseKnowledgeReference(resp);
      }))
      .pipe(
        tap(_ => console.log(`fetched KnowledgeReference`)),
        catchError(this.constService.handleError<KnowledgeReference[]>(`getKnowledgeReference`))
      );
  }

  public deleteKnowledgeReference(referenceId: string): Observable<number> {
    const URL = `${this.URL}/${referenceId}`;
    return this._http
    .delete(URL, { params: this.params }).pipe(
    map((res: any) => {
      return res;
    }),
    tap(data => console.log('References delete returned:', data)),);  // debug
  }

  public postKnowledgeReferences(reference: KnowledgeReference): Observable<KnowledgeReference> {
    const params: any = {
      'ZUMO-API-VERSION': this.constService.CONST.ZUMOAPIVersion
    };
    return this._http
      .post<KnowledgeReference>(this.URL, reference, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.constService.handleErrorObservable),);
  }

  private parseKnowledgeReference(data): KnowledgeReference[] {
    const knowledgeReference: KnowledgeReference[] = [];
    for (let i = 0; i < data.length; i++) {
      const reference = data[i];
      const knowledge: KnowledgeReference = <KnowledgeReference>{
        userId: reference.userId,
        content: reference.content,
        scope: reference.scope,
        username: reference.username,
        code: reference.code,
        basicName: reference.basicName,
        basicDescription: reference.basicDescription,
        isAvailable: reference.isAvailable,
        id: reference.id,
        version: reference.version,
        createdAt: reference.createdAt,
        updatedAt: reference.updatedAt,
        deleted: reference.deleted
      };
      knowledgeReference.push(knowledge);
    }
    return knowledgeReference;
  }
}
