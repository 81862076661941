import { Component, Input } from "@angular/core";

@Component({
    selector: "roots-loading",
    templateUrl: "./roots-loading.component.html",
    styleUrls: ["./roots-loading.component.scss"]
  })
export class RootsLoadingComponent {
    _loading: boolean;
    
    @Input("loading")
    public get loading() : boolean {
        return this._loading;
    }
    public set loading(loading : boolean) {
        this._loading = loading;
    }
    
    
}