import { Component, Input, OnInit } from '@angular/core';

export interface RootsNavigationItem {
  icon: string;
  name: string;
  toolTip?: string;
  link: string;
  subNavItems? : RootsNavigationItem[];
  isActive?: boolean;
}

export const NAV_LIST: RootsNavigationItem[] = [
  {
    icon: 'device_hub',
    name: 'Logs',
    link: '/manufacturinglogs',
    toolTip: 'Logs of type scout in prodscoutlog',
    subNavItems: [
      {
        icon: 'device',
        name: 'Scout Logs',
        link: '/manufacturinglogs/scouts',
        toolTip: 'Logs of type scout in prodscoutlog'
      },
      {
        icon: 'device',
        name: 'Cub Logs',
        link: '/manufacturinglogs/cubs',
        toolTip: 'Logs of type cub in prodscoutlog'
      }
    ]
  },
  {
    icon: 'device_hub',
    name: 'Reports',
    link: '/reports',
    toolTip: 'Reports Tab for analyzing general data'
  }
]

@Component({
  selector: 'app-roots-navigation',
  templateUrl: './roots-navigation.component.html'
})
export class RootsNavigationComponent implements OnInit {
  private _items: RootsNavigationItem[];

  @Input('items')
  public get items() : RootsNavigationItem[] {
    return this._items;
  }
  
  public set items(items : RootsNavigationItem[]) {
    this._items = items;
  }
  rootsLogoURL = '';

  constructor() {
    this.items  = NAV_LIST;
    this.rootsLogoURL = "assets/images/roots_menuLogo_d3.png";
   }

  ngOnInit(): void {
  }

}
