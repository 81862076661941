import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;

  constructor() { }

  ngOnInit(): void {
  }

  tabSelectionChanged(event){
    // Get the selected tab
    let selectedTab = event.tab;
    console.log(selectedTab);
  }
}
