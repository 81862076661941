import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NavItem} from '../nav-item.interface';
import { NavService } from '../menu-list-item/nav.service';
import { Subscription } from 'rxjs';
  
@Component({
  selector: 'app-menu-top-item',
  templateUrl: './menu-top-item.component.html'
})
export class MenuTopItemComponent implements OnInit, OnDestroy {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  navSubscription: Subscription;
  constructor(
      public navService: NavService,
        public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navSubscription = this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }
  
  ngOnDestroy() {
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      // this.router.navigate([item.route]);
      // this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }

  onItemNavigate(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}

