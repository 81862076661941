import { Injectable } from '@angular/core';
const APP_PREFIX = 'earthscout-';

@Injectable()
export class LocalStorageService {
  csvJSON: any;
  constructor() {}

  static loadInitialState() {
    return Object.keys(localStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map(key =>
            key
              .split('-')
              .map(
                (token, index) =>
                  index === 0
                    ? token
                    : token.charAt(0).toUpperCase() + token.slice(1)
              )
              .join('')
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  setPageFilters() {
    localStorage.setItem('dashboard_search', null);
    localStorage.setItem('reports_search', null);
    localStorage.setItem('customers_search', null);
    localStorage.setItem('assets_search', null);
    localStorage.setItem('references_search', null);
  }

  addRowToCustomDataTable(row: any) {
    const currentCsvDataSet = this.getItem('csvCustomDataSet');
    if (!currentCsvDataSet) {
      this.setItem('csvCustomDataSet', row)
      return;
    } else {
      const csvObject = this.getCurrentCustomDataTable();
      console.log(csvObject);
      // const count = currentCsvDataSet
      // currentCsvDataSet += row;
    }
  }

  getCurrentCustomDataTable() {
    const data = this.getItem('csvCustomDataSet');
    const jsonData = JSON.parse(data);
    return jsonData;
  }

  saveFilter(filter: string) {
    localStorage.setItem('prevFilter', 'test');
    localStorage.setItem('filter', filter);
  }

  loadFilter(): string {
    console.log('filter saved: ' + localStorage.getItem('filter'));
    return localStorage.getItem('filter');
  }

  setItem(key: string, value: any) {
    localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string) {
    return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
  }
}
