import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AuthenticationService } from './authentication.service';
import { ConstantService } from '../../shared';
import { UserService } from '../../shared/services/api/user.service';

@Injectable()
export class AdminAuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private _adalService: MsAdalAngular6Service,
        private _authService: AuthenticationService,
        private _constService: ConstantService,
        private _userService: UserService,
        private _router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this._adalService.isAuthenticated) {
            if (this._constService._isTypeAdmin == undefined) {
                this._userService.retreiveUserDetails().toPromise().then((data) => {
                    this._constService.userDetails = data;
                    const isAdmin = this._constService.isPermittedToUseById(data.userTypeId);
                    this._constService._isTypeAdmin = isAdmin;
                    if (isAdmin) {
                        return true;
                    } else {
                        return false;
                    }
                })
            } else if (this._constService._isTypeAdmin) {
                if (this._constService._isSupport || this._constService._isHardware) {
                    return false;
                }
                return true;
            } else{
                return false;
            }
        } else {
            // return false;
            this._adalService.acquireToken(this._authService.config.clientId)
                .toPromise().then((data) => {
                    localStorage.setItem('id_token', data);
                    return true;
                },
                    (error) => {
                        console.log('No user logged in');
                        localStorage.clear();
                        localStorage.setItem('id_token', 'No Token');
                        this._router
                            .navigate(['/login'],
                                {
                                    queryParams: { returnUrl: state.url }
                                });
                    return false;
                });
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(childRoute, state);
    }
}
