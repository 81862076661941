<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh($event)"
  (actionListClicked)="topMenuActionsClicked($event)"
>
</roots-page-header>
<mat-date-range-input
  class="calendar-input"
  (touchUi)="(true)"
  [formGroup]="range"
  [rangePicker]="picker"
>
  <input
    matStartDate
    [min]="minDate"
    formControlName="start"
    placeholder="Start date"
    (dateChange)="changeEvent($event)"
  />
  <input
    matEndDate
    [max]="maxDate"
    formControlName="end"
    placeholder="End date"
    (dateChange)="changeEndEvent($event)"
  />
</mat-date-range-input>
<mat-date-range-picker #picker></mat-date-range-picker>

<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
  >Invalid start date</mat-error
>
<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
  >Invalid end date</mat-error
>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
<!-- <section>
  <mat-table
    #sort="matSort"
    matSort
    [dataSource]="dataSource"
    class="main example-container"
  >
    <ng-container matColumnDef="indsma">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        INDSMA#
      </mat-header-cell>
      <mat-cell *matCellDef="let scoutLogs">
        {{ scoutLogs.indsma }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Username
      </mat-header-cell>
      <mat-cell *matCellDef="let scoutLogs">
        {{ scoutLogs.username }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastPayLoadDate">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Last Payload Date
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let scoutLogs">
        {{ scoutLogs.lastPayLoadDate | date: "short" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastBatteryVoltage">
      <mat-header-cell class="center" *matHeaderCellDef mat-sort-header>
        Last Voltage
      </mat-header-cell>
      <mat-cell class="center" *matCellDef="let scoutLogs">
        {{ scoutLogs.lastBatteryVoltage }} V
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="internalBoxTemperature">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Internal Temp
      </mat-header-cell>
      <mat-cell *matCellDef="let scoutLogs">
        {{ scoutLogs.internalBoxTemperature }} °C
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="relativeHumidity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Relative Humidity
      </mat-header-cell>
      <mat-cell *matCellDef="let scoutLogs">
        {{ scoutLogs.relativeHumidity }} %
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <mat-paginator [pageSize]="20" [pageSizeOptions]="[2, 4, 6, 10, 20]">
  </mat-paginator>
</section> -->
