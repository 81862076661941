import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoutLogExtended } from '../../../interfaces/scout-log-extended';
import { ScoutLogService } from '../../../services/scout-log.service';
import { ScoutLogDialogService } from '../../../services/scout-log-dialog.service';
@Component({
  selector: 'app-cub-verify-dialog',
  templateUrl: './cub-verify-dialog.component.html'
})
export class CubVerifyDialogComponent {
  input = '';
  searchedAsset: any;
  selectionMade = false;
  constructor(
    public dialogRef: MatDialogRef<CubVerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public log: ScoutLogExtended,
    @Inject(MAT_DIALOG_DATA) public serial: string,
    //private _logService: ScoutLogService,
    private _logDialogService: ScoutLogDialogService
    ) {
      this.input = this.log.serial;
      if (this.log.serial == null || this.log.serial == undefined) {
        this.log.serial = this.log['name'];
        // this.log.id = this.log.serial;
        this.input = this.log.serial;
      }

  }

  validateScout(id: string, sent: boolean) {
    console.log(id);
    if (sent) {
      this.selectionMade = true;
      //this._logService.validateCubScoutLog(this.log.id, this.input);
      this._logDialogService.validateCubScoutLog(this.log.id, this.input);
      this.dialogRef.close(sent);
    }
  }

  //this._logService.postScoutLogCreate(this.log);


  unValidateScout(id: string, sent: boolean) {
    console.log(id);
    if (sent) {
      this.selectionMade = true;
      //this._logService.validateCubScoutLog(this.log.id, ('UNDO_' + this.input));
      this._logDialogService.validateCubScoutLog(this.log.id, ('UNDO_' + this.input));
      this.dialogRef.close(sent);
    }
  }

  onNoClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
