<div mat-dialog-content>
    <div class="col-12" style="float: left;">
        <div class="text dashboard-text"><p class="list-inline-item font-weight-bold text-uppercase">Readings</p> <b>from {{readings[2]}}</b> on {{readings[1]}} </div>
        <div *ngFor="let reading of readings[0]; index as i;">   
            <ul>
                
                <div class="text dashboard-text"><b class="font-weight-bold text-uppercase">Code:  </b>{{reading.Code}}</div>
                <br>
                <!-- <small class="font-weight-bold text-uppercase">Name: {{reading.Name}}</small> -->
                <div class="text dashboard-text"><b class="font-weight-bold text-uppercase">Name:  </b>{{reading.Name}}</div>
                <br>
                <!-- <small class="font-weight-bold text-uppercase">Trend: {{reading.Trend}}</small> -->
                
                <div class="text dashboard-text"><b class="font-weight-bold text-uppercase">Trend:  </b>{{reading.Trend}}</div>
                <br>
                
                <div class="text dashboard-text"><b class="font-weight-bold text-uppercase">Unit:  </b>{{reading.Unit}}</div>
                <br>
                <!-- <small class="font-weight-bold text-uppercase">Unit: {{reading.Unit}}</small> -->
                
                <div class="text dashboard-text"><b class="font-weight-bold text-uppercase">Value:  </b>{{reading.Value}}</div>
                <br>
                <!-- <small class="font-weight-bold text-uppercase">Value: {{reading.Value}}</small> -->
                <!-- <button mat-icon-button color="secondary"style="position: absolute; right: 0;">
                    <mat-icon class="mat-18">search</mat-icon>
                </button> -->
              <br><hr>
            </ul>
        </div>
        </div>
</div>
<div mat-dialog-actions>
    <!-- <button mat-button color="warn" (click)="onNoClick()">No</button> -->
    <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Okay</button>
</div>