import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Asset } from '../../../shared/interfaces/asset';
import { AssetModel } from '../../../shared/interfaces/asset-model';
import { AssetStatus } from '../../../shared/interfaces/asset-status';
import { ConstantService } from '../../../shared';
import { FormControl } from '@angular/forms';
import { AdminService } from '../../../shared/services/admin.service';
import { AdminAsset } from '../../../shared/interfaces/admin/admin-asset';
import { SnackService } from '../../../shared/services/extra/snack.service';
import { AssetsService } from '../../../shared/services/assets.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'app-asset-form',
    templateUrl: './asset-form.component.html',
    styleUrls: ['./asset-form.component.scss']
  })
  export class AssetFormComponent implements OnInit {
    assetModels: AssetModelExtended[] = [];
    assetModel: AssetModel;
    assetStatus: AssetStatus[] = [];
    submitted = false;
    modelLength = 0;
    selectedTime = new Date;
    datePickerTime: Date;
    events: string[] = [];
    date1 = new Date((Date.now()));
    date = new FormControl(this.date1);
    modem = '';
    sim = '';
    assetSubscription: Subscription;
    constructor(
      public dialogRef: MatDialogRef<AssetFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _constService: ConstantService,
      private _adminService: AdminService,
      private _snack: SnackService,
      private _assetService: AssetsService
      ) {
        this.serviceCalls();
    }
    assetPost: Asset = {
      assetModelId: '',
      assetStatuslId: null,
      whenBegin: this._constService.convertDateUTC(this.selectedTime),
      whenEnd: null,
      currentAssetRevisionId: '',
      username: this._constService.system_username,
      code: '',
      basicDescription: '',
      basicName: '',
      content: '',
      isAvailable: 0,
      id: '',
      version: null,
      createdAt: null,
      updatedAt: '',
      deleted: false
    };
    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
      this.selectedTime = new Date();
      if (this.data.result) {
        this._snack.displaySuccess('Loaded from QR scanner');
        this.assetPost = {
          assetModelId: '',
          assetStatuslId: null,
          whenBegin: this._constService.convertDateUTC(this.selectedTime),
          whenEnd: null,
          currentAssetRevisionId: '',
          username: this._constService.system_username,
          code: this.data.result,
          basicDescription: '',
          basicName: this.data.result,
          content: '',
          isAvailable: 0,
          id: '',
          version: null,
          createdAt: null,
          updatedAt: '',
          deleted: false
        };
      }
      this._snack.displaySuccess('New Asset Model');
    }


  ngOnDestroy() {
  }

    defaultAsset(): Asset {
      // should probably have defaults for different assetTypes
      if (this.data.result) {
        this.assetPost = {
          assetModelId: this.assetModels[1].id,
          assetStatuslId: this.assetStatus[1].id,
          whenBegin: this._constService.convertDateUTC(this.selectedTime),
          whenEnd: null,
          currentAssetRevisionId: '',
          username: this._constService.system_username,
          code: this.data.result,
          basicDescription: `Scout ${this.data.result}`,
          basicName: this.data.result,
          content: '',
          isAvailable: 0,
          id: '',
          version: null,
          createdAt: null,
          updatedAt: null,
          deleted: false
        };
        console.log(this.assetPost);
        return this.assetPost;
      } else {
        this.assetPost = {
          assetModelId: this.assetModels[1].id,
          assetStatuslId: this.assetStatus[1].id,
          whenBegin: this._constService.convertDateUTC(this.selectedTime),
          whenEnd: null,
          currentAssetRevisionId: '',
          username: this._constService.system_username,
          code: '',
          basicDescription: '',
          basicName: '',
          content: '',
          isAvailable: 0,
          id: '',
          version: null,
          createdAt: null,
          updatedAt: null,
          deleted: false
        };
        console.log(this.assetPost);
        return this.assetPost;
      }
    }

    defaultHealth(): Asset {
      this.assetPost = {
        assetModelId: this.assetModels[11].id,
        assetStatuslId: this.assetStatus[1].id,
        whenBegin: this._constService.convertDateUTC(this.selectedTime),
        whenEnd: null,
        currentAssetRevisionId: '',
        username: this._constService.system_username,
        code: '_HS',
        basicDescription: 'Health sensor',
        basicName: '_HS',
        content: '',
        isAvailable: 0,
        id: '',
        version: null,
        createdAt: null,
        updatedAt: null,
        deleted: false
      };
      console.log(this.assetPost);
      return this.assetPost;
    }

    defaultSensor(): Asset {
      this.assetPost = {
        assetModelId: this.assetModels[this.assetModels.length - 3].id,
        assetStatuslId: this.assetStatus[1].id,
        whenBegin: this._constService.convertDateUTC(this.selectedTime),
        whenEnd: null,
        currentAssetRevisionId: '',
        username: this._constService.system_username,
        code: '',
        basicDescription: 'Sensor ',
        basicName: 'Sensor ',
        content: '',
        isAvailable: 0,
        id: '',
        version: null,
        createdAt: null,
        updatedAt: null,
        deleted: false
      };
      console.log(this.assetPost);
      return this.assetPost;
    }

    serviceCalls() {
      const getAssetModels = this._assetService.getAssetModels();
      const getAssetStatus = this._assetService.getAssetStatus();

      forkJoin([getAssetModels, getAssetStatus])
      .subscribe(result => {
        const models = result[0];
        const status = result[1];

        this.assetModels = <AssetModelExtended[]>models;
        if (this.assetModels !== undefined) {
          this.assetModels = this.assetModels.sort((a, b) => {
            return (a.code < b.code) ? -1 : (a.code > b.code) ? 1 : 0;
          });
        }
        this.modelLength = this.assetModels.length - 2;
        this.assetStatus = status;
        this.assetPost.assetStatuslId = this.assetStatus[1].id;
      })
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
      this.selectedTime = event.value;
    }

    onSubmit(): void {
      this.submitted = true;
      this.event.emit({data: this.assetPost});
      this.assetPost.whenBegin = this._constService.convertDateUTC(this.selectedTime);
      console.log(this.selectedTime);
      console.log(this.assetPost.whenBegin);
      // TODO : query
      if (this.assetPost.assetModelId === '25A57B26-D5C9-4669-BDBF-C0AB80AF2635') {
        const newAsset: AdminAsset = {
          currentAssetOwnership: null,
          currentAssetOwnershipId: null,
          whenBegin: this.assetPost.whenBegin,
          whenEnd: this.assetPost.whenEnd,
          assetModelId: this.assetPost.assetModelId,
          assetStatusId: this.assetPost.assetStatuslId,
          code: this.assetPost.code,
          name: this.assetPost.basicName,
          description: this.assetPost.basicDescription,
          content: this.modem + ', ' +  this.sim,
          id: null
        };
        this.assetSubscription = this._adminService.postAdminAsset(newAsset).subscribe(c => {
          console.log('Asset created: ' + c);
        });
        this.dialogRef.close();
      } else {
        const newAsset: AdminAsset = {
          currentAssetOwnership: null,
          currentAssetOwnershipId: null,
          whenBegin: this.assetPost.whenBegin,
          whenEnd: this.assetPost.whenEnd,
          assetModelId: this.assetPost.assetModelId,
          assetStatusId: this.assetPost.assetStatuslId,
          code: this.assetPost.code,
          name: this.assetPost.basicName,
          description: this.assetPost.basicDescription,
          content: this.assetPost.content,
          id: null
        };
        this.assetSubscription = this._adminService.postAdminAsset(newAsset).subscribe(c => {
          console.log('Asset created: ' + c);
        });
        this.dialogRef.close();
      }
    }
  }

export interface AssetModelExtended extends AssetModel {
  nameForView: string;
  descriptionForView: string;
  // Extra Properties
  assetTypeId: string;
  username: string;
  code: string;
  basicName: string;
  basicDescription: string;
  content: string;
  isAvailable: number;
  id: string;
  version: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
}
