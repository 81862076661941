import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ScoutLogService } from '../../../../shared/services/scout-log.service';
@Component({
  selector: 'app-prodlog-scout-dialog',
  templateUrl: './prodlog-scout-dialog.component.html'
})
export class ProdLogScoutDialogComponent {
  asset: any;
  constructor(
    public dialogRef: MatDialogRef<ProdLogScoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public log: any,
    @Inject(MAT_DIALOG_DATA) public serial: string,
    private _router: Router,
    private _logService: ScoutLogService
    ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  navigateToScoutLogs(log: any) {
    this._router.navigate([`manufacturinglogs/scouts/${log.serial}`]);
  }

  createAssetsDialog(log: any): void {
    this._logService.createAssetsDialog(log);
  }

  findAssets(log: any) {
    this._logService.postScoutLogCreate(log);
  }

  clickSensors(log: any) {
    log.sensorsExpanded = !log.sensorsExpanded;
  }

  clickTestResult(log: any) {
    log.testsExpanded = !log.testsExpanded;
  }
}
