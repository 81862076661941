import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { AdminService } from '../../../services/admin.service';
import { CustomerService } from '../../../services/customer.service';
import { AssignGroupDialogComponent } from './assign-group-dialog/assign-group-dialog.component';
@Component({
  selector: 'app-user-lookup-dialog',
  templateUrl: './user-lookup-dialog.component.html'
})
export class UserLookupDialogComponent implements OnDestroy {
  event: EventEmitter<any> = new EventEmitter();
  user: any;
  all: any[];
  url = '';
  loading = false;
  availableGroups: any[] = [];
  componentSubscription: Subscription;
  constructor(
    private _router: Router,
    public _dialog: MatDialog,
    public dialogRef: MatDialogRef<UserLookupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _customerService: CustomerService,
    private _adminService: AdminService
    ) {
      this.all = data.all;
      this.user = data.user;
      this.url = `customers/${this.user.customerId}/users/${this.user.id}`
  }

  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  changeUser(user: any) {
    this.user = user;
  }

  navigate() {
    // console.log('navigating');
    this._router.navigate([])
  }

  addGroups() {
    this.loading = true;
    this.componentSubscription = this._adminService.getCustomersUserGroups().subscribe(groups => {
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        for (let j = 0; j < this.user.userGroups.length; j++) {
          const userGroups = this.user.userGroups[j];
          if (userGroups.id == group.id) {
            groups.splice(i, 1)
          }
        }
      }
      this.loading = false;
      this.availableGroups = groups
      const dialogRef = this._dialog.open(AssignGroupDialogComponent, {
        width: "100%",
        height: "100%",
        data: { user: this.user, groups: this.availableGroups},
      });
      dialogRef.componentInstance.event.subscribe((result) => {
        if (result.data) {
          console.log(result);
        }
      });
    });
  }

  pullUserGroup(group: any) {
    this.loading = true;
    group.loading = true;
      const groupDetails = this._customerService.getUserGroupDetails(group.id);
      const scoutCall = this._adminService.getAdminUserGroupScout(group.id);
      return forkJoin([groupDetails, scoutCall])
      .subscribe(result => {
        const details = result[0];
        const scouts = result[1];
        group.details = details;
        group.scouts = scouts;
        this.loading = false;
        group.loading = false;
      }, err => {
        if (err) {
          this.loading = false;
          group.loading = false;
          group.details = {basicName: ''};
          group.details.basicName = "No Group Info found!";
          group.scouts = [];
        }
      })
  }
  
  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
