
import { tap, map, mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, UserType } from '../../../../shared';
import { ActivatedRoute } from '@angular/router';
import { ConstantService } from '../../../../shared/services/constant.service';
import { CustomerService } from '../../../../shared/services/customer.service';
import { EmailStructure } from '../../../../shared/classes/email-structure';
import { forkJoin, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// const uuidv1 = require('uuid/v1');
// const uuidv4 = require('uuid/v4');
const { v1: uuidv1 } = require('uuid');
const { v1: uuidv4 } = require('uuid');

@Component({
  selector: 'app-user-create-form',
  templateUrl: './user-create-form.component.html',
  styleUrls: ['./user-create-form.component.scss']
})
export class UserCreateFormComponent implements OnDestroy {
  customerId = '';
  types: any[];
  customer: any;
  request: Request;
  response = '';
  reqLoading = false;
  count = 1;
  regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  customerSubscription: Subscription;
  emailSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<UserCreateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _constService: ConstantService,
    private _customerService: CustomerService
  ) {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.customerId = id;
        return id;
      }),
      mergeMap(id => {
        const getUserTypes = this._customerService.getUserTypes();
        return forkJoin([getUserTypes])
      })).subscribe(results => {
        const type = results[0];
        this.types = type;
        this.populateUserTypes(type);
        this.customerId = this.data.id;
        this.customer = this.data.dataKey;
        this.count += this.data.count;
        console.log(this.count)
        console.log('current customer users: ' + this.customer);
        this.userPost.regionId = this.customer.regionId;
        this.userPost.username = this.customer.username;
      })
  }

  userPost: User = {
    basicName: '',
    basicDescription: '',
    code: this.removeHyphens(uuidv1()),
    contact_Address: '',
    contact_Cellphone: '',
    contact_Email: '',
    contact_Name: '',
    contact_Telephone: '',
    createdAt: null,
    customerId: this.customerId,
    updatedAt: null,
    isAvailable: 0,
    id: uuidv4(),
    deleted: false,
    username: '',
    version: null,
    languageTag: 'en-US',
    regionId: '',
    // password: null,
    userTypeId: '',
    systemUser_Username: '',
    userSettingsInfoContent: {
      languageTag: "en-US",
      scoutVisibility: null,
      sensorVisibility: null,
      regions: [],
      subjects: [],
      units: [
        {
          name: "Temperature",
          value: "F"
        },
        {
          name: "SoilEC",
          value: "uS/cm"
        }
      ],
      scoutSettings: [],
      sensorSettings: []
    }
  };
  event: EventEmitter<any> = new EventEmitter();
  submitted = false;

  defaultUser(): User {
    this.userPost = {
      basicName: `${this.customer.basicName} User (${this.count})`,
      basicDescription: `${this.customer.basicName}'s ${this.count} User`,
      code: this.removeHyphens(uuidv1()),
      contact_Address: '',
      contact_Cellphone: '',
      contact_Email: '',
      contact_Name: '',
      contact_Telephone: '',
      createdAt: null,
      customerId: this.customerId,
      updatedAt: null,
      isAvailable: 0,
      id: uuidv4(),
      deleted: false,
      username: this.customer.username,
      version: null,
      languageTag: 'en-US',
      regionId: this.customer.regionId,
      // password: null,
      userTypeId: this.types[this.types.length - 1].value,
      systemUser_Username: '',
      userSettingsInfoContent: {
        LanguageTag: "en-US",
        ScoutVisibility: null,
        SensorVisibility: null,
        Regions: [],
        Subjects: [],
        Units: [
          {
            Name: "Temperature",
            Value: "F"
          },
          {
            Name: "SoilEC",
            Value: "uS/cm"
          }
        ],
        ScoutSettings: [],
        SensorSettings: []
      }
    };
    return this.userPost;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnDestroy() {
    // if (this.customerSubscription) {
    //   this.customerSubscription.unsubscribe();
    // }
  }

  populateUserTypes(types: UserType[]): object[] {
    this.types = [];
    for (let i = 0; i < types.length; i++) {
      const t = types[i];
      const type = { key: t.basicName, value: t.id };
      this.types.push(type);
    }
    return this.types;
  }

  onSubmit(): void {
    console.log(this.userPost);

    this.userPost.userSettingsInfoContent = JSON.stringify(this.userPost.userSettingsInfoContent);

    this.submitted = true;
    this.reqLoading = true;
    this.userPost.customerId = this.customerId;

    //rewrite
    if (this.validateEmail(this.userPost.systemUser_Username)) {
      this.customerSubscription = this._customerService.createUser(this.userPost)
        .subscribe(c => {
          this.response = 'Creating User...';
          this.event.emit({ data: this.userPost });
          this.response = 'User Created.';
          if ((c.systemUser_Username)) {
            this.response = 'Sending Email...';
            this.emailSubscription = this._customerService.sendGridEmail(
              this.userPost.systemUser_Username,
              'support@earthscout.com',
              this.userPost.basicName)
              .subscribe(e => {
                this.response = 'Email Sent.';
                console.log(e);
                this.reqLoading = false;
                this.dialogRef.close(e);
              })
          }
        }, err => {
          this.reqLoading = false;
          this.response = err;
        });

    }
    else {
      this.reqLoading = false;
      this.response = 'User NOT Created - the user email was in an incorrect format and no email was sent. Please change the user email field to a correct email format to create the user and send an invite.'
    }
    this.dialogRef.close();
  }

  validateEmail(email) {
    var chrbeforAt = email.substr(0, email.indexOf('@'));
    if (!($.trim(email).length > 127)) {
      if (chrbeforAt.length >= 2) {
        var re = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(email);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  removeHyphens(code: string): string {
    const replaced = code.replace(/-/g, '');
    return replaced;
  }
}
