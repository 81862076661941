import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatModule } from "../../core";
import {
  ReferencesComponent,
  ReferenceFormComponent,
  ReferencesEditComponent,
} from ".";
import { ReferencesRoutingModule } from "./references-routing.module";

@NgModule({
  imports: [
    CommonModule,
    ReferencesRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatModule,
  ],
  declarations: [
    ReferencesComponent,
    ReferenceFormComponent,
    ReferencesEditComponent,
  ],
  bootstrap: [
    ReferencesComponent,
    ReferenceFormComponent,
    ReferencesEditComponent,
  ],
})
export class ReferencesModule {}
