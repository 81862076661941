

import { Injectable, } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackService implements ISnackService {
  public content: string;

  constructor(private _snackBar: MatSnackBar) { }
  seconds = 5;

  displaySearchError(text: string): void {
    this._snackBar.open(text, 'No Results', {
      duration: 1000,
      panelClass: 'search-warning-card'
    });
  }

  displaySearchSuccess(text: string): void {
    this._snackBar.open(text, 'Results Found', {
      duration: 1000,
      panelClass: 'success-card'
    });
  }
  
  displayError(text: string): void {
      this._snackBar.open(text, 'Error', {
        duration: (this.seconds * 2) * 1000,
        panelClass: 'warning-card',
      });
  }

  displayErrorTop(text: string): void {
    this._snackBar.open(text, 'Error', {
      duration: (this.seconds * 2) * 1000,
      panelClass: 'warning-card',
      horizontalPosition: "right",
      verticalPosition: "top"
    });
}

displaySuccessTop(text: string): void {
  this._snackBar.open(text, 'Success', {
      duration: this.seconds * 1000,
      panelClass: 'success-card',
      horizontalPosition: "right",
      verticalPosition: "top"
    });
}

  displaySuccess(text: string): void {
    this._snackBar.open(text, 'Success', {
        duration: this.seconds * 1000,
        panelClass: 'success-card'
      });
  }

  displayInfo(text: string): void {
    this._snackBar.open(text, 'Info', {
      duration: (this.seconds * 2) * 1000,
      panelClass: 'info-card'
    });
}

  displayLoading(text: string): void {
    this._snackBar.open(text, 'Loading', {
      duration: (this.seconds * 2) * 1000,
      panelClass: 'loading-card'
    });
}

  displayLogAssetCreation(resp: any): void {
    let constructedString = '';
    let count = 1;
    if (resp.length > 0) {
      resp.forEach(r => {
        constructedString += (count + ' ' + r.name + ' ')
        count ++;
      });
      //snack 1
      this._snackBar.open(constructedString, 'All Created', {
        duration: this.seconds * 1000,
        panelClass: 'success-card'
      });
    } else if(resp.items.length > 0) {
      constructedString += resp.items.length + ' Assets Already Exist:    ';
      resp.items.forEach(item => {
        constructedString += (item + '   ')
        count ++;
      });
      //snack 2
      this._snackBar.open(constructedString, 'Partially Created', {
        duration: this.seconds * 1000,
        panelClass: 'success-card'
      });
    }
    else {
      constructedString = resp.message;
      //snack 3
      this._snackBar.open(constructedString, 'None Created', {
        duration: this.seconds * 1000,
        panelClass: 'success-card'
      });
    }
  }
}

interface ISnackService {
  displayError(text: string): void;
  displaySuccess(text: string): void;
}
