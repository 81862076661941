import { catchError, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ConstantService } from "..";
import { UserGroupScouts } from "../interfaces/user-group-scouts";
import { ScoutGroupMember } from "../interfaces/scout-group-member";
import { AdminAsset } from "../interfaces/admin/admin-asset";
import { AdminUser } from "../interfaces/admin/admin-user";
import { AdminUserGroup } from "../interfaces/admin/admin-user-group";
import { AdminAssetOwnership } from "../interfaces/admin/admin-asset-ownership";
import { AdminAssetXfer } from "../interfaces/admin/admin-asset-xfer";
import { AdminAssetNotOwned } from "../interfaces/admin/admin-asset-not-owned";
import { AdminScoutInstallation } from "../interfaces/admin/admin-scout-installation";
import { environment } from "../../../environments/environment";
import { ExtraCustomerInfo } from "../classes/extra-customer-info";
import { FirmwareModel } from "../interfaces/firmware-model";
import { ScoutContent } from "../interfaces/scout-content";
import { SnackService } from "./extra/snack.service";

@Injectable()
export class AdminService {
  params: any;
  private authHeaders: HttpHeaders;
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _snackService: SnackService
  ) {}

  patchTableUsersSettings(id: string, notes: string) {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      "Content-Type": "application/json",
    };
    const URL = `${this._constService.CONST.URL}tables/User/${id}`;
    const patchObject = {
      content: notes,
    };
    return this._http.patch(URL, patchObject, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update Settings Response:", data))
    );
  }

  public getUserGroupScouts(): Observable<UserGroupScouts[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/UserGroupScouts`;
    return this._http.get<UserGroupScouts[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getTableScouts(): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/Scouts`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getApiScouts(): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/Scouts`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }


  public getUserGroupScoutsById(id: string): Observable<UserGroupScouts> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/UserGroupScouts/${id}`;
    return this._http.get<UserGroupScouts>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getScoutGroupMembers(): Observable<ScoutGroupMember[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/ScoutGroupMember`;
    return this._http.get<ScoutGroupMember[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getScoutGroupMember(id: string): Observable<ScoutGroupMember[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}tables/ScoutGroupMember/${id}`;
    return this._http.get<ScoutGroupMember[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAdminPacketsScout(scoutCode: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      "scoutCode": scoutCode
    };
    const URL = `${this._constService.CONST.URL}api/admin/packets/scout`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAllUtilitiesTypes(): Observable<any[]> {
    //TODO: fill in utility call details
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
    };
    const testURL = 'http://localhost:50517/api/admin/utility'
    const URL = `${this._constService.CONST.URL}api/admin/utility`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAllUtilities(): Observable<any[]> {
    //const testURL = 'http://localhost:50517/api/admin/utility'
    //TODO: fill in utility call details http://localhost:50517/
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
    };
    const URL = `${this._constService.CONST.URL}api/admin/utility`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAllCustomersAssets(): Observable<AdminAsset[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      doAll: true,
    };
    const URL = `${this._constService.CONST.URL}api/admin/asset`;
    return this._http.get<AdminAsset[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAllOfACustomersAssets(id: string): Observable<AdminAsset[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      doAll: true,
      customerIds: id,
    };
    const URL = `${this._constService.CONST.URL}api/admin/asset`;
    return this._http.get<AdminAsset[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET devices from the server */
  public getCustomersScouts(customerId: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerIds: customerId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/scout`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET notes from the server */
  public getNotesByEntityCodeOrId(
    entityCode?: string,
    entityId?: string
  ): Observable<any[]> {
    if (
      (entityCode === null && entityId === null) ||
      (entityCode === undefined && entityId === undefined)
    ) {
      this._snackService.displayError(
        "Please supply either an entityId OR entityCode."
      );
      throw throwError("Please supply either an entityId OR entityCode.");  
    } else {
      const params: any = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        entityCode: entityCode,
        entityId: entityId
      };
      const URL = `${this._constService.CONST.URL}api/admin/note`;
      return this._http.get<any[]>(URL, { params: params }).pipe(
        map((res: any) => res),
        catchError(this._constService.handleErrorObservable)
      );
    }
  }

  public getNotesByEntityCodeOrIdSecondary(
    entityCode?: string,
    entityId?: string
  ): Observable<any[]> {
    let params: any;
    if(entityCode !== null ) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        entityCode: entityCode
      };
    } else if(entityId !== null){
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        entityId: entityId
      };
    }
    const URL = `${this._constService.CONST.URL}api/admin/note`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET devices from the server */
  public getScoutsByAssetIds(assetIds: string[]): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      AssetIds: assetIds,
    };
    const URL = `${this._constService.CONST.URL}api/admin/scout`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET devices from the server */
  public getCustomersAssets(customerId: string): Observable<AdminAsset[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerIds: customerId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/asset`;
    return this._http.get<AdminAsset[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getCustomersUsers(customerId: string): Observable<AdminUser[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerIds: customerId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/user`;
    return this._http.get<AdminUser[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  getAdminCustomers(): Observable<ExtraCustomerInfo[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .get<ExtraCustomerInfo[]>(environment.apiUrl + "api/admin/customer", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public getPagedCustomersUsers(
    customerId: string,
    pageNumber: number,
    pageSize: number
  ): Observable<AdminUser[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerIds: customerId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/user`;
    const loc = `${this._constService.CONST.URL}api/admin/user?pagenumber=${pageNumber}&pagesize=${pageSize}`;

    return this._http.get<AdminUser[]>(loc, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getCustomersUserGroups(
    customerId?: string
  ): Observable<AdminUserGroup[]> {
    //TODO:
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    if (customerId) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        customerIds: customerId,
      };
    }
    const URL = `${this._constService.CONST.URL}api/admin/usergroup`;
    return this._http.get<AdminUserGroup[]>(URL, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET specified history of ownershipf for the asset*/
  public getScoutAssetOwnershipHistory(
    assetId: string
  ): Observable<AdminAssetOwnership[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      assetId: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetownership`;
    return this._http.get<AdminAssetOwnership[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET  history of ownership for the customer */
  public getScoutCustomerOwnershipHistory(
    customerId: string
  ): Observable<AdminAssetOwnership[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerId: customerId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetownership`;
    return this._http.get<AdminAssetOwnership[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  /** GET Asset XFER*/
  public xferAssetToCustomer(
    assetIds: string[],
    customerId: string,
    xferedAssets?: any[],
    doScoutSensor?: boolean
  ): Observable<AdminAssetXfer[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      customerId: customerId,
      doscsn: doScoutSensor,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetxfer`;
    return this._http
      .post<AdminAssetXfer[]>(URL, xferedAssets, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminUserGroupMember(id: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      requestIds: id,
    };
    return this._http
      .get<any[]>(environment.apiUrl + `api/admin/usergroupmember`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  public deleteAdminUserGroupMember(id: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      id: id,
    };
    const URL = `${this._constService.CONST.URL}api/admin/usergroupmember`;
    return this._http
      .delete(URL, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap((data) => console.log("deleted usergroupmember:", data))
      );
  }

  getAdminUserGroupScout(id?: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      requestIds: id,
    };
    return this._http
      .get<any[]>(environment.apiUrl + `api/admin/usergroupscout`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  public postAdminUserGroupScout(ids: string[]): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      requestIds: ids,
    };
    return this._http
      .post<any[]>(environment.apiUrl + "api/admin/usergroupscout", null, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public deleteAdminUserGroupScout(id: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      id: id,
    };
    const URL = `${this._constService.CONST.URL}api/admin/usergroupscout`;
    return this._http
      .delete(URL, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap((data) => console.log("deleted usergroupscout:", data))
      );
  }

  public postAdminUserGroupMember(ids: string[]): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      requestIds: ids,
    };
    return this._http
      .post<any[]>(environment.apiUrl + "api/admin/usergroupmember", null, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public postAdminScoutTable(entity, scoutId): Observable<ScoutContent> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      id: scoutId,
    };
    const URL = `${environment.apiUrl}api/admin/Scout`;
    return this._http.post<any>(URL, entity, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postCommand(entity): Observable<ScoutContent> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = `${environment.apiUrl}api/command/forward`;
    return this._http.post<any>(URL, entity, { params: params }).pipe(
      map((res: any) => {
        return res;
      }), 
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postAdminScoutStatisticsUpdate(entity): Observable<ScoutContent> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = `${environment.apiUrl}api/admin/scout/statistics/update`;
    return this._http.post<any>(URL, entity, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public xferScoutTreeToCustomer(
    assetIds: string[],
    customerId: string,
    xferedAssets?: any[]
  ): Observable<AdminAssetXfer[]> {
    const doscsn = true;
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      customerId: customerId,
      doscsn: doscsn,
    };
    return this._http
      .post<AdminAssetXfer[]>(
        environment.apiUrl + "api/admin/assetxfer",
        xferedAssets,
        { params: params }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }
  // get array of scouts
  public getAppScoutTreeFwm(id?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    if (id) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        ids: id,
      };
    }
    const URL = `${this._constService.CONST.URL}api/app/scout/treefwm`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        tap((_) => console.log(`fetched AppScout`)),
        catchError(this._constService.handleError<any[]>(`get_AppScout`))
      );
  }

  public getAppScoutTreeFwmByName(name?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    if (name) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        names: name,
        withrt: true
      };
    }

    const URL = `${this._constService.CONST.URL}api/app/scout/treefwm`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        tap((_) => console.log(`fetched AppScout`)),
        catchError(this._constService.handleError<any[]>(`get_AppScout`))
      );
  }

  // get array of scouts
  public getAppScouts(): Observable<any[]> {
    const URL = `${this._constService.CONST.URL}api/app/scout`;
    return this._http
      .get<any[]>(URL)
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        tap((_) => console.log(`fetched AppScout`)),
        catchError(this._constService.handleError<any[]>(`get_AppScout`))
      );
  }

  // get array of scouts
  public getAppScout(id?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    if (id) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
        ids: id,
      };
    }
    const URL = `${this._constService.CONST.URL}api/app/scout/tree`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        })
      )
      .pipe(
        tap((_) => console.log(`fetched AppScout`)),
        catchError(this._constService.handleError<any[]>(`get_AppScout`))
      );
  }

  public getAdminAssetNotOwned(): Observable<AdminAssetNotOwned[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetnotowned`;
    return this._http.get<AdminAssetNotOwned[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAdminUser(id: string): Observable<AdminUser[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      userName: id,
    };
    const URL = `${this._constService.CONST.URL}api/admin/user`;
    return this._http.get<AdminUser>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postAdminUser(user: AdminUser): Observable<AdminUser> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      // 'entity': user
    };

    const URL = `${this._constService.CONST.URL}api/admin/user`;
    return this._http.post<AdminUser>(URL, user, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postAdminAsset(asset: AdminAsset): Observable<AdminAsset> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    const URL = `${this._constService.CONST.URL}api/admin/asset`;
    return this._http.post<AdminAsset>(URL, asset, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public deleteAdminAsset(assetId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      id: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/asset`;
    //defines paramas for new delete
    return this._http
      .delete(URL, {
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap((data) => console.log("deleted asset:", data))
      );
  }

  public getAdminScout(customerIds?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      customerIds: customerIds,
    };
    if (customerIds == null) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      };
    }
    const URL = `${this._constService.CONST.URL}api/admin/scout`;
    //const loc = `${this._constService.CONST.URL}api/admin/scout?customerIds=${customerIds}`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getScoutData(scoutId: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}api/tables/ScoutData/${scoutId}`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getAdminSensorsForScout(scoutIds: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      scoutIds: scoutIds,
    };
    const URL = `${this._constService.CONST.URL}api/admin/sensor`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public getFirmwareModelById(id: string): Observable<FirmwareModel> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}api/admin/firmwaremodel/${id}`;
    return this._http.get<FirmwareModel[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }
  //FIRMWAREMODEL GET, POST, DELETE
  public getFirmwareModels(): Observable<FirmwareModel[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}api/admin/firmwaremodel`;
    return this._http.get<FirmwareModel[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }
  //need to define the models as classes or interfaces
  public postFirmwareModels(firmwareModel: any): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const URL = `${this._constService.CONST.URL}api/admin/firmwaremodel`;
    return this._http.post<any[]>(URL, firmwareModel, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public deleteFirmwareModels(assetId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: assetId,
    };
    this.authHeaders = new HttpHeaders();
    this.authHeaders = this.authHeaders.append(
      "ZUMO-API-VERSION",
      this._constService.CONST.ZUMOAPIVersion
    );
    const URL = `${this._constService.CONST.URL}api/admin/firmwaremodel`;
    return this._http
      .delete(URL, {
        // headers: this.authHeaders,
        params: params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleError())
      );
  }
  //FIRMWAREINSTANCE GET, POST, DELETE
  public getFirmwareInstance(entityId?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: entityId,
    };
    if (entityId == null) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      };
    }
    const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postFirmwareInstance(firmwareInstance: any): Observable<any> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    const headers: any = {
      "Content-Type": "multipart/form-data",
    };
    const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    return this._http
      .post<any[]>(URL, firmwareInstance, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public deleteFirmwareInstance(assetId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      assetIds: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    return this._http.delete(URL, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleError())
    );
  }
  //ASSETFIRMWAREMODEL GET, POST, DELETE
  public getAssetModelFirmwareModel(assetId?: string[]): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      requestIds: assetId,
    };
    if (assetId == null) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      };
    }
    const URL = `${this._constService.CONST.URL}api/admin/assetmodelfirmwaremodel`;
    return this._http.get<any[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public postAssetModelFirmwareModel(ids: string[]): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      requestIds: ids,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetmodelfirmwaremodel`;
    return this._http.post<any[]>(URL, ids, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public deleteAssetModelFirmwareModel(assetId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/assetmodelfirmwaremodel`;
    return this._http.delete(URL, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleError())
    );
  }

  public getAdminScoutInstallation(
    assetId: string
  ): Observable<AdminScoutInstallation[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      assetIds: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/scoutinstallation`;
    return this._http
      .get<AdminScoutInstallation[]>(URL, { params: params })
      .pipe(
        map((res: any) => res),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public postAdminScoutInstallation(
    assetId: string
  ): Observable<AdminScoutInstallation[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      assetIds: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/scoutinstallation`;
    return this._http
      .post<AdminScoutInstallation[]>(URL, null, { params: params })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public deleteAdminScoutInstallation(assetId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      assetIds: assetId,
    };
    const URL = `${this._constService.CONST.URL}api/admin/scoutinstallation`;
    return this._http.delete(URL, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleError())
    );
  }
}
