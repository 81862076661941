<!-- background-color: #007041; -->

<div class="col sub-nav" style="margin-left: auto; padding-left:0px;">
    <div class="example-sidenav" color="{{icon.color}}" 
    style="min-width:min-content ; max-width: fit-content;" 
    *ngFor="let icon of icons">
        <mat-icon class="mat-18" (click)="navigate(icon.command)"
        matTooltip="Navigate {{icon.message}}" matTooltipPosition="above">
        {{icon.name}}
    </mat-icon>
    </div>
</div>