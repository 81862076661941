<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
>
</roots-page-header>
<div class="col-md-12" style="min-height: 90vh">
  <div id="body" class="card-body" *ngIf="user && userDetails">
    <hr />
    <div class="row">
      <div class="col-md-6">
        <div class="text-truncat">
          <b>User Status:</b>
          <!-- <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor> -->
          <p class="set-text">{{ userSettings.userStatus }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncat">
          <b>User Reminder:</b>
          <p class="set-text">{{ userSettings.userReminder }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncate">
          <b>{{ "email" | translate }}:</b>
          <p class="set-text">{{ user.userName }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncate">
          <b>CustomerId:</b>
          <p class="set-text">{{ userDetails.customerId }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncate">
          <b>Ip Address:</b>
          <p class="set-text">{{ ip }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncat">
          <b>Language:</b>
          <p class="set-text">{{ userDetails.languageTag }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncat">
          <b>Dark Theme:</b>
          <p class="set-text">{{ userSettings.darkTheme }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="text-truncat">
          <b>Tutorial Completed:</b>
          <p class="set-text">{{ userSettings.tutorialCompleted }}</p>
        </div>
      </div>
    </div>
    <hr class="mb-2" />
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>
