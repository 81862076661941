import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'roots-dots-menu',
  templateUrl: './roots-dots-menu.component.html',
  styleUrls: ['./roots-dots-menu.component.scss']
})
export class RootsDotsMenuComponent {
  @Input('active') active: boolean;
  event: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  clickEvent($event) {
    $event.srcElement.checked = !$event.srcElement.checked;
    this.event.emit($event.srcElement.checked);
    const checkItems = $event.srcElement as HTMLInputElement;
    checkItems.checked = !checkItems.checked; 
  //   const checkItems = $event.srcElement ;
  //   for (let item = 0; item < checkItems.length; item++) {
  //     console.log((checkItems[item] as HTMLInputElement).checked = true);
  // }
    console.log(checkItems.checked);
  }

}
