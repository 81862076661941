
import {catchError, map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from '..';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class DevelopersService {

  constructor(
    private _http: HttpClient,
    private _constService: ConstantService
    ) { }

    
}

