import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MatModule } from '../../core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import UtilitiesComponent from './utilities.component';
import { UtilitiesRoutingModule } from './utilities-routing.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UtilitiesRoutingModule,
    MatModule,
  ],
  declarations: [
    UtilitiesComponent
  ],
  providers: [ ]
})
export class UtilitiesModule { }
