import {
  Component,
  forwardRef,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from "@angular/forms";
import { ScoutContent } from "../../interfaces/scout-content";
import { SnackService } from "../../services/extra/snack.service";
import { AdminService } from "../../services/admin.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

export function createCounterRangeValidator(maxValue, minValue) {
  return (c: FormControl) => {
    let err = {
      rangeError: {
        given: c.value,
        max: maxValue || 10,
        min: minValue || 0,
      },
    };

    return c.value > +maxValue || c.value < +minValue ? err : null;
  };
}
@Component({
  selector: "app-scout-interval",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CounterComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CounterComponent),
      multi: true,
    },
  ], 
})
export class CounterComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  constructor(
    private _snack: SnackService,
    private _adminService: AdminService,
    private _router: Router,
  ) {}
  sendCommandFormControl = new FormControl({ value: null, disabled: true });

  ngOnInit(): void {
    this.sendCommandFormControl.disable();
  }

  propagateChange: any = () => {};
  validateFn: any = () => {};

  @Input("loading") loading: boolean;
  @Input("counterValue") _counterValue = 0;
  @Input("scoutId") _scoutId = "";
  @Input("scout") _scout: ScoutContent;
  @Output() selectEmitt = new EventEmitter();
  changed = false;
  status = "";
  counterMin = 1;
  counterMax = 1440;
  counterInHours: number;
  counterRangeMax;
  counterRangeMin;
  sendOnEachRequest: boolean;
  componentSubscription: Subscription;
  commandSubscription: Subscription;
  sendCommand = false;

  command: string;

  get counterValue() {
    this.counterInHours =
      Math.round((this._counterValue / 60 + Number.EPSILON) * 100) / 100;
    return this._counterValue;
  }

  set counterValue(val) {
    this._counterValue = val;
    this.propagateChange(val);
    this.counterInHours =
      Math.round((this._counterValue / 60 + Number.EPSILON) * 100) / 100;
    this.loading = false;
  }

  set scout(val) {
    this._scout = val;
  }

  get scout() {
    return this._scout;
  }

  set scoutId(val) {
    this._scoutId = val;
  }

  get scoutId() {
    return this._scoutId;
  }

  ngOnChanges(inputs) {
    if (inputs.counterRangeMax || inputs.counterRangeMin) {
      this.validateFn = createCounterRangeValidator(
        this.counterRangeMax,
        this.counterRangeMin
      );
    }
  }
  
  ngOnDestroy() {
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }

    if (this.commandSubscription) {
      this.commandSubscription.unsubscribe();
    }
  }

  writeValue(value) {
    if (value) {
      this.counterValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  increase() {
    this.counterValue++;
    this.changed = true;
    this.status = "true";
  }

  decrease() {
    this.counterValue--;
    this.changed = true;
    this.status = "true";
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    let decimalPart = +value.toString().replace(/^[^\.]+/, "0");
    let mm = decimalPart * 60;
    var mmPart =
      mm.toString().length == 1 ? mm.toString() + "0" : mm.toString();

    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split(".");
      if (strArr[0].length == 1) {
        strArr[0] = "0" + strArr[0];
      }
      var hhPart = strArr[0];
    }

    return hhPart + ":" + mmPart;
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  navigateToScout(scoutId: string) {
    this._router.navigate([`/scouts/${scoutId}`])
  }

  update(scoutId: string) {
    this.loading = true;
    let content = this._scout.content.split(",");
    content[0] = this._counterValue.toString();
    // content[1] = this._counterValue.toString();
    const appended = content.join();
    let contentAndStatistics = appended;

    if (this.sendCommand) {
      const commandObj = {
        Command: this.command,
        SenderSerial: this.scout.code,
        SendCommandOnEachRequest: this.scout.sendCommandOnEachRequest,
        DoNotCheckSyntax: true
      };

      this.commandSubscription = this._adminService.postCommand(commandObj).subscribe(
        (resp) => {
          this._snack.displaySuccess(
            "Command sent: " + this.command
          );
          this.selectEmitt.emit({ stateChanged: true });
          this.loading = false;
        },
        (err) => {
          this._snack.displayError("An error has occurred: " + err);
        }
      );
    }

    const patchObj = {
      id: this.scoutId,
      content: contentAndStatistics,
      statistics: contentAndStatistics,
      sendCommandOnEachRequest: this.scout.sendCommandOnEachRequest,
    };

    if (this.counterValue > this.counterMax) {
      this._snack.displayError("Error: Interval Larger than Max: 1440");
      this.counterValue = 1440;
      this.loading = false; 
      return; 
    } else if (this.counterValue == this.counterMax) {
      this.componentSubscription = this._adminService.postAdminScoutStatisticsUpdate(patchObj).subscribe(
        (resp) => {
          this._snack.displaySuccess(
            "Interval set to max value: " + this.counterValue + " minutes"
          );
          this.selectEmitt.emit({ stateChanged: true });
          this.loading = false;
        },
        (err) => {
          this._snack.displayError("An error has occurred: " + err);
        }
      );
      return;
    } else {
      this.componentSubscription = this._adminService.postAdminScoutStatisticsUpdate(patchObj).subscribe(
        (resp) => {
          if (resp !== null) {
            this._snack.displaySuccess(
              "Interval set to: " + this.counterValue + " minutes"
            );
            this.selectEmitt.emit({ stateChanged: true });
            this.loading = false;
          } else {
            this._snack.displayError("Response from server was null: " + resp);
            this.loading = false;
          }
        },
        (err) => {
          this._snack.displayError("An error has occurred: " + err);
        }
      );
      return;
    }
  }

  sendCommandChangeEvent() {
    // this.connectivityCodeChange = !this.connectivityCodeChange;
    const isEnabled: boolean = this.sendCommand;
    if (isEnabled) {
      this.sendCommandFormControl.enable();
    } else {
      this.sendCommandFormControl.disable();
    }
  }
}