import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router";
import { BuilderComponent } from "./builder/builder.component";
import { GeneralreportComponent } from "./general/generalreport.component";
import { PacketreportComponent } from "./packets/packetreport.component";
import { ReportsComponent } from "./reports.component";
import { HealthLogsComponent } from "./scout/health-logs/health-logs.component";
import { PacketReliabilityComponent } from "./scout/packet-reliability/packet-reliability.component";
import { ScoutLogsComponent } from "./scout/scout-logs/scout-logs.component";
import { SensorreportComponent } from "./sensors/sensorreport.component";
import { WeatherComponent } from "./weather/weather.component";

const routes: Routes = [
  { path: "", component: ReportsComponent },
  { path: "packets", component: PacketreportComponent },
  { path: "sensors", component: SensorreportComponent },
  { path: "scouts", component: ScoutLogsComponent },
  { path: "scouts/health", component: HealthLogsComponent },
  { path: "scouts/reliability", component: PacketReliabilityComponent },
  { path: "users", component: GeneralreportComponent },
  { path: "builder", component: BuilderComponent },
  { path: "weather", component: WeatherComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
