import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Observable, forkJoin, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { AssetFormComponent } from "./create/asset-form.component";
import { AssetModel } from "../../shared/interfaces/asset-model";
import { AssetStatus } from "../../shared/interfaces/asset-status";
import { ScannerComponent } from "./scanner/scanner.component";
import { AdminService } from "../../shared/services/admin.service";
import { XferFormComponent } from "../customer/assets/xfer/xfer-form.component";
import { SnackService } from "../../shared/services/extra/snack.service";
import { AssetsService } from "../../shared/services/assets.service";
import { ExtraAssetAdmin } from "../../shared/classes/extra-asset-admin";
import { ExtraAssetInfo } from "../../shared/classes/extra-asset-info";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { SearchBarService } from "../../shared/services/searchbar.service";
import { RootsActionMenuItem, ActionType } from "../../shared/interfaces/roots-action-menu-item";
import { RootsPageComponentConfig } from "../../shared";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: "app-asset",
  templateUrl: "./asset.component.html",
  styleUrls: ["./asset.component.scss"]
})
export class AssetComponent implements OnInit, OnDestroy, AfterViewInit {
  reportBuilderEnabled = false;
  private currentUser: any;
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "name",
      "scoutOrSensorName",
      "customerName",
      "type",
      "status",
      "details",
      "assetStatus",
      "code",
    ],
    message: "",
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Manually Create Asset",
      icon: "add",
      toolTip: "Create new Asset Manually",
      type: ActionType.Create,
    },
    {
      name: "Open QR Scanner",
      icon: "camera",
      toolTip:
        "QR scanner is not tied to anything directly but is an example of how we may utilize scanning to generate assets",
      type: ActionType.Unique,
    },
  ];
  assets: ExtraAssetInfo[];
  selections: any[] = [
    {
      name: "All"
    },
    {
      name: "Scouts"
    },
    {
      name: "Sensors"
    },
    {
      name: "Health"
    }
  ]
  adminAssets: ExtraAssetAdmin[];
  assetModel: AssetModel[];
  assetStatus: AssetStatus[];
  models: any[];
  status: AssetStatus[];
  initialData: any[];
  searchSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _router: Router,
    private _adalService: MsAdalAngular6Service,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _assetService: AssetsService,
    private _snack: SnackService,
    public searchBarService: SearchBarService
  ) {
    this.joinedServiceCalls();
    this.currentUser = this._adalService.userInfo.userName;
    this._config.title = "Assets";
    this._config.toolTip =
      "Find details regarding all the current earthscout assets - Scouts, health data, and sensors. Click to refresh.";
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this._config.dataSource);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this._config.dataSource) {
      this._config.dataSource.paginator = this.paginator;
      this._config.dataSource.sort = this.sort;
    }
  }

  refresh() {
    this._config.loading = true;
    this.joinedServiceCalls();
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        this.openDialog();
        break;
      case 3:
        // this.navigateToDialog(action.entity);
        if (action.entity) {
          const asset = action.entity;
          const dialogRef = this._dialog.open(XferFormComponent, {
            width: "60vw",
            data: { asset },
          });
          dialogRef.componentInstance.event.subscribe((result) => {
            if (result.data) {
              this._snack.displaySuccess("Asset Transferred");
              this.joinedServiceCalls();
            }
          });
        }
        break;
      case 4:
        if (action.PROP_name) {
          if (action.PROP_name.includes("ownership")) {
            this.navigateToCustomerDetails(action.entity);
          }
        }
        this.navigateToAsset(action.entity);
        break;
      case 5:
        this.navigateToCustomerDetails(action.entity);
        break;
      case 6:
        this.openScanner();
        break;
      case 99:
        this.reportBuilderEnabled = !this.reportBuilderEnabled;
      default:
        break;
    }
  }

  joinedServiceCalls() {
    const getCustomerAssets = this._adminService.getAllCustomersAssets();
    const getAssetModels = this._assetService.getAssetModels();
    const getAssetStatus = this._assetService.getAssetStatus();

    forkJoin([getCustomerAssets, getAssetModels, getAssetStatus]).subscribe(
      (results) => {
        const assets = results[0];
        const models = results[1];
        const stats = results[2];

        this.adminAssets = assets;
        this.assetModel = models;
        this.assetStatus = stats;

        if (assets) {
          this._config.dataSource.data = assets as ExtraAssetAdmin[];
          this.populateCustomerInfo(this._config.dataSource.data);
          this.populateAssetStatus(this._config.dataSource.data);
        }
        this.parseAssetModel(models, assets as ExtraAssetAdmin[]);
        this.populateStatus(stats, assets as ExtraAssetAdmin[]);
        if (this._config.dataSource.data.length > 0) {
          this.initialData = this._config.dataSource.data;
          this._config.loading = false;
          this._snack.displaySuccess("Assets loaded");
          this._config.dataSource.data = this._config.dataSource.data.sort(function (a, b) {
            return (
              new Date(b.whenBegin).getUTCDate() -
              new Date(a.whenBegin).getUTCDate()
            );
          });
        }
        this.reFilter();
      }
    );
  }

  ngOnDestroy() {
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  navigateToCustomerAssets(asset: any) {
    this._router.navigate([
      `/customers/${asset.currentAssetOwnership.customerId}/assets`,
    ]);
  }

  navigateToCustomerDetails(asset: any) {
    this._router.navigate([
      `/customers/${asset.currentAssetOwnership.customerId}`,
    ]);
  }

  navigateToAsset = (asset: any) => {
    this._router.navigate([
      `/assets/${asset.id}`,
    ]);
  };

  navigateToDialog(asset: any) {
    if (asset) {
      const dialogRef = this._dialog.open(XferFormComponent, {
        width: "60vw",
        data: { asset },
      });
      dialogRef.componentInstance.event.subscribe((result) => {
        if (result.data) {
          this._snack.displaySuccess("Asset Transferred");
          this.joinedServiceCalls();
        }
      });
    }
  }

  openDialog(): void {
    const dialogRef = this._dialog.open(AssetFormComponent, {
      width: "60vw",
      data: { title: "Create Asset" },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result) {
        var that = this;
        // console.log(result);
        this._snack.displaySuccess("Asset created... Repopulating");
        setTimeout(function () {
          that.joinedServiceCalls();
        }, 5000);
      }
    });
  }

  openScanner(): void {
    const dialogRef = this._dialog.open(ScannerComponent, {
      width: "60vw",
      data: "Scanner",
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result);
    });
  }

  parseAssetModel(models: AssetModel[], assets: ExtraAssetAdmin[]): object[] {
    this.models = [];
    for (let i = 0; i < models.length; i++) {
      const m = models[i];
      const model = { key: m.basicName, value: m.id };
      this.models.push(model);
      for (let i = 0; i < assets.length; i++) {
        const a = assets[i];
        if (a.assetModelId.includes(model.value)) {
          a.type = model.key;
        }
      }
    }
    return this.models;
  }

  populateCustomerInfo(assets: ExtraAssetAdmin[]) {
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      if (asset.currentAssetOwnership) {
        asset.customerId = asset.currentAssetOwnership.customerId;
        asset.customerName = asset.currentAssetOwnership.customerName;
      }
    }
  }

  populateAssetStatus(data: any[]): void {
    for (var i = 0; i < data.length; i++) {
      if (data[i].assetStatusId != null) {
        data[i]['assetStatus'] = this.assetStatus.find(s => s.id == data[i].assetStatusId).basicName;
      }
    }
  }

  populateStatus(stats: AssetStatus[], assets: ExtraAssetAdmin[]) {
    for (let i = 0; i < assets.length; i++) {
      const asset = assets[i];
      for (let j = 0; j < stats.length; j++) {
        const s = stats[j];
        if (asset.assetStatusId === s.id) {
          asset["status"] = s.basicName;
          asset.ownership = false;
        }
      }
      //grab status by id found
      if (asset.currentAssetOwnership !== null) {
        asset["status"] = "In Use";
        asset.ownership = true;
        if (
          asset.currentAssetOwnership.whenEnd !== null ||
          asset.currentAssetOwnership.whenEnd !== undefined
        ) {
          asset["status"] = "Available";
          asset.ownership = false;
        }
        if (asset.currentAssetOwnership.whenEnd === null) {
          asset["status"] = "In Use";
          asset.ownership = true;
        }
      }
    }
  }

  reFilter() {
    const search = this._config.dataSource.filter;
    if (search.length > 0) {
      this.searchBarService.doFilter(search, this._config.dataSource);
    }
  }
}
