<div id="about_component" class="about-tab compact">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="brand-message">
          <app-liquid-title [text]="'Roots™'"></app-liquid-title>
          <app-liquid-title [text]="'Connect.'"></app-liquid-title>
          <app-liquid-title [text]="'Analyze.'"></app-liquid-title>
          <app-liquid-title [text]="'Empower.'"></app-liquid-title>
        </div>
        <hr class="my-3">
      </div>
      <div class="col-12 text-center">
        <h6 class="app-version">App Version:  {{angularAppVersion}}</h6>
        <h6 class="app-version">Api Version:  {{appApiVersion}}</h6>
        <!-- <h6 class="app-version">DB Version:  {{appDbVersion}}</h6> -->
        <hr class="my-3">
      </div>
      <div class="col-12 text-center">
        <div class="pt-3">
          <a class="link-primary" href="https://www.earthscout.com/terms-of-service" target='_blank'>Terms of
            Service</a>
          <a class="link-primary" href="https://www.earthscout.com/privacy-policy" target='_blank'>Privacy Policy</a>
          <a class="link-primary" (click)="logout()" href="" target='_blank'>Logout</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loading *ngIf="loading"></app-loading>
