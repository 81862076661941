import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MatModule } from "../../core";
import { FileUploadComponent } from "../../shared/components/file-upload/file-upload.component";
import { SharedModule } from "../../shared/shared.module";
import { FirmwareInstanceFormComponent } from "./create-instance/firmware-instance-form.component";
import { FirmwareFormComponent } from "./create/firmware-form.component";
import { FwmEditComponent } from "./edit/fwm-edit.component";
import { FirmwareRoutingModule } from "./firmware-routing.module";
import { FirmwareComponent } from "./firmware.component";
import { FirmwareModelsComponent } from './firmware-models/firmware-models.component';
import { FirmwareInstancesComponent } from './firmware-instances/firmware-instances.component';
import { FwmFwiDialogComponent } from './fwm-fwi-dialog/fwm-fwi-dialog.component';
import { FwiEditComponent } from "./edit/fwi-edit.component";
import { FirmwareAssetRelationshipComponent } from "./firmware-asset-relationship/firmware-asset-relationship.component";

@NgModule({
  imports: [
    CommonModule,
    FirmwareRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PerfectScrollbarModule,
    MatModule,
  ],
  declarations: [
    FirmwareComponent,
    FwmEditComponent,
    FwiEditComponent,
    FirmwareFormComponent,
    FirmwareInstanceFormComponent,
    FileUploadComponent,
    FirmwareModelsComponent,
    FirmwareInstancesComponent,
    FwmFwiDialogComponent,
    FirmwareAssetRelationshipComponent
  ],
  entryComponents: [
      FirmwareFormComponent,
      FirmwareInstanceFormComponent
    ]
})
export class FirmwareModule {}
