import { Component } from '@angular/core';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { ActionType, RootsActionMenuItem, RootsPageComponentConfig, RootsTableActionColumn } from '../../shared';
import { Router } from '@angular/router';
import { SnackService } from '../../shared/services/extra/snack.service';
import { MatTableDataSource } from '@angular/material/table';
import { SearchBarService } from '../../shared/services/searchbar.service';
import { AdminService } from '../../shared/services/admin.service';

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html'
})
export default class UtilitiesComponent {
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    displayedColumns: [
      "modelType",
      "name",
      "code",
      "status",
      "details",
    ],
    message: "",
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create new utility",
      icon: "add",
      toolTip: "Create new Uility table entry",
      type: ActionType.Create,
    }
  ];
  actionColumns: RootsTableActionColumn[];
  searchSubscription: Subscription;
  constructor(
    private _router: Router,
    private _snack: SnackService,
    private _adminService: AdminService,
    public searchBarService: SearchBarService
  ) {
    this.joinedServiceCalls();
    this._config.title = "Utilities";
    this._config.toolTip =
      "Run and manage stored procedures base on the utility table.";
  }
  ngOnInit(): void {
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this._config.message = message;
      if (message.length > 0) {
        this.searchBarService.doFilter(message, this._config.dataSource);
      }
    });
  }

  refresh() {
    this._config.loading = true;
    // this.joinedServiceCalls();
  }

  actionsClicked(action) {
    console.log(action);
    switch (action.type) {
      case 0:
        // this.openDialog(); CREATE
        console.log('create');
        break;
      default:
        break;
    }
  }

  joinedServiceCalls() {
    const getUtilities  = this._adminService.getAllUtilities();

    forkJoin([getUtilities])
    .subscribe(results => {
      const utilities = results[0];

      this._config.dataSource.data = utilities;
    })
  }

  ngOnDestroy() {
    // if (this._dialog) {
    //   this._dialog.ngOnDestroy();
    // }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }
}

