<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh($event)"
  (actionListClicked)="topMenuActionsClicked($event)"
>
</roots-page-header>
<mat-date-range-input
  class="calendar-input"
  (touchUi)="(true)"
  [formGroup]="range"
  [rangePicker]="picker"
>
  <input
    matStartDate
    [min]="minDate"
    formControlName="start"
    placeholder="Start date"
    (dateChange)="changeEvent($event)"
  />
  <input
    matEndDate
    [max]="maxDate"
    formControlName="end"
    placeholder="End date"
    (dateChange)="changeEndEvent($event)"
  />
</mat-date-range-input>
<mat-date-range-picker #picker></mat-date-range-picker>

<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
  >Invalid start date</mat-error
>
<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
  >Invalid end date</mat-error
>
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>
