import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CustomerComponent, 
  CustomerEditComponent, CustomerFormComponent, UserGroupsComponent, 
  UserGroupCreateFormComponent, UserGroupEditComponent, 
  UserCreateFormComponent, UserEditComponent, AssignAssetFormComponent, 
  XferFormComponent, UsersUserGroupsComponent, ScoutsComponent, 
  CustomerOwnershipComponent } from '.';
import { MatModule } from '../../core';
import { UserGroupManageComponent } from './user-groups/manage/user-group-manage.component';
import { ScoutsUserGroupsComponent } from './assets/scouts/scouts-user-groups/scouts-user-groups.component';
import { CustomerScoutsComponent } from './scouts/customer-scouts.component';
import { ShowGroupDialogComponent } from './users/dialog/show-groups-dialog.component';
import { CustomerAssetsComponent } from './assets/customer-assets.component';
import { CustomerRoutingModule } from './customer-routing.module';
import { UsersComponent } from './users/users.component';

@NgModule({
  imports: [
    CommonModule,
    CustomerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatModule
  ],
  declarations: [
    CustomerComponent,
    CustomerEditComponent,
    CustomerFormComponent,
    UsersComponent,
    CustomerAssetsComponent,
    UserGroupsComponent,
    UserGroupCreateFormComponent,
    UserGroupEditComponent,
    UserCreateFormComponent,
    UserEditComponent,
    AssignAssetFormComponent,
    XferFormComponent,
    UsersUserGroupsComponent,
    ScoutsUserGroupsComponent,
    ScoutsComponent,
    CustomerOwnershipComponent,
    UserGroupManageComponent,
    CustomerScoutsComponent,
    ShowGroupDialogComponent
  ],
  entryComponents: [
    CustomerFormComponent,
    UserGroupCreateFormComponent,
    UserCreateFormComponent,
    UsersUserGroupsComponent,
    ScoutsUserGroupsComponent,
    AssignAssetFormComponent,
    XferFormComponent
  ]
})
export class CustomerModule { }
