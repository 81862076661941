
import { catchError, tap, map, mergeMap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../shared/interfaces/customer';
import { Observable, forkJoin, EMPTY, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Region } from '../../../shared/interfaces/region';
import { AssetOwnership } from '../../../shared/interfaces/asset-ownership';
import { CustomerType } from '../../../shared/interfaces/customer-type';
import { User } from '../../../shared/interfaces/user';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from '../../../shared/services/admin.service';
import { AdminAssetOwnership } from '../../../shared/interfaces/admin/admin-asset-ownership';
import { environment } from '../../../../environments/environment';
import { CustomerService } from '../../../shared/services/customer.service';
import { AdminAsset } from '../../../shared/interfaces/admin/admin-asset';
import { of } from 'rxjs';
import { AppService } from '../../../shared/services/api/app.service';
import { ConfirmationDialogComponent } from '../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SearchBarService } from '../../../shared/services/searchbar.service';
import { Notes } from '../../../shared/interfaces/notes';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html'
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() customer: Customer;
  customerInitState: Customer;
  event: EventEmitter<any> = new EventEmitter();
  region?: Region;
  assets?: AssetOwnership[];
  adminAssetList?: AdminAsset[];
  languages = [
    { key: 'English', value: 'en-US' },
    { key: 'Nepalese', value: 'ne' },
    { key: 'Russian', value: 'rus' }]
  regions = [];
  types = [];
  users = [];
  customerId = '';
  hasAssets = true;
  customerArray: AdminAssetOwnership[] = [];
  notes: Notes[] = [];
  loading = true;
  scouts: any[] = [];
  title = '';
  toolTip = '';
  message = '';
  searchSubscription: Subscription;
  customerSubscription: Subscription;
  //init for search
  init_assets: any;
  init_users: any;
  init_scouts: any;
  init_avail: any;
  // fields: [{
  // }]
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _http: HttpClient,
    public _dialog: MatDialog,
    private _adminService: AdminService,
    private searchBarService: SearchBarService,
    private _customerService: CustomerService,
    private _appService: AppService
  ) { }

  ngOnInit() {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.customerId = id;
        return id;
      }),
      mergeMap(id => {
        const getCustomerDetails = this._customerService.getCustomerDetails(id);
        const getCustomerHistory = this._adminService.getScoutCustomerOwnershipHistory(id)
          .pipe(catchError((err) => of(undefined)));
        const getCustomersAssets = this._adminService.getCustomersAssets(id)
          .pipe(catchError((err) => of(undefined)));
        const getCustomersUsers = this._adminService.getPagedCustomersUsers(id, null, null);//pagination
        const getRegions = this._customerService.getRegions();
        const getCustomerTypes = this._customerService.getCustomerTypes();
        const getAdminScout = this._adminService.getAdminScout(id).pipe(catchError((err) => of(undefined)));
        const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.customerId)
          .pipe(catchError((err) => of(undefined)));
        return forkJoin([
          getCustomerDetails,
          getCustomerHistory,
          getCustomersAssets,
          getRegions,
          getCustomerTypes,
          getAdminScout,
          getCustomersUsers,
          getNotes
        ]);
      })).subscribe(results => {
        const details = results[0];
        const history = results[1];
        const assets = results[2];
        const regions = results[3];
        const types = results[4];
        const scouts = results[5];
        const users = results[6];
        const notes = results[7];

        this.init_assets = assets;
        this.init_scouts = scouts;
        this.init_users = users;

        this.notes = notes;
        this.customer = details;
        this.customerInitState = this.customer;
        this.customerArray = history;
        this.title = `Customer: ${this.customer.basicName}`;
        this.toolTip = 'Find scouts, assets, users, and details regarding this customer. Click to refresh.'
        this.users = users;
        if (scouts == undefined) {
          this.scouts = [];
        } else {
          this.scouts = scouts;
        }
        this.adminAssetList = this.removeHealthSensors(assets);
        if (assets.length < 1) {
          this.hasAssets = false;
        }

        this.formatAdminAssetList();

        this.regions = regions;
        this.populateRegions(regions);
        this.types = types;
        this.populateCustomerTypes(types);
        this.loading = false;
      });
    this.searchSubscription = this.searchBarService.currentMessage.subscribe((message) => {
      this.message = message;
      if (message.length > 0) {
        const assets = this.removeHealthSensors(this.init_assets),
          users = this.init_users,
          scouts = this.init_scouts
        this.adminAssetList = this.searchBarService.filterArrayOnProperty(
          message,
          assets,
          "name"
        );
        this.formatAdminAssetList();
        this.users = this.searchBarService.filterArrayOnProperty(
          message,
          users,
          "name"
        );
        this.scouts = this.searchBarService.filterArrayOnProperty(
          message,
          scouts,
          "name"
        );
      } else {
        this.assets = this.init_assets;
        this.adminAssetList = this.removeHealthSensors(this.init_assets);
        this.users = this.init_users;
        this.scouts = this.init_scouts;
      }
    });
  }

  ngOnDestroy() {
    if (this.customerSubscription) {
      this.customerSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  removeHealthSensors(assets: AdminAsset[]) {
    let newAssets = [];

    if (assets != null) {
      for (let i = 0; i < assets.length; i++) {
        if (!assets[i].name.includes("_HS")) {
          newAssets.push(assets[i]);
        }
      }
    }

    return newAssets;
  }

  getAppScout(scout: any) {
    this.loading = true;
    this.customerSubscription = this._adminService.getAppScout(scout.id).subscribe(results => {
      if (results.length > 0) {
        scout.tree = results;
      }
      else {
        scout.noSensorsFound = true;
      }
      this.loading = false;
    })
  }

  getSensorData(sensor: any) {
    this.customerSubscription = this._appService.getAppSensorData(sensor.id).subscribe(results => {
      sensor.data = results;
    })
  }

  onNoClick() {
    this._router.navigate(['/customers']);
  }


  noteAdded($event) {
    if ($event) {
      this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.customerId).subscribe(result => {
        this.notes = result;
      })
    }
  }


  onUpdate(customer: Customer): void {
    console.log('initial customer: ' + this.customerInitState);
    console.log('edited customer: ' + this.customer);
    this.loading = true;
    // customer.createdAt = null;
    const patchCustomer: any = {
      basicDescription: customer.basicDescription,
      basicName: customer.basicName,
      regionId: customer.regionId,
      languageTag: customer.languageTag,
      contact_Address: customer.contact_Address,
      contact_Telephone: customer.contact_Telephone,
      contact_Email: customer.contact_Email,
      contact_Name: customer.contact_Name,
      customerTypeId: customer.customerTypeId
    };
    this.customerSubscription = this._customerService.updateCustomer(customer.id, patchCustomer).subscribe(update => {
      this.getCustomerData(this.customerId);
      // this._router.navigate(['/customers']);
    });
  }

  getCustomerData(id: string) {
    this.loading = true;
    const getCustomerDetails = this._customerService.getCustomerDetails(id);
    const getCustomerHistory = this._adminService.getScoutCustomerOwnershipHistory(id);
    const getCustomersAssets = this._adminService.getCustomersAssets(id);

    const getRegions = this._customerService.getRegions();
    const getCustomerTypes = this._customerService.getCustomerTypes();

    return forkJoin([
      getCustomerDetails,
      getCustomerHistory,
      getCustomersAssets,
      getRegions,
      getCustomerTypes
    ]).subscribe(results => {
      const details = results[0];
      const history = results[1];
      const assets = results[2];
      const regions = results[3];
      const types = results[4];

      this.customer = details;
      this.customerInitState = this.customer;
      this.customerArray = history;
      this.adminAssetList = this.removeHealthSensors(assets);
      if (assets.length < 1) {
        this.hasAssets = false;
      }
      this.formatAdminAssetList();

      this.regions = regions;
      this.populateRegions(regions);
      this.types = types;
      this.populateCustomerTypes(types);
      this.loading = false;
    })
  }

  navigate(command: string, object?: any) {
    switch (command) {
      case 'users':
        this._router.navigate([`/customers/${this.customerId}/users`]);
        break;
      case 'assets':
        this._router.navigate([`/customers/${this.customerId}/assets`]);
        break;
      case 'ownership':
        this._router.navigate([`/customers/${this.customerId}/ownership`]);
        break;
      case 'usersGroup':
        this._router.navigate([`/customers/${this.customerId}/usergroups`]);
        break;
      case 'back':
        this._router.navigate([`/customers`]);
        break;
      default:
        break;
    }
  }

  navigateToAssetDetails(id: string) {
    this._router.navigate([`/assets/${id}`]);
  }

  navigateToUserDetails(id: string) {
    this._router.navigate([`/customers/${this.customerId}/users/${id}`]);
  }

  deleteDialog(customer: Customer): void {
    console.log(customer);
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Delete ${customer.basicName}?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        this.customerSubscription = this._customerService.deleteCustomer(customer.id).subscribe(deleted => {
          console.log(deleted);
          if (!deleted) {
            // added table delete because full delete doesn't always work when defaults weren't setup
            this.customerSubscription = this._customerService.tableDeleteCustomer(customer.id).subscribe(d => {
              console.log(d);
              console.log('Table Delete used');
              this._router.navigate(['/customers']);
            });
          } else {
            this._router.navigate(['/customers']);
          }
        });
      }
    });
  }

  navigateToHistory() {
    this._router.navigate(['/customers/' + this.customerId + '/ownership']);
  }

  navigateCustomerOverview() {
    this._router.navigate(['/customers']);
  }

  public onChange = event => {
    this.selectEmitt.emit(event.value);
  }

  processAssets(assets: AssetOwnership[]): AssetOwnership[] {
    const usersAssets: AssetOwnership[] = [];
    for (let i = 0; i < assets.length; i++) {
      const a = assets[i];
      if (a.customerId.includes(this.customer.id)) {
        usersAssets.push(a);
      }
    }
    return usersAssets;
  }

  linkUsers(users: User[]): User[] {
    const customersUsers: User[] = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      if (user.customerId.includes(this.customer.id)) {
        customersUsers.push(user);
      }
    }
    return customersUsers;
  }

  // CRUD functions
  getCustomerDetails(id: string): Observable<Customer> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<Customer>(environment.apiUrl + `tables/Customer/${id}`, {
        params: params
      }).pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap(data => console.log('server data:', data)),); // debug
  }

  getCustomerRegion(regionId: string): Observable<Region> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<Region>(environment.apiUrl + `tables/Region/${regionId}`, {
        params: params
      }).pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('Region returned:', data)),); // debug
  }

  // get asset ownership for customer - this will list asset Id's



  getCustomerAssets(id: string) {
    console.log(id);
  }

  populateRegions(regions: Region[]): object[] {
    this.regions = [];
    for (let i = 0; i < regions.length; i++) {
      const r = regions[i];
      const region = { key: r.basicName, value: r.id };
      this.regions.push(region);
    }
    return this.regions;
  }

  populateCustomerTypes(types: CustomerType[]): object[] {
    this.types = [];
    for (let i = 0; i < types.length; i++) {
      const t = types[i];
      const type = { key: t.basicName, value: t.id };
      this.types.push(type);
    }
    return this.types;
  }

  // go through adminAssetList and find whether the asset is a 
  // scout or sensor, and then get the user assigned name
  formatAdminAssetList(): void {
    this.adminAssetList.forEach(a => {
      a.whenBegin = (new Date(a.whenBegin)).toLocaleString();
      a['asoWhenBegin'] = (new Date(a.currentAssetOwnership.whenBegin)).toLocaleString();

      if (a.currentAssetOwnership['sensorId'] == null) {
        if (a.currentAssetOwnership['scoutName']) {
          a['userAssetName'] = a.currentAssetOwnership['scoutName'];
        }
        else {
          a['userAssetName'] = a.name;
        }
      }
      else {
        if (a.currentAssetOwnership['sensorName']) {
          a['userAssetName'] = a.currentAssetOwnership['sensorName'];
        }
        else {
          a['userAssetName'] = a.name;
        }
      }
    });
  }
}
