
import {catchError, map, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from '..';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ExtraAssetInfo } from '../classes/extra-asset-info';
import { AssetOwnership } from '../interfaces/asset-ownership';
import { AssetStatus } from '../interfaces/asset-status';
import { AssetModel } from '../interfaces/asset-model';
import { AssetType } from '../interfaces/asset-type';
import { ScoutContent } from '../interfaces/scout-content';
import { Asset } from '../interfaces/asset';

@Injectable()
export class AssetsService {

  constructor(
    private _http: HttpClient,
    private _constService: ConstantService
    ) { }
   
parseContentField(scout: ScoutContent): number {
  if (scout.content !== null || scout.content !== undefined) {
    const parsed = scout.content.split(',');
    const converted = parseInt(parsed[0]);
    return converted;
  }
}

public getAssetTypes(): Observable<AssetType[]> {
    const params: any = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
    };
    return this._http
      .get<AssetType[]>(environment.apiUrl + 'tables/AssetType', {
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }
      
  public updateFirmwareModel(id: string, fwm: any): Observable<any> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/FirmwareModel/${id}`;
    return this._http.patch(URL, fwm, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update FirmwareModel Response:", data))
    ); // debug
  }

  public updateFirmwareInstance(id: string, fwi: any): Observable<any> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/FirmwareInstance/${id}`;
    return this._http.patch(URL, fwi, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update FirmwareInstance Response:", data))
    ); // debug
  }

  //FIRMWAREINSTANCE GET, POST, DELETE
  public getFirmwareInstance(entityId?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: entityId,
    };
    if (entityId == null) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
      };
    }
    // const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    const URL = environment.apiUrl + `tables/FirmwareInstance/${entityId}`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => res),
        catchError(this._constService.handleErrorObservable)
      );
  }

  //FIRMWAREINSTANCE GET, POST, DELETE
  public getAssetModelFirmwareModels(entityId?: string): Observable<any[]> {
    let params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: entityId,
    };
    if (entityId == null) {
      params = {
        "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
      };
    }
    // const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    const URL = environment.apiUrl + `tables/FirmwareInstance/${entityId}`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => res),
        catchError(this._constService.handleErrorObservable)
      );
  }

  public getAssetModelFirmwareModelsTable(entityId?: string): Observable<any[]> {
    let params = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion
    };
    // const URL = `${this._constService.CONST.URL}api/admin/firmwareinstance`;
    const URL = environment.apiUrl + `tables/FirmwareInstance`;
    return this._http
      .get<any[]>(URL, { params: params })
      .pipe(
        map((res: any) => res),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAppAssetModels(): Observable<AssetModel[]> {
    const params: any = {
      'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
    };
    return this._http
      .get<AssetModel[]>(environment.apiUrl + 'api/app/AssetModel', {
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }
    
      getAssetModels(): Observable<AssetModel[]> {
        const params: any = {
          'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
        };
        return this._http
          .get<AssetModel[]>(environment.apiUrl + 'tables/AssetModel', {
            params: params
          })
          .pipe(
            map((resp: any) => {
              return resp;
            }),
            catchError(this._constService.handleErrorObservable)
          );
      }
    
      getAssetStatus(): Observable<AssetStatus[]> {
        const params: any = {
          'ZUMO-API-VERSION': '2.0.0'
        };
        return this._http
          .get<AssetStatus[]>(environment.apiUrl + 'tables/AssetStatus', {
            params: params
          })
          .pipe(
            map((resp: any) => {
              return resp;
            }),
            tap(data => console.log('Asset Status retrieved: ', data))
          );
      }
    
      getAssetOwnerships(): Observable<AssetOwnership[]> {
        const params: any = {
          'ZUMO-API-VERSION': '2.0.0'
        };
        return this._http
          .get<AssetOwnership[]>(environment.apiUrl + 'tables/AssetOwnership', {
            params: params
          })
          .pipe(
            map((resp: any) => {
              return resp;
            }),
            tap(data => console.log('Asset Ownership retrieved: ', data))
          );
      }

      getAdminAssetByName(name: string): Observable<any> {
        let params: any = {
          "ZUMO-API-VERSION": "2.0.0",
          assetName: name
        };
        return this._http
          .get<any>(environment.apiUrl + `api/admin/asset/name`, { params: params })
          .pipe(
            map((resp: any) => {
              return resp;
              // return this.parseCustomers(resp);
            }),
            tap((data) => console.log("server data:", data))
          ); // debug
      }

//TODO: Add search functionality for matching the name field on assets - return array of all matching assets
//TODO: May need to rework tsconfig with new version of npm
      getAdminAsset(id: string): Observable<any> {
        let params: any = {
          "ZUMO-API-VERSION": "2.0.0",
          customerIds: id
        };
        if (id == undefined) {
          params = {
            "ZUMO-API-VERSION": "2.0.0",
            userauthname : id 
          };
        }
        return this._http
          .get<any>(environment.apiUrl + `api/admin/asset`, { params: params })
          .pipe(
            map((resp: any) => {
              return resp;
              // return this.parseCustomers(resp);
            }),
            tap((data) => console.log("server data:", data))
          ); // debug
      }

      getAssetById(id: string): Observable<ExtraAssetInfo[]> {
        const params: any = {
          'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
        };
        return this._http
          .get<ExtraAssetInfo[]>(environment.apiUrl + 'tables/Asset/' + id, {
            params: params
          })
          .pipe(
            map((resp: any) => {
              return resp;
            }),
            catchError(this._constService.handleErrorObservable)
          );
      }
    
      getAssets(): Observable<ExtraAssetInfo[]> {
        const params: any = {
          'ZUMO-API-VERSION': this._constService.CONST.ZUMOAPIVersion
        };
        return this._http
          .get<ExtraAssetInfo[]>(environment.apiUrl + 'tables/Asset', {
            params: params
          })
          .pipe(
            map((resp: any) => {
              return this.parseAssets(resp);
            }),
            catchError(this._constService.handleErrorObservable)
          );
      }

      // CRUD functions
  getAssetDetails(id: string): Observable<Asset> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<Asset>(environment.apiUrl + `tables/Asset/${id}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('Asset retrieved:', data))
      );
  }

  getAssetType(id: string): Observable<AssetType> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<AssetType>(environment.apiUrl + `tables/AssetType/${id}`, {
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('AssetType retrieved: ', data))
      );
  }

  getAssetOwnershipById(id: string): Observable<AssetOwnership> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<AssetOwnership>(environment.apiUrl + `tables/AssetOwnership/${id}`, {
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('Asset Ownership for id: ', data))
      );
  }

  getScoutTable(id: string): Observable<ScoutContent> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0'
    };
    return this._http
      .get<ScoutContent>(environment.apiUrl + `tables/Scout/${id}`, {
        params: params
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(data => console.log('Scout for id: ', data))
      );
  }

  tableDeleteAsset(assetId: string): Observable<boolean> {
    return this._http
      .delete(`${environment.apiUrl}tables/Asset/${assetId}`, {
        params: this._constService.defaultParams
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap(data => console.log('deleted asset:', data))
      );
  }

  updateAsset(id: string, asset: any): Observable<Asset> {
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0',
      'Content-Type': 'application/json'
    };
    const URL = environment.apiUrl + `tables/Asset/${id}`;
    return this._http.patch(URL, asset, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap(data => console.log('Update Asset Response:', data))
    ); // debug
  }

  updateAdminAsset(id: string, entity: any){
    const params: any = {
      'ZUMO-API-VERSION': '2.0.0',
      "Content-Type": "application/json"
    };
    const URL = environment.apiUrl + `api/admin/asset`;
    return this._http.post(URL, entity, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap(data => console.log('Update Asset Response:', data))
    ); // debug
  }
  
      createAsset(asset: any) {
        delete asset.assetStatusId;
        // delete asset.id;
        const params: any = {
          'ZUMO-API-VERSION': '2.0.0'
        };
          return this._http
          .post(environment.apiUrl + 'tables/Asset', asset, { params: params }, ).pipe(
          map((resp: any) => {
            return resp;
          }),
          tap(data => console.log('Asset created:', data)), );
      }
    
      parseAssets(data): ExtraAssetInfo[] {
        const assets: ExtraAssetInfo[] = [];
        for (let i = 0; i < data.length; i++) {
          const a = data[i];
          const asset: ExtraAssetInfo = <ExtraAssetInfo>{
            assetModelId: a.assetModelId,
            assetStatuslId: a.assetStatuslId,
            whenBegin: a.whenBegin,
            whenEnd: a.whenEnd,
            currentAssetRevisionId: a.currentAssetRevisionId,
            username: a.username,
            code: a.code,
            basicName: a.basicName,
            basicDescription: a.basicDescription,
            content: a.content,
            isAvailable: a.isAvailable,
            id: a.id,
            version: a.version,
            createdAt: a.createdAt,
            updatedAt: a.updatedAt,
            deleted: a.deleted,
            assetStatus: a.assetStatus
          };
          assets.push(asset);
        }
        return assets;
      }

    
}

