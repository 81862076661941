<div mat-dialog-content>
  <h3 class="text label-header-text">
    Groups Assigned to {{user.name}}
  </h3>
  <p class="text small-text">
    You can assign more to {{user.authUsername}} by clicking off and using the Assign Group button.
  </p>
  <hr>
  <p *ngIf="user.userGroups === null || user.userGroups.length === 0">
    <a (click)="assign()" matTooltip="Assign groups to this user" matTooltipPosition="below">
      No Groups Assigned to this user
    </a>
  </p>
  <div>
    <div>
      <div class="col-12">
        <div *ngFor="let group of user.userGroups">
          <p *ngIf="user.userGroups.length > 0; else noGroups" class="text label-text">
            <a (click)="details(group)" matTooltip="Navigate to this group's details" matTooltipPosition="below">
              {{
              group.name
              }}
            </a>
          </p>
        </div>
      </div>
      <hr />
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" (click)="onNoClick()" color="primary">Okay</button>
</div>