<roots-page-header
  [toolTip]="_config.toolTip"
  [title]="_config.title"
  [loading]="_config.loading"
  [items]="actionButtons"
  (refresh)="refresh($event)"
  (actionListClicked)="topActionsClicked($event)"
>
</roots-page-header>
<mat-date-range-input style="display: none;" [rangePicker]="picker">
  <input matStartDate [value]="start" placeholder="From Date">
  <input matEndDate [value]="end" placeholder="To Date">
</mat-date-range-input>
<mat-datepicker-toggle style="display: none;" matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-date-range-picker #picker></mat-date-range-picker>
<!-- <input style="display: none;" [value]="start" matInput [matDatepicker]="picker" placeholder="Choose a date">
<mat-datepicker-toggle style="display: none;" matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker> -->
<roots-table
  [tableColumns]="_config.displayedColumns"
  [dataSource]="_config.dataSource"
  [filter]="_config.message"
  [csv]="_config.title"
  [items]="items"
  (actionsClicked)="actionsClicked($event)"
  [clickingEnabled]="true">
</roots-table>