import { Injectable } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Injectable()
export class FormBuilderService {

  public emailRegex: any = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  public passRegex: any = '^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$';
  public numberRegex: any = '';

  constructor() { }

// create field validators that return valid formats for user data

  usernameValidator(): FormControl {
    const form = new FormControl('',
      Validators.compose([
      // UsernameValidator.validUsername,
      Validators.maxLength(25),
      Validators.minLength(5),
      Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
      Validators.required
    ]));
    return form;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPass.value;

    return pass === confirmPass ? null : {notSame : true};
  }


}

export class UsernameValidator {
  static validUsername(control: FormControl) {
    if (control.value.toLowerCase()) {
      return ({validUsername: true});
    } else {
      return (null);
    }
  }
}
