import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoutLogService } from '../../../../../shared/services/scout-log.service';
import { ConstantService } from '../../../../../shared';

@Component({
  selector: 'app-create-test-sensor-dialog',
  templateUrl: './create-test-sensor-dialog.component.html'
})
export class CreateTestSensorDialogComponent {
  event: EventEmitter<any> = new EventEmitter();
  loading = false;
  testSensor = {
    name: ''
  }
  sensorPrefix = [
    '014AcclimaTR315L3.',
    '014AcclimaTR315H2.',
    '014ApogeeSP-4212.',
    '014ApogeeSQ-5212.',
    '014ApogeeSO-4212.'
  ]
  selected: any;
  constructor(
    private _scoutLogService: ScoutLogService,
    private _constService: ConstantService,
    public dialogRef: MatDialogRef<CreateTestSensorDialogComponent>
  ) { }

  okayClick(): void {
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onChange(prefix) {
    this.selected = prefix;
    this.testSensor.name = this.selected;
  }

  onSubmit(): void {
    let firmware;
    firmware = JSON.stringify(this.testSensor);
    this.event.emit({data: this.testSensor});
    // this._adminService.postFirmwareModels(firmware).subscribe(c => {
    //   console.log('firmware has been created succesfully: ' + c);
    //   this.event.emit({})
    // });
    this._scoutLogService.postTestSensor(this.testSensor.name)
    this.dialogRef.close();
  }
}
