import { catchError, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConstantService } from "..";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { AuditLogs } from "../../modules/reports/general/generalreport.component";
import { ScoutLogs } from "../interfaces/scout-logs";
import { SensorLogs } from "../classes/sensorlog";
import { PacketLogs } from "../classes/packetlogs";

@Injectable()
export class ReportsService {
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService
  ) {}

  getAdminReportScoutLogs(from: string, to: string): Observable<ScoutLogs[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<ScoutLogs[]>(
        environment.apiUrl + "api/admin/report/scoutlogs",
        {
          FromDate: from,
          ToDate: to,
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminScoutReliability(from: string, to: string) {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<ScoutLogs[]>(
        environment.apiUrl + "api/admin/report/scoutpacketreliability",
        {
          StartDate: from,
          EndDate: to,
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminScoutHealth(hasGeo?: boolean, minVolts?: number, maxVolts?: number) {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<ScoutLogs[]>(
        environment.apiUrl + "api/admin/report/scouthealthlogs",
        {
          hasGeoLocation: hasGeo,
          minimumBatteryVoltage: minVolts,
          maximumBatteryVoltage: maxVolts,
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminReportSensorLogs(): Observable<SensorLogs[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<SensorLogs[]>(
        environment.apiUrl + "api/admin/report/sensors",
        {
          // 'FromDate': from,
          // 'ToDate': to
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getSensors() {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      userId: this._constService.CONST.userId,
    };
    return this._http
      .get(environment.apiUrl + "tables/Sensor", { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }
  

  getAdminPacketLogDetails(
    asset: string,
    from: string,
    to: string
    ): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<any[]>(
        environment.apiUrl + "api/admin/report/packetlogdetails",
        {
          // AssetFilter: asset,
          StartDate: from,
          EndDate: to,
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminPacketsReport(date: string): Observable<PacketLogs[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post<PacketLogs[]>(
        environment.apiUrl + "api/admin/report/packetlogs",
        {
          CutOffDate: date,
        },
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminReportAuditLogs(
    from: string,
    to: string,
    id?: string
  ): Observable<AuditLogs[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    let body: any = {
      FromDate: from,
      ToDate: to,
    };
    if (id) {
      body = {
        FromDate: from,
        ToDate: to,
        customerIds: id,
      };
    }
    return this._http
      .post<AuditLogs[]>(
        environment.apiUrl + "api/admin/report/auditlogs",
        body,
        {
          params: params,
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }
}
