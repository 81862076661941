import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { AlertsRoutingModule } from './alerts-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { MatModule } from '../../core';

@NgModule({
  imports: [
    CommonModule,
    AlertsRoutingModule,
    SharedModule,
    MatModule
  ],
  declarations: [
    AlertsComponent
  ]
})
export class AlertsModule { }