import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'app-items-list-dialog',
  templateUrl: './items-list-dialog.component.html'
})
export class ItemsListDialogComponent {
  _items: any[];
  property_1 = 'name';
  property_2 = 'code';
  property_3 = 'description';
  title = 'Firmware Instances';
  event: EventEmitter<any> = new EventEmitter();
  selectedAsset: any;
  constructor(
    public dialogRef: MatDialogRef<ItemsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public items: any,
    public _dialog: MatDialog,
    private _router: Router
    ) {
      this._items = items;
  }

  navigateToItem(item) {
    console.log(item)
  }

  okayClick(): void {
    // if not the unit that you're looking for, suggest rename
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
